import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Alert, Input, Select, Spin } from 'antd';
import { Page, Breadcrumb } from '../../components';
import './styles.scss';
import ModifiersActions from '../../redux/middleware/modifiers';
// import CategoryActions from '../../redux/middleware/category';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import { checkAuthentication } from '../../helper/checkAuthentication';

const ModifierWizard = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const [visible, setVisible] = useState(false);
  const [modifier, setModifier] = React.useState('');
  const { state } = useLocation();
  const { update, id } = state;
  const [loader, setLoader] = useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  // const [consumption, setconsumption] = React.useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [menuCategory, setMenuCategory] = React.useState<any>([]);
  // const [, setConsumptionCategory] = React.useState<any>([]);
  const fetchModifiersType = async () => {
    const menuCategory = await MenuCategoryActions.getParentCategories();
    if (menuCategory?.success) {
      const filterValue = menuCategory?.data?.find(
        (x: any) => x?.slug === 'menu'
      )?.id;
      // const ModifiersType: any = await ModifiersActions.getModifiersType();
      // setConsumptionCategory(ModifiersType.data.items);
      const menucategory: any = await ModifiersActions.getMenuCategory(
        filterValue
      );
      setMenuCategory(
        menucategory.data.items.map((item: any) => ({
          label: item?.sub_category,
          value: item?.sub_category,
          key: item?.id,
          maxVal: 10,
          minVal: 1,
        }))
      );
    }
  };
  useEffect(() => {
    checkAuthentication(user, navigate);
    fetchModifiersType();
    if (update) {
      setLoader(true);
      fetchDataById();
    }
  }, []);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/modifiers');
    }
  }, [currentBrand, currentShop]);

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const handleModifier = (data: string) => {
    setModifier(data);
  };
  // const handelChangeConsumption = (data: any) => {
  //   let dataArray: any = consumptionCategory.filter((val: any) => {
  //     return val.id === data;
  //   });
  //   setconsumption(dataArray);
  // };
  const handelChangeCategory = (value: any) => {
    const detail: any = value.map((data: any) => {
      return menuCategory.filter((id: any) => {
        if (id.value == data) {
          return id;
        }
      });
    });
    const filteredBrands = detail.flat();
    // let getCategory: any = menuCategory.find((val: any) => val?.id == data);
    setCategory(filteredBrands);
  };

  const handleBack = () => {
    navigate('/app/modifiers');
  };

  const fetchDataById = async () => {
    const data = await ModifiersActions.getModifiersById(id);
    setModifier(data?.data?.items[0]?.modifier_name);
    setCategory(
      data?.data?.items[0]?.menu_category?.map((obj: any) => ({
        label: obj?.menu_category,
        value: obj?.menu_category,
        key: obj?.menu_category_id,
        maxVal: obj?.max_quantity,
        minVal: obj?.min_quantity,
      }))
    );

    setLoader(false);
  };

  const onChangeCategoryValue = (value: any, index: any, type: string) => {
    let data = [...category];
    data[index][type] = parseInt(value);
    setCategory([...data]);
  };

  const handleContinue = async () => {
    const user = Utils.getCurrentUser();
    setErrorList({});
    setErrorStatus(false);
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const company = await Utils.getCurrentCompany();
    if (
      modifier === '' ||
      (category &&
        Object.keys(category).length === 0 &&
        Object.getPrototypeOf(category) === Object.prototype)
    ) {
      setVisible(true);
    } else if (user) {
      const invalidCategory = category?.some(
        (data: any) => data.minVal == null || data.maxVal == null
      );
      if (invalidCategory) {
        setErrorStatus(true);
        return;
      }
      setVisible(false);
      const param = {
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        modifier_name: modifier,
        modifier_type_id: 1,
        modifier_type: 'Add',
        // menu_category: category?.sub_category,
        menu_category: category?.map((data: any) => ({
          id: data.key,
          min_quantity: data.minVal,
          max_quantity: data.maxVal,
        })),
        created_by: 2,
      };
      if (update) {
        const response = await ModifiersActions.updateModifier(id, param);
        if (!response?.success) {
          if (response?.success != undefined) {
            setErrorList(response?.error);
            setErrorStatus(true);
          }
        } else navigate('/app/modifiers');
      } else {
        const response = await ModifiersActions.createModifiers(param);
        if (!response?.success) {
          if (response?.success != undefined) {
            setErrorList(response?.error);
            setErrorStatus(true);
          }
        } else navigate('/app/modifiers');
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Modifier Group"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/modifiers');
        }}
      />
      <Page title="Add New Modifier">
        <div className="modifier-alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div>

        {loader ? (
          <Spin
            spinning={loader}
            size="default"
            className="loadIngredient recipeBuilderloaderHeight"
          />
        ) : (
          <div className="wizardContainer">
            <div className="wizardSubContainer modifier-subcontainer-margin">
              <div className="wizardLabel">
                Modifier Group Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Modifier name"
                size="large"
                value={modifier}
                onChange={(e) => handleModifier(e.target.value)}
                required
                className={
                  errorList['modifier_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['modifier_name']}
                </div>
              )}
            </div>

            {/* <div className="wizardDropdown">
              <div className="wizardLabel">
                Consumption Type <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select consumption type'}
                onChange={handelChangeConsumption}
                style={{ width: 560 }}
                value={consumptionCategory?.name}
              >
                {consumptionCategory?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </div> */}
            <div className="wizardDropdown spanWidth">
              <div className="wizardLabel">
                Category <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                mode="multiple"
                placeholder={'Select Category'}
                onChange={handelChangeCategory}
                style={{ width: 560 }}
                className={
                  errorList['menu_category_id']?.length > 0
                    ? 'createIngredientBorderRed'
                    : ''
                }
                options={menuCategory}
                value={category}
              >
                {/* {menuCategory?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data?.sub_category}
                  </Select.Option>
                ))} */}
              </Select>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['menu_category_id']}
                </div>
              )}
            </div>
            {category.length > 0 && (
              <>
                <div className="wizardCategoryLabelContainer">
                  <div className="categoryNameLabel">
                    Category Name <span className="sterik">*</span>
                  </div>
                  <div className="minValueLabel">
                    Min Value <span className="sterik">*</span>
                  </div>
                  <div className="maxValueLabel">
                    Max Value <span className="sterik">*</span>
                  </div>
                </div>
                {category.map((data: any, index: number) => (
                  <div className="inputFieldContainer" key={index}>
                    <div className="categoryNameInput">
                      <Input
                        placeholder="Enter Category Name"
                        size="large"
                        value={data?.label}
                        disabled
                      />
                    </div>
                    <div className="minValueInput">
                      <Input
                        placeholder="Enter Min Value"
                        size="large"
                        value={data?.minVal}
                        type="number"
                        onChange={(e) =>
                          onChangeCategoryValue(e.target.value, index, 'minVal')
                        }
                      />
                    </div>
                    <div className="maxValueInput">
                      <Input
                        placeholder="Enter Max Value"
                        size="large"
                        value={data?.maxVal}
                        type="number"
                        onChange={(e) =>
                          onChangeCategoryValue(e.target.value, index, 'maxVal')
                        }
                      />
                    </div>
                    {errorStatus && (
                      <div className="subRecipeErrorMessages modifier-error">
                        {'Min/Max quantity must be at least 1'}
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        )}
      </Page>
    </React.Fragment>
  );
};

export default ModifierWizard;
