/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Spin, Tooltip, Input, Select } from 'antd';
import SupplierActions from '../../redux/middleware/supplier';
import IngredientActions from '../../redux/middleware/ingredient';
import SupplierApi from '../../redux/middleware/supplier';
import ResponseDialog from '../ResopnseDialog';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import './styles.scss';

type Props = {
  handleCancel: any;
  onSubmit?: any;
  open: boolean;
  component?: string;
  supplierModalData?: any;
};

const SupplierModal = (props: Props) => {
  const shop = Utils.getCurrentShop();
  let { handleCancel, onSubmit, open, supplierModalData } = props;
  const [loader, setLoader] = useState(false);
  const [totalCost, setTotalCost] = useState<any>();
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierId, setSupplierId] = useState<any>(null);
  const [buyingUnitDefault, setBuyingUnitDefault] = useState<any>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const { currentShop } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentShop?.shop_id);

  const { t } = useTranslation('translation', {
    keyPrefix: 'buyingUnitPage',
  });

  useEffect(() => {
    getSupplier();
  }, [currentBrand, currentShop]);

  const getSupplier = async () => {
    const supplierData = await SupplierActions.getSupplierOfShop(
      500,
      '',
      shop?.shop_id
    );
    if (supplierData?.success) {
      setSuppliers(
        supplierData?.data?.items?.map((data: any) => {
          return {
            label: data?.supplier_name,
            value: data?.id,
          };
        })
      );
    }
  };

  const fetchBuyingUnits = async (supplierId: number) => {
    const response = await SupplierApi.getBuyingUnits(supplierId);
    if (response?.success) {
      if (Array.isArray(response?.data?.items)) {
        const matchingUnit = response?.data?.items.find(
          (unit: any) => unit?.buying_unit == supplierModalData?.storage_unit
        );
        if (matchingUnit) {
          setBuyingUnitDefault(matchingUnit?.id);
        }
      }
    }
  };

  const handleSubmit = async () => {
    const shop = await Utils.getCurrentShop();
    setLoader(true);
    setErrorList({});
    setErrorStatus(false);
    const payload = {
      shop_id: shop?.shop_id ? shop?.shop_id : 0,
      supplier_id: supplierId,
      buying_unit_id: buyingUnitDefault,
      buying_factor: 1,
      total_cost: totalCost,
      is_default: 1,
    };
    try {
      setLoader(true);
      const response = await IngredientActions.addSuplierToInventory(
        payload,
        supplierModalData?.sku_id
      );
      setLoader(false);
      if (response?.success) {
        onSubmit();
      } else if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      }
    } catch (e) {
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: ['Validation Failed!', 'Something Went Wrong'],
      };
      ResponseDialog(responseDialogData);
    }
  };

  return (
    <Modal
      title={<div className="title font">Tag Supplier</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <Row>
          <Col lg={24} md={24}>
            <div className="ingredientCategoryContainerWidth">
              <div className="wizardSubContainer">
                <div className="wizardLabel buying-unit-modal">
                  Ingredient Name
                </div>
                <Input
                  placeholder="Ingredient Name"
                  size={'large'}
                  style={{ width: '100%' }}
                  disabled={true}
                  value={supplierModalData?.ingredient_name}
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['total_cost']}
                  </div>
                )}
              </div>
              <div className="wizardSubContainer">
                <div className="wizardLabel">
                  {t('supplier_label')} <span className="sterik">*</span>
                </div>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('select_placeholder')}
                  onChange={(e) => {
                    setSupplierId(e);
                    fetchBuyingUnits(e);
                  }}
                  value={supplierId}
                  options={suppliers}
                />
              </div>
              <div className="wizardSubContainer">
                <div className="wizardLabel buying-unit-modal">
                  Total Cost <span className="sterik">*</span>
                </div>
                <Input
                  className={
                    errorList['total_cost']?.length > 0 ? 'borderRed' : ''
                  }
                  placeholder={`Enter 1 ${supplierModalData?.storage_unit} Cost`}
                  size={'large'}
                  style={{ width: '100%' }}
                  onChange={(e) => setTotalCost(e.target.value)}
                  value={totalCost}
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['total_cost']}
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <div className="butonGroup supplier-modal">
          <div className="wizard-button" onClick={() => handleCancel(false)}>
            Cancel
          </div>
          <div className="continue wizard-button" onClick={handleSubmit}>
            Submit
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default SupplierModal;
