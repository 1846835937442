/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  Stepper,
  ResponseDialog,
  SustainabilityStepOne,
  SustainabilityStepTwo,
} from '../../components';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Attributes from '../../redux/middleware/attributes';
import { Alert, Spin } from 'antd';
import { decimalCount, handleFloat } from '../../helper/sortData';
import { useSelector } from 'react-redux';
import './styles.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type Database = {
  value: any;
  label: any;
  key: any;
};

type category = {
  category_type: string;
  created_at: string;
  id: number;
  parent_category: string;
  slug: string;
  status: number;
  updated_at: string;
};

const SustainabilityWizard = () => {
  const user = Utils.getCurrentUser();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { update, id } = state;
  const company = Utils.getCurrentCompany();
  const [errorList, setErrorList] = useState<any>({});
  const [allergenData, setAlergenData] = useState<any[]>();
  const [database, setDatabase] = useState<Database>({
    value: 2,
    label: 'My Market List',
    key: 3,
  });
  const [allergensByDefault, setAllergensByDefault] = useState<any>([]);

  //Step 1
  const [loader, setLoader] = useState(false);
  const [hide, setHide] = useState(false);
  const [SubRecipeName, setSubRecipeName] = React.useState('');
  const [category, setCategory] = React.useState<any>();
  const [unit, setUnit] = React.useState<any>({});
  const [yeild, setYeild] = React.useState('');
  const [costRatio, setCostRatio] = React.useState(0);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [price, setPrice] = React.useState(0);
  const [waste, setWaste] = React.useState('');
  const [finalPrice, setFinalPrice] = React.useState('');
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [subCategory, setSubCategory] = useState<any>({
    sub_category: '',
    id: -1,
  });
  const [ingredient, setIngredient] = useState<any[]>([
    {
      ingredient_name: '',
      quantity: 0,
      unit_cost: '',
      waste: '',
      small_unit: 'g',
      total_price: 0,
    },
  ]);
  const [blockSustain, setBlockSustain] = useState(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [alerts, setAlerts] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [toggle, setToggle] = useState(-1);
  const [imageObject, setImageObject] = useState({});
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [steps, setSteps] = useState(['']);
  const [menu, setMenu] = React.useState<category>({
    category_type: 'general',
    created_at: '2023-04-05T15:35:27.000000Z',
    id: 2,
    parent_category: 'Sub Recipe Category',
    slug: 'sub_recipe',
    status: 1,
    updated_at: '2023-04-05T15:35:34.000000Z',
  });
  const [kitchenSection, setKitchenSection] = useState('');
  const [sustainability, setSustainability] = useState(false);
  const [productionCategoryList, setProductionCategoryList] = useState<any[]>(
    []
  );
  const [ingredientCategoryList, setIngredientCategoryList] = useState<any[]>(
    []
  );
  const [productionCategory, setProductionCategory] = useState<any>({});
  const [parentCategory, setParentCategory] = useState<any[]>([]);
  const [parentSubCategory, setParentSubCategory] = useState([]);
  const [prep, setPrep] = useState('');
  const [calory, setCalory] = useState<any>();
  const [carb, setcarb] = useState<any>();
  const [fat, setFat] = useState<any>();
  const [protein, setProtein] = useState<any>();
  const [allergens, setAllergens] = useState<any[]>([]);
  const [additonal_info, setAdditonal_info] = useState<any[]>([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [stepDeleteDialog, setStepDeleteDialog] = useState(false);
  const [recipeNote, setRecipeNote] = useState('');
  const [visible, setVisible] = useState(false);
  const [reuseWastePercent, setReuseWastePercent] = useState<any>();
  const [reuseWasteValue, setReuseWasteValue] = useState<any>();
  const [compostableWastePercent, setCompostableWastePercent] = useState<any>();
  const [compostableWasteValue, setCompostableWasteValue] = useState<any>();
  const [ingredientCategory, setingredientCategory] = useState<any>(null);
  const [ingredientUnit, setingredientUnit] = useState<any>({});
  const [ingredientName, setingredientName] = useState<any>('');
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId, setCurrentBrandId] = useState(currentBrand?.brand_id);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/subrecipe');
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    setIngredient([
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: '',
        multiplier: 0,
      },
    ]);
    setSubCategory({
      sub_category: '',
      id: -1,
    });
    setProductionCategory({});
    if (currentBrand?.brand_id !== currentBrandId) {
      setCurrentBrandId(currentBrand?.brand_id);
      fetchParentCategories();
    }
  }, [currentBrand]);

  const handleRecipe = (data: string) => {
    setSubRecipeName(data);
  };

  const handleUnit = (data: any) => {
    setUnit(data[0]);
  };
  const handleYeild = (data: any) => {
    setYeild(data);
  };

  const handleProposedPrice = (ratio: any) => {
    setPrice((totalPrice * 100) / ratio);
  };

  const handleChangeDatabase = (value: string | string[], data: any) => {
    setDatabase(data);
  };

  const handleSubCategory = (data: any) => {
    setSubCategory(data);
  };

  const handleCostRatio = (data: any) => {
    if (+data <= 100) {
      setCostRatio(data);
      data == '' ? setPrice(0) : handleProposedPrice(+data);
    }
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };
  const handleWaste = (data: any) => {
    if (+data <= 100) setWaste(data);
  };

  const handleWastePercent = (data: any) => {
    if (data == 0) {
      setCompostableWastePercent(data);
      setCompostableWasteValue(0);
      setReuseWastePercent(0);
      setReuseWasteValue(0);
    }
    if (+data <= 100 && decimalCount(+data) <= 2) {
      const wasteQuantity = (ingredient[0]?.quantity / 100) * Number(waste);
      const wasteReusePer = 100 - Number(data);

      setCompostableWastePercent(data);
      setReuseWastePercent(wasteReusePer);
      setReuseWasteValue((wasteQuantity / 100) * wasteReusePer);
      setCompostableWasteValue((wasteQuantity / 100) * Number(data));
    }
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (key == 'quantity' && data != '')
      dataArray[index]['total_price'] = Number(
        (
          (dataArray[index]['unit_cost'] / dataArray[index]['divided_by']) *
          +data
        ).toFixed(2)
      );
    else
      dataArray[index]['total_price'] = Number(
        (
          dataArray[index]['unit_cost'] / dataArray[index]['divided_by']
        ).toFixed(2)
      );

    return dataArray;
  };

  const handleEmptyIngredient = () => {
    setIngredient([
      {
        id: -1,
        ingredient_name: '',
        quantity: 0,
        unit_cost: 0,
        waste_ingredient: '',
        small_unit: 'g',
        total_price: 0,
      },
    ]);
    setYeild('');
    setWaste('');
    setTotalPrice(0);
  };

  const handleIngredient = (data: any, index: number, key: any) => {
    let dataArray = ingredient;
    if (key == 'quantity')
      dataArray = handleCostIngredient(dataArray, data, key, index);
    key == 'quantity' && handleTotalPrice(dataArray);
    dataArray[index][key] = data;

    setIngredient([...dataArray]);
  };

  const autoFetchAllergen = (dataSource: any) => {
    let arr = dataSource.map((data: any) => {
      if (data?.ingredient_name != '' || +data?.unit_cost != 0)
        return data.allergens_id;
    });
    arr = arr
      ?.filter(function (element: any) {
        return element !== undefined;
      })
      ?.filter((data: any) => data !== null);
    arr = arr.map((data: any) => data.split(',')).flat();
    let allergenId = [...new Set(arr)];
    const FetchAllergen = allergenId.map((data) => {
      let fetchObject = allergenData?.filter((element) => element?.id == data);
      return fetchObject;
    });
    setAllergensByDefault(FetchAllergen.flat());
    setAllergens(FetchAllergen.flat());
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map((data) => data?.total_price);
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(price);
  };

  const handleIngredientObject = (data: any, index: number) => {
    let dataArray = ingredient;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredient(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setAlerts(true);
      setVisible(false);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredient([...dataArray]);
    } else {
      dataArray[index] = {
        ...data,
        quantity: 0,
        total_price: 0,
      };
      handleTotalPrice(dataArray);
      autoFetchAllergen(dataArray);
      setIngredient([...dataArray]);
    }
  };

  const calculateNutritionValues = () => {
    let c_carb = 0;
    let c_fat = 0;
    let c_protein = 0;
    let c_calorie = 0;

    for (let index = 0; index < ingredient.length; index += 1) {
      const { quantity } = ingredient[index];
      const {
        protein_per_unit,
        carb_per_unit,
        calories_per_unit,
        fat_per_unit,
      } = ingredient[index];

      c_carb += carb_per_unit * parseFloat(quantity);
      c_fat += fat_per_unit * parseFloat(quantity);
      c_protein += protein_per_unit * parseFloat(quantity);
      c_calorie += calories_per_unit * parseFloat(quantity);
    }

    setcarb(c_carb);
    setProtein(c_protein);
    setFat(c_fat);
    setCalory(c_calorie);
  };

  const handleContinue = () => {
    if (stepper < 3) handleNextStepper(stepper + 1);
    if (stepper == 0) calculateNutritionValues();
  };

  const deleteIngredient = (index: number) => {
    let DataArray = ingredient;
    DataArray.splice(index, 1);
    handleTotalPrice(DataArray);
    setIngredient([...DataArray]);
    autoFetchAllergen(DataArray);
  };

  const addIngredient = () => {
    setIngredient((prev) => [
      ...prev,
      {
        id: -1,
        ingredient_name: '',
        quantity: 0,
        unit_cost: 0,
        waste_ingredient: '',
        small_unit: 'g',
        total_price: 0,
      },
    ]);
  };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      // const filterValue = data?.find((x: any) => x.slug === 'sub_recipe');
      const categoryData = data?.filter(
        (x: any) =>
          x?.slug === 'prep_category' || x?.slug === 'ingredient_category'
      );
      setLoader(true);
      // setParentCategory(menu?.id);
      setParentCategory(categoryData);
      categoryData?.map(async (categoryObj: any) => {
        const subCategories =
          await MenuCategoryActions.getSubCategoriesForWizard(
            categoryObj?.id,
            100
          );
        if (categoryObj?.slug == 'prep_category')
          setProductionCategoryList(subCategories?.data?.items);
        else setIngredientCategoryList(subCategories?.data?.items);
      });

      // setCategoryData(subCategories?.data?.items);
      setLoader(false);

      // setSustainability(filterDataForCheckbox);

      // setParentCategory(filterValue?.id);
    }
  };

  const onSubmitKitchenSection = async (name: string) => {
    const parentCategoryId = parentCategory?.find(
      (category: any) => category?.slug == 'prep_category'
    )?.id;
    const brand = await Utils.getCurrentBrand();

    setErrorList({});
    setErrorStatus(false);
    const param = {
      company_id: company?.id,
      sub_category: name,
      brand_id: brand?.brand_id,
      parent_category_id: parentCategoryId || 0,
    };

    const response = await MenuCategoryActions.addSubCategories(param);
    if (!response?.success) {
      setErrorList(response?.error);
      setErrorStatus(true);
    } else {
      fetchParentCategories();
      setProductionCategory({
        value: name,
        label: name,
      });
    }
  };

  const handleProductionCategory = (data: any) => {
    setProductionCategory({ label: data?.sub_category, value: data?.id });
  };

  const handleSustainability = (checked: any) => {
    setSustainability(checked);
  };

  const handleIngredientCategory = (data: any) => {
    setingredientCategory(data);
  };

  const handleIngredientUnit = (data: any) => {
    setingredientUnit(data);
  };

  const handleIngredientName = (data: any) => {
    setingredientName(data);
  };

  //Step 2

  const handleIndex = (data: number) => setStepIndex(data);
  const fetchDataById = async (id: any) => {
    const data = await SubRecipeActions.getDetailRecipe(id);

    if (data) {
      setLoader(false);
    }
    setImage(data?.data.general.thumbnail);
    setUnit({
      unit: data?.data.general.unit,
      id: data?.data.general.unit_id,
    });
    setSubRecipeName(data?.data.general.recipe_name);
    setDescription(data?.data.general.description);

    setIngredient(
      data?.data.ingredients.map((data: any) => {
        return {
          ...data,
          id: data?.ingredient_id,
          small_unit: data?.unit,
          waste_ingredient: data?.waste,
          total_price: data?.cost_per_ingredient
            ? data?.cost_per_ingredient
            : 0,
        };
      })
    );
    setSubCategory({
      id: data?.data?.general?.menu_category_id,
      sub_category: data?.data?.general?.menu_category,
    });
    setAllergens(data?.data.general.allergens);
    setYeild(data?.data.general.quantity);
    setWaste(data?.data.general.waste);
    setCostRatio(data?.data.general.fcr);
    setFinalPrice(data?.data.general.final_price);
    setSteps(data?.data.general.steps);
    setCalory(data?.data.general.calorie);
    setFat(data?.data.general.fat);
    setcarb(data?.data.general.carb);
    setProtein(data?.data.general.protein);
    setPrep(data?.data.general.prep_time);
    setRecipeNote(data?.data.general.instruction);
    setAdditonal_info(data?.data.general.additional_attributes);
    setTotalPrice(data?.data?.general?.total_cost);
    setPrice(data?.data?.general?.propose_price);
    setProductionCategory({
      value: data?.data?.general?.kitchen_category_id,
      label: data?.data?.general?.kitchen_category,
    });
    setSustainability(data?.data?.general?.is_sustainable ? true : false);
    setBlockSustain(data?.data?.general?.is_sustainable ? true : false);
    if (data?.data?.general?.is_sustainable) {
      setingredientName(data?.data?.sustainable?.ingredient_name);
      setingredientCategory(data?.data?.sustainable?.ingredient_category_id);
      // handleWastePercent(data?.data?.sustainable?.waste);
      setCompostableWastePercent(data?.data?.sustainable?.waste);
    }
  };
  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
    fetchParentCategories();
    fetchAllergen();
  }, []);

  useEffect(() => {
    costRatio != 0 && handleProposedPrice(costRatio);
  }, [ingredient]);

  useEffect(() => {
    if (ingredient[0]?.sku) {
      handleWastePercent(compostableWastePercent);
    }
  }, [ingredient, compostableWastePercent, waste, yeild]);

  // useEffect(() => {
  //   if (parentCategory) {
  //     fetchSubCategories();
  //   }
  // }, [parentCategory]);

  // const fetchSubCategories = async () => {
  //   const subCategories = await MenuCategoryActions.getSubCategoriesForWizard(
  //     parentCategory,
  //     100
  //   );
  //   const categories = subCategories?.data?.items.map((x: any) => {
  //     return {
  //       value: x.id,
  //       label: x.sub_category,
  //       key: x.id,
  //     };
  //   });
  //   setParentSubCategory(categories);
  // };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject({});
    }
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const addSteps = () => {
    setSteps((prev) => [...prev, '']);
  };

  const handlePrep = (data: string) => {
    setPrep(data);
  };

  const handleChangeStep = (text: any, index: number) => {
    let DataArray = steps;
    DataArray[index] = text;
    setSteps([...DataArray]);
  };
  const handleDeleteStep = (index: number) => {
    let DataArray = steps;
    DataArray.splice(index, 1);
    setSteps([...DataArray]);
    setStepDeleteDialog(false);
  };

  const handleDeleteDialog = (data: boolean) => setStepDeleteDialog(data);

  const handleRecipeNote = (data: string) => {
    setRecipeNote(data);
  };

  const handleCalory = (data: string) => {
    setCalory(+data);
  };

  const handlecarb = (data: string) => {
    setcarb(+data);
  };

  const handleFat = (data: string) => {
    setFat(+data);
  };

  const handleProtein = (data: string) => {
    setProtein(+data);
  };

  const handleallergenCheck = (e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;
    if (
      allergensByDefault.find((val: any) => val?.id == data?.id) == undefined
    ) {
      if (checked) {
        setAllergens((prev) => [...prev, data]);
      } else {
        let dataArray = allergens;
        const updated = dataArray.filter((val) => val?.id != data?.id);
        setAllergens([...updated]);
      }
    }
  };
  const handleaddionalinfocheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: additionalInfo
  ) => {
    const { checked } = e.target;

    if (checked) {
      setAdditonal_info((prev) => [...prev, data]);
    } else {
      let dataArray = additonal_info;
      const updated = dataArray.filter((val) => val?.id !== data?.id);

      setAdditonal_info([...updated]);
    }
  };

  const onChangeMenu = (e: CheckboxChangeEvent, data: any) => {
    setMenu(data);
    if (data?.slug === 'sub_recipe') {
      setToggle(1);
    } else {
      setToggle(2);
    }
  };

  const handleBack = () => {
    if (stepper > 0) {
      setVisible(false);
      setAlerts(false);
      setStepper(stepper - 1);
    } else navigate('/app/subrecipe');
  };

  const checkEmptyIngredientQuantity = () => {
    const quantity = ingredient.filter(
      (data) => data?.quantity != '' || data?.quantity != 0
    );
    return quantity.length > 0 ? false : true;
  };
  const handleNextStepper = async (index: any) => {
    const checkIngredientQuantity = checkEmptyIngredientQuantity();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();

    // if (!sustainability && stepper == 0 && ingredient?.length == 1) {
    //   setAlertMessage('Please add two or more Ingredient');
    //   setVisible(true);
    //   setAlerts(false);
    //   window.scrollTo(0, 0);
    //   return false;
    // }
    if (sustainability && !waste) {
      setAlertMessage(
        'Sub recipes must have a Sub Recipe Waste % value for Sustainability!'
      );
      setVisible(true);
      setAlerts(false);
      window.scrollTo(0, 0);
      return;
    }
    if (
      sustainability &&
      (!ingredientName || !ingredientCategory || !compostableWastePercent)
    ) {
      setAlertMessage('Please complete all required fields to proceed');
      setVisible(true);
      setAlerts(false);
      window.scrollTo(0, 0);
      return;
    }

    if (
      stepper == 0 &&
      (SubRecipeName == '' ||
        (Object.keys(unit).length === 0 &&
          (Object.getPrototypeOf(unit) === Object.prototype) == undefined) ||
        !yeild ||
        Number(yeild) == 0 ||
        // !waste ||
        ingredient?.length == 0 ||
        // totalPrice == 0 ||
        // (+price === 0 && +finalPrice === 0) ||
        checkIngredientQuantity)
    ) {
      setVisible(true);
      setAlerts(false);
      setAlertMessage('');
      window.scrollTo(0, 0);
      return;
    }

    if (stepper == 1 && update) {
      setErrorList({});
      setErrorStatus(false);
      setVisible(false);
      const params = {
        general: {
          recipe_name: SubRecipeName,
          menu_category_id: subCategory?.id,
          kitchen_category_id: productionCategory?.value || 0,
          unit_id: unit?.id,
          quantity: Number(yeild),
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          currency_id: brand?.currency_id,
          is_sustainable: sustainability ? 1 : 0,
          is_verified: 1,
          verified_by: 'Head of Culinary',
          created_by: user?.id,
        },
        ingredients: ingredient.map((data: any) => {
          return {
            ingredient_name: data?.ingredient_name,
            ingredient_id: data?.id,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            cost_per_ingredient: data?.total_price,
            waste: Number(data?.waste_ingredient),
          };
        }),

        costing: {
          total_cost: totalPrice,
          fcr: Number(costRatio),
          propose_price: JSON.stringify(price),
          final_price: +finalPrice === 0 ? price : finalPrice,
          waste: Number(waste),
        },
        production: {
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image?.split('/').pop()
              : '',
          instructions: recipeNote,
          description: description,
          prep_time: Number(prep),
          calorie: calory,
          servings: 0,
          protein: protein,
          carb: carb,
          fat: fat,
          allergens_id: allergens.map((data) => data?.id).toString(),
          steps: steps,
          additional_attribute: additonal_info
            .map((data) => data?.attribute_name)
            .toString(),
          additional_attribute_id: additonal_info
            .map((data) => data?.id)
            .toString(),
        },
        inventory: {
          calorie: +handleFloat((calory / Number(yeild)) * 100),
          calorie_unit: 1,
          calories_per_unit: +handleFloat(calory / Number(yeild)),
          carb: +handleFloat((carb / Number(yeild)) * 100),
          carb_unit: 2,
          carb_per_unit: +handleFloat(carb / Number(yeild)),
          protein: +handleFloat((protein / Number(yeild)) * 100),
          protein_unit: 2,
          protein_per_unit: +handleFloat(protein / Number(yeild)),
          fat: +handleFloat((fat / Number(yeild)) * 100),
          fat_unit: 2,
          fat_per_unit: +handleFloat(fat / Number(yeild)),
        },
        sustainable: sustainability
          ? {
              ingredient_name: ingredientName,
              brand_id: brand?.brand_id,
              company_id: company?.id,
              unit_id: unit?.id || 0,
              waste: compostableWastePercent,
              // waste: reuseWastePercent,
              // waste_value: reuseWasteValue,
              // compostable_waste: compostableWastePercent,
              // compostable_waste_value: compostableWasteValue,
              ingredient_category_id: ingredientCategory,
              product_code: 0,
              calorie: ingredient[0]?.calorie,
              calorie_unit: 1,
              calories_per_unit: ingredient[0]?.calories_per_unit,
              carb: ingredient[0]?.carb,
              carb_unit: 2,
              carb_per_unit: ingredient[0]?.carb_per_unit,
              protein: ingredient[0]?.protein,
              protein_unit: 2,
              protein_per_unit: ingredient[0]?.protein_per_unit,
              fat: ingredient[0]?.fat,
              fat_unit: 2,
              fat_per_unit: ingredient[0]?.fat_per_unit,
              allergens_id: ingredient[0]?.allergens_id
                ? ingredient[0]?.allergens_id
                : '',
              is_verified:
                ingredient[0]?.calorie == null ||
                ingredient[0]?.calorie == 0 ||
                ingredient[0]?.carb == null ||
                ingredient[0]?.carb == 0 ||
                ingredient[0]?.fat == null ||
                ingredient[0]?.fat == 0 ||
                ingredient[0]?.protein == null ||
                ingredient[0]?.protein == 0
                  ? 0
                  : 1,
              verified_by: 'Chef Admin',
              created_by: user?.id,
            }
          : null,
      };
      // console.log('ingredient down', ingredient);
      setLoader(true);
      const response = await SubRecipeActions.updateSubRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? {}
          : imageObject,
        id
      );
      setLoader(false);
      if (response != undefined) {
        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
          setStepper(0);
        } else {
          setStepper(stepper + 1);
          navigate('/app/subrecipe');

          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/subrecipe');
          //   },
          //   type: 'success',
          //   messageArray: [` Your ${SubRecipeName}`, ` has been Updated`],
          // };
          // ResponseDialog(responseDialogData);
        }
      }
    } else if (stepper < 2) {
      if (stepper == 1) {
        setErrorList({});
        setErrorStatus(false);

        try {
          // let responseDialogData;
          setLoader(true);
          const params = {
            general: {
              recipe_name: SubRecipeName,
              menu_category_id: subCategory?.id,
              kitchen_category_id: productionCategory?.value || 0,
              unit_id: unit?.id || null,
              quantity: Number(yeild),
              company_id: company?.id,
              brand_id: brand?.brand_id,
              shop_id: shop?.id || 0,
              currency_id: brand?.currency_id,
              is_verified:
                +handleFloat(calory) &&
                +handleFloat(protein) &&
                +handleFloat(carb) &&
                +handleFloat(fat)
                  ? 1
                  : 0,
              verified_by: 'Head of Culinary',
              created_by: user?.id,
              is_sustainable: sustainability ? 1 : 0,
            },
            ingredients: ingredient.map((data: any) => {
              return {
                ingredient_name: data?.ingredient_name,
                ingredient_id: data?.id,
                unit_id: data?.unit_id,
                quantity: data?.quantity,
                cost_per_ingredient: data?.total_price,
                waste: Number(data?.waste_ingredient),
              };
            }),

            costing: {
              total_cost: totalPrice,
              fcr: Number(costRatio),
              propose_price: JSON.stringify(price),
              final_price: +finalPrice === 0 ? price : finalPrice,
              waste: Number(waste),
            },
            production: {
              instructions: recipeNote,
              description: description,
              prep_time: Number(prep),
              calorie: +handleFloat(calory),
              protein: +handleFloat(protein),
              carb: +handleFloat(carb),
              fat: +handleFloat(fat),
              servings: 0,
              allergens_id: allergens.map((data) => data?.id).toString(),
              steps: steps,
              // additional_attribute: additonal_info
              //   .map((data) => data?.attribute_name)
              //   .toString(),
              additional_attribute_id: additonal_info
                .map((data) => data?.id)
                .toString(),
            },
            inventory: {
              calorie: +handleFloat((calory / Number(yeild)) * 100),
              calorie_unit: 1,
              calories_per_unit: +handleFloat(calory / Number(yeild)),
              carb: +handleFloat((carb / Number(yeild)) * 100),
              carb_unit: 2,
              carb_per_unit: +handleFloat(carb / Number(yeild)),
              protein: +handleFloat((protein / Number(yeild)) * 100),
              protein_unit: 2,
              protein_per_unit: +handleFloat(protein / Number(yeild)),
              fat: +handleFloat((fat / Number(yeild)) * 100),
              fat_unit: 2,
              fat_per_unit: +handleFloat(fat / Number(yeild)),
            },
            sustainable: sustainability
              ? {
                  ingredient_name: ingredientName,
                  brand_id: brand?.brand_id,
                  company_id: company?.id,
                  unit_id: unit?.id || 0,
                  waste: compostableWastePercent,
                  // waste: reuseWastePercent,
                  // waste_value: reuseWasteValue,
                  // compostable_waste: compostableWastePercent,
                  // compostable_waste_value: compostableWasteValue,
                  ingredient_category_id: ingredientCategory,
                  product_code: 0,
                  calorie: ingredient[0]?.calorie,
                  calorie_unit: 1,
                  calories_per_unit: ingredient[0]?.calories_per_unit,
                  carb: ingredient[0]?.carb,
                  carb_unit: 2,
                  carb_per_unit: ingredient[0]?.carb_per_unit,
                  protein: ingredient[0]?.protein,
                  protein_unit: 2,
                  protein_per_unit: ingredient[0]?.protein_per_unit,
                  fat: ingredient[0]?.fat,
                  fat_unit: 2,
                  fat_per_unit: ingredient[0]?.fat_per_unit,
                  allergens_id: ingredient[0]?.allergens_id
                    ? ingredient[0]?.allergens_id
                    : '',
                  is_verified:
                    ingredient[0]?.calorie == null ||
                    ingredient[0]?.calorie == 0 ||
                    ingredient[0]?.carb == null ||
                    ingredient[0]?.carb == 0 ||
                    ingredient[0]?.fat == null ||
                    ingredient[0]?.fat == 0 ||
                    ingredient[0]?.protein == null ||
                    ingredient[0]?.protein == 0
                      ? 0
                      : 1,
                  verified_by: 'Chef Admin',
                  created_by: user?.id,
                }
              : null,
          };
          const data = {
            general: {
              recipe_name: SubRecipeName,
              menu_category_id: subCategory?.id,
              kitchen_category_id: productionCategory?.value,
              unit_id: unit?.id || null,
              quantity: Number(yeild),
              company_id: company?.id,
              brand_id: brand?.brand_id,
              shop_id: shop?.id || 0,
              currency_id: brand?.currency_id,
              is_verified: 1,
              verified_by: 'Head of Culinary',
              created_by: user?.id,
            },
            ingredients: ingredient.map((data: any) => {
              return {
                ingredient_name: data?.ingredient_name,
                ingredient_id: data?.id,
                unit_id: data?.unit_id,
                quantity: data?.quantity,
                cost_per_ingredient: data?.total_price,
                waste: Number(data?.waste_ingredient),
              };
            }),

            costing: {
              total_cost: totalPrice,
              fcr: Number(costRatio),
              propose_price: JSON.stringify(price),
              final_price: +finalPrice === 0 ? price : finalPrice,
              waste: Number(waste),
            },
            production: {
              instructions: recipeNote,
              description: description,
              prep_time: Number(prep),
              servings: 0,
              calorie: +handleFloat(calory),
              protein: +handleFloat(protein),
              carb: +handleFloat(carb),
              fat: +handleFloat(fat),
              allergens_id: allergens.map((data) => data?.id).toString(),
              steps: steps,
              additional_attribute: additonal_info
                .map((data) => data?.attribute_name)
                .toString(),
              additional_attribute_id: additonal_info
                .map((data) => data?.id)
                .toString(),
            },
            inventory: {
              calorie: +handleFloat((calory / Number(yeild)) * 100),
              calorie_unit: 1,
              calories_per_unit: +handleFloat(calory / Number(yeild)),
              carb: +handleFloat((carb / Number(yeild)) * 100),
              carb_unit: 2,
              carb_per_unit: +handleFloat(carb / Number(yeild)),
              protein: +handleFloat((protein / Number(yeild)) * 100),
              protein_unit: 2,
              protein_per_unit: +handleFloat(protein / Number(yeild)),
              fat: +handleFloat((fat / Number(yeild)) * 100),
              fat_unit: 2,
              fat_per_unit: +handleFloat(fat / Number(yeild)),
            },
          };
          const response = await SubRecipeActions.createSubRecipe(
            params,
            imageObject &&
              Object.keys(imageObject).length === 0 &&
              Object.getPrototypeOf(imageObject) === Object.prototype
              ? {}
              : imageObject
          );
          setLoader(false);
          if (response != undefined) {
            if (!response?.success) {
              setErrorList(response?.error);
              setErrorStatus(true);
              setStepper(0);
            } else {
              setStepper(stepper + 1);
              navigate('/app/subrecipe');

              // responseDialogData = {
              //   OnClick: () => {
              //     navigate('/app/subrecipe');
              //   },
              //   type: 'success',
              //   messageArray: [` Your ${SubRecipeName} has been Created`],
              // };
              // ResponseDialog(responseDialogData);
            }
          }
        } catch (e) {
          let responseDialogData = {
            OnClick: () => {
              setStepper(0);
            },
            type: 'error',
            messageArray: ['Validation Failed!', 'Server Error'],
          };
          ResponseDialog(responseDialogData);
        }
      } else setStepper(stepper == 2 ? stepper : index);
      setVisible(false);
      setAlerts(false);
      window.scrollTo(0, 0);
    }
  };
  const handleStepper = (value: number) => {
    if (value > stepper) handleContinue();
    else setStepper(value);
  };
  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/subrecipe');
        }}
      />
      <Page title="Add New Sub Recipe" className="subRecipeContainer">
        {visible && (
          <Alert
            message="Error"
            description={
              alertMessage
                ? alertMessage
                : 'Please complete all required fields to proceed'
            }
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
        <Stepper
          stepper={stepper}
          component="subRecipe"
          onStepperChange={handleStepper}
        />

        <Spin
          spinning={loader}
          size="large"
          className="loadIngredient recipeBuilderloaderHeight"
        >
          <div className="wizardContainer">
            {stepper === 0 ? (
              <SustainabilityStepOne
                update={blockSustain}
                blocked={false}
                component="subRecipe"
                SubRecipeName={SubRecipeName}
                category={category}
                unit={unit}
                yeild={yeild}
                menu={menu}
                costRatio={costRatio}
                totalPrice={totalPrice}
                price={price}
                waste={waste}
                onChangeMenu={onChangeMenu}
                sustainability={sustainability}
                toggle={toggle}
                finalPrice={finalPrice}
                ingredient={ingredient}
                hide={hide}
                subCategory={subCategory}
                productionCategoryList={productionCategoryList}
                productionCategory={productionCategory}
                ingredientCategoryList={ingredientCategoryList}
                reuseWastePercent={reuseWastePercent}
                compostableWastePercent={compostableWastePercent}
                reuseWasteValue={reuseWasteValue}
                compostableWasteValue={compostableWasteValue}
                ingredientCategory={ingredientCategory}
                ingredientUnit={ingredientUnit}
                ingredientLabelName={ingredientName}
                onSubmitKitchenSection={onSubmitKitchenSection}
                handleIngredientName={handleIngredientName}
                handleIngredientUnit={handleIngredientUnit}
                handleIngredientCategory={handleIngredientCategory}
                handleWastePercent={handleWastePercent}
                handleRecipe={handleRecipe}
                handleUnit={handleUnit}
                handleYeild={handleYeild}
                // parentSubCategory={parentSubCategory}
                handleCostRatio={handleCostRatio}
                handleFinalPrice={handleFinalPrice}
                handleWaste={handleWaste}
                handleIngredient={handleIngredient}
                deleteIngredient={deleteIngredient}
                addIngredient={addIngredient}
                handleIngredientObject={handleIngredientObject}
                screenName={'Sub Recipe'}
                handleChangeDatabase={handleChangeDatabase}
                handleSubCategory={handleSubCategory}
                handleProductionCategory={handleProductionCategory}
                handleSustainability={handleSustainability}
                handleEmptyIngredient={handleEmptyIngredient}
                database={database}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : (
              <SustainabilityStepTwo
                component="subRecipe"
                image={image}
                description={description}
                recipeNote={recipeNote}
                steps={steps}
                prep={prep}
                calory={calory}
                carb={carb}
                fat={fat}
                protein={protein}
                stepDeleteDialog={stepDeleteDialog}
                additonal_info={additonal_info}
                allergens={allergens}
                serving={''}
                handleServing={(e: any) => {
                  e;
                }}
                handleRecipeNote={handleRecipeNote}
                handleIndex={handleIndex}
                handleImage={handleImage}
                handleDescription={handleDescription}
                addSteps={addSteps}
                handleChangeStep={handleChangeStep}
                handleCalory={handleCalory}
                handlecarb={handlecarb}
                handleFat={handleFat}
                handleProtein={handleProtein}
                handleallergenCheck={handleallergenCheck}
                handleaddionalinfocheck={handleaddionalinfocheck}
                handleDeleteStep={handleDeleteStep}
                handleDeleteDialog={handleDeleteDialog}
                handlePrep={handlePrep}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            )}
            <div className="butonGroup">
              <div className="wizard-button" onClick={handleBack}>
                Back
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                Continue
              </div>
            </div>
          </div>
        </Spin>
      </Page>
    </React.Fragment>
  );
};
export default SustainabilityWizard;
