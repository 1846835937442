import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  SearchBox,
  CompanyList,
  ListCount,
} from '../../components';
import { sortNumber, sortedData } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
// import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';
import SubscriptionApi from '../../redux/middleware/subscription';
import './styles.scss';
import Utils from '../../redux/utils';
import moment from 'moment';
import More from '../../assets/images/More.png';
import InvoiceDialog from './invoice';

const PageDataLength = [6, 10, 20, 50];

const Subscription = () => {
  const user = Utils.getCurrentUser();
  // eslint-disable-next-line no-unused-vars
  const [selectedRecord, setSelectedRecord] = useState('');
  const { t } = useTranslation('translation', {
    keyPrefix: 'companyPage',
  });
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [openInvoice, setOpenInvoice] = useState(false);

  const navigate = useNavigate();
  const { currentCompany } = useSelector((state: any) => state.Auth);
  const items: MenuProps['items'] = [
    /* {
      key: '1',
      label: <div className="dotOption"> {t('view')}</div>,
      icon: <img src={Eye} width={15} className="iconDropdown" />,
    }, */
    {
      key: '1',
      label: <div className="dotOption">Update Invoice</div>,
      icon: <img src={EditSmall} className="iconDropdown" />,
    },
  ];

  React.useEffect(() => {
    if (currentCompany) {
      pageData();
    }
  }, [currentCompany]);
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const getExpiryString = (record: any) => {
    // eslint-disable-next-line no-unused-vars
    const { invoice_due_date, interval, trial_ends_at } = record;

    const date = moment(trial_ends_at);
    const curr_date = moment();

    if (trial_ends_at) {
      const difference = date.diff(curr_date, 'days');
      return (
        <>
          <p>{moment(trial_ends_at).format('DD MMM YYYY')}</p>
          {difference > 0 && (
            <>
              <span>{`${difference} Days Left - `}</span>
              <span> Free Trial</span>
            </>
          )}
        </>
      );
    }

    const addition = interval === 'month' ? 30 : 365;

    const license_date = moment(invoice_due_date).add(addition, 'days');

    return (
      <>
        <p>{moment(license_date).format('DD MMM YYYY')}</p>
      </>
    );
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setOpenInvoice(true);
        break;
      default:
    }
  };

  const DropDownmenuProps = {
    items,
    onClick: handleDropDownClick,
  };

  const columns: any = [
    /* {
      title: () => (
        <div className="tableTitle">
          <span>{t('id')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('item_id', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="company">{record?.item_id}</div>;
      },
    }, */
    {
      title: () => (
        <div className="tableTitle">
          <span>USER NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('user_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'user_name',
      key: 'user_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.user_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('company_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('company_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col1 font">{record?.company_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PRODUCT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('product_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'product_name',
      key: 'product_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.product_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INTERVAL</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('interval', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'interval',
      key: 'interval',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">
          {record?.interval === 'year' ? 'Annually' : 'Monthly'}
          <sub className="city-sub">
            {record?.type === 'Stripe' ? 'Stripe' : 'Manual'}
          </sub>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>EXPIRY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            /* onClick={() => {
              handleSort('product_name', 'string');
              setAscending(!ascending);
            }} */
          />
        </div>
      ),
      dataIndex: 'product_name',
      key: 'product_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="expiry">{getExpiryString(record)}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('status')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      // eslint-disable-next-line no-unused-vars
      render: (data: any, record: any) => {
        return (
          <div
            className={
              record?.access_status === true
                ? 'statusContainerActive'
                : 'statusContainerNonActive'
            }
          >
            <div className="status">
              {record?.access_status === true ? 'Active' : 'Not Active'}
            </div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => {
        const { invoice_status = '' } = record;
        return (
          <>
            {invoice_status === 'open' && (
              <Dropdown
                menu={DropDownmenuProps}
                trigger={['click']}
                placement="bottomRight"
                arrow={{ pointAtCenter: true }}
              >
                <a onClick={() => setSelectedRecord(record)}>
                  <Space>
                    <div className="dots">
                      <img src={More} />
                    </div>
                  </Space>
                </a>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    setLoader(true);
    const response = await SubscriptionApi.getUserSubscriptions(
      dataLength,
      current
    );
    if (response?.success) {
      setTotal(response?.data?.pagination?.total);
      setSortData(response?.data?.items);
      setCount(response?.data?.subscriptions_count);
    }
    setLoader(false);
  };

  const actionButton = (
    <Button
      text={t('add_new')}
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  // eslint-disable-next-line no-unused-vars
  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResult = await SubscriptionApi.getUserSubscriptions(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.companies_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const OnCloseModal = () => {
    setOpenInvoice(!openInvoice);
    pageData();
  };

  return (
    <React.Fragment>
      <InvoiceDialog
        subscriptionInfo={selectedRecord}
        open={openInvoice}
        handleClose={OnCloseModal}
      />
      <Breadcrumb
        heading={'User Subscriptions'}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={20} md={24}>
            <ListCount count1={count} title1={'Total Subscriptions'} />
          </Col>
          <Col lg={4} md={24}>
            <div className="lead-search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  action={onSearch}
                  setKeyword={setKeyword}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <div className="companyTable">
                <CompanyList
                  items={items}
                  dataSource={sortData ? [...sortData] : []}
                  columns={columns}
                  limit={dataLength}
                />
              </div>
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default Subscription;
