/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Modal, Spin, Input, Button } from 'antd';
import SubscriptionActions from '../../redux/middleware/subscription';
import { sortNumber, sortedData } from '../../helper/sortData';
import { ResponseDialog } from '../../components';
import './style.scss';
import Subscription from '../../pages/subscription/index';
type Props = {
  handleCancel: any;
  open: boolean;
  component?: string;
  leadId?: any;
  userId?: any;
};

const SubscriptionModal = (props: Props) => {
  let { handleCancel, open, leadId, userId } = props;
  const [current] = useState(1);
  const [dataLength] = useState(5000);
  const [loader, setLoader] = useState(false);
  const [planPrice, setPlanPrice] = useState<any>();
  const [branchLimit, setBranchLimit] = React.useState<any>();
  const [brandLimit, setBrandLimit] = React.useState<any>();
  const [trialPeriod, setTrialPeriod] = React.useState<any>();
  const [centralKitchen, setCentralKitchen] = useState<any>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackageListing();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoader(true);
      const param = {
        user_id: userId,
        lead_id: leadId,
        price: planPrice,
        brand_limit: brandLimit,
        shop_limit: branchLimit,
        trial_period: trialPeriod,
        ck_limit: centralKitchen,
      };
      const response = await SubscriptionActions.createEnterprisePlan(param);
      setLoader(false);
      const { success, message } = response;
      if (success) {
        const responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: ['Plan Created for the User!'],
        };
        ResponseDialog(responseDialogData);
        handleCancel(false);
      } else {
        setErrorList(response?.error);
        setErrorStatus(true);
      }
    } catch (e) {
      console.log(e);
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          'Plan Creation Failed!',
          `Somthing went wrong, please try later`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const getPackageListing = async () => {
    setLoader(true);
    const response = await SubscriptionActions.getSubscriptionPackages(
      dataLength,
      current
    );
    if (response?.success) {
      const { data } = response;
      if (data) {
        const { month } = data;
        setTrialPeriod(month[0]?.trial_period_days);
      }
    }
    setLoader(false);
  };

  return (
    <Modal
      title={<div className="title font">Subscription Details</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <div className="ingredientDialog">
          <div className="ingredientSubContainer subs-modal">
            <div className="ingredientLabel">
              Price <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input Price here"
              size="large"
              value={planPrice}
              onChange={(e) => setPlanPrice(e.target.value)}
              className={errorList['price']?.length > 0 ? 'borderRed' : ''}
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">{errorList['price']}</div>
            )}
          </div>
          <div className="menuSubContainer">
            <div className="row">
              <span className="col">
                <div className="wizardLabel">
                  Branch Limit <span className="sterik">*</span>
                </div>
                <span className="finalMenuPrice">
                  <Input
                    placeholder="0"
                    type="number"
                    size="large"
                    value={branchLimit}
                    onChange={(e) => setBranchLimit(e.target.value)}
                    required
                    className={
                      errorList['shop_limit']?.length > 0 ? 'borderRed' : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['shop_limit']}
                    </div>
                  )}
                </span>
              </span>
              <span className="col">
                <div className="wizardLabel">
                  Brand Limit <span className="sterik">*</span>
                </div>
                <span className="finalMenuPrice">
                  <Input
                    placeholder="0"
                    type="number"
                    size="large"
                    value={brandLimit}
                    onChange={(e) => setBrandLimit(e.target.value)}
                    required
                    className={
                      errorList['brand_limit']?.length > 0 ? 'borderRed' : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['brand_limit']}
                    </div>
                  )}
                </span>
              </span>
            </div>
          </div>
          <div className="menuSubContainer subs-input">
            <div className="row">
              <span className="col">
                <div className="wizardLabel">
                  Trial Period <span className="sterik">*</span>
                </div>
                <span className="finalMenuPrice">
                  <Input
                    placeholder="0"
                    type="number"
                    size="large"
                    value={trialPeriod}
                    disabled={true}
                    onChange={(e) => setTrialPeriod(e.target.value)}
                    required
                    className={
                      errorList['trial_period']?.length > 0 ? 'borderRed' : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['trial_period']}
                    </div>
                  )}
                </span>
              </span>
              <span className="col">
                <div className="wizardLabel">
                  CK Limit <span className="sterik">*</span>
                </div>
                <span className="finalMenuPrice">
                  <Input
                    placeholder="0"
                    type="number"
                    size="large"
                    value={centralKitchen}
                    onChange={(e) => setCentralKitchen(e.target.value)}
                    required
                    className={
                      errorList['ck_limit']?.length > 0 ? 'borderRed' : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['ck_limit']}
                    </div>
                  )}
                </span>
              </span>
            </div>
          </div>
          <div className="butonGroup">
            <div className="wizard-button" onClick={() => handleCancel(false)}>
              Cancel
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleSubmit()}
            >
              Submit
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
export default SubscriptionModal;
