import React from 'react';
import { Steps } from 'antd';
import './style.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  stepper: number;
  component: string;
  onStepperChange?: any;
};

const Stepper = (props: Props) => {
  let { stepper, component, onStepperChange } = props;
  const { t } = useTranslation('translation', {
    keyPrefix: 'stepper',
  });
  const steps = [
    {
      title: t('general'),
      description: t('general_description'),
    },
    {
      title: t('ingredient'),
      description: t('ingredient_description'),
    },
    {
      title: t('instruction'),
      description: t('instruction_description'),
    },
  ];

  const steps2 = [
    {
      title: t('ingredient'),
      description: t('ingredient_description'),
    },
    {
      title: t('instruction'),
      description: t('instruction_description'),
    },
  ];

  const userSteps = [
    {
      title: t('user'),
      description: t('user_description'),
    },
    {
      title: t('user_access'),
      description: t('user_access_description'),
    },
  ];
  const Uploadimagestep = [
    {
      title: t('upload_image'),
      description: t('upload_image_description'),
    },
    {
      title: t('tag_recipe'),
      description: t('tag_recipe_description'),
    },
  ];

  const productionPlanStep = [
    {
      title: t('recipes'),
      description: t('recipes_description'),
    },
    {
      title: t('quantity'),
      description: t('quantity_description'),
    },
  ];
  let stepsmapping =
    component === 'recipeBuilder'
      ? steps
      : component === 'UploadImage'
      ? Uploadimagestep
      : component === 'user'
      ? userSteps
      : component == 'productionPlan'
      ? productionPlanStep
      : steps2;
  return (
    <div
      className={
        component == 'recipeBuilder'
          ? 'container wizard'
          : 'recipeWizardStepperContainer wizard'
      }
    >
      <Steps
        current={stepper}
        onChange={onStepperChange}
        items={stepsmapping.map((item) => ({
          key: item.title,
          title: item.title,
          description: item.description,
        }))}
      />
      {/* {stepper < steps.length - 1 && (
        <Button
          onClick={() => handleNextStepper(stepper + 1)}
          className="stepper-button"
        >
          Next
        </Button>
      )}
      {stepper === steps.length - 1 && (
        <Button
          className="stepper-button"
          onClick={() => message.success('Processing complete!')}
        >
          Done
        </Button>
      )}
      {stepper > 0 && (
        <Button
          onClick={() => handleBackStepper(stepper - 1)}
          className="stepper-button stepperButon"
        >
          Previous
        </Button>
      )} */}
    </div>
  );
};

export default Stepper;
