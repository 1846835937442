import React from 'react';
import { Input } from 'antd';
import './menuCategoryStep.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  handleMenuCategory: any;
  menuCategory: string;
  errorList?: any;
  errorStatus?: boolean;
  component?: any;
  selectedCategory?: any;
  handleContinue?: any;
};

const MenuCategoryStep = (prop: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'categoryPage',
  });

  const handleFieldHeading = () => {
    if (selectedCategory?.parent_category === 'Menu Category') {
      return t('menu_category_name');
    } else if (selectedCategory?.parent_category === 'Sub Recipe Category') {
      return t('subrecipe_category_name');
    } else if (selectedCategory?.parent_category === 'Kitchen Section') {
      return t('kitchen_section_name');
    } else if (selectedCategory?.parent_category === 'Ingredient Category') {
      return t('ingredient_category_wizard');
    } else if (selectedCategory?.parent_category === 'R&D Menu Category') {
      return t('rdmenu_category_name');
    } else if (
      selectedCategory?.parent_category === 'R&D Sub Recipe Category'
    ) {
      return t('rdsubrecipe_category_name');
    } else if (
      selectedCategory?.parent_category === 'R&D Ingredient Category'
    ) {
      return t('rdingredient_category_name');
    }
  };

  let {
    handleMenuCategory,
    menuCategory,
    errorList,
    errorStatus,
    selectedCategory,
    handleContinue,
  } = prop;

  return (
    <React.Fragment>
      <div className="parentContainer">
        <div className="wizardSubContainer">
          <div className="wizardLabel">
            {handleFieldHeading()}
            <span className="sterik">*</span>
          </div>
          <Input
            placeholder={t('category_placeholder')}
            size="large"
            value={menuCategory}
            onChange={(e) => {
              handleMenuCategory(e.target.value);
            }}
            onKeyDown={(event) =>
              menuCategory != '' && event.key === 'Enter' && handleContinue()
            }
            required
            className={
              errorList['sub_category']?.length > 0 ? 'borderRed size' : 'size'
            }
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">
              {errorList['sub_category']}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default MenuCategoryStep;
