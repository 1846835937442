/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Alert, Collapse, Dropdown, Input, MenuProps, Space, Spin } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrderListDialog } from '../../components';
import { Page, Breadcrumb } from '../../components';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import SubMenu from '../../assets/images/SubMenu.svg';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { setDisableBrand } from '../../redux/reducers/recipeSlice';
import { useDispatch } from 'react-redux';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import useClickListener from '../../helper/outsideClickListener';
import Delete from '../../assets/images/Delete.svg';
import './style.scss';
import ProductionAction from '../../redux/middleware/production';
import { checkAuthentication } from '../../helper/checkAuthentication';
import ResponseDialog from '../../components/ResopnseDialog';

const ProductionPlanWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  const shop = Utils.getCurrentShop();
  const { update, id, executeEnabled } = state;
  const { Panel } = Collapse;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const [loader, setLoader] = useState(true);
  const [responseLoader, setResponseLoader] = useState(false);
  const [sortData, setSortData] = useState<any>([]);
  const [check, setCheck] = useState(true);
  const [recipeList, setRecipeList] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [productionName, setProductionName] = useState('');
  const [recipes, setRecipes] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [units, setUnits] = useState<any>([]);
  const [recipeIndex, setRecipeIndex] = useState<any>();
  const [openOrderList, setOpenOrderList] = useState(false);
  const [error, setError] = useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [selectedBrand, setSelectedBrand] = useState<any>(currentBrand);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/productionPlan');
    }
  }, [currentBrand, currentShop]);

  const fetchUnit = async () => {
    const data = await SubRecipeActions.getStorageUnits();
    setUnits(data?.data);
  };

  useEffect(() => {
    if (
      currentBrand?.brand_id !== selectedBrand?.brand_id &&
      currentBrand !== undefined
    ) {
      setSelectedBrand(Utils.getCurrentBrand());
      handleResetFields();
    } else {
      getRecipes();
      fetchUnit();
    }
  }, [check, currentBrand]);
  useEffect(() => {
    checkAuthentication(user, navigate);
    dispatch(setDisableBrand(true));
    return () => {
      dispatch(setDisableBrand(false));
    };
  }, []);

  useEffect(() => {
    if (update) fetchDataById();
  }, [update]);

  useEffect(() => {
    if (shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      return;
    }
  }, [shop]);

  const fetchDataById = async () => {
    setResponseLoader(true);
    const { data, success } = await ProductionAction.getDetailRecipe(id);
    setResponseLoader(false);
    if (success) {
      setProductionName(data?.production_name);
      setRecipes(
        data?.recipes?.map((recipe: any) => ({
          ...recipe,
          ingredients: recipe?.ingredients?.map((ingredient: any) => ({
            ...ingredient,
            amount: ingredient?.quantity,
          })),
        }))
      );
    }
  };

  const getRecipes = async () => {
    const brand = await Utils.getCurrentBrand();
    setLoader(true);
    const recipeData = check
      ? await RecipeApiActions.getRecipes(
          1000,
          1,
          currentBrand?.brand_id || brand?.brand_id,
          ''
        )
      : await SubRecipeActions.getSubRecipes(
          1000,
          1,
          currentBrand?.brand_id || brand?.brand_id,
          ''
        );

    setLoader(false);
    if (recipeData == undefined || !recipeData?.success) {
      setRecipeList([]);
      setSortData([]);
    } else {
      setSortData(recipeData?.data?.items);
      setRecipeList(recipeData?.data?.items);
    }
  };

  const handleResetFields = () => {
    setProductionName('');
    setSearch('');
    setRecipes([]);
    getRecipes();
  };

  const handleBackStepper = () => {
    navigate('/app/productionPlan');
  };
  const handleContinue = async () => {
    if (
      productionName == '' ||
      !recipes?.length ||
      recipes.some((data: any) => {
        return data?.quantity === '';
      })
    ) {
      setVisible(true);
      window.scrollTo(0, 0);
    } else {
      const params = {
        company_id: company?.id,
        shop_id: shop?.shop_id,
        brand_id: brand?.brand_id,
        created_by: user?.id,
        production_name: productionName,
        production_items: recipes?.map((recipe: any) => ({
          id: recipe?.recipe_id || recipe?.sub_recipe_id,
          quantity: recipe?.quantity !== undefined ? recipe.quantity : 1,
          tag:
            recipe?.sub_recipe_id || recipe?.recipe_id?.includes('SR')
              ? 'sub_recipe'
              : 'recipe',
          ingredients: recipe?.ingredients?.map((ingredient: any) => ({
            ingredient_id: ingredient?.ingredient_id || ingredient?.id,
            quantity: ingredient?.amount || 0,
          })),
          unit_id: recipe?.unit_id,
        })),
      };
      setResponseLoader(true);
      const { success } = update
        ? await ProductionAction.updateProductionPlan(params, id)
        : await ProductionAction.createProductionPlan(params);
      setResponseLoader(false);
      if (success) {
        navigate('/app/productionPlan');
        // const responseDialogData = {
        //   type: 'success',
        //   messageArray: [
        //     ` Your ${productionName}`,
        //     `has been ${update ? 'Updated' : 'Created'}`,
        //   ],
        //   OnClick: () => {

        //   },
        // };
        // ResponseDialog(responseDialogData);
      }
    }
  };

  const handleChange = async (e: any) => {
    setSearch(e.target.value);

    setSortData(() =>
      recipeList?.filter((data: any) =>
        data?.recipe_name?.toLowerCase()?.includes(e.target.value)
      )
    );
  };

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  const handleRecipes = (ingredient: any) => {
    const recipe_unit = units?.find(
      (unitObj: any) => unitObj?.storage_unit?.toLowerCase() === 'piece'
    );
    setRecipes([
      ...recipes,
      {
        ...ingredient,
        quantity: '',
        unit: recipe_unit?.storage_unit,
        unit_id: recipe_unit?.id,
      },
    ]);
    const newRecipe = {
      ...ingredient,
      quantity: 1,
      unit: recipe_unit?.storage_unit,
      unit_id: recipe_unit?.id,
    };
    newRecipe.ingredients = newRecipe.ingredients.map((ingredient: any) => ({
      ...ingredient,
      amount: (+ingredient.quantity * 1).toFixed(2),
    }));
    setRecipes([...recipes, newRecipe]);
    setIsComponentVisible(!isComponentVisible);
    setSearch('');
  };

  const handleRecipeValues = (data: any, index: number, key: string) => {
    const recipeData = recipes;

    // if (key == 'unit') {
    //   recipeData[index][key] = data?.unit;
    //   recipeData[index].unit_id = data?.id;
    // } else
    recipeData[index][key] = data;
    // if (key != 'unit') {
    recipeData[index].ingredients = recipeData[index]?.ingredients?.map(
      (ingredient: any) => ({
        ...ingredient,
        // (+ingredient?.quantity /
        amount:
          //   ((recipeData[index]?.tag == 'recipe' && update) ||
          //   (recipeData[index]?.recipe_id && !update)
          //     ? +recipeData[index]?.servings
          //     : +recipeData[index]?.yield_quantity)) *
          // data
          (+ingredient?.quantity * data)?.toFixed(2),
      })
    );
    // }
    setRecipes([...recipeData]);
  };

  const handleRecipeIngredient = (
    data: any,
    index: number,
    ingredientIndex: number
  ) => {
    const recipeData = recipes;
    recipeData[index].ingredients[ingredientIndex].amount = data;
    setRecipes([...recipeData]);
  };

  const items: MenuProps['items'] = units?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.unit}</div>,
    };
  });

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    const unitObject = units.find((data: any) => data?.id == option);

    handleRecipeValues(unitObject, recipeIndex, 'unit');
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  const handleCancel = () => {
    setOpenOrderList(false);
  };

  const handleRemoveRecipe = (index: any) => {
    let recipeArray = recipes;
    recipeArray.splice(index, 1);
    setRecipes([...recipeArray]);
  };

  const emptyStates = () => {
    setProductionName('');
    setRecipes([]);
  };

  const handleFinishPlan = async (flag: boolean) => {
    setResponseLoader(true);
    const { success, error } = await ProductionAction.finishPlan(id);
    setResponseLoader(false);
    if (success) {
      flag ? navigate('/app/productionPlan') : emptyStates();
      // const responseDialogData = {
      //   type: 'success',
      //   messageArray: [` Your ${productionName}`, `has been Executed`],
      //   OnClick: () => {
      //     flag ? navigate('/app/productionPlan') : emptyStates();
      //   },
      // };
      // ResponseDialog(responseDialogData);
    } else {
      setErrorList(error);
      setError(true);
    }
  };
  return (
    <div className="uploadimageblock">
      <Breadcrumb
        heading="Production Plan"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/productionPlan');
        }}
      />
      <Spin spinning={responseLoader}>
        <Page title="Production Plan">
          {visible && (
            <Alert
              message="Error"
              description={'Please complete all required fields to proceed'}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          <OrderListDialog
            open={openOrderList}
            handleCancel={handleCancel}
            handleContinue={handleFinishPlan}
            component={'productionPlan'}
            recipes={recipes}
            loader={responseLoader}
            errorList={errorList}
            error={error}
          />
          <div className="wizardContainer">
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Production Name <span className="sterik">*</span>
              </div>
              <Input
                type="text"
                size="large"
                placeholder="Enter Production Name"
                onChange={(e) => setProductionName(e.target.value)}
                value={productionName}
                disabled={executeEnabled}
                // className={errorList['menu_category_id']?.length > 0 ? 'borderRed' : ''}
              />
            </div>

            <div className="rd-category-list-menu-container">
              <div
                className={check ? 'menu' : 'menuLight'}
                onClick={() => !executeEnabled && setCheck(true)}
              >
                <div className="text rd-category-list-menu-style">Recipes</div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => !executeEnabled && setCheck(false)}
              >
                <div className="text rd-category-list-menu-style">
                  {' '}
                  Sub Recipes
                </div>
              </div>
            </div>

            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Recipes <span className="sterik">*</span>{' '}
              </div>
              <div ref={ref} className="productionPlan-recipe-selection">
                <Input
                  type="text"
                  size="large"
                  placeholder="Input category here"
                  onClick={handleClick}
                  onChange={(e) => handleChange(e)}
                  value={search}
                  disabled={executeEnabled}
                  // className={errorList['menu_category_id']?.length > 0 ? 'borderRed' : ''}
                />
                {isComponentVisible && (
                  <ul className="productionPlan-dropdown">
                    <li className="li-label">
                      <h4 className="placeholder">Select an option</h4>
                    </li>

                    <Spin spinning={loader} size="default">
                      {sortData?.length ? (
                        sortData?.map((ingredient: any, innerIndex: number) => {
                          return (
                            <li
                              key={innerIndex}
                              onClick={() => {
                                // handleIngredientClick(ingredient, index)
                                handleRecipes(ingredient);
                              }}
                            >
                              <p>
                                {ingredient?.recipe_name}{' '}
                                {ingredient?.ingredient_category?.toLowerCase() ==
                                  'sub recipe' && (
                                  <img
                                    src={SubMenu}
                                    width={15}
                                    alt=""
                                    className="subRecipeIcons"
                                  />
                                )}
                              </p>
                            </li>
                          );
                        })
                      ) : (
                        <div>No Recipes Found</div>
                      )}
                    </Spin>
                  </ul>
                )}
              </div>
            </div>
            <Collapse
              className="productionPlanCollapseContainer"
              bordered={false}
              accordion
              ghost
              expandIconPosition="right"
            >
              {recipes?.map((data: any, index: number) => (
                <>
                  <Panel
                    className="panelStyling"
                    key={index}
                    header={
                      <div>
                        <div className="collapse-ingredient-label">Recipe</div>
                        <div className="gramContainer" key={index}>
                          <div className="recipeField-subContainer panel-recipe-name-field">
                            {data?.recipe_name}
                          </div>

                          <div className="gramSubContainer disable-background-transparent">
                            <input
                              className="gramValue"
                              placeholder="0"
                              type={'number'}
                              disabled={executeEnabled}
                              defaultValue={
                                data.quantity !== undefined
                                  ? Number(data.quantity || 1).toString()
                                  : 1
                              }
                              onChange={(e) => {
                                if (+e.target.value <= 999999) {
                                  handleRecipeValues(
                                    +e.target.value,
                                    index,
                                    'quantity'
                                  );
                                } else {
                                  handleRecipeValues(1, index, 'quantity');
                                }
                                // handleIngredientWeight(+e.target.value, index);
                              }}
                            />
                            <div className="gramBoxWrapper">
                              <div className="gramBox">
                                <Dropdown
                                  menu={DropDownmenuProps}
                                  trigger={['click']}
                                  placement="bottomRight"
                                  arrow={{ pointAtCenter: true }}
                                  disabled
                                >
                                  <a onClick={() => setRecipeIndex(index)}>
                                    <Space>
                                      <div>{data?.unit}</div>{' '}
                                      {/* <img src={ArrowDown} alt="" />  */}
                                    </Space>
                                  </a>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          {!executeEnabled && (
                            <img
                              src={Delete}
                              width={15}
                              alt=""
                              onClick={() => {
                                handleRemoveRecipe(index);
                              }}
                              className="production-plan-recipe-delete-button"
                            />
                          )}
                        </div>
                      </div>
                    }
                  >
                    <div className="panel-container">
                      <div className="collapse-ingredient-label">
                        Ingredients
                      </div>
                      {data?.ingredients?.map(
                        (ingredient: any, ingredientIndex: number) => (
                          <div className="gramContainer" key={ingredientIndex}>
                            <div className="recipeField-subContainer panel-recipe-name-field">
                              {ingredient?.ingredient_name}
                            </div>

                            <div className="gramSubContainer disable-background-transparent">
                              <input
                                className="gramValue"
                                placeholder="0"
                                type={'number'}
                                disabled={executeEnabled}
                                value={
                                  ingredient.amount
                                    ? Number(ingredient?.amount)
                                    : ''
                                }
                                onChange={(e) => {
                                  // if (+e.target.value <= 999) {
                                  handleRecipeIngredient(
                                    +e.target.value,
                                    index,
                                    ingredientIndex
                                  );
                                  // }
                                  // handleIngredientWeight(+e.target.value, index);
                                }}
                              />
                              <div className="gramBoxWrapper">
                                <div className="gramBox">
                                  <div>{ingredient?.unit}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </Panel>
                </>
              ))}
            </Collapse>

            {executeEnabled ? (
              <div className="butonGroup">
                <div
                  className="continue wizard-button"
                  onClick={() => setOpenOrderList(true)}
                >
                  Finish Plan
                </div>
              </div>
            ) : (
              <div className="butonGroup">
                <div className="wizard-button" onClick={handleBackStepper}>
                  Back
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  Continue
                </div>
              </div>
            )}
          </div>
        </Page>
      </Spin>
    </div>
  );
};

export default ProductionPlanWizard;
