import React from 'react';
import GridViewIcon from '../../assets/images/GridView.png';
import ListViewIcon from '../../assets/images/ListView.png';
import './styles.scss';

type Props = {
  listViewLayout: boolean;
  setListView: Function;
  component?: string;
};

const IconSwitcher = (props: Props) => {
  const { listViewLayout, setListView } = props;

  {
    /* <div className="gridIconContainer">
        <img
          src={GridViewIcon}
          className={`gridIcon ${listViewLayout ? 'gridActive' : ''}`}
          onClick={() => setListView(true)}
        ></img>

        <img
          src={ListViewIcon}
          className={`gridIcon ${!listViewLayout ? 'gridActive' : ''}`}
          onClick={() => setListView(false)}
        ></img>
      </div>
    </> */
  }

  return (
    <>
      <div className="gridIconContainer">
        <img
          src={ListViewIcon}
          className={`gridIcon ${listViewLayout ? 'gridActive' : ''}`}
          onClick={() => setListView(true)}
        ></img>
        <img
          src={GridViewIcon}
          className={`gridIcon ${!listViewLayout ? 'gridActive' : ''}`}
          onClick={() => setListView(false)}
        ></img>
      </div>
    </>
  );
};

export default IconSwitcher;
