/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Row, Col, PaginationProps, Spin, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  SubscriptionModal,
  ListViewWithoutExpand,
} from '../../components';
import LeadGenerationApi from '../../redux/middleware/leadGeneration';
import { sortNumber, sortedData } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import moment from 'moment';
import './leadGeneration.scss';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useNavigate } from 'react-router-dom';
import { convertCipherTextToObject } from '../../helper/utils';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">View</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const LeadGeneration = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [userId, setUserId] = useState();
  const [leadId, setLeadId] = useState();
  const [keyword, setKeyword] = useState('');
  const { currentBrand } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>DESCRIPTION</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('description', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'description',
      key: 'description',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 feedbackListViewScreen feedbackMinWidth">
          {record?.description?.length > 40
            ? record?.description?.substring(0, 40) + '...'
            : record?.description}
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>BRAND</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('brand_name', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'brand_name',
    //   key: 'brand_name',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => {
    //     return <div className="col3 cellSpacing">{record?.brand_name}</div>;
    //   },
    // },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>SCREEN</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('screen', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'screen',
    //   key: 'screen',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col3 feedbackListViewScreen">{record?.screen}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="feedbackCreatedAt">
          <div className="feedbackUsernameListView">{record?.user_name}</div>
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Created At</div>
                <div className="tootipText">
                  {moment(UTCtoLocal(record?.created_at)).format(
                    'DD-MM-YYYY HH:mm:ss'
                  )}
                </div>
              </div>
            }
          >
            <div className="feedbackCreatedAtValue">
              {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}{' '}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any) =>
        record?.lead_status === 0 ? (
          <div
            onClick={() => {
              setSubscriptionModal(true);
              setUserId(record?.created_by);
              setLeadId(record?.id);
            }}
            className="update-stock-button"
          >
            Custom Enterprise Plan
          </div>
        ) : (
          ''
        ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'FeedBack'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    setLoader(true);
    const variationData = await LeadGenerationApi.getLeads(dataLength, current);
    if (variationData === undefined || !variationData?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      // convertCipherTextToObject(variationData?.data);
      setCount(variationData?.data?.leads_count);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setLoader(true);
    const searchResultofVariations = await LeadGenerationApi.getSearchLeads(
      dataLength,
      keyword
    );
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResultofVariations?.data?.leads_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Lead Generation"
        breadcrumbIcon
        iconAction={() => alert('test')}
        button={''}
        perm_status={permissionStatus}
      />
      {subscriptionModal ? (
        <SubscriptionModal
          open={subscriptionModal}
          handleCancel={() => setSubscriptionModal(false)}
          component="Lead-Generation"
          leadId={leadId}
          userId={userId}
        />
      ) : null}
      <Page title="Lead Generation">
        <Row className="listingTopFilter">
          <Col lg={20} md={24}>
            <div style={{ display: 'flex', marginRight: '100px' }}>
              <ListCount count1={count} title1="Total Leads" />
            </div>
          </Col>
          <Col lg={4} md={24}>
            <div className="lead-search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!loader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={sortData ? [...sortData] : []}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={loader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default LeadGeneration;
