/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  BranchList,
  DeleteConfirmation,
  FoodicsTagging,
} from '../../components';
import BranchActions from '../../redux/middleware/branches';
import { sortNumber, sortedData } from '../../helper/sortData';
// import CompanyLight from '../../assets/images/CompanyLight.png';
// import Delete from '../../assets/images/Delete.svg';
import SettingDialog from '../../components/SettingDialog';
import More from '../../assets/images/More.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import EditSmall from '../../assets/images/EditSmall.png';
import Delete from '../../assets/images/Delete.svg';
import Connect from '../../assets/images/connect.svg';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import TaggingDialog from './tagging';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">View</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={EditSmall} className="iconDropdown" />,
  },
  {
    type: 'divider',
  },
  {
    key: '3',
    label: <div className="dotOption">Foodics Tagging</div>,
    icon: <img src={Connect} className="iconDropdown" />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} className="iconDropdown" />,
  },
];

const PageDataLength = [6, 10, 20, 50];
const Branch = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'branch',
  });
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [Loader, setLoader] = useState(false);
  const [Count, setCount] = useState(0);
  const [info, setInfo] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const [foodicsTaggingModal, setFoodicsTaggingModal] = useState(false);
  const [, setSearch] = useState('');
  const [openTaggingDialong, setTaggingDialog] = useState(false);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);

  const screenPermission = Utils.getScreenPermission('Branch');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('branch_name_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('shop_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'shop_name',
      key: 'shop_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div
            className="company table-title"
            onClick={() => {
              getBranchInfo(record?.id);
            }}
          >
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Name</div>
                  <div className="tootipText">{record?.shop_name}</div>
                </div>
              }
            >
              {record?.shop_name?.length > 20
                ? record?.shop_name?.substring(0, 20) + '...'
                : record?.shop_name}
            </Tooltip>
          </div>
        );
      },
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>BRAND NAME</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('company_name', 'string');
    //           setAscending(!ascending);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'company_name',
    //   key: 'company_name',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="col1 font">
    //       {/* <img src={record?.thumbnail} width="52px" height="52px" /> */}
    //       <div className="subCol1 font">
    //         <span className="col1Text font">
    //           {record?.brand_id
    //             ?.map((data: any) => data?.brand_name)
    //             ?.toString()}
    //         </span>
    //         <div className="subTextCol1 font">
    //           <img src={CompanyLight} alt="" className="companyLight" />
    //           <div className="email">{record?.company_name}</div>
    //         </div>
    //       </div>
    //     </div>
    //   ),
    // },

    {
      title: () => (
        <div className="tableTitle">
          <span>{t('brand_count')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('brand_id', 'array');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'brand_id',
      key: 'brand_id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Brand Names:</div>
              <div className="tootipText">
                {record?.brand_id
                  ?.map((brand: any) => ' ' + brand?.brand_name)
                  ?.toString()}
              </div>
            </div>
          }
        >
          <div className="cursor">{record?.brand_id?.length}</div>
        </Tooltip>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('ck_list_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('is_ck', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'is_ck',
      key: 'is_ck',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="city">{record?.is_ck ? 'YES' : 'NO'}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('city_list_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('city_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'city_name',
      key: 'city_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="city">{record?.city_name}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownmenuProps(record)}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setDialogData(record);
              setSelectedId(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        getBranchInfo(selectedId);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedId } });
        break;
      case 3:
        setFoodicsTaggingModal(true);
        break;
      case 4:
        // setOpenDeleteDialog(true);
        setTaggingDialog(true);
        break;
      default:
    }
  };

  const validateFTAction = (record: any) => {
    const brand = Utils.getCurrentBrand();
    const { brand_id } = record;
    const isExist = brand_id.filter((x: any) => x?.brand_id == brand?.brand_id);
    if (isExist?.length < 1) {
      return items.filter((x) => x?.key !== '4');
    }
    return items;
  };

  const DropDownmenuProps = (record: any) => {
    return {
      items: validateActionPermission()
        ? validateFTAction(record)
        : items.filter((x) => x?.key !== '2' && x?.key !== '4'),
      onClick: handleDropDownClick,
    };
  };

  const pageData = async () => {
    setLoader(true);
    const brand = await Utils.getCurrentBrand();
    const Data = await BranchActions.getShops(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );
    if (Data == undefined || !Data?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(Data?.data?.shop_count);
      setTotal(Data?.data?.pagination?.total);
      setSortData(Data?.data?.items);
    }
    setLoader(false);
  };

  const actionButton = (
    <Button
      text={t('add_new')}
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  // eslint-disable-next-line no-unused-vars
  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setLoader(true);
    const searchResult = await BranchActions.searchShops(
      dataLength,
      current,
      keyword
    );
    if (searchResult == undefined || !searchResult?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResult?.data?.shop_count);
      setTotal(searchResult?.data?.pagination?.total);
      setSortData(searchResult?.data?.items);
    }
    setLoader(false);
  };

  const getBranchInfo = async (id: any) => {
    const response = await BranchActions.getBranchById(id);
    setOpenDialog(true);
    if (response?.success) {
      const { data } = response;
      setInfo(data);
    }
  };

  // useEffect(() => {
  //   if (openDialog) getBranchInfo(selectedId);
  // }, [openDialog]);

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    await BranchActions.removeBranch(selectedId).then(() => pageData());
  };
  return (
    <React.Fragment>
      {foodicsTaggingModal ? (
        <FoodicsTagging
          open={foodicsTaggingModal}
          handleCancel={() => setFoodicsTaggingModal(false)}
          component="FoodicsTagging"
          branchId={selectedId}
        />
      ) : null}
      <TaggingDialog
        branch_id={selectedId}
        open={openTaggingDialong}
        handleClose={() => setTaggingDialog(!openTaggingDialong)}
      />
      <SettingDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={info}
        component={'branch'}
        title={'Branch Detail'}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete ${dialogData?.shop}?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />
      <Breadcrumb
        heading={t('breadcrumb_label')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={validateActionPermission() && actionButton}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={16}>
            <ListCount count1={Count} title1={t('total_branch')} />
          </Col>
          <Col lg={8} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <BranchList
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
                limit={dataLength}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" size="large" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default Branch;
