/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  Button,
  ListViewWithoutExpand,
  DetailDialog,
  DeleteConfirmation,
} from '../../components';
// import SubRecipeActions from '../../redux/middleware/subRecipe';
// import IngredientActions from '../../redux/middleware/ingredient';
import { sortNumber, sortedData } from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Delete from '../../assets/images/Delete.svg';
import ApproveInventoryTransfer from '../../assets/images/approveInventoryTransfer.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './requisitions.scss';
import SupplierApi from '../../redux/middleware/supplier';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const user = Utils.getCurrentUser();
const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="requisitions-dot-option">View</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  ...(user?.roles[0]?.id === 5
    ? [
        {
          key: '2',
          label: <div className="requisitions-dot-option">Edit</div>,
          icon: <img src={Edit} width={15} />,
        },
      ]
    : []),
  // {
  //   key: '4',
  //   label: <div className="requisitions-dot-option">Accept</div>,
  //   icon: <CheckCircleOutlined className="requisition-iconDropdown" />,
  // },
  {
    type: 'divider',
  },
  ...(user?.roles[0]?.id === 5
    ? [
        {
          key: '3',
          label: <div className="dotOption deleteText">Delete</div>,
          icon: <img src={Delete} width={15} />,
        },
      ]
    : []),
];

const PageDataLength = [6, 10, 20, 50];

const Requisitions = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [requisitions, setRequisitions] = useState([{}]);
  // const [database, setDatabase] = useState<any>([]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  // const [selectedDbId] = useState(3);
  const [requisitionLoader, setRequisitionLoader] = useState(false);
  const [requisitionCount, setRequisitionCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentShop?.shop_id);
  useEffect(() => {
    if (currentBrandId !== currentShop?.shop_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);
    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const fetchStatuses = async () => {
    const { data, success } = await SupplierApi.getSupplyStatus('status');
    if (success) {
      setStatuses(data);
    }
  };

  const columns = [
    {
      title: () => (
        <div className="requisitions-table-title requisition-table-serial-no">
          <span>REQUISITION ID</span>
          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('id', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div
          className="requisitions-column requisitions-cell-spacing table-title"
          onClick={() => {
            setLoader(true);
            fetchDialogData(record?.id);
          }}
        >
          {record?.id}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>RAISED BY BRAND</span>
          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('brand_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="requisitions-column requisitions-cell-spacing">
          {/* {record?.brand_name} */}
          {record?.brand_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.brand_name}</div>
                </div>
              }
            >
              {record?.brand_name?.substring(0, 22)}
              {record?.brand_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          ) : (
            record?.brand_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>REQUEST TO BRANCH</span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('shop_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">
          {record?.shop_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tootipText">{record?.shop_name}</div>
                </div>
              }
            >
              {record?.shop_name?.substring(0, 22)}
              {record?.shop_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          ) : (
            record?.shop_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>STATUS </span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('request_status', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="requisitions-table-col requisitions-column requisitions-col4">
        //   {record?.request_status}
        // </div>
        <div
          className={`requestStatusContainer ${
            record?.request_status?.toLowerCase() == 'pending'
              ? 'inventoryTransfer-request-pending'
              : record?.request_status?.toLowerCase() == 'approved'
              ? 'inventoryTransfer-request-approve'
              : 'inventoryTransfer-request-reject'
          } `}
        >
          {record?.request_status}
        </div>
      ),
    },
    {
      title: () => (
        <div className="requisitions-table-title">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="requisitions-title-arrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="requisitions-table-col requisitions-column createdAtAlignment">
            <div className="subrecipecol2">
              {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) =>
        record?.request_status?.toLowerCase() === 'approved' ? (
          <div className="inventory-transfer-list-icons-container">
            <div className="imagesContainer">
              <Tooltip
                title={
                  <div>
                    <div className="tootipText">Accept Stock Request</div>
                  </div>
                }
              >
                <img
                  src={ApproveInventoryTransfer}
                  alt=""
                  className="inventory-transfer-list-view-icons"
                  onClick={() => SubmitAcceptStatus()}
                />
              </Tooltip>
            </div>
          </div>
        ) : (
          ''
        ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              record?.request_status?.toLowerCase() === 'approved'
                ? items.filter((x) => x?.key !== '2')
                : record?.request_status?.toLowerCase() === 'closed'
                ? items.filter((x) => x?.key !== '4' && x?.key !== '2')
                : items.filter((x) => x?.key !== '4'),
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="requisitions-dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
    fetchStatuses();
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Stock Request'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    // fetchDatabase();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand, currentShop]);

  // const fetchDatabase = async () => {
  //   const data = await IngredientActions.getDatabase();
  //   // setDatabase(data?.data);
  // };

  const fetchDialogData = async (id: any) => {
    const { data, success } = await SupplierApi.getInventorySupplyById(id);
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const SubmitAcceptStatus = async () => {
    const statusId =
      statuses?.find((status: any) => status?.slug == 'received')?.id || '';
    const { success } = await SupplierApi.acceptInventorySupply(
      { request_status_id: statusId },
      selectedMenu
    );
    setLoader(false);
    if (success) pageData();
  };
  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        setLoader(true);
        fetchDialogData(selectedMenu);
        break;
      case 2:
        navigate('wizard', { state: { update: true, id: selectedMenu } });
        break;
      case 3:
        setOpenDeleteDialog(true);
        break;
      // case 4:
      //   setLoader(true);
      //   SubmitAcceptStatus();
      //   break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    setLoader(true);
    const { success } = await SupplierApi.deleteInventorySupply(
      {
        company_id: company?.id,
      },
      selectedMenu
    );
    setLoader(false);
    if (success) pageData();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: (
        <div className="requisitions-font requisitions-dot-option">{data}</div>
      ),
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    let request_id;
    setRequisitionLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'requisition');
    const requisitionData = await SupplierApi.searchInventorySupply(
      request_id?.id,
      dataLength,
      current,
      shop?.shop_id ? shop?.shop_id : '',
      brand?.brand_id ? brand?.brand_id : '',
      keyword
    );
    if (requisitionData === undefined || !requisitionData?.success) {
      setRequisitionCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRequisitionCount(requisitionData?.data?.supply_count);
      setTotal(requisitionData?.data?.pagination?.total);
      setRequisitions(requisitionData?.data?.items);
      setSortData(requisitionData?.data?.items);
    }
    setRequisitionLoader(false);
  };

  const onSearch = async (keyword: string) => {
    // let request_id;
    // setSearch(keyword);
    setRequisitionLoader(true);
    // const shop = await Utils.getCurrentShop();
    // const brand = await Utils.getCurrentBrand();
    // const id = selectedDbId;
    // const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    // if (success)
    //   request_id = data?.find((supply: any) => supply?.slug == 'requisition');
    // // const id = selectedDbId;
    // const requisitionSearchResult = await SupplierApi.searchInventorySupply(
    //   request_id?.id,
    //   dataLength,
    //   current,
    //   shop?.shop_id ? shop?.shop_id : '',
    //   brand?.brand_id ? brand?.brand_id : '',
    //   keyword
    // );
    // if (
    //   requisitionSearchResult === undefined ||
    //   !requisitionSearchResult?.success
    // ) {
    //   setRequisitionCount(0);
    //   setTotal(0);
    //   setSortData([]);
    // } else {
    //   setRequisitionCount(requisitionSearchResult?.data?.supply_count);
    //   setTotal(requisitionSearchResult?.data?.pagination?.total);
    //   setSortData(requisitionSearchResult?.data?.items);
    // }

    if (keyword) {
      const results = sortData.filter(
        (obj: any) =>
          obj.brand_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.shop_name?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.request_status?.toLowerCase().includes(keyword.toLowerCase()) ||
          obj.created_at?.includes(keyword)
      );
      setSortData(results);
    } else {
      setSortData(requisitions);
    }
    setRequisitionLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Stock Request"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={user?.roles[0]?.id === 5 ? actionButton : ''}
        // perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Stock Request">
          <DeleteConfirmation
            handleDeleteCancel={handleDeleteCancel}
            handleDeleteContinue={handleDeleteContinue}
            open={openDeleteDialog}
            message={'Are you sure want to delete requisition?'}
          />
          <DetailDialog
            open={openDialog}
            handleClose={handleClose}
            data={dialogData}
            component={'requisition'}
          />
          <Row className="requisitions-listingTopFilter">
            <Col lg={14} md={24}>
              <div style={{ display: 'flex', marginRight: '100px' }}>
                <ListCount
                  count1={requisitionCount}
                  title1="Total Requisitions"
                  title3="Brand"
                  title4="Branch"
                  component={'Requisitions'}
                />
                {/* <div className="uploadImageMenuContainer">
                {database?.map((db: any, index: any) => {
                  return (
                    <>
                      <div
                        className={
                          selectedDbId === db?.id ? 'menu' : 'menuLight'
                        }
                      >
                        <div
                          onClick={() => handleActiveTab(db)}
                          className="text menustyle"
                        >
                          {db?.db_name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
              </div>
            </Col>
            <Col lg={10} md={24}>
              <div className="requisitions-search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!requisitionLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={items}
                  dataSource={sortData ? [...sortData] : []}
                  columns={columns}
                />
              ) : (
                <div className="requisitions-loader-wrapper">
                  <Spin spinning={requisitionLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Requisitions;
