import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
// import Print from '../../assets/images/Print.png';
import ArrowLeft from '../../assets/images/ArrowLeft.png';
import './style.scss';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import RecipeActions from '../../redux/middleware/recipe';
import { handleFloat } from '../../helper/sortData';

type Props = {
  open: boolean;
  handleSubDialog: any;
  handleCloseAll: any;
  data: any;
  component?: any;
};

const SubDialog = (props: Props) => {
  let { open, handleSubDialog, handleCloseAll, data, component } = props;
  const [Recipedata, setRecipedata] = useState<any>([]);
  const [ingredients, setIngredients] = useState<any>([]);

  const fetchDataById = async (id: any) => {
    let recipeId;
    if (component === 'recipe' || component === 'subRecipe') {
      recipeId = id;
    } else {
      if (
        typeof id === 'object' &&
        id.ingredients &&
        id.ingredients.length > 0
      ) {
        const subRecipe = id.ingredients.find(
          (ingredient: any) => ingredient.ingredient_type === 'sub_recipe'
        );
        if (subRecipe) {
          recipeId = subRecipe?.external_id;
        }
      }
    }
    if (component === 'menu') {
      if (!recipeId && id.sub_recipe_id) {
        recipeId = id.sub_recipe_id;
      }
    }
    if (!recipeId) {
      return;
    }
    if (component === 'RecipeBuilder') {
      const details = await RecipeActions.getDetailRecipe(recipeId);
      setRecipedata(details?.data?.general);
      setIngredients(details?.data?.ingredients);
    } else {
      const data = await SubRecipeActions.getDetailRecipe(recipeId);
      setRecipedata(data?.data?.general);
      setIngredients(data?.data?.ingredients);
    }
  };

  useEffect(() => {
    if (
      data &&
      !(
        data &&
        Object.keys(data).length === 0 &&
        Object.getPrototypeOf(data) === Object.prototype
      )
    ) {
      fetchDataById(data);
    }
  }, [data]);
  return (
    <Modal
      title={
        <div className="titleContainer">
          <img
            src={ArrowLeft}
            alt=""
            onClick={handleSubDialog}
            className="arrowBack"
          />
          <div className="title-recipe font">Sub Recipe</div>
        </div>
      }
      centered
      open={open}
      onCancel={handleCloseAll}
      width={480}
      footer={null}
      bodyStyle={{ height: '530px' }}
      className="SubDialogWrapper"
    >
      <div className="subModelBody">
        {/* <div className="cover-photo">
          <img
            src={Recipedata?.thumbnail}
            alt=""
            width={'432px'}
            height="240px"
            className="image"
          />
          <div className="imgtag">{Recipedata?.menu_category}</div>
        </div> */}
        <div className="subhead-recipe">
          <div className="subHead-left-recipe font">
            {Recipedata?.recipe_name}
          </div>
          {/* <div className="subhead-recipe">
            <img src={Print} alt="" />
            <div className="subHead-right-recipe">Print</div>
          </div> */}
        </div>
        <div className="block block1">
          <div>
            <span className={`callories-number `}>
              {handleFloat(+Recipedata?.calorie)}
            </span>
            <span className="font callories-unit">{'kcal'}</span>
          </div>
          <div>
            <span className={`carb-number `}>
              {handleFloat(Recipedata?.carb)}
            </span>
            <span className="font carb-unit">{'g'}</span>
          </div>
          <div>
            <span className={`fat-number `}>
              {handleFloat(Recipedata?.fat)}
            </span>
            <span className="font fat-unit">{'g'}</span>
          </div>
          <div>
            <span className={`font protein-number `}>
              {handleFloat(Recipedata?.protein)}
            </span>
            <span className="font protein-unit">{'g'}</span>
          </div>
        </div>
        <div className="block block2">
          <span className={'callories-text'}>calorie</span>
          <span className={'callories-text'}>carb</span>
          <span className={'callories-text'}>fat</span>
          <span className={'callories-text'}>protein</span>
        </div>
        <div className="block-recipe block2-recipe">
          {data?.specs?.map((detail: any, index: any) => (
            <div
              key={index}
              className={detail?.name === 'Protein' ? 'protein-recipe' : ''}
            >
              {detail?.name}
            </div>
          ))}
        </div>

        <div className="block3-recipe">
          <div className="ingredient-recipe">Ingredients</div>
          <div className="ingredient-recipe">QUANTITY</div>
        </div>
        {ingredients?.map((ingredient: any, index: number) => {
          return (
            <div className="block4" key={index}>
              <div className={`block4-text font `}>
                {ingredient?.ingredient_name}
              </div>
              <div className="block4-weight">
                {ingredient?.quantity + ' ' + ingredient?.unit}
              </div>
            </div>
          );
        })}
        <div className="allergen font">Allergens</div>
        <div className="allergen-text">
          {component === 'RecipeBuilder'
            ? Recipedata?.allergen
            : Recipedata?.allergen}{' '}
        </div>
        <div className="additional font">Additional Info</div>
        <div className="additional-text">
          {Recipedata?.additional_attribute}
        </div>
      </div>
    </Modal>
  );
};

export default SubDialog;
