/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Modal, Spin, Table, Button } from 'antd';
import BranchActions from '../../redux/middleware/branches';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import { sortNumber, sortedData } from '../../helper/sortData';
import { ResponseDialog } from '../../components';
import './style.scss';
type Props = {
  handleCancel: any;
  open: boolean;
  component?: string;
  branchId?: any;
};

const FoodicsTaggingModal = (props: Props) => {
  let { handleCancel, open, branchId } = props;
  const [loader, setLoader] = useState(false);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getFoodicsBranches();
  }, []);

  const getFoodicsBranches = async () => {
    setLoader(true);
    const response = await BranchActions.getFoodicsBranches();
    if (response?.success) {
      const { data } = response;
      setData(data);
    }
    setLoader(false);
  };

  const onSubmit = async (id: any) => {
    try {
      setLoader(true);
      const response = await BranchActions.setFTBranch(branchId, id);
      setLoader(false);
      const { success, message } = response;
      if (success) {
        resetFormOnClose();
      } else {
        const responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Tagging Failed!', `${message}`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          'Tagging Failed!',
          `Somthing went wrong, please try later`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const resetFormOnClose = () => {
    !loader && handleCancel();
  };

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const columns = [
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>ID</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('id', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'id',
    //   key: 'id',
    //   render: (text: any, record: any) => (
    //     <div className="font">{record?.id}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>FOODICS BRANCH NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      render: (text: any, record: any) => (
        <div className="col1 font">{record?.name}</div>
      ),
    },
    {
      title: '',
      key: '',
      render: (text: any, record: any) => (
        <Button
          className="theme-button recipeBtn taging-btn"
          onClick={() => onSubmit(record.id)}
        >
          Tag Branch
        </Button>
      ),
    },
  ];

  return (
    <Modal
      title={<div className="title font">Foodics Tagging</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <Table
          dataSource={data}
          columns={columns}
          rowKey="id"
          pagination={false}
        />
        <div className="butonGroup">
          <div className="wizard-button" onClick={() => handleCancel(false)}>
            Cancel
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
export default FoodicsTaggingModal;
