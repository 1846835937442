/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Checkbox, Modal } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './orderTypeDialog.scss';

type Props = {
  orderVariation: any;
  handleDialogCancel: any;
  open: any;
  orderTypeData: any;
  handleDialog: any;
};

const OrderTypeDialog = (props: Props) => {
  let {
    orderVariation,
    open,
    handleDialogCancel,
    orderTypeData,
    handleDialog,
  } = props;
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [checkedListCategory, setCheckedListCategory] = useState<
    CheckboxValueType[]
  >([]);
  const [displayedOrderType, setDisplayedOrderType] = useState<string>('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (orderVariation?.length) {
      const orderTypes = orderVariation?.map((data: any) => ({
        label: data?.name,
        value: data?.id,
      }));

      setCategoryOptions(
        orderTypes?.filter((data: any) => data?.label !== 'All')
      );
    }
  }, [orderVariation]);

  useEffect(() => {
    if (orderTypeData && Object.keys(orderTypeData).length !== 0) {
      const checkedList = orderTypeData?.id?.split(',');
      setCheckedListCategory(checkedList?.map((data: any) => +data));
      if (checkedList.length === categoryOptions.length) {
        // setDisplayedOrderType(orderTypeData?.name);
        setDisplayedOrderType('All');
      } else {
        setDisplayedOrderType('Limited');
      }
    }
  }, [orderTypeData?.id]);

  function onChangeCategoryValues(checkedValues: any) {
    setCheckedListCategory(checkedValues);
    if (checkedValues.length === categoryOptions.length) {
      setDisplayedOrderType('All');
      setError(false);
    } else if (checkedValues.length === 0) {
      setDisplayedOrderType('Not Selected');
    } else {
      setError(false);
      const selectedLabels = categoryOptions
        .filter((option) => checkedValues.includes(option.value))
        .map((option) => option.label)
        .join(', ');
      setDisplayedOrderType('Limited');
      // setDisplayedOrderType(selectedLabels);
    }
  }

  return (
    <Modal
      title={<div className="title font">Order Type</div>}
      centered
      open={open}
      onCancel={() => {
        const checkedList = orderTypeData?.id?.split(',');
        setCheckedListCategory(checkedList?.map((data: any) => +data));
        handleDialogCancel();
      }}
      footer={null}
      className="orderTypeModal"
    >
      <div className="orderTypeDialogContainer">
        <Checkbox.Group style={{ width: '100%' }}>
          <Checkbox.Group
            className="checkBoxStyling"
            options={categoryOptions}
            defaultValue={['Apple']}
            value={checkedListCategory}
            onChange={onChangeCategoryValues}
          />
        </Checkbox.Group>
        {error && (
          <div className="subRecipeErrorMessages">
            Please select an order type
          </div>
        )}
        <div className={`butonGroup ordertype-btn`}>
          <div className="wizard-button" onClick={handleDialogCancel}>
            Back
          </div>
          <div
            className={`continue wizard-button`}
            onClick={() => {
              if (checkedListCategory.length === 0) {
                setError(true);
                return;
              }
              handleDialog(
                checkedListCategory?.toString(),
                checkedListCategory?.length == categoryOptions?.length
                  ? 'All'
                  : checkedListCategory?.length == 0
                  ? 'Not Selected'
                  : displayedOrderType
              );
            }}
          >
            Continue
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderTypeDialog;
