import React, { useEffect, useState } from 'react';
import { Modal, Select, Spin } from 'antd';
// import ResponseDialog from '../ResopnseDialog';
import PermissionApi from '../../redux/middleware/permission';
import UserAccessApi from '../../redux/middleware/userAccess';
import './userAccessDialog.scss';

type Props = {
  handleDialog: any;
  open: boolean;
  update?: boolean;
  id?: any;
  blocked?: any;
  handleDialogOnSuccess: any;
};

const UserAccessDialog = (props: Props) => {
  let { handleDialog, open, update, id, blocked, handleDialogOnSuccess } =
    props;

  const [accessList] = useState([
    { label: 'Read Only', value: 1 },
    { label: 'GrantAccess', value: 2 },
  ]);
  const [access, setAccess] = useState<any>({});
  const [permission, setPermission] = useState<any>({});
  const [permissions, setPermissions] = useState<any>([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [roles, setRoles] = useState<any>([]);
  const [role, setRole] = useState<any>({});
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const fetchData = async () => {
    const user = await UserAccessApi.getUserAccessById(id);
    setAccess({
      label: user?.data[0]?.perm_status,
      value: user?.data[0]?.perm_status_id,
    });
    setPermission({
      label: user?.data[0]?.permission,
      value: user?.data[0]?.permission_id,
    });
    setRole({
      label: user?.data[0]?.role,
      value: user?.data[0]?.role_id,
    });
    setLoader(false);
  };

  const fetchRoles = async () => {
    const rolesList = await UserAccessApi.getAllRoles();
    setRoles(
      rolesList?.data?.items?.slice(1).map((data: any) => ({
        label: data?.name,
        value: data?.id,
      }))
    );
    setLoader(false);
  };

  const fetchPermissions = async () => {
    const permissionData = await PermissionApi.getPermission(1000, 1);
    setPermissions(
      permissionData?.data?.items?.map((data: any) => ({
        label: data?.name,
        value: data?.id,
      }))
    );
    setLoader(false);
  };

  useEffect(() => {
    fetchRoles();
    fetchPermissions();
    if (update) {
      fetchData();
      setLoader(true);
    }
  }, []);

  const handlePermission = (value: string | string[]) => {
    setPermission(
      permissions?.filter((data: any) => data?.value == value)[0] || {}
    );
  };

  const handleRole = (value: string | string[]) => {
    setRole(roles?.filter((data: any) => data?.value == value)[0] || {});
  };

  const handleAccess = (value: string | string[]) => {
    setAccess(accessList?.filter((data: any) => data?.value == value)[0] || {});
  };

  const handleSubmit = async () => {
    if (
      (permission &&
        Object.keys(permission).length === 0 &&
        Object.getPrototypeOf(permission) === Object.prototype) ||
      (role &&
        Object.keys(role).length === 0 &&
        Object.getPrototypeOf(role) === Object.prototype) ||
      (access &&
        Object.keys(access).length === 0 &&
        Object.getPrototypeOf(access) === Object.prototype)
    ) {
      setError(true);
      return;
    }
    setError(false);
    let param: any = {
      role_id: role?.value,
      permission_id: permission?.value,
      perm_status_id: access?.value,
    };
    setErrorList({});
    setErrorStatus(false);
    try {
      // let responseDialogData;
      let response: any;
      if (update) {
        response = await UserAccessApi.updateUserAccess(param, id);
      } else {
        response = await UserAccessApi.createUserRole(param);
      }
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
        // responseDialogData = {
        //   OnClick: () => {},
        //   type: 'error',
        //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
        // };
        // ResponseDialog(responseDialogData);
      } else {
        handleDialogOnSuccess();

        // responseDialogData = {
        //   OnClick: () => {
        //     handleDialogOnSuccess();
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your item has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal
      title={<div className="title font">Add User Access</div>}
      centered
      open={open}
      onCancel={handleDialog}
      width={608}
      footer={null}
      className="userAccessDialogContainer"
    >
      {loader ? (
        <Spin spinning={loader} size="large" className="loaderWidthHeight" />
      ) : (
        <div className="ingredientDialog">
          <div className="ingredientSubContainer">
            <div className="ingredientLabel">
              Role <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              defaultValue={'Select Role'}
              onChange={handleRole}
              style={{ width: '100%' }}
              options={roles}
              value={role?.label}
              disabled={update}
              className={
                errorList['role_id']?.length > 0 ? 'dropdownUnitColorRed' : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['role_id']}
              </div>
            )}
          </div>

          <div className="ingredientSubContainer UOMAllergen">
            <div className="spanWidth">
              <div className="ingredientLabel">
                Permission <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                defaultValue={'Select Permission'}
                onChange={handlePermission}
                style={{ width: '100%' }}
                options={permissions}
                value={permission?.label}
                disabled={update}
                className={
                  errorList['perm_status_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['perm_status_id']}
                </div>
              )}
            </div>
          </div>
          <div className="ingredientSubContainer">
            <div className="ingredientLabel">
              Access <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              defaultValue={'Select Access'}
              onChange={handleAccess}
              style={{ width: '100%' }}
              options={accessList}
              value={access?.label}
              className={
                errorList['permission_id']?.length > 0
                  ? 'dropdownUnitColorRed'
                  : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['permission_id']}
              </div>
            )}
          </div>

          {error && <div className="error">*Fill All Feilds</div>}

          {blocked ? null : (
            <div className="butonGroup">
              {/* <div className="wizard-button" onClick={() => handleDialog({})}>
            Back
          </div> */}
              <div className="continue wizard-button" onClick={handleSubmit}>
                Continue
              </div>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default UserAccessDialog;
