/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import './styles.scss';
import Utils from '../../redux/utils';
import { useTranslation } from 'react-i18next';

type Props = {
  categoryData: any;
  roleData: any;
  designationData: any;
  companyData: any;
  brandsData: any;
  branchesData: any;
  branchesDataForRole2?: any;
  branchIdForRole2?: any;
  handleCompany: any;
  handleBranchIdForRole2?: any;
  handleBrand: any;
  handleChangeRole: any;
  userRole: any;
  designation: any;
  company: any;
  branchId: any;
  handleBranchId: any;
  handleChangeDesgnation: any;
  name: any;
  handleName: any;
  handlePhone: any;
  phone: any;
  handleEmail: any;
  email: any;
  handlePassword: any;
  password: any;
  brandId: any;
  errorList?: any;
  errorStatus?: boolean;
  countryCode?: any;
  toggleField?: any;
  hidePasswordField?: boolean;
  handleTogglePasswordField?: any;
  verified?: number;
  isConfigurationForm?: boolean;
  update?: boolean;
};
const UserWizardStepOne = (props: Props) => {
  let {
    roleData,
    designationData,
    companyData,
    brandsData,
    branchesData,
    branchIdForRole2,
    branchesDataForRole2,
    handleBranchIdForRole2,
    handleCompany,
    handleBrand,
    handleChangeRole,
    userRole,
    designation,
    company,
    handleChangeDesgnation,
    branchId,
    brandId,
    handleBranchId,
    handleName,
    name,
    handlePhone,
    phone,
    handleEmail,
    email,
    password,
    handlePassword,
    errorList,
    errorStatus,
    countryCode,
    toggleField,
    hidePasswordField,
    handleTogglePasswordField,
    //verified,
    update,
    isConfigurationForm,
  } = props;
  const user = Utils.getCurrentUser();
  const role_id = user?.roles[0]?.id;
  // const country_code = user?.country_code;
  const companyObject = Utils.getCurrentCompany();
  const company_name = user?.company_name;

  const { t } = useTranslation('translation', {
    keyPrefix: 'users',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // React.useEffect(() => {
  //   if (role_id !== 3 && role_id !== 2) {
  //     setBlock(true);
  //   }
  // }, []);

  // const selectBefore = (
  //   <Select defaultValue="+92">
  //     <Option value="+92">+92</Option>
  //     <Option value="+93">+93</Option>
  //   </Select>
  // );

  const getBranchBrandDropdown = () => {
    return toggleField ? (
      <>
        {role_id === 1 ? null : (
          <div className="wizardDropdown">
            <div className="wizardLabel">
              {t('brand_label')} <span className="sterik">*</span>
            </div>
            {role_id == 2 || role_id == 3 ? (
              <Select
                mode={'multiple'}
                style={{ width: '100%' }}
                placeholder={t('please_select_placeholder')}
                value={brandId}
                onChange={handleBrand}
                options={brandsData}
              />
            ) : (
              <Input
                size={'large'}
                placeholder={t('select_branch_placeholder')}
                disabled
                style={{ width: 560 }}
                value={Utils.getCurrentBrand()?.brand_name}
              />
            )}
          </div>
        )}
        {role_id === 2 || role_id === 1 ? null : (
          <div className="wizardDropdown">
            <div className="wizardLabel">
              {t('branch_label_wizard')}
              {/* <span className="sterik">*</span> */}
            </div>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              // disabled
              placeholder={t('please_select_placeholder')}
              value={branchId || []}
              onChange={handleBranchId}
              options={branchesData}
            />
          </div>
        )}
      </>
    ) : (
      <>
        {role_id === 2 || role_id === 1 ? null : (
          <div className="wizardDropdown">
            <div className="wizardLabel">
              {t('branch_label_wizard')}
              {/* <span className="sterik">*</span> */}
            </div>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              placeholder={t('please_select_placeholder')}
              value={branchId}
              onChange={handleBranchId}
              options={branchesData}
            />
          </div>
        )}

        {role_id === 1 ? null : (
          <div className="wizardDropdown">
            <div className="wizardLabel">
              {t('brand_label')} <span className="sterik">*</span>
            </div>
            {role_id == 2 || role_id == 3 ? (
              <Select
                mode={'multiple'}
                style={{ width: '100%' }}
                placeholder={t('please_select_placeholder')}
                value={brandId || []}
                onChange={handleBrand}
                options={brandsData}
              />
            ) : (
              <Input
                size={'large'}
                placeholder={t('select_branch_placeholder')}
                style={{ width: 560 }}
                disabled
                value={Utils.getCurrentBrand()?.brand_name}
              />
            )}
          </div>
        )}

        {role_id === 2 ? (
          <div className="wizardDropdown">
            <div className="wizardLabel">
              {t('branch_label_wizard')}
              {/* <span className="sterik">*</span> */}
            </div>
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              // disabled
              placeholder={t('please_select_placeholder')}
              value={branchIdForRole2}
              onChange={handleBranchIdForRole2}
              options={branchesDataForRole2}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className="userWizardStepOne">
      <div className="Ingredient-head">{t('user_role_label')}</div>
      {!isConfigurationForm && (
        <div className="wizardDropdown">
          <div className="wizardLabel">
            {t('user_role_label')} <span className="sterik">*</span>
          </div>

          {user?.roles[0]?.id == 3 ? (
            <Select
              placeholder={t('select_here')}
              onChange={handleChangeRole}
              style={{ width: 560 }}
              options={roleData}
              value={userRole}
            />
          ) : (
            <span className="userRole">
              <span>{userRole?.label}</span>
            </span>
          )}
        </div>
      )}
      <div className="wizardDropdown">
        <div className="wizardLabel">
          {t('designation')} <span className="sterik">*</span>
        </div>
        {role_id == 1 || isConfigurationForm ? (
          <span className="userRole">
            <span>{designation[0]?.label}</span>
          </span>
        ) : (
          <>
            <Select
              disabled={role_id == 3}
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('please_select_placeholder')}
              onChange={handleChangeDesgnation}
              options={designationData}
              value={designation}
              className={
                errorList['designation']?.length > 0
                  ? 'dropdownUnitColorRed'
                  : ''
              }
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['designation']}
              </div>
            )}
          </>
        )}
      </div>
      {!isConfigurationForm && (
        <div className="wizardDropdown">
          <div className="wizardLabel">
            {t('company')} <span className="sterik">*</span>
          </div>
          {role_id === 1 ? (
            <div>
              <Select
                placeholder={t('select_company')}
                onChange={(e: any) => {
                  handleCompany(e);
                }}
                style={{ width: 560 }}
                options={companyData}
                value={company}
                className={
                  errorList['company_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['company_id']}
                </div>
              )}
            </div>
          ) : (
            <span>
              <Input
                className="user-company"
                size={'large'}
                placeholder={t('input_company_here')}
                onChange={handleCompany(companyObject?.id)}
                disabled={true}
                style={{ width: 560 }}
                value={company_name}
              />
            </span>
          )}
        </div>
      )}
      {!isConfigurationForm && getBranchBrandDropdown()}
      <div className="Ingredient-head">{t('user_information')}</div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          {t('name')} <span className="sterik">*</span>
        </div>
        <Input
          placeholder={t('enter_name')}
          size="large"
          value={name}
          onChange={(e) => handleName(e.target.value)}
          required
          className={errorList['name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['name']}</div>
        )}
      </div>
      {/* <div className="wizardSubContainer">
        <div className="wizardLabel">Phone Number</div>
        <Input
          addonBefore={country_code}
          placeholder={t("enter_phone")}
          value={phone}
          onChange={(e) => handlePhone(Number(e.target.value))}
        />
      </div> */}
      {!isConfigurationForm && (
        <div className="wizardSubContainer">
          <div className="wizardLabel">{t('phone')}</div>
          <Input
            addonBefore={countryCode}
            placeholder={t('enter_phone')}
            type="number"
            value={phone}
            onChange={(e) => handlePhone(e.target.value)}
            className={errorList['phone']?.length > 0 ? 'borderRed' : ''}
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">{errorList['phone']}</div>
          )}
        </div>
      )}
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          {t('email')} <span className="sterik">*</span>{' '}
        </div>
        <Input
          placeholder={t('enter_email')}
          size="large"
          type="email"
          value={email}
          onChange={(e) => handleEmail(e.target.value)}
          required
          className={errorList['email']?.length > 0 ? 'borderRed' : ''}
          disabled={update || isConfigurationForm}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">{errorList['email']}</div>
        )}
      </div>
      {/* {hidePasswordField ? (
        <>
          <div className="wizardSubContainer">
            <div className="wizardLabel">
              {t('password')} <span className="sterik">*</span>
            </div>
            <Input
              placeholder={t('enter_password')}
              size="large"
              value={password}
              onChange={(e) => handlePassword(e.target.value)}
              required
              className={errorList['password']?.length > 0 ? 'borderRed' : ''}
            />
            {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['password']}
              </div>
            )}
          </div>
          {password.length >= 10
            ? ''
            : !errorStatus && (
                <div className="passwordValidation">
                  {t('password_error_text')}
                </div>
              )}
        </>
      ) : (
        <div
          className="user-change-password-text"
          onClick={() => handleTogglePasswordField()}
        >
          {t('change_password')}
        </div>
      )} */}
    </div>
  );
};

export default UserWizardStepOne;
