import React, { useState } from 'react';
import { Modal } from 'antd';
// import { handleFloat } from '../../../helper/sortData';
import './style.scss';
import moment from 'moment';

type Props = {
  open: boolean;
  handleClose: any;
  data: any;
};

const Dialog = (props: Props) => {
  let { open, handleClose, data } = props;
  // eslint-disable-next-line no-unused-vars
  const [wastageInfo, setWastageInfo] = useState<any>([]);

  React.useEffect(() => {
    setWastageInfo(data);
  }, [data]);

  return (
    <Modal
      title={<div className="title font">Wastage Detail</div>}
      centered
      open={open}
      onCancel={() => handleClose()}
      width={480}
      footer={null}
      className="DialogWrapper wastageModal"
    >
      <div className="block3-wastage">
        <div className="ingredient">Wastage Type</div>
        <div className="ingredient">Wastage QTY</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient-text">{wastageInfo?.waste_type}</div>
        <div className="ingredient-text">{wastageInfo?.quantity}</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient">Cash Value</div>
        <div className="ingredient">Business Date</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient-text">{wastageInfo?.cash_value}</div>
        <div className="ingredient-text">
          {moment(wastageInfo?.business_date).format('DD/MM/YYYY')}
        </div>
      </div>

      <div className="title font">Wastage Detail</div>
      <div className="block3-wastage">
        <div className="ingredient">Wastage Reason</div>
        <div className="ingredient">Company</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient-text">{wastageInfo?.reason}</div>
        <div className="ingredient-text">{wastageInfo?.company_name || ''}</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient">Brand</div>
        <div className="ingredient">Branch</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient-text">{wastageInfo?.brand_name}</div>
        <div className="ingredient-text">{wastageInfo?.shop_name}</div>
      </div>
      <div className="block3">
        <div className="ingredient">Created By</div>
        <div className="ingredient">Created Date</div>
      </div>
      <div className="block3-wastage">
        <div className="ingredient-text">{wastageInfo?.created_by}</div>
        <div className="ingredient-text">
          {moment(wastageInfo?.created_at).format('DD/MM/YYYY')}
        </div>
      </div>
    </Modal>
  );
};

export default Dialog;
