import React from 'react';
import { useLocation } from 'react-router-dom';
import RDSubRecipeWizard from '../rdSubRecipeWizard';
import RDRecipeWizard from './recipeWizard';

const RDWrapper = () => {
  const { state } = useLocation();
  const { type } = state;

  if (type === 2) return <RDSubRecipeWizard />;
  return <RDRecipeWizard />;
};

export default RDWrapper;
