import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Input, Select, Spin, Checkbox } from 'antd';
import { Page, Breadcrumb } from '../../components';
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import './branchCopy.scss';
import BranchActions from '../../redux/middleware/branches';
import Utils from '../../redux/utils';

const BranchCopyWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [brandList, setBrandList] = useState([]);
  //   const [menu, setMenu] = React.useState<recipes>({
  //     created_at: '2022-04-22T22:52:41.000000Z',
  //     id: 2,
  //     recipe_type: 'Sub Recipe',
  //     status: 1,
  //     updated_at: '2022-04-22T22:52:41.000000Z',
  //   });

  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [brand, setBrand] = React.useState<any>({});
  const [branchName, setBranchName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [cityData, setCityData] = useState<any[]>([]);
  const [city, setCity] = useState<any>({});
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const handleBack = () => {
    navigate('/app/branches');
  };

  const handleContinue = async () => {
    const company = await Utils.getCurrentCompany();

    if (
      branchName == '' ||
      address == '' ||
      (brand &&
        Object.keys(brand).length === 0 &&
        Object.getPrototypeOf(brand) === Object.prototype) ||
      (city &&
        Object.keys(city).length === 0 &&
        Object.getPrototypeOf(city) === Object.prototype)
    ) {
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    }
    const payload = {
      brand_id: brand?.key,
      shop_name: branchName,
      city: city?.name,
      city_id: city?.id,
      region: city?.region,
      region_id: city?.region_id,
      company_id: company?.id,
      country_id: user?.country_id,
      address,
    };

    try {
      // let responseDialogData;
      setApiLoader(true);
      let response;
      if (update) {
        response = await BranchActions.updateBranch(id, payload);
      } else {
        response = await BranchActions.addBranch(payload);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        navigate('/app/branches');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/branches');
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your branch - ${branchName}`,
        //     ` has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  //   type recipes = {
  //     id: number;
  //     recipe_type: string;
  //     status: number;
  //     created_at: string;
  //     updated_at: string;
  //   };

  const handlePickerChange = (value: string | string[], data: any) => {
    setBrand(data);
  };
  const handleCityChange = (value: string) => {
    const city = cityData?.find((data: any) => data?.id == value);
    setCity(city);
  };

  //   const onChangeMenu = (e: CheckboxChangeEvent, data: recipes) => {
  //     setMenu(data);
  //   };

  const getBrandListing = async () => {
    const response = await BranchActions.getBrands(10000, 1);
    if (response?.success) {
      const { data } = response;
      const tempList =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          label: item?.name,
          value: item?.name,
          key: item?.brand_id,
        }));
      setBrandList(tempList);
    }
  };

  const getBranchInfo = async () => {
    const response = await BranchActions.getBranchById(id);
    if (response?.success) {
      const { data } = response;
      setBranchName(data?.shop_name);
      setAddress(data?.address);
      setBrand({
        label: data?.brand,
        value: data?.brand,
        key: data?.brand_id,
      });
      setCity({
        name: data?.city,
        id: data?.city_id,
        region: data?.region,
        region_id: data?.region_id,
      });
    }
  };

  const getCities = async () => {
    const response = await BranchActions.getCities(user?.country_id);
    if (response?.success) {
      setCityData(response?.data?.items);
    } else {
      setCityData([]);
    }
  };

  React.useEffect(() => {
    getBrandListing();
    getCities();
    if (update) {
      getBranchInfo();
    }
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Branch"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/branches');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Branch">
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardDropdown">
              <div className="wizardLabel">
                Brand <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select brand'}
                onChange={handlePickerChange}
                style={{ width: 560 }}
                value={brand?.label}
                options={brandList}
                className={
                  errorList['brand_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['brand_id']}
                </div>
              )}
            </div>
            <div className="wizardDropdown">
              <Checkbox
              // onChange={(e) => onChangeMenu(e, data)}
              // value={menu?.status}
              // checked={data?.recipe_type == menu?.recipe_type}
              >
                Centralized Kitchen
              </Checkbox>
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                City <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select City'}
                onChange={handleCityChange}
                style={{ width: 560 }}
                value={city?.name}
                className={
                  errorList['city']?.length > 0 ? 'dropdownUnitColorRed' : ''
                }
              >
                {cityData?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['city']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Branch Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter branch name"
                size="large"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
                className={
                  errorList['shop_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['shop_name']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer minimumheight">
              <div className="wizardLabel">
                Address <span className="sterik">*</span>
              </div>
              <Input.TextArea
                placeholder="Enter address"
                size="large"
                value={address}
                className={`BranchTextArea ${
                  errorList['shop_name']?.length > 0 ? 'borderRed' : ''
                }`}
                onChange={(e) => setAddress(e.target.value)}
                rows={3}
                required
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['address']}
                </div>
              )}
            </div>
            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default BranchCopyWizard;
