import React, { useEffect } from 'react';
import './style.scss';
import { Row, Col, Checkbox, Switch } from 'antd';
import { useTranslation } from 'react-i18next';

type Props = {
  toggleSwitch: any;
  checkBox: any;
  menuHandler: any;
  handleCheckbox: any;
  handleSwitch: any;
  permissionList: any;
};

const UserWizardStepTwo = (props: Props) => {
  let {
    toggleSwitch,
    menuHandler,
    handleCheckbox,
    handleSwitch,
    permissionList,
    checkBox,
  } = props;

  const { t } = useTranslation('translation', {
    keyPrefix: 'users',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="userWizardStepTwo">
      <Row>
        <Col lg={24}>
          <div className="userWizardStepTwo-heading">
            <h3>{t('step2Heading')}</h3>
            <Checkbox onChange={menuHandler}>{t('active_all')}</Checkbox>
          </div>

          <div className="">
            {permissionList.map((val: any) => {
              return val?.permissions?.length ? (
                <div className="" key={val.id}>
                  <div className="permission-parent-title font">
                    {val.parent_screen}
                  </div>
                  <div className="userWizardStepTwo-content permissions-block-border">
                    {val?.permissions?.map(
                      (permission: any, innerIndex: number) => (
                        <div className="checkBox-wrapper" key={innerIndex}>
                          <div>
                            <div className="title">{permission.name}</div>
                            {permission.perm_status_id === 2 ? (
                              <Checkbox
                                onChange={(e) => handleCheckbox(e, permission)}
                                checked={
                                  checkBox.filter(
                                    (data: any) => data == permission?.id
                                  ).length > 0
                                    ? true
                                    : false
                                }
                              >
                                {t('read_only')}
                              </Checkbox>
                            ) : null}
                          </div>
                          <div className="toggle">
                            <Switch
                              checked={
                                toggleSwitch.filter(
                                  (data: any) => data == permission?.id
                                ).length > 0
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleSwitch(e, permission)}
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default UserWizardStepTwo;
