/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Dropdown, Space } from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  UserListDialog,
  UserList,
  PermissionModal,
} from '../../components';
import PermissionApi from '../../redux/middleware/permission';
import { sortNumber, sortedData } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.png';
import DownRed from '../../assets/images/DownRed.png';
import DownGreen from '../../assets/images/DownGreen.png';
import Company from '../../assets/images/Company.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">Edit</div>,
    icon: <img src={Edit} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const PermissionView = () => {
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [userLoader, setUserLoader] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [openPermissionDialog, setOpenPermissionDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [keyword, setKeyword] = useState('');
  const navigate = useNavigate();
  const [selectedMenu, setSelectedMenu] = useState({});
  const [update, setUpdate] = useState(false);
  //   const [selectedUserId, setSelectedUserId] = useState('');
  const [id, setid] = useState();
  const [dialogData, setDialogData] = useState({});
  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      PermissionApi.disablePermission(id, '1');
      pageData();
    } else {
      PermissionApi.disablePermission(id, '0');
      pageData();
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const userColumns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="id">{record?.id}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="font">
          <div className="name">{record?.name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Parent Screen</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('parent_screen', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'parent_screen',
      key: 'parent_screen',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="companyContainer">
          <img src={Company} />
          <div className="company">{record?.parent_screen}</div>
        </div>
      ),
    },

    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>STATUS</span>
    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('status');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <Dropdown
    //       overlayStyle={{ height: '100px' }}
    //       menu={menuDropdownProps}
    //       trigger={['click']}
    //       placement="bottomRight"
    //       arrow={{ pointAtCenter: true }}
    //     >
    //       <a
    //         onClick={(e) => {
    //           setid(record.id);
    //         }}
    //       >
    //         <Space>
    //           <div
    //             className={
    //               record?.status === 1
    //                 ? 'statusContainerActive'
    //                 : 'statusContainerNonActive'
    //             }
    //           >
    //             <div className="status">
    //               {record?.status == 1 ? 'Active' : 'Non-active'}
    //             </div>
    //             <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
    //           </div>
    //         </Space>
    //       </a>
    //     </Dropdown>
    //   ),
    // },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record?.id)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  useEffect(() => {
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Users'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        handleDialogData();
        break;
      }
      default:
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const handleDialogData = async () => {
    setUpdate(true);
    setOpenPermissionDialog(true);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        setOpenPermissionDialog(true);
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setUserLoader(true);
    const userData = await PermissionApi.getPermission(dataLength, current);
    if (userData === undefined) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(userData?.data?.total_permissions);
      setTotal(userData?.data?.pagination?.total);
      setSortData(userData?.data?.items);
    }
    setUserLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setUserLoader(true);
    const searchResultofRecipes = await PermissionApi.searchPermission(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setUserCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setUserCount(searchResultofRecipes?.data?.total_permissions);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setUserLoader(false);
  };

  const handleDialog = () => {
    setOpenPermissionDialog(false);
  };

  const handleSuccessDialog = () => {
    setOpenPermissionDialog(false);
    pageData();
  };

  return (
    <React.Fragment>
      {openPermissionDialog ? (
        <PermissionModal
          handleDialog={handleDialog}
          open={openPermissionDialog}
          component={'recipe'}
          name={''}
          update={update}
          id={selectedMenu}
          handleSuccessDialog={handleSuccessDialog}
        />
      ) : null}

      <Breadcrumb
        heading="Screen Permissions"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Permission">
        <UserListDialog
          open={openDialog}
          handleClose={handleClose}
          data={dialogData}
        />
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <ListCount count1={userCount} title1="Total Permissions" />
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
              </div>
              {/* <div className="exportButton">
                <img src={Export} alt="" />
                <div className="exportText"> Export</div>
              </div>
              */}
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!userLoader ? (
              <UserList
                dataSource={[...sortData]}
                userColumns={userColumns}
                limit={dataLength}
              />
            ) : (
              // <ListViewWithoutExpand
              //   items={items}
              //   dataSource={[...sortData]}
              //   columns={columns}
              // />
              <div className="loader-wrapper">
                <Spin spinning={userLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default PermissionView;
