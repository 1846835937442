/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts';

interface ChartBarVerticalProps {
  dataKey1?: string | any;
  dataKey2?: string | any;
  data: any[];
}

const ChartBarVertical = (props: ChartBarVerticalProps) => {
  const { data, dataKey1, dataKey2 } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 5) {
      return numberStr.substring(0, 5) + '...';
    }
    return numberStr;
  }

  const CustomLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    if (value === 0) return null;

    const offsetX = width < 20 ? -10 : width / 2;
    const offsetY = height < 20 ? -10 : height / 2;
    return (
      <text
        x={x + offsetX}
        y={y + offsetY}
        fill="grey"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  const filteredData = data.filter((entry) => entry.name !== null);

  return filteredData && filteredData.length > 0 ? (
    <BarChart
      width={460}
      height={180}
      data={filteredData}
      layout="vertical"
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
      <XAxis type="number" />
      <YAxis
        type="category"
        dataKey="name"
        tickFormatter={formatNumberWithEllipsis}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip
        formatter={(value, name, entry) => {
          if (name === dataKey1) {
            return [`${value}`, `${dataKey1}`];
          } else if (name === dataKey2) {
            return [`${value}`, `${dataKey2}`];
          }
          return [`${value}`, name];
        }}
      />
      <Legend />
      <Bar dataKey={dataKey1} stackId="stack" fill="#d62728">
        {/* <LabelList dataKey={dataKey1} content={CustomLabel} /> */}
      </Bar>
      <Bar dataKey={dataKey2} stackId="stack" fill="#f6a117 ">
        {/* <LabelList dataKey={dataKey2} content={CustomLabel} /> */}
      </Bar>
    </BarChart>
  ) : (
    <div className="no-data-graph">No data to display</div>
  );
};

export default ChartBarVertical;
