import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { SubWizardDropdown } from '../index';
import RecipeActions from '../../redux/middleware/recipe';
import { setRecipeTypes } from '../../redux/reducers/recipeSlice';
import { store } from '../../redux/store';
import { useDispatch } from 'react-redux';
import Delete from '../../assets/images/Delete.svg';
import './style.scss';

type Props = {
  stepper: number;
  getBase64: any;
  onChangeMenu: any;
  onChangeRecipe: any;
  handleChange: any;
  // handleContinue: any;
  handleBackStepper: any;
  handleRecipe: any;
  handleDescription: any;
  handleImage: any;
  image: string;
  category: any;
  recipe: string;
  menu: any;
  subRecipe: any;
  description: string;
  errorList?: any;
  errorStatus?: boolean;
  component: any;
  parentSubCategory: any;
  handleSubCategory: any;
  subCategory: any;
};

type recipes = {
  id: number;
  recipe_type: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const StepOne = (props: Props) => {
  const { TextArea } = Input;
  const [, setTypes] = useState<recipes[]>();
  const dispatch = useDispatch();
  const state = store.getState();
  const fileRef = React.useRef<HTMLInputElement>(null);
  let {
    // onChangeMenu,
    getBase64,
    // handleChange,
    handleRecipe,
    handleDescription,
    handleImage,
    image,
    // category,
    recipe,
    // menu,
    description,
    errorList,
    errorStatus,
    // eslint-disable-next-line no-unused-vars
    component,
    // parentSubCategory,
    subCategory,
    handleSubCategory,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    const recipeTypes = state.Recipe.recipeTypes;
    if (recipeTypes.length === 0) {
      fetchTypes();
    } else {
      setTypes(state.Recipe.recipeTypes);
    }
  }, []);

  const fetchTypes = async () => {
    const data = await RecipeActions.recipeType();
    setTypes(data?.data);
    dispatch(setRecipeTypes(data?.data));
  };

  return (
    <>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          Recipe Name <span className="sterik">*</span>
        </div>
        <Input
          placeholder="Enter recipe name"
          size="large"
          value={recipe}
          onChange={(e) => handleRecipe(e.target.value)}
          required
          className={errorList['recipe_name']?.length > 0 ? 'borderRed' : ''}
        />
        {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_name']}
          </div>
        )}
      </div>
      <div className="wizardSubContainer checkBox">
        {/* {types?.map((data: recipes, index: number) => {
          return (
            <Checkbox
              // onChange={(e) => onChangeMenu(e, data)}
              value={menu?.status}
              checked={data?.recipe_type == menu?.recipe_type}
              key={index}
            >
              {data?.recipe_type}
            </Checkbox>
          );
        })} */}
        {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
      </div>
      <div className="wizardSubContainer">
        <div className="wizardLabel">
          {component === 'rd-subrecipe'
            ? 'R&D Sub Recipe Category'
            : component === 'recipeBuilder'
            ? 'R&D Menu Category'
            : 'Category'}
          <span className="sterik">*</span>
        </div>
        {component !== 'rd-subrecipe' ? (
          // <WizardDropdown
          //   component="recipebuilder"
          //   blocked={false}
          //   text={category}
          //   handleChangeText={handleChange}
          // />
          <SubWizardDropdown
            component={'rdrecipe'}
            text={subCategory?.sub_category}
            handleChangeText={handleSubCategory}
          />
        ) : (
          <SubWizardDropdown
            component={'rdsubrecipe'}
            text={subCategory?.sub_category}
            handleChangeText={handleSubCategory}
          />

          // <WizardDropdown
          //   component="rd-sub-recipe"
          //   blocked
          //   text={category}
          //   handleChangeText={handleChange}
          // />
        )}
        {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['recipe_type_id'][0]}
              </div>
            )} */}
      </div>
      {/* component !== 'rd-subrecipe' ? (

      ) : (
        <div className="wizardSubContainer">
          <span className="category">
            <div className="wizardLabel">
              Category <span className="sterik">*</span>
            </div>

            <span>{category}</span>
          </span>
        </div>
      ) */}

      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">Description</div>

        <TextArea
          rows={4}
          className="text-Area"
          placeholder="Input description here"
          autoSize={{ minRows: 5, maxRows: 6 }}
          value={description}
          onChange={(e) => handleDescription(e.target.value)}
        />
      </div>
      <div className="wizardSubContainer checkBox">
        <div className="wizardLabel">Recipe Image</div>

        {image ? (
          <div className="imageUpload">
            <div className="imageWrapper">
              <img src={image} alt="" className="imageHeight" />
              <div className="deleteButton" onClick={() => handleImage('', {})}>
                <img src={Delete} width={15} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="formgroup formgroupStepOne">
            <div className="label-wrapper">
              <label>
                <p className="ant-upload-text">
                  Drop your image here or browse
                </p>
                <p className="ant-upload-hint">
                  {`Minimum file 2 MB (.png,.jpg,.jpeg)`}
                </p>
                <input
                  ref={fileRef}
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={async (e: any) => {
                    const response = await getBase64(e.currentTarget.files[0]);
                    if (response) handleImage(response, e);
                  }}
                />
              </label>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default StepOne;
