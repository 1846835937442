/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  Dialog,
  ListCount,
  SearchBox,
  SubDialog,
  DeleteConfirmation,
  OrderListDialog,
  ListViewWithoutExpand,
} from '../../components';
import ProductionAction from '../../redux/middleware/production';
import { sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Download from '../../assets/images/Download.svg';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import './styles.scss';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  // {
  //   key: '2',
  //   label: <div className="dotOption">Download</div>,
  //   icon: <img src={Download} width={15} />,
  // },
  {
    key: '3',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOption deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const ProductionPlan = () => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [productionPlan, setProductionPlan] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [productionRecipeId, setProductionRecipeId] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openOrderList, setOpenOrderList] = useState(false);
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentShop?.shop_id);
  useEffect(() => {
    if (currentBrandId !== currentShop?.shop_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setProductionRecipeId(recipe?.production_id);
  };
  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };
  const itemsForReadOnlyUsers: MenuProps['items'] = [
    // {
    //   key: '2',
    //   label: (
    //     <a href={''} download>
    //       <div className="dotOption">Download</div>
    //     </a>
    //   ),
    //   icon: <img src={Download} width={15} />,
    // },
  ];
  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return <div>{record?.id}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>NAME</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('production_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'production_name',
      key: 'production_name',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div
          className="font table-title"
          onClick={() => handleOrderListDialog(record)}
        >
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Name</div>
                <div className="tootipText">{record?.production_name}</div>
              </div>
            }
          >
            <div className="subrecipesubCol2 font">
              {record?.production_name?.substring(0, 20)}
              {record?.production_name?.length > 20 ? <span>...</span> : null}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>RUN BY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('last_executed_by', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div>{record?.last_executed_by ? record?.last_executed_by : '_'}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>LAST RUN ON</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('last_executed', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'last_executed',
      key: 'last_executed',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="">
          {record?.last_executed
            ? moment(UTCtoLocal(record?.last_executed)).format(
                'DD-MM-YYYY HH:mm:ss'
              )
            : '_'}
        </div>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'subrecipe_count',
    //   key: 'subrecipe_count',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div
    //       className="production-list-execute-button"
    //       onClick={() =>
    //         navigate('wizard', {
    //           state: {
    //             update: true,
    //             id: record?.production_id,
    //             executeEnabled: true,
    //           },
    //         })
    //       }
    //     >
    //       Execute
    //     </div>
    //   ),
    // },
    // {
    //   title: '',
    //   dataIndex: 'unit',
    //   key: 'unit',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div
    //       className="production-list-order-list-button"
    //       onClick={() => handleOrderListDialog(record)}
    //     >
    //       Order List
    //     </div>
    //   ),
    // },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) =>
        (user?.roles[0]?.id == 4 && shop?.is_ck) || user?.roles[0]?.id == 3 ? (
          <div className="production-plan-list-view-buttons">
            <div
              className="production-list-execute-button"
              onClick={() =>
                navigate('wizard', {
                  state: {
                    update: true,
                    id: record?.production_id,
                    executeEnabled: true,
                  },
                })
              }
            >
              Run It!
            </div>
            <div
              className="production-list-order-list-button"
              onClick={() => handleOrderListDialog(record)}
            >
              Summary
            </div>
            <Dropdown
              menu={DropDownmenuProps}
              trigger={['click']}
              placement="bottomRight"
              arrow={{ pointAtCenter: true }}
            >
              <a onClick={() => setSelectedMenu(record)}>
                <Space>
                  <div className="dots">
                    <img src={More} />
                  </div>
                </Space>
              </a>
            </Dropdown>
          </div>
        ) : null,
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions.find(
      (permission: any) => permission?.permission === 'Production Plan'
    );
    data && setPermissionStatus(data?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand, currentShop]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  // const handleingredient = async (data: any) => {
  //   const details = await ProductionAction.getIngredientDetail(
  //     data?.production_id
  //   );
  //   setDialogData(details);
  //   setOpenDialog(true);
  // };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleOrderListDialog = async (production: any) => {
    const { data, success } = await ProductionAction.getDetailRecipe(
      production?.production_id
    );
    if (success) {
      setIngredients(data?.order_items);
      setOpenOrderList(true);
    }
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', {
          state: {
            update: true,
            id: productionRecipeId,
            executeEnabled: false,
          },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    productionRecipeId.length &&
      (await ProductionAction.delProductionRecipe(productionRecipeId));
    pageData();
  };

  const DropDownmenuProps = {
    // items,
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const ProductionrecipeData = await ProductionAction.getProductionRecipes(
      dataLength,
      current,
      keyword
    );
    if (ProductionrecipeData === undefined || !ProductionrecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(ProductionrecipeData?.data?.production_count);
      setIngredientCount(ProductionrecipeData?.data?.ingredients_count);
      setTotal(ProductionrecipeData?.data?.pagination?.total);
      setProductionPlan(ProductionrecipeData?.data?.items);
      setSortData(ProductionrecipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const ProductionRecipes = await ProductionAction.ProductionRecipesFilters(
      dataLength,
      current,
      filters
    );
    if (ProductionRecipes === undefined || !ProductionRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(ProductionRecipes?.data?.recipe_count);
      setIngredientCount(ProductionRecipes?.data?.ingredients_count);
      setTotal(ProductionRecipes?.data?.pagination?.total);
      setSortData(ProductionRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleCancel = () => {
    setOpenOrderList(false);
  };

  const actionButton =
    user?.roles[0]?.id == 4 || user?.roles[0]?.id == 3 ? (
      <Button
        text="Add New"
        className="theme-button recipeBtn"
        icon={<PlusOutlined />}
        handleButtonClick={() => {
          navigate('wizard', {
            state: { update: false, id: '', executeEnabled: false },
          });
        }}
        shape="round"
        size="middle"
      />
    ) : null;

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);

    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.production_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.last_executed_by?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.last_executed?.toLowerCase().includes(keyword.toLowerCase())
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(productionPlan);
    // }

    const searchResultofRecipes = await ProductionAction.searchProductionRecipe(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.production_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  // const handleSubDialogOpen = async (id: any) => {
  //   const details = await SubRecipeActions.getIngredientDetail(id);
  //   setDialogData(details);
  //   setOpenDialog(true);
  // };

  return (
    <div className="productionRecipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete recipe?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        component={'production'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <OrderListDialog
        open={openOrderList}
        handleCancel={handleCancel}
        ingredients={ingredients}
        component={'orderList'}
        loader={false}
      />

      <Breadcrumb
        heading="Production Plan"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={12}>
            <ListCount count1={recipeCount} title1="Total Plans" />
          </Col>
          <Col lg={12}>
            <div className="search-wrapper">
              <SearchBox
                keyword={keyword}
                setKeyword={setKeyword}
                action={onSearch}
                placeholderText="Search..."
              />
              {/* <IconSwitcher listViewLayout={design} setListView={setDesign} /> */}
            </div>
          </Col>
        </Row>

        <Row>
          {/* <Col lg={6} md={8}>
            <Filter
              component="production"
              action={pageDataFilter}
              apiCall={ProductionAction.getProductionRecipesFilters}
            />
          </Col> */}
          <Col lg={24} md={16}>
            {/* {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'productionRecipe'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )} */}

            {/* {design && !recipeLoader ? ( */}
            {!recipeLoader ? (
              // <ListView
              //   limit={dataLength}
              //   component="production"
              //   items={items}
              //   dataSource={[...sortData]}
              //   columns={columns}
              //   handleOpen={handleSubDialogOpen}
              // />
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default ProductionPlan;
