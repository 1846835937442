/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import ModifierActions from '../../redux/middleware/modifiers';
import { SubRecipeDropdown } from '../index';

import './modifierModal.scss';
type Props = {
  handleDialog: any;
  open: boolean;
  component?: any;
  name?: any;
  modalName?: any;
  ingredient?: any;
  addIngredient?: any;
  handleIngredientObject?: any;
  deleteIngredient?: any;
  database?: any;
  handleIngredient?: any;
  id: any;
};

const ModifierModal = (props: Props) => {
  // const company = Utils.getCurrentCompany();
  // const brand = Utils.getCurrentBrand();
  let {
    handleDialog,
    open,
    ingredient,
    addIngredient,
    deleteIngredient,
    database,
    handleIngredientObject,
    handleIngredient,
    id,
  } = props;

  const [loader, setLoader] = useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);

  const handleSubmit = async () => {
    setLoader(true);

    const param = ingredient.map((data: any) => {
      if (!data?.net_price) {
        setLoader(false);
        setErrorStatus(true);
        return;
      }
      return {
        ingredient_id: data?.id,
        unit_id: data?.unit_id,
        quantity: data?.quantity,
        net_price: data?.net_price,
        ingredient_name: data?.ingredient_name,
        max_quantity: data?.max_quantity,
        min_quantity: data?.min_quantity,
        cost_per_ingredient: data?.total_price,
        // menu_price: data?.net_price,
      };
    });
    const tagIngredientToModifier =
      await ModifierActions.updateModifierIngredient(id, param);
    setLoader(false);
    if (tagIngredientToModifier?.success) {
      handleDialog();
    }
  };

  const handleIngredientOnChange = (data: any, index: any, key: string) => {
    handleIngredient(data, index, key);
    // key == 'ingredient_name' && setIngredientName(data);
  };

  return (
    <div className="modifierModal">
      <Modal
        title={<div className="title font">Edit Ingredient</div>}
        centered
        open={open}
        onCancel={() => handleDialog(false, {})}
        width={700}
        footer={null}
      >
        <Spin spinning={loader} size="large">
          <div className="Modal modifierModal">
            <div className="ingredientCategoryContainerWidth">
              <div className="wizardSubContainer">
                <div className="subrecipe-calorie-ingredient-container">
                  <div className="wizardIngredientsLabel modifierWizardIngredientsLabel">
                    <span className="modifierModalIngredientsLabel">
                      <span className="wizardLabel">
                        Add Ingredients <span className="sterik">*</span>
                      </span>
                    </span>
                    <span className="label">
                      <span className="wizardLabel">
                        Qty <span className="sterik">*</span>
                      </span>
                    </span>

                    <span className="label">
                      <span className="wizardLabel">Unit</span>
                    </span>
                    <span className="label">
                      <span className="wizardLabel">Cost</span>
                    </span>
                    <span className="label">
                      <span className="wizardLabel">
                        Price<span className="sterik">*</span>
                      </span>
                    </span>
                    {/* <span className="label">
                      <span className="wizardLabel">
                        Min<span className="sterik">*</span>
                      </span>
                    </span>
                    <span className="label">
                      <span className="wizardLabel">
                        Max<span className="sterik">*</span>
                      </span>
                    </span> */}
                  </div>
                </div>
                {ingredient.map((data: any, index: number) => {
                  return (
                    <SubRecipeDropdown
                      key={index}
                      data={data}
                      index={index}
                      database={database}
                      // blocked={blocked}
                      handleIngredientObject={handleIngredientObject}
                      openDialog={() => {
                        // setOpenIngredientDialog(true);
                        // setIngredientIndex(index);
                      }}
                      handleIngredientOnChange={handleIngredientOnChange}
                      deleteIngredient={deleteIngredient}
                      component={'modifier'}
                    />
                  );
                })}
                {errorStatus && (
                  <div
                    className="subRecipeErrorMessages"
                    style={{ margin: '2px 5px 5px 5px' }}
                  >
                    {'Please complete all the required fields to proceed'}
                  </div>
                )}
                <div className="addButton add-mod-btn">
                  <div
                    className="addNewsubDiv"
                    onClick={() => {
                      addIngredient();
                    }}
                  >
                    + Add New Row
                  </div>
                </div>
              </div>
            </div>
            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => handleDialog(false, {})}
              >
                Back
              </div>
              <div className="continue wizard-button" onClick={handleSubmit}>
                Submit
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default ModifierModal;
