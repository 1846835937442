import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';

interface ChartData {
  name: string;
  [key: string]: string | number | any;
}

interface ChartLineProps {
  data: ChartData[];
}

function formatNumberWithEllipsis(number: any) {
  const numberStr = number.toString();
  if (numberStr.length > 3) {
    return numberStr.substring(0, 3) + '...';
  }
  return numberStr;
}

const getTop5Values = (data: ChartData) => {
  const valueLabelPairs = Object.keys(data)
    .filter((key) => key.startsWith('value'))
    .map((key) => ({ key, value: data[key] as number }));

  valueLabelPairs.sort((a, b) => b.value - a.value);

  return valueLabelPairs.slice(0, 5).map((pair) => pair.key);
};

const ChartLine: React.FC<ChartLineProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  const top5ValueKeysPerDay = data.reduce<{ [day: string]: string[] }>(
    (acc, dayData) => {
      acc[dayData.name] = getTop5Values(dayData);
      return acc;
    },
    {}
  );

  const valueKeys = Array.from(
    new Set(Object.values(top5ValueKeysPerDay).flat())
  );

  const keyToLabelMap: { [key: string]: string } = valueKeys.reduce(
    (acc: any, valueKey) => {
      const labelKey = `label${valueKey.match(/\d+/)?.[0]}`;
      acc[valueKey] = labelKey;
      return acc;
    },
    {}
  );

  return (
    <LineChart
      width={460}
      height={180}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" tickFormatter={formatNumberWithEllipsis} />
      <YAxis />
      <Tooltip
        formatter={(value, name, entry) => {
          const labelKey = keyToLabelMap[name as string];
          const label = entry.payload[labelKey];
          return [`${value}`, label];
        }}
      />
      <Legend />
      {valueKeys.map((valueKey, index) => (
        <Line
          key={valueKey}
          type="monotone"
          dataKey={valueKey as string}
          stroke={`hsl(${(index * 60) % 360}, 100%, 50%)`}
          activeDot={{ r: 10 }}
        />
      ))}
    </LineChart>
  );
};

ChartLine.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value1: PropTypes.number,
      label1: PropTypes.string,
      // Additional values and labels are dynamically handled
    }).isRequired
  ).isRequired,
};

export default ChartLine;
