import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin, Select } from 'antd';
import './style.scss';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
} from '../../components';
import UploadRecipeApi from '../../redux/middleware/uploadImageApi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
const UploadImages = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();

  const [current, setCurrent] = useState(1);
  const [taggedImageData, setTaggedImageData] = useState([{}]);
  const [untaggedImageData, setUntaggedImageData] = useState([]);
  const [untaggedRecipeData, setUntaggedRecipeData] = useState([]);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState(0);
  const [totaltaggedImage, setTotaTaggedImage] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20, 50];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  // useEffect(() => {
  //   pageData();
  //   handleCount();
  // }, [currentBrand]);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
      handleCount();
    }
  }, [dataLength, current, check, currentBrand]);
  useEffect(() => {
    setCurrent(1);
    if (!check) {
      getUntaggedRecipes();
    }
  }, [check]);
  const handleCount = async () => {
    setRecipeLoader(true);

    if (check === true) {
      const UntaggedImagesData = await UploadRecipeApi.getUntaggedImages(
        dataLength,
        current,
        'recipe'
      );
      if (UntaggedImagesData === undefined || !UntaggedImagesData?.success) {
        setTotalUntaggedImage(0);
      } else {
        setTotalUntaggedImage(UntaggedImagesData?.data?.pagination?.total);
      }
      setRecipeLoader(false);
    } else {
      const TaggedImagesData = await UploadRecipeApi.getTaggedImages(
        dataLength,
        current,
        'recipe'
      );
      if (TaggedImagesData === undefined || !TaggedImagesData?.success) {
        setTotaTaggedImage(0);
      } else {
        setTotaTaggedImage(TaggedImagesData?.data?.pagination?.total);
      }
      setRecipeLoader(false);
    }
  };
  const getUntaggedRecipes = async () => {
    const untaggedRecipes = await UploadRecipeApi.getUntaggedRecipes('recipe');
    if (untaggedRecipes == undefined || !untaggedRecipes?.success)
      setUntaggedRecipeData([]);
    else setUntaggedRecipeData(untaggedRecipes?.data?.items);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>Image Name</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div>
              <div className="subCol1 titleContainer font">
                {/* <span className="title">Tenderloin-beef.jpg</span> */}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Tagged</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.name}</div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
        </div>
      ),
    },
    {
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div style={{ marginBottom: '5px' }}>
          <Button
            text="Remove"
            className="theme-button recipeBtn"
            handleButtonClick={() => {
              setRecipeLoader(true);
              handleRemove(record?.recipe_id);
            }}
            shape="round"
            size="middle"
          />
        </div>
      ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>Image Name</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.image} width="52px" height="52px" />
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">{record?.name}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Tagged</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          <Select
            size={'large'}
            placeholder={'-'}
            onChange={(e) => {
              handleMenu(e, record);
            }}
            style={{ width: 200 }}
            optionFilterProp="children"
          >
            {untaggedRecipeData?.map((data: any, menuIndex: number) => (
              <Select.Option value={data?.recipe_id} key={menuIndex}>
                {data.recipe_name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
        </div>
      ),
    },
  ];
  const handleRemove = async (id: any) => {
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const response = await UploadRecipeApi.removetaggedImage(
      brand?.brand_id,
      company?.id,
      id
    );
    if (response?.success) {
      // setRecipeLoader(false);
      pageData();
      handleCount();
    }
  };
  const handleMenu = async (value: any, data: any) => {
    const payload = {
      recipe_id: value,
      type: 'recipe',
    };
    const assignTag = await UploadRecipeApi.assignUntaggedImage(
      payload,
      data?.id
    );
    let responseDialogData;
    if (assignTag === undefined || !assignTag?.success) {
      responseDialogData = {
        type: 'error',
        messageArray: [`Validation Failed!`, 'Something Went Wrong'],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
    } else {
      getUntaggedRecipes();
      handleCount();
      pageData();
    }

    //   responseDialogData = {
    //     type: 'success',
    //     messageArray: [`Congratulation!`, ` ${assignTag?.message}`],
    //     OnClick: () => {
    //       pageData();
    //     },
    //   };
    // ResponseDialog(responseDialogData);
  };
  const pageData = async () => {
    setRecipeLoader(true);
    const imagesData = check
      ? await UploadRecipeApi.getTaggedImages(dataLength, current, 'recipe')
      : await UploadRecipeApi.getUntaggedImages(dataLength, current, 'recipe');
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTotaTaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTotaTaggedImage(imagesData?.data?.pagination?.total);
        setTaggedImageData(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUntaggedImageData(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = check
      ? await UploadRecipeApi.searchUploadImages(
          'get_tagged_images',
          dataLength,
          current,
          keyword,
          'recipe'
        )
      : await UploadRecipeApi.searchUploadImages(
          'get_untagged_images',
          dataLength,
          current,
          keyword,
          'recipe'
        );
    if (searchResultofRecipes === undefined) {
      setTotal(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
      setTotalUntaggedImage(0);
    } else {
      if (check) {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTaggedImageData(searchResultofRecipes?.data?.items);
      } else {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTotalUntaggedImage(searchResultofRecipes?.data?.pagination?.total);
        setUntaggedImageData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Upload Images"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/recipes');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={10}></Col>
          <Col lg={8} />
          <Col lg={6}>
            <SearchBox
              keyword={keyword}
              setKeyword={setKeyword}
              action={onSearch}
              placeholderText="Search..."
            />
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="uploadImagesWrapper">
            <div className="uploadImageMenuContainer">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div onClick={() => setCheck(true)} className="text menustyle">
                  Matched Images
                  <div
                    className={`counterOne ${
                      +totaltaggedImage >= 10 && 'largeCounter'
                    }`}
                  >
                    {totaltaggedImage}
                  </div>
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text menustyle">UnMatched Images</div>
                <div
                  className={`counter ${
                    +totalUntaggedImage >= 10 && 'largeCounter'
                  }`}
                >
                  {totalUntaggedImage}
                </div>
              </div>
            </div>

            {!recipeLoader ? (
              <div className="uploadImageTable">
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={''}
                  dataSource={
                    check ? [...taggedImageData] : [...untaggedImageData]
                  }
                  columns={check ? taggedColumns : unTaggedColumns}
                />
              </div>
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default UploadImages;
