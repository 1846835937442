/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Spin } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { Page, ComboStep, Breadcrumb, ResponseDialog } from '../../components';
import ComboActions from '../../redux/middleware/combo';
import Utils from '../../redux/utils';
import Attributes from '../../redux/middleware/attributes';
import { checkAuthentication } from '../../helper/checkAuthentication';
import './combo.scss';
import { useSelector } from 'react-redux';

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ComboWizard = () => {
  const { integrations } = Utils.getCurrentBrand();
  const isFoodicsIntegrated = integrations.length > 0;
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id, status = 0 } = state;
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [image, setImage] = React.useState('');
  const [sizes, setSizes] = React.useState<any>([]);
  const [groups, setGroups] = React.useState<any>([]);
  const [newSize, setNewSize] = useState('');
  const [newGroup, setNewGroup] = useState('');
  const [comboSizes, setComboSizes] = React.useState<any>([]);
  const [comboGroups, setComboGroups] = React.useState<any>([]);
  const [selectedSizes, setSelectedSizes] = useState<any>([]);
  const [selectedGroups, setSelectedGroups] = useState<any>({});
  const [category, setCategory] = useState<any>();
  const [imageObject, setImageObject] = React.useState({});
  const [combo, setCombo] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [stepper, setStepper] = React.useState(0);
  const [costRatio, setCostRatio] = useState<any>('');
  const [serving, setServing] = useState<any>('');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [comboIngredient, setComboIngredient] = useState<any[]>([
    {
      parent_recipe_id: '',
      recipe_id: '',
      variation: { variation_id: 0, variation_name: '' },
      quantity: '',
      total_price: 0,
      disable: true,
    },
  ]);
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);
  const [finalPrice, setFinalPrice] = useState<any>();
  const [proposedPrice, setProposedPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [recipeLoader, setRecipeLoader] = React.useState(false);

  const fetchDataById = async () => {
    try {
      const data = await ComboActions.getDetailCombo(id);
      if (data) {
        setImage(data?.data?.thumbnail);
        setCombo(data?.data?.combo_name);
        setDescription(data?.data?.description);
        setServing(data?.data?.servings);
        setFinalPrice(data?.data?.final_price);
        setTotalCost(data?.data?.total_price);
        setProposedPrice(data?.data?.propose_price);
        setCostRatio(data?.data?.fcr);
        // if (isFoodicsIntegrated) {
        //   comboIngredients = data?.data.groups.map((group: any) => {
        //     return {
        //       ...group,
        //       items: group.categories.flatMap((category: any) =>
        //         category.recipes.map((recipe: any) => ({
        //           ...recipe,
        //           combo_size_id: recipe.combo_size_id, // Ensure size ID is included
        //           combo_size: recipe.combo_size, // Include size name if needed
        //         }))
        //       ),
        //       disable: group?.is_variation === 'no',
        //       quantity: group?.quantity,
        //       total_cost: group?.total_cost,
        //       total_price: group?.net_price,
        //       category: {
        //         sub_category: group?.categories[0]?.menu_category, // Example: assuming all categories share the same menu_category
        //         id: group?.categories[0]?.menu_category_id, // Same assumption as above
        //         recipes: group.categories.flatMap((category: any) =>
        //           category.recipes.map((recipe: any) => ({
        //             combo_size_id: recipe.combo_size_id,
        //           }))
        //         ),
        //       },
        //     };
        //   });
        // }

        if (isFoodicsIntegrated) {
          const comboIngredients = data?.data.groups.map((group: any) => {
            return {
              ...group,
              items: group.categories.flatMap((category: any) =>
                category.recipes.map((recipe: any) => ({
                  ...recipe,
                }))
              ),
              category: group.categories.flatMap((category: any) => ({
                sub_category: category?.menu_category,
                id: category?.menu_category_id,
                quantity: category?.quantity,
                total_price: category?.net_price,
              })),
              comboSizes: group.categories.flatMap((category: any) =>
                category.recipes.map((recipe: any) => ({
                  id: recipe.combo_size_id,
                  label: recipe.combo_size,
                }))
              ),
              disable: group?.is_variation === 'no',
            };
          });
          setComboIngredient([...comboIngredients]);
          // setComboSizes(comboIngredients[0]?.comboSizes);
          // console.log(
          //   'comboIngredients?.comboSizes',
          //   comboIngredients?.comboSizes
          // );
          // console.log('comboIngredients', comboIngredients);

          const formattedComboSizes = Array.from(
            new Map(
              comboIngredients.flatMap((group: any) =>
                group.items.map((item: any) => [
                  item.combo_size_id,
                  {
                    label: item.combo_size,
                    value: item.combo_size_id,
                  },
                ])
              )
            ).values()
          );

          const matchingSizes = data.data.sizes
            .filter((size: any) =>
              comboIngredients
                .flatMap((group: any) =>
                  group.items.map((item: any) => item.combo_size_id)
                )
                .includes(size.id)
            )
            .map((size: any) => ({
              label: comboIngredients
                .flatMap((group: any) =>
                  group.items.find(
                    (item: any) => item.combo_size_id === size.id
                  )
                )
                .map((item: any) => item.combo_size)[0],
              value: size.id,
            }));

          setSelectedSizes(formattedComboSizes);

          // setComboSizes(matchingSizes);

          // console.log('formattedComboSizes', formattedComboSizes, comboSizes);
          // console.log('matchingSizes', matchingSizes);
          setComboSizes(formattedComboSizes);
        }

        if (!isFoodicsIntegrated) {
          const recipes = data?.data.recipes.map((recipe: any) => {
            const getSelectedVariant = recipe?.variation_list?.find(
              (variation: any) =>
                variation?.variation_id === recipe?.variation_id
            );
            return {
              ...recipe,
              items: recipe?.items?.map((item: any) => ({ ...item })),
              variations: recipe?.variation_list,
              disable: recipe?.is_variation === 'no',
              quantity: recipe?.quantity,
              total_cost: recipe?.total_cost,
              total_price: recipe?.net_price,
              variation:
                recipe?.is_variation === 'no' ? [] : getSelectedVariant,
              category: {
                sub_category: recipe?.menu_category,
                id: recipe?.menu_category_id,
              },
            };
          });
          setComboIngredient((prevIngredients) => [...recipes]);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoader(false);
    }
  };

  // const checkCategory = async () => {
  //   const menuCategories = await Attributes.getMenuCategory(company?.id);

  //   const getMenuCategory = menuCategories?.data?.items.filter(
  //     (data: any) => data?.name.toLowerCase() == 'combo'
  //   );
  //   setLoader(false);
  //   if (
  //     menuCategories?.data?.items?.length > 0 &&
  //     getMenuCategory?.length > 0
  //   ) {
  //     setCategory(getMenuCategory[0]);
  //   }
  //   //  else {
  //   //   let responseDialogData = {
  //   //     type: 'error',
  //   //     messageArray: [`Please create Menu Category, before!`],
  //   //     OnClick: () => {
  //   //       navigate('/app/combos');
  //   //     },
  //   //   };
  //   //   ResponseDialog(responseDialogData);
  //   // }
  // };

  useEffect(() => {
    checkAuthentication(user, navigate);
    if (update) {
      if (status) {
        let responseDialogData = {
          type: 'error',
          messageArray: [
            `This Combo is part of active menu, Kindly change its status to inactive before Updating it`,
          ],
          OnClick: () => {
            navigate('/app/combos');
          },
        };
        ResponseDialog(responseDialogData);
      }
      setLoader(true);
      fetchDataById();
    }
    // checkCategory();
  }, []);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/combos');
    }
    window.scrollTo(0, 0);
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (isFoodicsIntegrated) {
      getComboSizes();
      getComboGroups();
    }
  }, [currentBrand]);

  const handleImage = (url: any, data: any) => {
    setImage(url);
    setImageObject(data?.target?.files[0]);
  };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const handleNewSize = (data: string) => {
    setNewSize(data);
  };

  const handleNewGroup = (data: string) => {
    setNewGroup(data);
  };

  const handleCombo = (data: string) => {
    setCombo(data);
  };

  const getComboSizes = async () => {
    setRecipeLoader(true);
    const comboData = await ComboActions.getComboSizes();
    if (comboData?.success) {
      const sizes = comboData?.data.map((item: any) => ({
        name: item.size_name,
        id: item.id,
      }));
      setSizes(sizes);
      setComboSizes(comboData?.data);
    }
    setRecipeLoader(false);
  };

  const getComboGroups = async () => {
    setRecipeLoader(true);
    const comboData = await ComboActions.getComboGroups();
    if (comboData?.success) {
      setComboGroups(comboData?.data);
    }
    setRecipeLoader(false);
  };

  const handleSizeChange = (values: any) => {
    setSelectedSizes(values);
  };

  const handleGroupChange = (size: any, selectedIds: any) => {
    const updatedGroups = comboGroups
      .filter((group: any) => selectedIds.includes(group.id))
      .map((group: any) => ({
        name: group.group_name,
        id: group.id,
      }));

    setSelectedGroups((prevGroups: any) => ({
      ...prevGroups,
      [size]: updatedGroups,
    }));
    setGroups(updatedGroups);
  };

  const handleCreateSize = async () => {
    const brand = await Utils.getCurrentBrand();
    const param = {
      brand_id: brand?.brand_id,
      size_name: newSize,
    };
    if (newSize) {
      const response = await ComboActions.createComboSizes(param);
      if (response?.success) {
        setNewSize('');
        getComboSizes();
      }
    }
  };

  const handleCreateGroup = async () => {
    const brand = await Utils.getCurrentBrand();
    const param = {
      brand_id: brand?.brand_id,
      group_name: newGroup,
    };
    if (newGroup) {
      const response = await ComboActions.createComboGroups(param);
      if (response?.success) {
        setNewGroup('');
        getComboGroups();
      }
    }
  };

  const handleBackStepper = () => {
    navigate('/app/combos');
  };

  const handleNextStepper = async () => {
    const user = Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    setAlertMessage('');
    setErrorList({});
    setErrorStatus(false);
    // if (comboIngredient?.length < 2) {
    //   setAlertMessage('You must have atleast 2 Recipes while making a Combo');
    //   setVisible(true);
    //   window.scrollTo(0, 0);
    //   return;
    // }
    const checkEmptyComboIngredient = comboIngredient?.filter(
      (data) => data?.parent_recipe_id == '' && data?.recipe_id == ''
    );

    // if (proposedPrice === 0 && finalPrice === undefined) {
    //   setVisible(true);
    //   window.scrollTo(0, 0);
    //   return;
    // }
    const checkEmptyField = combo == '' || description == '' || serving == '';
    // checkEmptyComboIngredient?.length !== 0;

    if (user && !checkEmptyField && !update) {
      // menu_category_id: category?.id,

      const param = {
        // Common parameters
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        combo_name: combo,
        description: description,
        servings: serving,
        menu_category_id: 4,
        created_by: user?.id,
        total_cost: totalCost ?? 0,
        propose_price: proposedPrice ?? 0,
        final_price: finalPrice === undefined ? proposedPrice : finalPrice,
        fcr: costRatio ?? 0,

        ...(isFoodicsIntegrated && {
          integration: 'foodics',
          sizes: sizes,
          groups: groups.map((group: any) => ({
            name: group.name,
            id: group.id,
            categories: comboIngredient.map((item) => ({
              menu_category_id: item.category?.id,
              menu_category: item.category?.sub_category,
              net_price: item.total_price,
              quantity: item.quantity,
              recipes: item.items.flatMap((recipe: any) =>
                selectedSizes.map((size: any) => ({
                  combo_size_id: size,
                  parent_recipe_id: recipe.parent_recipe_id,
                  recipe_id: recipe.recipe_id,
                  sale_price: item.sale_price,
                }))
              ),
            })),
          })),
        }),

        ...(!isFoodicsIntegrated && {
          integration: 'none',
          recipes: comboIngredient.map((data) => ({
            menu_category_id: data?.category?.id,
            menu_category: data?.category?.sub_category,
            net_price: data?.total_price,
            quantity: data?.quantity,
            items: data?.items?.map((item: any) => ({
              parent_recipe_id: item?.parent_recipe_id,
              recipe_id: item?.recipe_id,
              variation_id: item?.variation?.variation_id || 0,
            })),
          })),
        }),
      };
      // let responseDialogData = {
      //   type: 'info',
      //   messageArray: [`We are building your ${combo}. Kindly Wait !!!`],
      //   OnClick: () => {},
      // };
      // ResponseDialog(responseDialogData);
      const response = await ComboActions.createCombo(
        param,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? null
          : imageObject
      );

      if (!response?.success) {
        if (response?.success != undefined) {
          setErrorList(response?.error);
          setErrorStatus(true);
        }
      } else {
        navigate('/app/combos');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/combos');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${combo}`, ` has been created`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } else if (update && !checkEmptyField) {
      // console.log('in the update');
      const param = {
        menu_category_id: 4,
        menu_category: category?.name,
        company_id: company?.id,
        brand_id: brand?.brand_id,
        shop_id: shop?.id || 0,
        thumbnail:
          imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
            ? image
            : null,
        combo_name: combo,
        description: description,
        servings: serving,
        total_cost: totalCost,
        propose_price: proposedPrice,
        final_price: finalPrice == undefined ? proposedPrice : finalPrice,
        fcr: costRatio,
        created_by: user?.id,

        ...(!isFoodicsIntegrated && {
          integration: 'none',
          recipes: comboIngredient.map((data) => ({
            menu_category_id: data?.category?.id,
            menu_category: data?.category?.sub_category,
            net_price: data?.total_price,
            quantity: data?.quantity,
            items: data?.items?.map((item: any) => ({
              parent_recipe_id: item?.parent_recipe_id,
              recipe_id: item?.recipe_id,
              variation_id: item?.variation?.variation_id || 0,
            })),
          })),
        }),
        ...(isFoodicsIntegrated && {
          integration: 'foodics',
          sizes: sizes,
          groups: comboGroups.map((group: any) => ({
            name: group.group_name,
            id: group.id,
            categories: comboIngredient.map((item) => ({
              menu_category_id: item.category?.id,
              menu_category: item.category?.sub_category,
              net_price: item.total_price,
              quantity: item.quantity,
              recipes: item.items.flatMap((recipe: any) =>
                selectedSizes.map((size: any) => ({
                  combo_size_id: size,
                  parent_recipe_id: recipe.parent_recipe_id,
                  recipe_id: recipe.recipe_id,
                }))
              ),
            })),
          })),
        }),
      };

      setVisible(false);
      const response = await ComboActions.updateCombo(
        param,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? {}
          : imageObject,
        id
      );
      if (!response?.success) {
        if (response?.success != undefined) {
          setErrorList(response?.error);
          setErrorStatus(true);
        }
      } else {
        navigate('/app/combos');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/combos');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${combo} has been updated`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } else {
      setVisible(true);
      window.scrollTo(0, 0);
    }
  };

  const handleChangeCategory = (data: any, index: number) => {
    let dataArray = comboIngredient;
    dataArray[index] = { ...dataArray[index], category: data, disable: false };
    setComboIngredient([...dataArray]);
    setTotalCost(0);
  };

  const handleChange = (data: any, index: number) => {
    let dataArray = comboIngredient;
    dataArray[index] = { ...dataArray[index], items: [...data] };
    // dataArray[index] = {
    //   ...dataArray[index],
    //   ...data?.general,
    //   variations: data?.variation_list,
    //   quantity: '',
    //   total_cost:
    //     data?.general?.is_variation == 'no'
    //       ? data?.general?.total_cost
    //       : data?.variation_list[0]?.total_cost,
    //   total_price: 0,
    //   variation:
    //     data?.general?.is_variation == 'no' ? [] : data?.variation_list[0],
    // };
    handleTotalPrice(dataArray);
    setComboIngredient([...dataArray]);
  };

  const deleteComboMenu = (index: number) => {
    let DataArray = comboIngredient;
    DataArray.splice(index, 1);
    setComboIngredient([...DataArray]);
    handleTotalPrice(DataArray);
  };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    // console.log('cost', dataArray, data, key, index);
    if (key === 'quantity' && data !== '') {
      const totalCost = dataArray[index]['items'].reduce(
        (acc: any, item: any) => {
          return acc + item.total_cost * +data;
        },
        0
      );
      dataArray[index]['total_price'] = Number(totalCost.toFixed(2));
    } else {
      dataArray[index]['total_price'] = 0;
    }
    return dataArray;
  };

  const handleTotalPrice = (dataSource: Array<any>) => {
    // console.log('data total bottom', dataSource);
    const totalPrice = dataSource.reduce((acc, data) => {
      return acc + (data?.total_price || 0);
    }, 0);
    const averagePrice =
      dataSource.length > 0 ? totalPrice / dataSource.length : 0;
    // console.log('total price', averagePrice);
    setTotalCost(averagePrice);
  };

  const handleChangeValue = (data: any, index: number, key: string) => {
    let dataArray = comboIngredient;
    dataArray = handleCostIngredient(dataArray, data, key, index);
    key == 'variation'
      ? (dataArray[index] = {
          ...dataArray[index],
          quantity: '',
          total_cost: data?.menu_price,
          total_price: 0,
          variation: data,
        })
      : (dataArray[index][key] = data);
    setComboIngredient([...dataArray]);
    handleTotalPrice(dataArray);
  };
  const handleChangePrice = (data: any, index: number, key: string) => {
    let dataArray = comboIngredient;
    key == 'variation'
      ? (dataArray[index] = {
          ...dataArray[index],
          sale_price: '',
          total_cost: data?.menu_price,
          total_price: 0,
          variation: data,
        })
      : (dataArray[index][key] = data);
    setComboIngredient([...dataArray]);
  };

  const addComboMenu = () => {
    setComboIngredient((prev) => [
      ...prev,
      {
        parent_recipe_id: '',
        recipe_id: '',
        variation: { variation_id: 0, variation_name: '' },

        quantity: '',
        total_price: 0,
        disable: true,
      },
    ]);
  };

  const handleServing = (data: any) => {
    setServing(data);
  };

  const handleProposedPrice = (ratio: any) => {
    setProposedPrice((totalCost * 100) / ratio);
  };
  const handleCostRatio = (data: any) => {
    if (+data <= 100) {
      setCostRatio(data);
      data == '' ? setProposedPrice(0) : handleProposedPrice(+data);
    }
  };

  const handleFinalPrice = (data: any) => {
    setFinalPrice(data);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Combo"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/combos');
        }}
      />
      <div className="combo-alertMessage">
        {visible && (
          <Alert
            message="Error"
            description={
              alertMessage?.length
                ? alertMessage
                : 'Please complete all required fields to proceed'
            }
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}

        {error &&
          errorMessage?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setError(false)}
              key={index}
            />
          ))}
      </div>
      <Page title="Add New Combo">
        {loader ? (
          <Spin
            spinning={loader}
            size="default"
            className="loadIngredient recipeBuilderloaderHeight"
          />
        ) : (
          <div className="combo-wizard wizardContainer">
            <ComboStep
              handleCostRatio={handleCostRatio}
              handleServing={handleServing}
              handleChangeValue={handleChangeValue}
              handleChangePrice={handleChangePrice}
              addComboMenu={addComboMenu}
              deleteComboMenu={deleteComboMenu}
              handleChange={handleChange}
              handleBackStepper={handleBackStepper}
              handleCombo={handleCombo}
              handleDescription={handleDescription}
              handleImage={handleImage}
              getBase64={getBase64}
              handleFinalPrice={handleFinalPrice}
              handleChangeCategory={handleChangeCategory}
              stepper={stepper}
              image={image}
              combo={combo}
              description={description}
              comboIngredient={comboIngredient}
              serving={serving}
              costRatio={costRatio}
              proposedPrice={proposedPrice}
              totalCost={totalCost}
              finalPrice={finalPrice}
              errorList={errorList}
              errorStatus={errorStatus}
              comboSizes={comboSizes}
              comboGroups={comboGroups}
              newSize={newSize}
              newGroup={newGroup}
              selectedSizes={selectedSizes}
              selectedGroups={selectedGroups}
              handleGroupChange={handleGroupChange}
              handleSizeChange={handleSizeChange}
              handleNewSize={handleNewSize}
              handleNewGroup={handleNewGroup}
              handleCreateSize={handleCreateSize}
              handleCreateGroup={handleCreateGroup}
              handleCostIngredient={handleCostIngredient}
              handleTotalPrice={handleTotalPrice}
              update={update}
            />

            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => handleBackStepper()}
              >
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleNextStepper()}
              >
                Continue
              </div>
            </div>
          </div>
        )}
      </Page>
    </React.Fragment>
  );
};

export default ComboWizard;
