import React from 'react';
import { Modal } from 'antd';
import Success from '../../assets/images/succesResponse.png';
import Error from '../../assets/images/errorResponse.png';
import recipeLoader from '../../assets/icons/recipeLoader.gif';
import './responseDialog.scss';

type Props = {
  type: string;
  messageArray: any;
  OnClick: any;
  BtnText?: string;
  showCloseIcon?: boolean;
};

const ResponseDialog = (props: Props) => {
  let {
    type,
    messageArray,
    OnClick,
    BtnText = 'OK',
    showCloseIcon = false,
  } = props;
  Modal.destroyAll();
  return Modal.info({
    icon: null,
    content: (
      <div className="responseDialogContainer">
        {type == 'error' ? (
          <img src={Error} alt="" className="successImage" />
        ) : type == 'success' ? (
          <img src={Success} alt="" className="successImage" />
        ) : (
          <img src={recipeLoader} alt="loading..." className="gifWidth" />
        )}
        <div>
          {messageArray?.map((data: string, index: number) => (
            <div
              className={`messageText ${type} responseDialogTextSpacing`}
              key={index}
            >
              {data}
            </div>
          ))}
        </div>
      </div>
    ),

    okText: <div>{BtnText}</div>,
    onOk: OnClick,
    okButtonProps: {
      disabled: type == 'info' ? true : false,
      className: 'theme-button responseButton',
    },
    closable: showCloseIcon,
  });
};

export default ResponseDialog;
