/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Radio, RadioChangeEvent, Row, Col, Spin, Tooltip } from 'antd';
import SyncActions from '../../redux/middleware/syncPreferences';
import ResponseDialog from '../ResopnseDialog';
import moment from 'moment';
import './style.scss';

type Props = {
  handleCancel: any;
  open: boolean;
  component?: string;
};

const SyncPreferences = (props: Props) => {
  let { handleCancel, open } = props;
  const [syncBy, setSyncBy] = useState('');
  const [loader, setLoader] = useState(false);
  const [controlGroupOptions, setControlGroupOptions] = useState<{
    [key: string]: string;
  }>({});
  const [syncOptions, setSyncOptions] = useState<{
    [key: string]: string;
  }>({});
  const [dateInput, setDateInput] = useState('');
  const [timeInput, setTimeInput] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [localTimes, setLocalTimes] = useState('');

  useEffect(() => {
    getSyncListing();
    getBrandSyncData();
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    setSyncBy(e.target.value);
  };

  const UTCtoLocal = (utcTime: any) => {
    const localTime = moment.utc(utcTime).local();
    return localTime.format('HH:mm:ss');
  };

  const localToUTC = (localTime: any) => {
    const localMoment = moment(localTime, 'HH:mm:ss');
    const utcMoment = localMoment.utc();
    return utcMoment.format('HH:mm:ss');
  };

  const emptyAllFields = () => {
    setSyncBy('');
    setTimeInput('');
    setDateInput('');
  };

  const newDate =
    (syncBy === 'schedule' || syncBy === 'manual_sync') &&
    (dateInput || selectedDate) !== selectedDate
      ? dateInput || selectedDate
      : selectedDate;

  const newTime =
    (syncBy === 'schedule' || syncBy === 'manual_sync') &&
    (timeInput || selectedTime) !== selectedTime
      ? localToUTC(timeInput) || UTCtoLocal(selectedTime)
      : selectedTime;

  const handleSubmit = async () => {
    setLoader(true);
    const originalDate = dateInput || selectedDate;
    const originalTime = timeInput || selectedTime;
    const combinedLocalDateTime = moment(
      `${originalDate} ${originalTime}`,
      'YYYY-MM-DD HH:mm:ss'
    );
    const convertedUTCDateTime = moment.utc(
      `${originalDate} ${localToUTC(originalTime)}`,
      'YYYY-MM-DD HH:mm:ss'
    );
    let adjustedDate = moment(originalDate, 'YYYY-MM-DD');
    if (convertedUTCDateTime.isAfter(combinedLocalDateTime, 'day')) {
      adjustedDate.add(1, 'days');
    } else if (convertedUTCDateTime.isBefore(combinedLocalDateTime, 'day')) {
      adjustedDate.subtract(1, 'days');
    } else {
      console.log('No Date Adjustment Needed');
    }
    const payload = {
      control_group: ['inventory', 'menu', 'supply'],
      sync_options: syncBy,
      frequency: 'daily',
      start_date:
        syncBy === 'schedule'
          ? adjustedDate.format('YYYY-MM-DD')
          : syncBy === 'manual_sync'
          ? adjustedDate.format('YYYY-MM-DD')
          : syncBy === 'off'
          ? null
          : getCurrentDate(),
      scheduled_time:
        syncBy === 'schedule'
          ? `${adjustedDate.format('YYYY-MM-DD')} ${convertedUTCDateTime.format(
              'HH:mm:ss'
            )}`
          : syncBy === 'realtime'
          ? getCurrentTime()
          : syncBy === 'off'
          ? null
          : `${adjustedDate.format('YYYY-MM-DD')} ${convertedUTCDateTime.format(
              'HH:mm:ss'
            )}`,
    };

    try {
      let responseDialogData;
      const response = await SyncActions.setPreferences(payload);
      if (response?.success) {
        emptyAllFields();
        setLoader(false);
        handleCancel(false);
        responseDialogData = {
          OnClick: () => {},
          type: 'success',
          messageArray: ['Preferences Synced!'],
        };
        ResponseDialog(responseDialogData);
      } else {
        setLoader(false);
        emptyAllFields();
        responseDialogData = {
          OnClick: () => {
            emptyAllFields();
          },
          type: 'error',
          messageArray: ['Sync Failed!'],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log('Error during sync:', e);
    }
  };

  const getCurrentDate = () => {
    const currentDate = moment().format('YYYY-MM-DD');
    return currentDate;
  };

  const getCurrentTime = () => {
    const currentUTC = moment().utc();
    return currentUTC.format('YYYY-MM-DD HH:mm:ss');
  };

  //In case you want to add the checkbox change option(Not for now)
  // const handleCheckboxChange = (itemName: string) => {
  //   setSelectedItems((prevSelectedItems) => {
  //     if (prevSelectedItems.includes(itemName)) {
  //       return prevSelectedItems.filter((item) => item !== itemName);
  //     } else {
  //       return [...prevSelectedItems, itemName];
  //     }
  //   });
  // };

  const getSyncListing = async () => {
    setLoader(true);
    const response = await SyncActions.getSyncOptions();
    if (response?.success) {
      const { data } = response;
      setControlGroupOptions(data.control_group);
      setSyncOptions(data.sync_options);
    }
    setLoader(false);
  };

  const getBrandSyncData = async () => {
    setLoader(true);
    const response = await SyncActions.getBrandSyncOptions();
    if (response?.success) {
      const { data } = response;
      if (response.data.scheduled_time !== null) {
        const [datePart, timePart] = data.scheduled_time.split(' ');
        setSyncBy(data.sync_options);
        setSelectedDate(datePart);
        setSelectedTime(timePart);
        setLocalTimes(UTCtoLocal(data.scheduled_time));
      }
    }
    setLoader(false);
  };

  const handleOptionChange = (option: string) => {
    setSyncBy(option);
    setDateInput('');
    setTimeInput('');
  };

  const renderDateAndTimeInputs = () => {
    if (syncBy === 'schedule' || syncBy === 'manual_sync') {
      const currentDate = new Date().toISOString().split('T')[0];
      let validTimes = [
        '00:00:00',
        '01:00:00',
        '02:00:00',
        '03:00:00',
        '04:00:00',
        '05:00:00',
        '06:00:00',
      ];
      return (
        <div className="ingredientSubContainer date-time-inputs">
          <label htmlFor="dateInput">Start Date</label>
          <input
            type="date"
            id="dateInput"
            value={dateInput || selectedDate}
            onChange={(e) => setDateInput(e.target.value)}
            min={currentDate}
          />
          <label htmlFor="timeInput">Select Time</label>
          <select
            id="timeInput"
            className="time-select"
            value={timeInput}
            onChange={(e) => setTimeInput(e.target.value)}
          >
            <option value="" disabled>
              {timeInput ? 'Select Time' : localTimes}
            </option>
            {validTimes.map((time, index) => (
              <option key={index} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      title={<div className="title font">Sync Preferences</div>}
      centered
      open={open}
      onCancel={handleCancel}
      width={600}
      footer={null}
    >
      <Spin spinning={loader} size="large" className="loaderWidthHeight">
        <div className="ingredientDialog">
          <div className="ingredientSubContainer">
            <p>Following modules will be synced</p>
            <div className="checkboxContainer">
              {Object.entries(controlGroupOptions).map(([key, value]) => (
                <label className="checkboxLabel" key={key}>
                  <input
                    type="checkbox"
                    id={key}
                    checked={true}
                    // checked={selectedItems.includes(key)}
                    // onChange={() => handleCheckboxChange(key)}
                  />
                  {value}
                </label>
              ))}
            </div>
          </div>

          <div className="ingredientSubContainer">
            <Row>
              <Col lg={10} md={24}>
                <Radio.Group
                  className="inventoryFilter-Radio"
                  onChange={onChange}
                  value={syncBy}
                >
                  {Object.entries(syncOptions).map(([key, value]) => (
                    <Radio
                      className="radio-button"
                      id={key}
                      key={key}
                      name="sync_option"
                      value={key}
                      checked={syncBy === key}
                      onChange={() => handleOptionChange(key)}
                    >
                      <Tooltip
                        title={
                          key === 'schedule' ? (
                            <div>
                              <div className="tooltipHead">Scheduled Sync</div>
                              <div className="tootipText">
                                Data will sync same time daily
                              </div>
                            </div>
                          ) : key === 'manual_sync' ? (
                            <div>
                              <div className="tooltipHead">Manual Sync</div>
                              <div className="tootipText">
                                Data will sync one time only
                              </div>
                            </div>
                          ) : key === 'realtime' ? (
                            <div>
                              <div className="tooltipHead">Realtime Sync</div>
                              <div className="tootipText">
                                Data will sync upon creation
                              </div>
                            </div>
                          ) : key === 'off' ? (
                            <div>
                              <div className="tooltipHead">Turn Sync Off</div>
                              <div className="tootipText">
                                No data will be synced
                              </div>
                            </div>
                          ) : (
                            ''
                          )
                        }
                      >
                        {value}
                      </Tooltip>
                    </Radio>
                  ))}
                </Radio.Group>
              </Col>
            </Row>
            {renderDateAndTimeInputs()}
          </div>

          <div className="butonGroup">
            <div className="wizard-button" onClick={() => handleCancel(false)}>
              Cancel
            </div>
            <div className="continue wizard-button" onClick={handleSubmit}>
              Submit
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default SyncPreferences;
