/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
  DetailDialog,
  ModifierModal,
} from '../../components';
import ModifiersActions from '../../redux/middleware/modifiers';
import { sortNumber, sortedData } from '../../helper/sortData';
import Delete from '../../assets/images/Delete.svg';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import './styles.scss';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  {
    key: '2',
    label: (
      <div className="dotOption modifierDropdownLabels">Add Modifiers</div>
    ),
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">View</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption modifierDropdownLabels">Edit Groups</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: (
      <div className="dotOption deleteText modifierDropdownLabels">Delete</div>
    ),
    icon: <img src={Delete} width={15} />,
  },
];

const items1: MenuProps['items'] = [
  {
    key: '2',
    label: (
      <div className="dotOption modifierDropdownLabels">Edit Modifiers</div>
    ),
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '1',
    label: <div className="dotOption modifierDropdownLabels">View</div>,
    icon: <img src={Eye} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOption modifierDropdownLabels">Edit Groups</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: (
      <div className="dotOption deleteText modifierDropdownLabels">Delete</div>
    ),
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const Modifiers = () => {
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [modifiers, setModifiers] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModifierDialog, setOpenModifierDialog] = useState(false);
  const [ingredient, setIngredient] = useState<any[]>([
    {
      ingredient_name: '',
      quantity: 0,
      unit_cost: '',
      waste: '',
      small_unit: 'g',
      total_price: 0,
    },
  ]);

  const [database] = useState<any>({
    value: 2,
    label: 'My Market List',
    key: 3,
  });

  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [update, setUpdate] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => <div>{record?.id}</div>,
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>MODIFIER GROUP</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('modifier_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'modifier_name',
      key: 'modifier_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <span
          className="subrecipecol1Text font table-title1-hover"
          onClick={() => {
            setLoader(true);
            handleDetails(record);
          }}
        >
          {/* <div className=" cellSpacing font">{record?.modifier_name}</div> */}
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Name</div>
                <div className="tootipText">{record?.modifier_name}</div>
              </div>
            }
          >
            {record?.modifier_name?.length > 20
              ? record?.modifier_name?.substring(0, 20) + '...'
              : record?.modifier_name}
          </Tooltip>
        </span>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>CONSUMPTION TYPE</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('modifier_type', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'modifier_type',
    //   key: 'modifier_type',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="">{record?.modifier_type}</div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY COUNT</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('menu_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'menu_category',
      key: 'menu_category',
      responsive: ['md'],

      render: (data: any, record: any) => (
        <div className="col2">
          <div>
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Category</div>
                  <div className="tootipText">
                    {record?.menu_category
                      .map((data: any) => data?.menu_category)
                      .toString()}
                  </div>
                </div>
              }
            >
              {/* <img src={SubMenu} width={15} alt="" className="icons" /> */}
              {record?.menu_category?.length}
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="tableCol col3 ">
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            items:
              permissionStatus && permissionStatus === 2
                ? record?.ingredients?.length !== 0
                  ? items1
                  : items
                : [],
            onClick: handleDropDownClick,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Modifier Groups'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);
  useEffect(() => {
    if (update) {
      const filterData: any = sortData.find(
        (val: any) => val?.id == selectedRecipeId
      );

      setIngredient(
        filterData?.ingredients.map((data: any) => {
          return {
            ...data,
            id: data?.ingredient_id,
            ingredient_name: data?.ingredient_name,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            small_unit: data?.unit,
            total_price: data?.cost_per_ingredient
              ? data?.cost_per_ingredient
              : 0,
          };
        })
      );
      fetchModifierData();
    }
  }, [update]);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const handleDetails = (record: any) => {
    setLoader(false);
    const filterData: any = sortData.find((val: any) => val?.id == record?.id);
    if (Object.keys(filterData).length !== 0) {
      setDialogData(filterData);
      setOpenDialog(true);
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (modifier: any) => {
    setSelectedRecipeId(modifier.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1: {
        setLoader(true);
        fetchDialogData();
        break;
      }

      case 2: {
        if (e.domEvent.target.innerText === 'Edit Modifiers') {
          setUpdate(true);
        }
        setLoader(true);
        fetchModifierData();
        break;
      }

      case 3:
        navigate('wizard', { state: { update: true, id: selectedRecipeId } });
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const fetchDialogData = async () => {
    setLoader(false);
    const filterData: any = sortData.find(
      (val: any) => val?.id == selectedRecipeId
    );
    if (Object.keys(filterData).length !== 0) {
      setDialogData(filterData);
      setOpenDialog(true);
    }
  };

  const fetchModifierData = async () => {
    setLoader(false);
    setOpenModifierDialog(true);
  };

  const handleIngredientObject = (data: any, index: number) => {
    let dataArray = ingredient;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredient(() => [...dataArray]);
      // setMessages([
      //   'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      // ]);
      // setAlerts(true);
      // setVisible(false);
      // window.scrollTo(0, 0);
      return false;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredient([...dataArray]);
    } else {
      dataArray[index] = {
        ...data,
        quantity: 0,
        total_price: 0,
      };
      // handleTotalPrice(dataArray);
      setIngredient([...dataArray]);
    }
  };

  const addIngredient = () => {
    setIngredient((prev) => [
      ...prev,
      {
        id: -1,
        ingredient_name: '',
        quantity: 0,
        unit_cost: 0,
        waste_ingredient: '',
        small_unit: 'g',
        total_price: 0,
        menu_price: 0,
      },
    ]);
  };

  const deleteIngredient = (index: number) => {
    let DataArray = ingredient;
    DataArray.splice(index, 1);
    setIngredient([...DataArray]);
  };

  const handleIngredient = (data: any, index: number, key: any) => {
    let dataArray = ingredient;
    if (key == 'quantity')
      dataArray = handleCostIngredient(dataArray, data, key, index);
    // key == 'quantity' && handleTotalPrice(dataArray);
    dataArray[index][key] = data;
    setIngredient([...dataArray]);
  };

  // const handleTotalPrice = (dataSource: Array<any>) => {
  //   let total = dataSource.map((data) => data?.total_price);
  //   const price = total.reduce((partialSum, a) => partialSum + a, 0);
  //   setTotalPrice(price);
  // };

  const handleCostIngredient = (
    dataArray: any,
    data: any,
    key: any,
    index: any
  ) => {
    if (
      !isNaN(Number(dataArray[index]['unit_cost'])) &&
      !isNaN(dataArray[index]['divided_by'])
    ) {
      if (key == 'quantity' && data != '') {
        dataArray[index]['total_price'] = Number(
          (
            (dataArray[index]['unit_cost'] / dataArray[index]['divided_by']) *
            +data
          ).toFixed(2)
        );
      } else {
        dataArray[index]['total_price'] = Number(
          (
            dataArray[index]['unit_cost'] / dataArray[index]['divided_by']
          ).toFixed(2)
        );
      }
    } else {
      dataArray[index]['total_price'] = 0;
    }
    return dataArray;
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    selectedRecipeId && (await ModifiersActions.delModifier(selectedRecipeId));
    pageData();
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleCloseForModifier = () => {
    setOpenModifierDialog(false);
    setIngredient([
      {
        ingredient_name: '',
        quantity: 0,
        unit_cost: '',
        waste: '',
        small_unit: 'g',
        total_price: 0,
      },
    ]);
    setUpdate(false);
    pageData();
  };

  const pageData = async () => {
    setRecipeLoader(true);
    const modifierData = await ModifiersActions.getModifiers(
      dataLength,
      current,
      keyword
    );
    if (modifierData === undefined || !modifierData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
      setModifiers([]);
    } else {
      setRecipeCount(modifierData?.data?.modifier_count);
      setTotal(modifierData?.data?.pagination?.total);
      setModifiers(modifierData?.data?.items);
      setSortData(modifierData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);

    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.modifier_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.modifier_type.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.menu_category.toLowerCase()?.includes(keyword.toLowerCase()) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(modifiers);
    // }

    const searchResultofRecipes = await ModifiersActions.searchModifiers(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.modifier_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
      setModifiers(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Modifier Groups"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete modifier?'}
      />
      <DetailDialog
        open={openDialog}
        handleClose={handleClose}
        data={dialogData}
        component={'modifier'}
      />

      <ModifierModal
        open={openModifierDialog}
        handleDialog={handleCloseForModifier}
        // data={dialogData}
        database={database}
        handleIngredient={handleIngredient}
        id={selectedRecipeId}
        handleIngredientObject={handleIngredientObject}
        deleteIngredient={deleteIngredient}
        addIngredient={addIngredient}
        ingredient={ingredient}
        component={'modifier'}
      />

      <Spin spinning={loader} size="large">
        <Page title="Modifier Groups">
          <Row className="listingTopFilter">
            <Col lg={10} md={24}>
              <ListCount count1={recipeCount} title1="Total Modifiers" />
            </Col>
            <Col lg={14} md={24}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!recipeLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={items}
                  dataSource={sortData ? [...sortData] : []}
                  columns={columns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default Modifiers;
