/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
} from '../../components';
import Filter from './filter';
import {
  handleFloat,
  formatNumberFloatValue,
  sortedData,
  sortNumber,
} from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Eye from '../../assets/images/Eye.svg';
import WastageActions from '../../redux/middleware/wastage';
import './styles.scss';
import Dialog from './dialog';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const PageDataLength = [6, 10, 20, 50];

const Wastage = () => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  // const [wastage, setWastage] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [wastageLoader, setWastageLoader] = useState(false);
  const [wastageCount, setWastageCount] = useState(0);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [, setSearch] = useState('');

  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>TYPE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('waste_type', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'waste_type',
      key: 'waste_type',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="wastagecol3">{record?.waste_type}</div>;
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>ITEM NAME</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="wastagecol3">{record?.ingredient_name}</div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>ITEM CATEGORY</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('ingredient_category', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'ingredient_category',
    //   key: 'ingredient_category',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div style={{ display: 'flex', justifyContent: 'center' }}>
    //       <div className="wastagecol2">{record?.ingredient_category}</div>
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>QTY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('quantity', 'int');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="subrecipecol2">
            {record?.quantity
              ? record?.quantity.toFixed(2) + ' ' + record?.unit
              : 0 + ' ' + record?.unit}
          </div>
        );
      },
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>UNITS</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('unit', 'string');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'unit',
    //   key: 'unit',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => {
    //     return <div className="wastagecol3">{record?.unit}</div>;
    //   },
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>CASH VALUE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('cash_value', 'int');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'cash_value',
      key: 'cash_value',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="wastagecol3">
            {formatNumberFloatValue(record?.cash_value)}
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      // eslint-disable-next-line no-unused-vars
      render: (data: any, record: any) => (
        <div className="detailButton" onClick={() => onPressDetail(record)}>
          <img src={Eye} alt="" />
          <div> Detail</div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Wastage'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    // setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const onSearch = async (keyword: string) => {
    setWastageLoader(true);
    setSearch(keyword);

    const searchResultofRecipes = await WastageActions.searchWastage(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setWastageCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      // const { items, waste_count, pagination } = searchResultofRecipes;

      // setWastageCount(waste_count);
      // setTotal(pagination?.total);
      // // setWastage(items);
      // setSortData(items);
      setWastageCount(searchResultofRecipes?.data?.waste_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.waste_type.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.ingredient_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.ingredient_category
    //         .toLowerCase()
    //         ?.includes(keyword.toLowerCase()) ||
    //       obj.quantity?.toString()?.includes(keyword.toString()) ||
    //       obj.unit.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.cash_value?.toString()?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(wastage);
    // }
    setWastageLoader(false);
  };

  const pageData = async (filters: any = null) => {
    setWastageLoader(true);
    if (Utils.getCurrentShop()?.shop_id) {
      filters = { ...filters, shop_id: Utils.getCurrentShop()?.shop_id };
    }
    const response = await WastageActions.getWastage(
      filters,
      dataLength,
      current,
      keyword
    );
    const { success, data } = response;
    if (!success) {
      setWastageCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      const { items = [], waste_count = 0, pagination = {} } = data;

      setWastageCount(waste_count);
      setTotal(pagination?.total);
      // setWastage(items);
      setSortData(items);
    }
    setWastageLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    pageData(filters);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard');
      }}
      shape="round"
      size="middle"
    />
  );

  // eslint-disable-next-line no-unused-vars

  const onPressDetail = (data: any) => {
    setSelectedData(data);
    setOpenDialog(true);
  };

  return (
    <div className="wastagePage">
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        data={selectedData}
      />
      <Breadcrumb
        heading="Wastage"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={
          (user?.roles[0]?.id === 4 && shop?.is_ck == 1) ||
          user?.roles[0]?.id === 3 ||
          user?.roles[0]?.id == 5
            ? actionButton
            : ''
        }
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={12}>
            <ListCount count1={wastageCount} title1="Total Wastage" />
          </Col>
          <Col lg={12}>
            <div className="search-wrapper">
              <SearchBox
                keyword={keyword}
                setKeyword={setKeyword}
                action={onSearch}
                placeholderText="Search..."
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={8}>
            <Filter
              action={pageDataFilter}
              apiCall={WastageActions.getWasteFilter}
            />
          </Col>
          <Col lg={18} md={16}>
            {!wastageLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={null}
                dataSource={[...sortData]}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={wastageLoader} className="wastageLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default Wastage;
