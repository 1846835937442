import React from 'react';
import Utils from '../../redux/utils';
import UserInfo from './userInfo';
import UserConfiguration from './configurations';

const UserPersonalInfo = () => {
  const { company_profile_status } = Utils.getCurrentUser();
  return (
    <React.Fragment>
      {company_profile_status !== 0 ? <UserInfo /> : <UserConfiguration />}
    </React.Fragment>
  );
};

export default UserPersonalInfo;
