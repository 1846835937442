import { baseURL } from '../../config/constant';
import Utils from '../../redux/utils';

const getUserSubscriptions = async (
  dataLength,
  page,
  keyword = '',
  is_subscribed = 1
) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/get_user_subscriptions?limit=${dataLength}&page=${page}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
      params: { keyword, is_subscribed },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSubscriptionPackages = async (dataLength, page, keyword = '') => {
  try {
    const apiOptions = {
      // ! Below endpoint change to stripe packages
      // endpoint: `${baseURL}/api/subscription/ca_packages?limit=${dataLength}&page=${page}`,
      endpoint: `${baseURL}/api/subscription/get_packages?limit=${dataLength}&sort_order=asc`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
      params: { keyword },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getCASubscriptionPackages = async (dataLength, page, keyword = '') => {
  try {
    const apiOptions = {
      // ! Below endpoint change to stripe packages
      endpoint: `${baseURL}/api/subscription/ca_packages?limit=${dataLength}&page=${page}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
      params: { keyword },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSubscriptionPaymentTypes = async (dataLength, page, keyword = '') => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/ca_subscription_payment_types?limit=${dataLength}&page=${page}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
      params: { keyword },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSubscriptionById = async (id) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/user/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const changePlans = async (param) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/change_plan`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: param,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const cancelSubscription = async (param) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/cancel`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: param,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const getSubscriptionPlans = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/get_packages?limit=8`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const manualSubscription = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/manual_subscription`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const manualSubscriptionInvoice = async (id, thumbnail) => {
  try {
    const formData = new FormData();
    formData.set('status', 'paid');
    formData.append('invoice_image', thumbnail);
    formData.append('_method', 'PUT');

    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/ca_invoices/${id}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const createEnterprisePlan = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/user_packages`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const UpdateEnterprisePlan = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/user_packages/${params?.packageId}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'PUT',
      data: {
        status: 1,
        payment_method: params?.payment_method,
        token: params?.token,
      },
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const createSubscriptionIntent = async (params) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/create_payment_intent`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: params,
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

//user_packages
const getUserPackage = async (packageId) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/subscription/user_packages/${packageId}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const SubscriptionApi = {
  getUserSubscriptions,
  getSubscriptionById,
  getSubscriptionPackages,
  getCASubscriptionPackages,
  getSubscriptionPaymentTypes,
  cancelSubscription,
  changePlans,
  getSubscriptionPlans,
  manualSubscription,
  manualSubscriptionInvoice,
  createEnterprisePlan,
  createSubscriptionIntent,
  getUserPackage,
  UpdateEnterprisePlan,
};

export default SubscriptionApi;
