import React, { useEffect, useState } from 'react';
// import useClickListener from '../../helper/outsideClickListener';
// import DragDot from '../../assets/images/dragDot.png';
// import EditMenu from '../../assets/images/editMenu.png';
// import Check from '../../assets/images/check.png';
import Utils from '../../redux/utils';
import { Spin } from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import './menuBar.scss';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import MenuActions from '../../redux/middleware/menu';
import { useSelector } from 'react-redux';

type Props = {
  handleSelectedPill: Function;
  allowMorePills: boolean;
  component: any;
  handleSelectedCategory?: any;
  category?: any;
  pillRender?: number;
  categoryPills?: any;
};

interface PillOption {
  id: number;
  sub_category: string;
  slug: string;
  created_at: string;
  company_id: number;
  brand_id: number;
  parent_category: string;
  parent_category_id: number;
  parent_slug: string;
  status: number;
  // Add other properties as needed
}

const Menu = (prop: Props) => {
  let {
    handleSelectedPill,
    component,
    handleSelectedCategory,
    category,
    // pillRender,
    categoryPills,
  } = prop;
  const [selectedCategory, setSelectedCategory] = useState<any>(0);
  // eslint-disable-next-line no-unused-vars
  const [selectedFilter, setSelectedFilter] = useState(category || false);
  // eslint-disable-next-line no-unused-vars
  const [pillCounter, setPillCounter] = useState(0);
  const [id, setId] = useState(0);
  const [pillOption, setPillOption] = useState<PillOption[]>([]);
  const [allowEditPill, setAllowEditPill] = useState(false);
  const [loader, setLoader] = useState(false);
  let { allowMorePills } = prop;
  const { currentBrand } = useSelector((state: any) => state.Auth);
  // const { ref, isComponentVisible, setIsComponentVisible } =
  //   useClickListener(false);

  // const handleMoreOptions = () => {
  //   setIsComponentVisible(!isComponentVisible);
  // };

  // const handleSelectedOption = (option: any) => {
  //   setSelectedFilter(option?.sub_category || option?.parent_category);
  //   handleSelectedPill(option?.id, option?.sub_category);
  //   handleSelectedCategory(option);
  //   setIsComponentVisible(false);
  // };

  const handlePill = (option: any) => {
    setId(option?.id || option?.slug);
    component === 'category' || component === 'report'
      ? setSelectedFilter(option?.name || option?.parent_category)
      : setSelectedFilter(option?.name || option?.sub_category);
    handleSelectedPill(option?.id || option?.slug, option?.sub_category);
    component === 'category' || component === 'report'
      ? handleSelectedCategory(option)
      : null;
  };

  const handleEditClick = () => {
    setAllowEditPill(true);
  };

  const handleCheckClick = async () => {
    setLoader(true);
    const company = Utils.getCurrentCompany();
    const brand = Utils.getCurrentBrand();
    const pillArray = pillOption.map((data: any) => data?.id);
    const params = {
      company_id: company?.id,
      brand_id: brand?.brand_id,
      parent_category_id: selectedCategory,
      sequence: pillArray,
    };
    const submissionPillData = await MenuActions.submitMenuSequence(params);
    setLoader(false);
    if (submissionPillData?.success) {
      setAllowEditPill(false);
    }
  };

  useEffect(() => {
    if (component !== 'category' && component !== 'report') {
      // handleParentCategory();
      // setPillOption(categoryPills);
      handleMenuCategory();
    }
  }, []);

  useEffect(() => {
    if (component === 'category' || component === 'report') {
      // handleParentCategory();
      setPillOption(categoryPills);
    }
  }, [categoryPills]);

  useEffect(() => {
    if (category) {
      setSelectedFilter(category);
      setId(category);
    }
  }, [category]);

  useEffect(() => {
    if (allowMorePills) {
      setPillCounter(6);
    } else {
      setPillCounter(4);
    }
  }, [allowMorePills]);

  useEffect(() => {
    if (selectedCategory !== 0) {
      handleSubCategories();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (
      currentBrand?.brand_id &&
      component !== 'category' &&
      component !== 'report' &&
      selectedCategory !== 0
    )
      handleSubCategories();
  }, [currentBrand?.brand_id]);

  // const handleParentCategory = async () => {
  //   if (component === 'category') setPillOption(categoryPills);
  //   else {
  //     const menuCategory = await MenuCategoryActions.getParentCategories();
  //     setPillOption(menuCategory?.data);
  //   }
  // };

  const handleMenuCategory = async () => {
    const menuCategory = await MenuCategoryActions.getParentCategories();
    if (menuCategory?.success) {
      const filterValue = menuCategory?.data?.find(
        (x: any) => x?.slug === 'menu'
      )?.id;
      setSelectedCategory(filterValue);
    }
  };

  const handleSubCategories = async () => {
    setLoader(true);
    let recipeData = await MenuCategoryActions.getSubCategoriesForMenu(
      `1,4`,
      500
    );
    if (recipeData?.success) {
      let filterData = recipeData?.data?.items.filter((val: any) => {
        return val?.name !== 'Production' && val?.name !== 'Sub Recipe';
      });
      setPillOption([
        {
          id: 0,
          sub_category: 'All',
          slug: 'all',
          company_id: 0,
          brand_id: 0,
          parent_category_id: 4,
          parent_category: 'Extra',
          parent_slug: 'extra',
          status: 1,
          created_at: '2023-04-06T06:14:47.000000Z',
          sequence: 0,
        },
        ...filterData,
      ]);
    } else {
      setPillOption([]);
    }
    setLoader(false);

    // filterData.unshift({
    //   id: 4,
    //   parent_category: 'Menu Category',
    //   parent_category_id: 1,
    //   parent_slug: 'menu',
    //   sub_category: 'Combo',
    // });
  };

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    option: any,
    index: number
  ) => {
    const data = { option, index };
    event.stopPropagation();
    event.dataTransfer.setData('application/json', JSON.stringify(data));
    // if (data?.option?.sub_category === 'Combo') {
    //   event.dataTransfer.effectAllowed = 'none';
    // } else {
    event.dataTransfer.effectAllowed = 'move';
    // }
  };
  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    const droppedItem = JSON.parse(
      event.dataTransfer.getData('application/json')
    );

    const updatedPillOption: PillOption[] = [...pillOption];
    updatedPillOption[droppedItem?.index] = updatedPillOption[index];
    updatedPillOption[index] = droppedItem?.option;
    setPillOption(updatedPillOption);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const ref = React.useRef<any>(null);

  const handleScroll = (offset: any) => {
    if (ref.current) {
      ref.current.scrollLeft += offset;
    }
  };

  return (
    <Spin spinning={loader} size="small">
      <div className="menuBarMainWrapper">
        {component !== 'report' && (
          <div className="menubar-left-right-buttons">
            <CaretLeftOutlined
              size={30}
              onClick={(e) => {
                e.preventDefault();
                handleScroll(-250);
              }}
              className="menubar-back-button"
            />
          </div>
        )}

        <div
          className={`menuContainer ${
            !(component === 'menu' || component === 'manageMenu')
              ? 'menuContainerWithoutDrag menuContainerMargin'
              : ''
          }`}
          ref={ref}
        >
          {/* <div className="menu">
            {component !== 'category' ? (
              <span>
                {' '}
                <img src={DragDot} />
              </span>
            ) : null}
             <div className="text">
              {selectedFilter ? selectedFilter : 'Selected Filter'}
            </div>
          </div> */}

          {component !== 'report'
            ? pillOption?.map((data: any, index: number) => (
                <div
                  key={index}
                  className={
                    data.id === 0 && data.slug === 'all' && id === 0
                      ? 'menuDark'
                      : data.id === id
                      ? data.name?.length < 10 || data.sub_category?.length < 10
                        ? 'menuDarkWithWidth'
                        : 'menuDark'
                      : data.id !== 0 && data.slug !== 'all'
                      ? data.name?.length < 10 || data.sub_category?.length < 10
                        ? 'menuLightWithWidth'
                        : 'menuLight'
                      : 'menuLight'
                  }
                  onClick={() => handlePill(data)}
                  draggable={
                    component === 'menu' ||
                    component === 'manageMenu' ||
                    component === 'category'
                  }
                  onDragStart={(event) => onDragStart(event, data, index)}
                  onDrop={(event) => handleDrop(event, index)}
                  onDragOver={(event) => handleDragOver(event)}
                >
                  {/* {component !== 'category' && component !== 'report' ? (
                    <span className="imageStye">
                      {' '}
                      <img src={DragDot} />
                    </span>
                  ) : null} */}
                  {component !== 'category' && component !== 'report' ? (
                    <div className="text">
                      {data?.name || data?.sub_category}
                    </div>
                  ) : (
                    <div className="text">
                      {data?.name || data?.parent_category}
                    </div>
                  )}
                </div>
              ))
            : pillOption?.map((data: any, index: number) => (
                <div
                  key={index}
                  className={id == data?.slug ? 'menuDark' : 'menuLight'}
                  onClick={() => handlePill(data)}
                  // draggable={component === 'menu' || component === 'manageMenu'}
                  // onDragStart={(event) => onDragStart(event, data, index)}
                  // onDrop={(event) => handleDrop(event, index)}
                  // onDragOver={(event) => handleDragOver(event)}
                >
                  <div className="text">{data?.name}</div>
                </div>
              ))}
          {/* {pillOption?.length > 6 ? (
          <div
            className="menuLight"
            onClick={handleMoreOptions}
            style={{ position: 'relative' }}
            ref={ref}
          >
            <div className="text">{pillOption?.length - pillCounter} more</div>
            {isComponentVisible ? (
              <div className="listBox">
                {pillOption
                  .slice(pillCounter, pillOption?.length)
                  ?.map((data: any, index: number) => (
                    <div
                      key={index}
                      className="listBoxText"
                      onClick={() => handleSelectedOption(data)}
                    >
                      {data?.sub_category || data?.parent_category}
                    </div>
                  ))}
              </div>
            ) : null}
          </div>
        ) : null} */}
        </div>

        {component !== 'report' && (
          <div className="menubar-left-right-buttons">
            {' '}
            <CaretRightOutlined
              onClick={(e) => {
                e.preventDefault();
                handleScroll(250);
              }}
              className="menubar-forward-button"
            />
          </div>
        )}

        {!allowEditPill &&
        (component === 'menu' || component === 'manageMenu') ? (
          <div className="editButtonStyle">
            {/* <img src={EditMenu} onClick={handleEditClick} /> */}
            <LockOutlined
              size={30}
              onClick={handleEditClick}
              className="lock-icon-style"
            />
          </div>
        ) : null}
        {allowEditPill ? (
          <div className="editButtonStyle">
            {/* <img src={Check} onClick={handleCheckClick} /> */}
            <UnlockOutlined
              onClick={handleCheckClick}
              className="lock-icon-style"
            />
          </div>
        ) : null}
      </div>
    </Spin>
  );
};

export default Menu;
