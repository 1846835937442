import React, { useState } from 'react';
import { Modal, Spin } from 'antd';
import Delete from '../../../assets/images/Delete.svg';
import './style.scss';
import { RcFile } from 'antd/lib/upload';
import { Button } from '../../../components';
import SubscriptionApi from '../../../redux/middleware/subscription';

type Props = {
  open: boolean;
  handleClose: any;
  subscriptionInfo: any;
};

const InvoiceDialog = (props: Props) => {
  let { open, handleClose, subscriptionInfo } = props;
  const [image, setImage] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  const [imageObject, setImageObject] = React.useState(null);
  const fileRef = React.useRef<HTMLInputElement>(null);

  const resetFormOnClose = () => {
    setImage('');
    setImageObject(null);
    !loader && handleClose();
  };

  const onSubmit = async () => {
    try {
      setLoader(true);
      const response = await SubscriptionApi.manualSubscriptionInvoice(
        subscriptionInfo?.invoice_id,
        imageObject
      );
      setLoader(false);
      if (response?.success) {
        resetFormOnClose();
      }
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Modal
      title={<div className="title font">Subscription Invoice</div>}
      centered
      open={open}
      onCancel={resetFormOnClose}
      width={480}
      footer={null}
      className="DialogWrapper InvoiceContainer"
    >
      <Spin spinning={loader} size="large">
        <div>
          <div className="wizardSubContainer">
            <div className="wizardLabel">{'Upload Invoice Image'}</div>

            {image ? (
              <div className="imageUpload">
                <div className="imageWrapper">
                  <img src={image} alt="" className="imageHeight" />
                  <div
                    className="deleteButton"
                    onClick={() => handleImage('', {})}
                  >
                    <img src={Delete} width={15} alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="formgroup formgroupStepOne">
                <div className="label-wrapper">
                  <label>
                    <p className="ant-upload-text ">
                      {`Drop your image here or browse`}
                    </p>
                    <p className="ant-upload-hint">{`Minimum file 2MB (.png,.jpg,.jpeg)`}</p>
                    <input
                      ref={fileRef}
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={async (e: any) => {
                        const response = await getBase64(
                          e.currentTarget.files[0]
                        );
                        if (response) handleImage(response, e);
                      }}
                    />
                  </label>
                </div>
              </div>
            )}

            <div className="invoiceBtnContainer">
              <Button
                text={'Pay Invoice'}
                className="theme-button recipeBtn"
                handleButtonClick={onSubmit}
                shape="round"
                size="middle"
                disabled={imageObject == null}
              />
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default InvoiceDialog;
