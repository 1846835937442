export const getFormattedNumericValue = (value: string | number) =>
  value.toLocaleString('en');

export const convertCipherTextToObject = (encryptedData: string) => {
  // Convert the encrypted data from base64 to ArrayBuffer
  const encryptedArrayBuffer = new Uint8Array(
    atob(encryptedData)
      .split('')
      .map((char) => char.charCodeAt(0))
  ).buffer;

  // Decryption key (must be a CryptoKey object)
  const decryptionKey: any =
    '$2a$12$b6DtpMWr/D30VEBwBKqv4O.PEr7w/0Tyz0lHTCnP4hZkeM47s/SLa';

  // Decryption algorithm
  const decryptAlgorithm = {
    name: 'AES-GCM',
    iv: new Uint8Array(12), // Initialization vector
  };

  // Decrypt the data
  crypto.subtle
    .decrypt(decryptAlgorithm, decryptionKey, encryptedArrayBuffer)
    .then((decryptedArrayBuffer) => {
      // Convert the decrypted data to a string
      const decryptedData = new TextDecoder().decode(decryptedArrayBuffer);
      console.log('Decrypted data:', decryptedData);
    })
    .catch((error) => {
      console.error('Error decrypting data:', error);
    });
};
