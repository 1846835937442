/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, CountDown, ResponseDialog } from '../../components';
import { Select, Row, Col, Alert, Spin } from 'antd';
import detailImg1 from '../../assets/images/detail-img1.png';
import detailImg2 from '../../assets/images/detail-img2.png';
import fileIcon from '../../assets/images/excelIcon.png';
import arrowDown from '../../assets/images/DownArrow.png';
import arrowLeft from '../../assets/images/ArrowLeft.png';
import { formatFileSize } from 'react-papaparse';
import Delete from '../../assets/images/Delete.png';
import { RcFile } from 'antd/lib/upload';
import RecipeActions from '../../redux/middleware/recipe';
import BulkUpload from '../../redux/middleware/bulkUploadRDSubRecipe';
import Utils from '../../redux/utils';
import './BulkUploadRDSubRecipe.scss';

const BulkUploadRDSubRecipe = () => {
  const navigate = useNavigate();
  const array = [
    { value: 'csvFile', label: 'CSV File' },
    { value: 'csvMinimal', label: 'CSV Minimal' },
    { value: 'csvFull', label: 'CSV Full' },
  ];
  const fileRef = React.useRef<HTMLInputElement>(null);

  const [tableData, setTableData] = useState<any>('');
  const [Variation, setVariation] = useState<any>(array[1]);
  const [hideBtn, setHideBtn] = useState(false);
  const [errorList, setErrorList] = useState<any>('');
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState(false);

  // const screenPermission = Utils.getScreenPermission('R&D Sub Recipe');
  // const validateActionPermission = () => {
  //   if (screenPermission) {
  //     // eslint-disable-next-line camelcase
  //     const { perm_status_id, perm_create } = screenPermission;

  //     // eslint-disable-next-line camelcase
  //     if (perm_status_id === 1 || perm_create === 1) {
  //       return false;
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  const handleImage = (url: any, data: any) => {
    setTableData(data?.target?.files[0]);
  };
  const handleBack = () => {
    navigate('/app/r&d-subrecipe');
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleContinue = async () => {
    setLoader(true);
    try {
      setVisible(false);
      // let responseDialogData;
      const response = await BulkUpload.uploadRecipes(
        tableData,
        'recipe_builder'
      );
      setLoader(false);
      if (!response?.success || response == undefined) {
        setTableData('');
        if (response !== undefined) setErrorList(response.error);
        if (response?.error?.messages?.length <= 3) {
          setHideBtn(true);
        } else {
          setHideBtn(false);
        }
      } else {
        // setErrorList('');
        navigate('/app/r&d-subrecipe');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/r&d-subrecipe');
        //   },
        //   type: 'success',
        //   messageArray: [` Your listing has been added`],
        // };
        // ResponseDialog(responseDialogData);
        setTableData('');
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeUOM = (data: any) => {
    setVariation(data);
  };

  return (
    <div>
      <Breadcrumb
        heading="R&D Bulk Upload Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/r&d-subrecipe');
        }}
      />
      {<CountDown />}
      <Row>
        <Col lg={24} md={24}>
          {visible && (
            <Alert
              message="Error"
              description={
                'File format is Invalid. Please Select Correct File!'
              }
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <Spin spinning={loader} size="large">
            <div className="BulkUploadRDSubRecipe">
              <div className="wizardSubContainer checkBox">
                <div className="wizardLabel">File Attachment</div>

                {tableData ? (
                  <div className="imageUpload">
                    <div className="imageWrapper">
                      <div>
                        <img src={fileIcon} alt="" style={{ width: '100%' }} />
                        <h4> {tableData?.name} </h4>
                        <p>{formatFileSize(tableData?.size)}</p>
                      </div>
                      <div
                        className="deleteButton"
                        onClick={() => handleImage('', [])}
                      >
                        <img src={Delete} width={15} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="formgroup formgroupStepOne">
                    <div className="label-wrapper">
                      <label>
                        <p className="ant-upload-text ">
                          Drop your csv file here or browse
                        </p>
                        <p className="ant-upload-hint">
                          Minimum file 2 MB (.csv)
                        </p>
                        <input
                          ref={fileRef}
                          type="file"
                          accept=".csv"
                          onChange={async (e: any) => {
                            if (e.currentTarget.files[0]?.type == 'text/csv') {
                              const response = await getBase64(
                                e.currentTarget.files[0]
                              );
                              if (response) handleImage(response, e);
                            } else {
                              setVisible(true);
                            }
                          }}
                          // disabled={!validateActionPermission()}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {/* <div className="dropDown-wrapper">
                <label>
                  CSV File <span>*</span>
                </label>
                <Select
                  size={'large'}
                  value={Variation}
                  placeholder="Select Variation"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={array}
                  disabled
                />
              </div> */}
                {errorList &&
                  (errorList.length <= 3 ? (
                    <div className="ErrorContainer">
                      {Utils.renderErrorItem(errorList)?.map(
                        (error: any, index: any) => (
                          <p
                            className={
                              error.includes('Success')
                                ? 'SuccessContainerRDRecipe'
                                : ''
                            }
                            key={index}
                          >
                            {error}
                          </p>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="ErrorContainer">
                      {Utils.renderErrorItem(errorList)
                        ?.slice(0, 3)
                        .map((error: any, index: any) => (
                          <p
                            className={
                              error.includes('Success')
                                ? 'SuccessContainerRDRecipe'
                                : ''
                            }
                            key={index}
                          >
                            {error}
                          </p>
                        ))}
                      {showAllErrors && (
                        <div>
                          {Utils.renderErrorItem(errorList)
                            ?.slice(3)
                            .map((error: any, index: any) => (
                              <p
                                className={
                                  error.includes('Success')
                                    ? 'SuccessContainerRDRecipe'
                                    : ''
                                }
                                key={index}
                              >
                                {error}
                              </p>
                            ))}
                        </div>
                      )}
                      <div>
                        <div
                          className="wizard-button upload-errors"
                          onClick={() => setShowAllErrors(!showAllErrors)}
                          hidden={hideBtn}
                        >
                          {showAllErrors ? 'Hide' : 'Show more'}
                          <img
                            src={showAllErrors ? arrowDown : arrowLeft}
                            alt={
                              showAllErrors ? 'Expand Icon' : 'Collapse Icon'
                            }
                            className="expand-icon"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="butonGroup">
                  <div className="wizard-button" onClick={handleBack}>
                    Back
                  </div>
                  <div
                    className="continue wizard-button"
                    onClick={handleContinue}
                  >
                    Continue
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Col>

        <Col lg={12} md={24} style={{ display: 'none' }}>
          <div className="fileDetailBlockRDSubRecipe">
            <ol className="fileDetailListRDSubRecipe">
              <li>
                <div className="list-contentRD-SubRecipe">
                  <span>1.</span>
                  <p>
                    Download <b>CSV File</b>, you can choose <b>CSV Minimal</b>{' '}
                    or
                    <b>CSV Full</b>
                  </p>
                </div>
              </li>
              <li>
                <div className="list-contentRD-SubRecipe">
                  <span>2.</span>
                  <p>
                    Download <b>Get Marketlist</b> File
                  </p>
                </div>
              </li>
              <li>
                <div className="list-contentRD-SubRecipe">
                  <span>3.</span>
                  <p>
                    Open the <b>CSV File</b> and Input ingredients based on
                    <b>The Marketlist Data</b>. Make sure the ingredients is
                    suitable with the
                    <b>Marketlist Data</b>.
                  </p>
                </div>

                <div className="listImg-RD-SubRecipe">
                  <Row>
                    <div className="imgWrapper-RD-SubRecipe">
                      <img src={detailImg1} alt="" />
                    </div>
                    <div className="imgWrapper-RD-SubRecipe">
                      <img src={detailImg2} alt="" />
                    </div>
                  </Row>
                </div>
              </li>
              <li>
                <div className="list-contentRD-SubRecipe">
                  <span>4.</span>
                  <p>
                    Upload the <b>CSV File</b> on the
                    <b>Upload Recipes’s Menu Download</b>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BulkUploadRDSubRecipe;
