import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Page,
  Breadcrumb,
  MenuCategoryStep,
  // ResponseDialog,
} from '../../components';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Utils from '../../redux/utils';
import './menuCategoryWizard.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const menuCategoryWizard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'categoryPage',
  });
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = React.useState(false);
  const company = Utils.getCurrentCompany();
  const [apiLoader, setApiLoader] = React.useState(false);
  const [errorList, setErrorList] = React.useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id, selectedCategory } = state;
  const [menuCategory, setMenuCategory] = React.useState('');
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/menuCategory');
    }
  }, [currentBrand, currentShop]);

  const fetchDataById = async (id: any) => {
    const { data, success } = await MenuCategoryActions.getSubCategoriesById(
      id
    );

    if (success) {
      setLoader(false);
      setMenuCategory(data?.sub_category);
    }
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, []);

  const handleMenuCategory = (data: string) => {
    setMenuCategory(data);
  };

  const handleContinue = async () => {
    const brand = await Utils.getCurrentBrand();

    setErrorList({});
    setErrorStatus(false);
    const param = {
      company_id: company?.id,
      sub_category: menuCategory,
      brand_id: brand?.brand_id,
      parent_category_id: selectedCategory?.id,
    };
    // let responseDialogData;
    setApiLoader(true);

    const response = update
      ? await MenuCategoryActions.updateSubCategories(id, param)
      : await MenuCategoryActions.addSubCategories(param);
    setApiLoader(false);
    if (!response?.success) {
      setErrorList(response?.error);
      setErrorStatus(true);
    } else {
      navigate(`/app/menuCategory?id=${selectedCategory?.id}`);
      // responseDialogData = {
      //   OnClick: () => {
      //     navigate(`/app/menuCategory?id=${selectedCategory?.id}`);
      //   },
      //   type: 'success',
      //   messageArray: [
      //     ` Your ${menuCategory}`,
      //     ` has been ${update ? 'Updated' : 'Created'}`,
      //   ],
      // };
      // ResponseDialog(responseDialogData);
    }
  };

  const handleHeadingWizard = () => {
    if (selectedCategory?.parent_category === 'Menu Category') {
      return t('menu_category_wizard');
    } else if (selectedCategory?.parent_category === 'Sub Recipe Category') {
      return t('subrecipe_category_wizard');
    } else if (selectedCategory?.parent_category === 'Kitchen Section') {
      return t('kitchen_section_wizard');
    } else if (selectedCategory?.parent_category === 'Ingredient Category') {
      return t('ingredient_category_name');
    } else if (selectedCategory?.parent_category === 'R&D Menu Category') {
      return t('rdmenu_category_wizard');
    } else if (
      selectedCategory?.parent_category === 'R&D Sub Recipe Category'
    ) {
      return t('rdsubrecipe_category_wizard');
    } else if (
      selectedCategory?.parent_category === 'R&D Ingredient Category Name'
    ) {
      return t('rdingredient_category_wizard');
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={handleHeadingWizard()}
        breadcrumbIcon
        iconAction={() => {
          navigate(`/app/menuCategory?id=${selectedCategory?.id}`);
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title={`Add New ${selectedCategory?.parent_category}`}>
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="wizardContainer">
              <MenuCategoryStep
                selectedCategory={selectedCategory}
                menuCategory={menuCategory}
                handleMenuCategory={handleMenuCategory}
                errorList={errorList}
                errorStatus={errorStatus}
                handleContinue={handleContinue}
              />
              <div className="butonGroup">
                <div
                  className="wizard-button"
                  onClick={() =>
                    navigate(`/app/menuCategory?id=${selectedCategory?.id}`)
                  }
                >
                  {t('back')}{' '}
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  {t('continue')}{' '}
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default menuCategoryWizard;
