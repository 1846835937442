/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Input, Select } from 'antd';
import VariationActions from '../../redux/middleware/variation';
import './variationStep.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  handleVariationChange: any;
  VariationTypeId?: any;
  handleRecipe: any;
  variation: string;
  type: string;
  errorList: any;
  errorStatus: any;
};

const VariationStep = (prop: Props) => {
  let {
    handleVariationChange,
    VariationTypeId,
    handleRecipe,
    type,
    variation,
    errorList,
    errorStatus,
  } = prop;
  const [category, setCategory] = React.useState<any>([]);
  const data: any = category.find((val: any) => {
    return val.id === type || val.id == VariationTypeId;
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'variationPage',
  });

  const fetchVariationType = async () => {
    const VariationType: any = await VariationActions.getVariationType();

    setCategory(VariationType.data.items);
  };
  useEffect(() => {
    fetchVariationType();
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <div className="parentContainer">
        <div className="wizardSubContainer">
          <div className="wizardLabel">
            {t('variation_type')} <span className="sterik">*</span>
          </div>
          <Select
            size={'large'}
            placeholder={t('select_placeholder')}
            onChange={handleVariationChange}
            style={{ width: 560 }}
            optionFilterProp="children"
            value={data?.name}
            disabled={true}
          >
            {category?.map((data: any, index: number) => (
              <Select.Option value={data?.id} key={index}>
                {data.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="wizardSubContainer variation-wrapper">
          <div className="wizardLabel">
            {t('variation_name_label')} <span className="sterik">*</span>
          </div>
          <Input
            placeholder={t('input_placeholder')}
            size="large"
            value={variation}
            onChange={(e) => {
              handleRecipe(e.target.value);
            }}
            required
            className={errorList['name']?.length > 0 ? 'borderRed' : ''}
          />
          {errorStatus && (
            <div className="subRecipeErrorMessages">{errorList['name']}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default VariationStep;
