/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
  Select,
  Button,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  PlusOutlined,
  FilterOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  Button as CSButton,
  ListViewWithoutExpand,
  IngredientDialog,
  DeleteConfirmation,
  Dialog,
  SubDialog,
  ResponseDialog,
  DonutChart,
  InventoryFilter,
  SupplierModal,
} from '../../components';
import CloudActions from '../../assets/icons/CloudActions.svg';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import IngredientActions from '../../redux/middleware/ingredient';
import UsersApi from '../../redux/middleware/users';
import Attributes from '../../redux/middleware/attributes';
import {
  handleFloat,
  formatNumberFloatValue,
  sortNumber,
  sortedData,
} from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Delete from '../../assets/images/Delete.svg';
import SubMenu from '../../assets/images/SubMenu.svg';
import Supplier from '../../assets/images/Supply.svg';
import ProductionIcon from '../../assets/icons/sidebar/Production.svg';
import Sustainable from '../../assets/icons/sidebar/Wastage.svg';
import Fire from '../../assets/images/Fire.svg';
import moment from 'moment';
import './marketlist.scss';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
// import MenuCategoryActions from '../../redux/middleware/menuCategory';
import { checkAuthentication } from '../../helper/checkAuthentication';

interface Brand {
  id: string;
  brand_id?: string;
  brand_name: string;
  has_shops?: {
    id: any;
    shop_name: string;
  }[];
}

interface Branch {
  id: string;
  shop_id?: string;
  shop_name: string;
  has_brands?: { id: any; brand_name: string }[];
}

interface ShopBrands {
  id?: string;
  brand_id?: string;
  shop_id?: string;
  shop_name?: string;
  brand_name?: string;
  user_brand_status?: boolean;
  user_shop_status?: boolean;
}

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">View</div>,
    icon: <img src={Eye} width={15} className="iconDropdown" />,
  },
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    key: '3',
    label: <div className="dotOptionBuilder deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const itemsForInventory: MenuProps['items'] = [
  {
    key: '1',
    label: <div className="dotOption">Upload Ingredients</div>,
  },
  {
    key: '2',
    label: <div className="dotOption">Export Full Marketlist CSV</div>,
  },
  {
    key: '3',
    label: <div className="dotOption">Export Stock on Hand CSV</div>,
  },
];

const PageDataLength = [6, 10, 20, 50];

const InventoryListPage = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const brand = Utils.getCurrentBrand();
  const company = Utils.getCurrentCompany();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [inventory, setInventory] = useState([{}]);
  // const [database, setDatabase] = useState<any>([]);
  const [popOver, setPopOver] = useState(false);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [openIngredientDialog, setOpenIngredientDialog] = useState(false);
  const [openDialogForSupplier, setOpenDialogForSupplier] = useState(false);
  const [allowRequest, setAllowRequest] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [selectedDbId, setSelectedDbId] = useState(3);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState<any>({});
  const [dialogData, setDialogData] = useState({});
  const [supplierModalData, setSupplierModalData] = useState({});
  const [update, setUpdate] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [filters, setFilters] = useState({
    type: '',
    sortField: 'ingredient_name',
    sort: ['asc', 'desc'],
    ingredientType: '',
    subCategory: '',
  });
  const [id, setId] = useState('');
  const [loader, setLoader] = useState(false);
  const [types] = useState<any[]>([
    { label: 'Sub Recipe', value: 'sub_recipe' },
    { label: 'Production', value: 'production' },
    { label: 'Ingredients', value: 'ingredient' },
    { label: 'Sustainable', value: 'sustainable' },
  ]);
  // const [categories, setCategories] = useState<any[]>([]);
  // const [selectedIngredientType, setSelectedIngredientType] = useState<any[]>(
  //   []
  // );
  // const [subCategories, setSubCategories] = useState<any[]>([]);
  // const [sortedSubCategory, setSortedSubCategory] = useState<any[]>([]);
  // const [category, setCategory] = useState<any[]>();
  const [subCategory, setSubCategory] = useState<any[]>([]);
  const [popUp, setPopUp] = useState<any>(null);
  const [search, setSearch] = useState('');
  const brandList = Utils.getBrandList();
  const branchList = Utils.getShopList();
  const [brands, setBrands] = useState<any[] | string>(
    user?.roles[0]?.id === 3 ? 'brand' : []
  );

  const [allBrands, setAllBrands] = useState<Brand[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(['ALL']);
  const [selectedBranches, setSelectedBranches] = useState<string[]>(['ALL']);
  const [shopBrands, setShopBrands] = useState<ShopBrands[]>([]);
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);
  const [negativeCount, setNegativeCount] = useState(0);
  const [showNegative, setShowNegative] = useState(false);
  const [activeNegativeBtn, setActiveNegativeBtn] = useState(false);

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Inventory List'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    if (user?.roles[0]?.id !== 3) {
      setBrands([brand?.brand_id]);
    }
    // fetchParentCategories();
  }, []);

  useEffect(() => {
    if (dataLength && current && brands?.length) {
      pageData();
    }
  }, [
    dataLength,
    current,
    brands,
    // filters,
  ]);

  useEffect(() => {
    if (selectedBrands[0] !== 'ALL' || selectedBranches[0] !== 'ALL') {
      pageData();
    }
  }, [selectedBrands, selectedBranches]);

  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  // useEffect(() => {
  //   if (categories?.length) {
  //     setSortedSubCategory([]);
  //     fetchSubCategories();
  //   }
  // }, [categories, selectedIngredientType]);

  useEffect(() => {
    if (filters?.sort || subCategory?.length) {
      pageData(1);
    }
  }, [filters, subCategory]);

  useEffect(() => {
    setSelectedBrands(['ALL']);
    setSelectedBranches(['ALL']);
  }, []);

  useEffect(() => {
    setAllBrands([]);
    setBranches([]);
    if (user?.roles[0]?.id === 3) {
      setAllBrands(company?.brands);
      setBranches(company?.shops);
    }
  }, [brands]);

  useEffect(() => {
    if (user?.roles[0]?.id === 3) {
      getBrandBranch();
    }
  }, [selectedBrands, selectedBranches]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);
    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const handleingredient = async (sku: any) => {
    setLoader(true);
    const brand = await Utils.getCurrentBrand();
    const details = await SubRecipeActions.getIngredientDetail(
      sku,
      brand?.brand_id
    );
    setLoader(false);
    setDialogData(details);
    setOpenDialog(true);
  };

  const handlesupplier = async (sku: any) => {
    setLoader(true);
    setOpenDialog(true);
  };

  // const fetchParentCategories = async () => {
  //   const brand = await Utils.getCurrentBrand();
  //   const shop = await Utils.getCurrentShop();
  //   // setBrands(brandList.map((c: any) => c.brand_id));
  //   if (user?.roles[0]?.id === 3) {
  //     setBrands('brand' || 'shop');
  //   } else if (user?.roles[0]?.id === 4) {
  //     setBrands([shop?.shop_id]);
  //   } else {
  //     setBrands([brand?.brand_id]);
  //   }
  //   const { data, success } = await MenuCategoryActions.getParentCategories();
  //   if (success) {
  //     setCategories(
  //       data?.filter(
  //         (categoryObj: any) =>
  //           categoryObj?.slug === 'sub_recipe' ||
  //           categoryObj?.slug === 'prep_category' ||
  //           categoryObj?.slug === 'ingredient_category'
  //       )
  //     );
  //   }
  // };

  // const fetchSubCategories = async () => {
  // const SubCategories = category?.map(async (id: any) => {
  //   return MenuCategoryActions.getSubCategoriesForMenu(id, 500).then(
  //     (response: any) => response?.data?.items
  //   );
  // });
  // Promise.all(SubCategories).then((values) => {
  //   setSubCategories(values.flat());
  //   setSortedSubCategory(values.flat());
  // });

  //   const SubCategories =
  //     await MenuCategoryActions.getSubCategoriesForInventory(
  //       5,
  //       500,
  //       selectedIngredientType
  //     );

  //   if (SubCategories?.success) {
  //     setSubCategories(SubCategories?.data?.items);
  //     setSortedSubCategory(SubCategories?.data?.items);
  //   }
  // };

  // const onSearchSubCategory = (value: any) => {
  //   const filteredArray = subCategories.filter((subCategoryObj: any) =>
  //     subCategoryObj?.sub_category.toLowerCase().includes(value)
  //   );
  //   setSortedSubCategory([...filteredArray]);
  // };

  const handleBrandBranch = (value: any) => {
    setBrands(value);
  };

  const handleBrand = (value: any) => {
    setBrands(value);
  };

  const handleBranch = (value: any) => {
    setBrands(value);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span className="inventory-sku-margin">SKU</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('sku_id', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sku_id',
      key: 'sku_id',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing list-view-normal-font">
          {record?.sku_id}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle ">
          <span>NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col1">
          <div>
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Name</div>
                  <div className="tootipText">{record?.ingredient_name}</div>
                </div>
              }
              className="table-title"
            >
              <div
                className="subrecipesubCol2"
                onClick={() => {
                  setSelectedMenu(record);
                  handleDialogData();
                }}
              >
                {record?.ingredient_name?.substring(0, 50)}
                {record?.ingredient_name?.length > 50 ? <span>...</span> : null}
              </div>
            </Tooltip>
          </div>

          {/* <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-gap">
            <div className="common-flex-center">
              {' '}
              {record?.ingredient_type?.toLowerCase() == 'sub_recipe' && (
                <img
                  src={SubMenu}
                  width={15}
                  alt=""
                  className="subRecipeIcons"
                  onClick={() => {
                    handleingredient(record?.sku);
                  }}
                />
              )}
              {record?.ingredient_type?.toLowerCase() == 'production' && (
                <img
                  src={ProductionIcon}
                  width={15}
                  alt=""
                  className="subRecipeIcons "
                />
              )}
              {record?.ingredient_type?.toLowerCase() == 'sustainable' && (
                <img
                  src={Sustainable}
                  width={20}
                  height={23}
                  alt=""
                  className="subRecipeIcons ingredient-sustainable-icon-space "
                />
              )}
            </div>
            <div className="tableCol col3 common-flex-center">
              <DonutChart
                popUp={popUp}
                setPopUp={setPopUp}
                index={index}
                data={record}
                component={'listView'}
              >
                <Tooltip
                  title={
                    <div className="ingredient-verify-icon-tooltip">
                      <div className="tooltipHead">Calorie Counted : </div>
                      <div className="tootipText">
                        {!record?.is_verified ? 'NO' : 'YES'}
                      </div>
                    </div>
                  }
                  className="table-title"
                >
                  {
                    record?.is_verified ? (
                      <img
                        src={Fire}
                        width={15}
                        alt=""
                        className=" ingredient-verified-icon"
                      />
                    ) : null
                    // <img
                    //   src={Fire}
                    //   width={15}
                    //   alt=""
                    //   className="ingredient-not-verified-icon"
                    // />
                  }
                </Tooltip>
              </DonutChart>
            </div>
          </div> */}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>DETAILS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="title-alignment">
          <div>
            <Tooltip
              title={
                <div className="ingredient-verify-icon-tooltip">
                  {/* <div className="tooltipHead">Calorie Counted : </div> */}
                  <div className="tootipText">Sub Recipe</div>
                </div>
              }
              className="table-title"
            >
              {record?.ingredient_type?.toLowerCase() == 'sub_recipe' && (
                <img
                  src={SubMenu}
                  width={15}
                  alt=""
                  className="subRecipeIcons IconsAlignment"
                  onClick={() => {
                    handleingredient(record?.sku);
                  }}
                />
              )}
            </Tooltip>
            <Tooltip
              title={
                <div className="ingredient-verify-icon-tooltip">
                  {/* <div className="tooltipHead">Calorie Counted : </div> */}
                  <div className="tootipText">Production</div>
                </div>
              }
              className="table-title"
            >
              {record?.ingredient_type?.toLowerCase() == 'production' && (
                <img
                  src={ProductionIcon}
                  width={15}
                  alt=""
                  className="subRecipeIcons spacingForImg"
                />
              )}
            </Tooltip>

            <Tooltip
              title={
                <div className="ingredient-verify-icon-tooltip">
                  {/* <div className="tooltipHead">Calorie Counted : </div> */}
                  <div className="tootipText">Sustainable</div>
                </div>
              }
              className="table-title"
            >
              {record?.ingredient_type?.toLowerCase() == 'sustainable' && (
                <img
                  src={Sustainable}
                  width={20}
                  height={20}
                  alt=""
                  className="subRecipeIcons ingredient-sustainable-icon-space sustainableIconAlignment"
                />
              )}
            </Tooltip>
          </div>

          <div>
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Usage : </div>
                  <div className="tootipText">
                    Recipes: {record?.recipe_count}, Sub Recipes:{' '}
                    {record?.sub_recipe_count}
                  </div>
                </div>
              }
              className="table-title"
            >
              <ApartmentOutlined
              // size={100}
              // onClick={() => setPopOver(!popOver)}
              />
            </Tooltip>
          </div>
          <div>
            <Tooltip
              title={
                <div className="ingredient-verify-icon-tooltip">
                  <div className="tooltipHead">Supplier Name : </div>
                  {record?.supplier_name ? (
                    <div className="tootipText">{record?.supplier_name}</div>
                  ) : (
                    'No Supplier'
                  )}
                </div>
              }
              className="table-title"
            >
              {record?.ingredient_type?.toLowerCase() == 'ingredient' && (
                <img
                  src={Supplier}
                  width={15}
                  alt=""
                  className="subRecipeIcons IconsAlignment"
                  onClick={() => {
                    if (record?.supplier_name === null) {
                      setOpenDialogForSupplier(true);
                      setSupplierModalData(record);
                    }
                  }}
                />
              )}
            </Tooltip>
          </div>

          <div>
            <DonutChart
              popUp={popUp}
              setPopUp={setPopUp}
              index={index}
              data={record}
              component={'listView'}
            >
              <Tooltip
                title={
                  <div className="ingredient-verify-icon-tooltip">
                    <div className="tooltipHead">Calorie - Allergens</div>
                    {/* <div className="tootipText">
                      {!record?.is_verified ? 'NO' : 'YES'}
                    </div> */}
                  </div>
                }
                className="table-title"
              >
                {record?.is_verified ? (
                  <img
                    src={Fire}
                    width={20}
                    alt=""
                    className=" ingredient-verified-icon"
                  />
                ) : (
                  <img
                    src={Fire}
                    width={20}
                    alt=""
                    className="ingredient-not-verified-icon"
                  />
                )}
              </Tooltip>
            </DonutChart>
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">
          {record?.ingredient_category?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tootipText">
                    {record?.ingredient_category}
                  </div>
                </div>
              }
            >
              {record?.ingredient_category?.substring(0, 22)}
              {record?.ingredient_category?.length > 22 ? (
                <span>...</span>
              ) : null}
            </Tooltip>
          ) : (
            record?.ingredient_category
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{'UNIT COST'}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit_cost', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="tableCol col3 col4">
        <div className="subrecipecol2">
          {formatNumberFloatValue(record?.unit_cost)}
        </div>
        // </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>QTY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('storage_unit', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'storage_unit',
      key: 'storage_unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        // <div className="tableCol col3 col4">
        <div className="subrecipecol2">
          {record?.current_balance
            ? record?.current_balance.toFixed(2) + ' ' + record?.storage_unit
            : 0 + ' ' + record?.storage_unit}
        </div>
        // </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items:
              permissionStatus && permissionStatus === 2
                ? user?.roles[0]?.id === 4 || user?.roles[0]?.id === 5
                  ? []
                  : record?.ingredient_type === 'ingredient'
                  ? items
                  : items.filter((x) => x?.key !== '2')
                : [],
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const columnsForUsDb = [
    {
      title: () => (
        <div className="tableTitle">
          <span>NO</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{index + 1}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.ingredient_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_category',
      key: 'ingredient_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.ingredient_category}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="tableCol col3 col4">
            {/* <img src={Clock} alt="" className="icons" /> */}
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
  ];

  // const fetchDatabase = async () => {
  //   const data = await IngredientActions.getDatabase();
  //   // setDatabase(data?.data);
  // };

  // const handleActiveTab = (db: any) => {
  //   setSelectedDbId(db?.id);
  // };

  const handleDialog = () => {
    setOpenIngredientDialog(false);
    setUpdate(false);
    setBlocked(false);
  };

  const handleEditForDialog = () => {
    setBlocked(false);
    setUpdate(true);
    setOpenIngredientDialog(true);
  };

  const handleSuccesDialog = () => {
    setOpenIngredientDialog(false);
    setUpdate(false);
    setBlocked(false);
    pageData();
  };

  const handleDialogData = () => {
    setUpdate(true);
    setOpenIngredientDialog(true);
    setBlocked(true);
  };

  // const handleCategories = (value: any) => {
  //   setCategory(value);
  // };

  // const handleSubCategoryChange = (value: any) => {
  //   setSubCategory(value);
  // };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        handleDialogData();
        break;
      case 2:
        setUpdate(true);
        setOpenIngredientDialog(true);
        break;
      case 3:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteContinue = async () => {
    setLoader(true);
    const response = await IngredientActions.deleteInventory(
      selectedMenu?.sku_id
    );
    if (response?.success) {
      pageData();
      setOpenDeleteDialog(false);
    } else if (response?.success === false) {
      setLoader(false);
      const recipeNames = response?.error.messages
        .map((error: any) => error.recipe_name)
        .join(', ');
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          'Error!',
          `This ingredient is part of recipe name ${recipeNames}`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const handleCSV = async () => {
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const response = await Attributes.exportCSVForInventory(
      brand?.brand_id,
      shop?.shop_id ? shop?.shop_id : ''
    );
    const { data } = response;
    window.open(data?.url);
  };

  const handleCSVForStock = async () => {
    setLoader(true);
    const response = await Attributes.exportCSVForIngredientStock(brands);
    setLoader(false);
    const { data } = response;
    window.open(data?.url);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const actionButton = (
    <CSButton
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        setOpenIngredientDialog(true);
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async (
    page: number = current,
    filter: any = filters,
    stock: string | null = null,
    tag: string | any[] = brands
  ) => {
    setLoader(true);
    setSortData([]);
    const id = selectedDbId;
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    tag =
      user?.roles[0]?.id === 3
        ? brands === 'brand'
          ? 'brand'
          : brands === 'shop'
          ? 'shop'
          : brands === 'company'
          ? 'company'
          : 'brand'
        : brands;

    const variationData = await SubRecipeActions.getAllIventoriesForList(
      id,
      page,
      dataLength,
      user?.roles[0]?.id === 5
        ? brands
        : user?.roles[0]?.id === 3
        ? selectedBrands[0] === 'ALL'
          ? allBrandIDs
          : selectedBrands
        : user?.roles[0]?.id === 4 && brands?.length
        ? brand?.brand_id
        : null,
      user?.roles[0]?.id === 4
        ? brands
        : user?.roles[0]?.id === 3
        ? selectedBranches[0] === 'ALL'
          ? allBranchIDs
          : selectedBranches
        : shop?.shop_id,
      // subCategory?.toString(),
      // filter?.type?.toString(),
      filter.subCategory
        ? filter?.subCategory.map((item: number) => +item)
        : '',
      filter?.ingredientType?.toString(),
      keyword,
      filter?.sortField,
      filter?.sort ? filter?.sort : ['desc'],
      // brand?.brand_id?brand?.brand_id:""
      stock,
      tag
    );

    if (variationData === undefined || !variationData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(variationData?.data?.ingredients_count);
      setTotal(variationData?.data?.pagination?.total);
      setInventory(variationData?.data?.items);
      setSortData(variationData?.data?.items);
      setNegativeCount(variationData?.data?.negative_stock_ingredients_count);
    }
    setLoader(false);
  };

  const onSearch = async (keyword: string) => {
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const tag =
      user?.roles[0]?.id === 3
        ? brands === 'brand'
          ? 'brand'
          : 'shop'
        : brands;
    setSearch(keyword);
    setLoader(true);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.ingredient_name?.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.ingredient_category
    //         ?.toLowerCase()
    //         .includes(keyword.toLowerCase()) ||
    //       obj.unit_cost?.toString().includes(keyword.toString()) ||
    //       obj.current_balance?.toString().includes(keyword.toString()) ||
    //       obj.storage_unit?.toLowerCase().includes(keyword.toLowerCase())
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(inventory);
    // }

    const id = selectedDbId;
    const searchResultofVariations =
      await SubRecipeActions.searchAllSubRecipeInventory(
        id,
        current,
        dataLength,
        (user?.roles[0]?.id === 3 && brands === 'shop') ||
          user?.roles[0]?.id === 4
          ? brand?.brand_id
          : brand?.brand_id,
        // (user?.roles[0]?.id === 3 || user?.roles[0]?.id === 4) && brands?.length
        //   ? user?.roles[0]?.id === 3
        //     ? brands === 'shop'
        //       ? brand?.brand_id
        //       : brand?.brand_id
        //     : brand?.brand_id
        //   : brand?.brand_id,
        shop?.shop_id ? shop?.shop_id : '',
        keyword,
        filters?.type,
        filters?.sortField,
        // filters?.sort,
        tag
      );
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofVariations?.data?.ingredients_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setLoader(false);
  };

  // const handleNegativeStock = () => {
  //   setActiveNegativeBtn(!activeNegativeBtn);
  //   if (!showNegative) {
  //     pageData(1, filters, 'negative');
  //     setShowNegative(true);
  //   } else {
  //     pageData(1, filters);
  //     setShowNegative(false);
  //   }
  // };

  const handleOptionSelection: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 1:
        navigate('/app/uploadIngredients');
        break;
      case 2:
        handleCSV();
        break;
      case 3:
        handleCSVForStock();
        break;
      default:
    }
  };

  const getBrandBranch = async () => {
    try {
      const response = await UsersApi.getShopHasBrands(
        brands,
        brands === 'brand' ? selectedBrands : selectedBranches
      );
      if (response?.success) {
        const { data } = response;
        setShopBrands(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const allBrandIDs = company?.brands.map((brand: any) => brand?.brand_id);
  const allBranchIDs = company?.shops.map((shop: any) => shop?.shop_id);

  // const isAllSelected = (selectedValues: string[], allValues: string[]) => {
  //   selectedValues.length === allValues.length;
  // };

  const getBrandIdentifier = (brand: any) => {
    return brand.brand_id;
  };

  const getShopIdentifier = (shop: any) => {
    return shop.shop_id;
  };

  const handleBrandChange = (brandIDs: string[]) => {
    setSelectedBrands(brandIDs.includes('ALL') ? ['ALL'] : brandIDs);
    let filteredShops: { id: string; shop_name: string }[] = [];
    if (brandIDs.includes('ALL')) {
      const allBranches = branches.map((branch) => ({
        id: branch.id || '',
        shop_name: branch.shop_name || '',
      }));
      filteredShops = [{ id: 'ALL', shop_name: 'All Shops' }, ...allBranches];
    } else {
      const matchingBrands = allBrands.filter((brand) =>
        brandIDs.includes(getBrandIdentifier(brand))
      );
      const uniqueShopIDs = new Set<string>();
      filteredShops = shopBrands
        .map((shopBrand) => {
          const matchingBrand = matchingBrands.find(
            (brand) => brand.brand_id === shopBrand.brand_id
          );
          if (
            matchingBrand &&
            shopBrand.shop_id &&
            shopBrand.user_brand_status === true &&
            shopBrand.user_shop_status === true
          ) {
            if (!uniqueShopIDs.has(shopBrand.shop_id)) {
              uniqueShopIDs.add(shopBrand.shop_id);
              return {
                id: shopBrand.shop_id,
                shop_name: shopBrand.shop_name || '',
              };
            }
          }
          return null;
        })
        .filter(
          (shop): shop is { id: string; shop_name: string } => shop !== null
        );
    }
    setBranches(filteredShops);
  };

  const handleBranchChange = (branchIDs: string[]) => {
    setSelectedBranches(branchIDs.includes('ALL') ? ['ALL'] : branchIDs);
    let filteredBrands: Brand[] = [];
    if (branchIDs.includes('ALL')) {
      const AllBrands = allBrands.map((brand) => ({
        id: brand.id,
        brand_name: brand.brand_name,
      }));
      filteredBrands = [{ id: 'ALL', brand_name: 'All Brands' }, ...AllBrands];
    } else {
      const matchingBranches = branches.filter((shop) =>
        branchIDs.includes(getShopIdentifier(shop))
      );
      const uniqueBrandIDs = new Set<string>();
      filteredBrands = shopBrands
        .map((shopBrand) => {
          const matchingBranch = matchingBranches.find(
            (shop) => shop.shop_id === shopBrand.shop_id
          );
          if (
            matchingBranch &&
            shopBrand.shop_id &&
            shopBrand.user_brand_status === true &&
            shopBrand.user_shop_status === true
          ) {
            if (!uniqueBrandIDs.has(shopBrand.shop_id)) {
              uniqueBrandIDs.add(shopBrand.shop_id);
              return {
                id: shopBrand.brand_id,
                brand_name: shopBrand.brand_name || '',
              };
            }
          }
          return null;
        })
        .filter(
          (shop): shop is { id: string; brand_name: string } => shop !== null
        );
      setAllBrands(filteredBrands);
    }
  };

  const renderBrandSelect = () => {
    const getBrandKeyValue = (brand: any) => {
      return brand.brand_id;
    };
    return (
      <Select
        size="large"
        placeholder="Brand"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            handleBrandChange(allBrandIDs);
          } else {
            handleBrandChange(value);
          }
        }}
        value={
          selectedBrands.length === allBrandIDs.length
            ? ['ALL']
            : selectedBrands
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Brands
        </Select.Option>
        {allBrands.map((brand: any) => (
          <Select.Option
            key={getBrandKeyValue(brand)}
            value={getBrandKeyValue(brand)}
            disabled={selectedBrands.includes('ALL')}
          >
            {brand.brand_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderBranchSelect = () => {
    const getBranchKeyValue = (branch: any) => {
      return branch.shop_id;
    };
    return (
      <Select
        size="large"
        placeholder="Branch"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            handleBranchChange(allBranchIDs);
          } else {
            handleBranchChange(value);
          }
        }}
        value={
          selectedBranches.length === allBranchIDs.length
            ? ['ALL']
            : selectedBranches
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Branches
        </Select.Option>
        {branches.map((branch: any) => (
          <Select.Option
            key={getBranchKeyValue(branch)}
            value={getBranchKeyValue(branch)}
            disabled={selectedBranches.includes('ALL')}
          >
            {branch.shop_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderOptions = () => {
    return (
      <>
        {/*
        <Button
          text="Export CSV"
          className="theme-button recipeBtn uploadMarketListButton"
          handleButtonClick={handleCSV}
          shape="round"
          size="middle"
        />
        <Button
          text="Upload Ingredients"
          className="theme-button recipeBtn uploadMarketListButton"
          handleButtonClick={() => navigate('/app/uploadIngredients')}
          shape="round"
          size="middle"
    /> */}
        <Dropdown
          menu={{ items: itemsForInventory, onClick: handleOptionSelection }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
          trigger={['click']}
        >
          <Button
            className="theme-button recipeBtn actionBtn"
            // icon={<PlusOutlined />}
            icon={
              <img src={CloudActions} alt="actions" style={{ width: '30px' }} />
            }
            shape="round"
            size="middle"
          ></Button>
        </Dropdown>
        {/* <CSButton
          text="Count Stock"
          className="theme-button recipeBtn uploadMarketListButton"
          handleButtonClick={() => navigate('/app/countStock')}
          shape="round"
          size="middle"
        /> */}
      </>
    );
  };

  const onSubmit = () => {
    pageData();
    setOpenDialogForSupplier(false);
  };

  return (
    <div className="inventoryPage">
      {openIngredientDialog ? (
        <IngredientDialog
          handleSuccesDialog={handleSuccesDialog}
          handleDialog={handleDialog}
          handleEditForDialog={handleEditForDialog}
          open={openIngredientDialog}
          component={'marketlist'}
          name={''}
          blocked={blocked}
          update={update}
          id={selectedMenu?.id}
        />
      ) : null}

      <Breadcrumb
        heading="Inventory List"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={selectedDbId === 3 ? actionButton : ''}
        perm_status={
          user?.roles[0]?.id === 4 || user?.roles[0]?.id === 5
            ? 1
            : permissionStatus
        }
        options={renderOptions}
      />
      {openDialog && (
        <Dialog
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          handleSubDialog={(id: any) => {
            setOpenDialog(false);
            setOpenSubDialog(true);
            setId(id);
          }}
          data={dialogData}
          // printApi={RecipeActions}
          component={'subRecipe'}
        />
      )}

      {openDialogForSupplier ? (
        <SupplierModal
          open={openDialogForSupplier}
          handleCancel={() => setOpenDialogForSupplier(false)}
          component="Inventory"
          supplierModalData={supplierModalData}
          onSubmit={onSubmit}
        />
      ) : null}

      {openSubDialog && (
        <SubDialog
          open={openSubDialog}
          data={id}
          handleSubDialog={() => {
            setOpenSubDialog(false);
            setOpenDialog(true);
          }}
          handleCloseAll={() => {
            setOpenSubDialog(false);
            setOpenDialog(false);
          }}
        />
      )}
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete ingredient?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />
      <Spin spinning={loader} size="large">
        <Page title="Inventory List">
          <Row className="listingTopFilter">
            <Col lg={2} md={12}>
              <div style={{ display: 'flex', marginRight: '100px' }}>
                <ListCount
                  count1={recipeCount}
                  title1="Total Ingredients"
                  title3="Brand"
                  title4="Branch"
                  component={'Inventory List'}
                />
                {/* <div className="uploadImageMenuContainer">
                {database?.map((db: any, index: any) => {
                  return (
                    <>
                      <div
                        className={
                          selectedDbId === db?.id ? 'menu' : 'menuLight'
                        }
                      >
                        <div
                          onClick={() => handleActiveTab(db)}
                          className="text menustyle"
                        >
                          {db?.db_name}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div> */}
              </div>
            </Col>
            <Col lg={16} md={24}>
              {/* <div className="dropdown-filter-label-container">
                <div className="type">Type</div>
                <div className="category">Category</div>
                <div>Sub Category</div>
              </div> */}
              <div className="dropdown-filter-container">
                {user?.roles[0]?.id === 3 ? (
                  <>
                    <Select
                      style={{ width: '30%' }}
                      placeholder="Select option"
                      maxTagCount="responsive"
                      value={brands}
                      onChange={(value: any) => {
                        setSelectedBrands(['ALL']);
                        setSelectedBranches(['ALL']);
                        handleBrandBranch(value);
                      }}
                      optionFilterProp="children"
                    >
                      <Select.Option value="brand">Brand</Select.Option>
                      <Select.Option value="shop">Branch</Select.Option>
                      <Select.Option value="company">Company</Select.Option>
                    </Select>
                    {brands === 'company' ? null : (
                      <>
                        {brands === 'shop'
                          ? renderBranchSelect()
                          : renderBrandSelect()}
                        {brands === 'shop'
                          ? renderBrandSelect()
                          : renderBranchSelect()}
                      </>
                    )}
                  </>
                ) : null}
                {user?.roles[0]?.id === 4 ? (
                  <Select
                    mode="multiple"
                    style={{ width: '20%' }}
                    placeholder="Select Branch"
                    // maxTagCount="responsive"
                    value={brands}
                    onChange={(value: any) => {
                      handleBranch(value);
                    }}
                    optionFilterProp="children"
                  >
                    {branchList?.map((branch: any, index: number) => (
                      <Select.Option key={index} value={branch?.shop_id}>
                        {branch?.shop_name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}

                {user?.roles[0]?.id === 5 ? (
                  <Select
                    mode="multiple"
                    style={{ width: '20%' }}
                    placeholder="Select Brand"
                    // maxTagCount="responsive"
                    value={brands}
                    onChange={(value: any) => {
                      handleBrand(value);
                    }}
                    optionFilterProp="children"
                  >
                    {brandList?.map((brand: any, index: number) => (
                      <Select.Option key={index} value={brand?.brand_id}>
                        {brand?.brand_name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}

                {/* <Select
                  mode="multiple"
                  // allowClear
                  style={{ width: '20%' }}
                  placeholder="Ingredient Type"
                  // defaultValue={['a10', 'c12']}
                  onChange={(value: any) => {
                    setSelectedIngredientType(value);
                    // pageData();
                    setFilters({
                      ...filters,
                      type: value,
                      sort: filters?.sort,
                    });
                  }}
                  options={types}
                /> */}
                {/* <Select
                  mode="multiple"
                  // allowClear
                  style={{ width: '30%' }}
                  placeholder="Select Category"
                  // defaultValue={['a10', 'c12']}
                  onChange={handleCategories}
                  value={category}
                >
                  {categories?.map((data: any, categoryIndex: number) => (
                    <Select.Option value={data?.id} key={categoryIndex}>
                      {data.parent_category}
                    </Select.Option>
                  ))}
                </Select> */}
                {/* <Select
                  mode="multiple"
                  // allowClear
                  onSearch={onSearchSubCategory}
                  style={{ width: '20%' }}
                  placeholder="Select Sub Category"
                  // defaultValue={['a10', 'c12']}
                  onChange={handleSubCategoryChange}
                  // options={options}
                  optionLabelProp="label"
                  value={subCategory}
                >
                  {sortedSubCategory?.map(
                    (data: any, subCategoryIndex: number) => (
                      <Select.Option
                        value={data?.id}
                        key={subCategoryIndex}
                        label={data.sub_category}
                      >
                        {data.sub_category}
                      </Select.Option>
                    )
                  )}
                </Select> */}
                {/* <div
                  className={`negative-stock-button ${
                    activeNegativeBtn && 'active-negative'
                  } `}
                >
                   <div className="negative-stock-label"> </div> 
                  <div
                    className="negative-stock-label"
                    onClick={handleNegativeStock}
                  >
                    Negative Items: {negativeCount}
                  </div>
                </div> */}
              </div>
            </Col>
            <Col lg={1}>
              <InventoryFilter
                onApply={(
                  sortField: string,
                  sort: string[],
                  ingredientType: any,
                  subCategory: any
                ) => {
                  setFilters({
                    type: '',
                    sortField,
                    sort,
                    ingredientType,
                    subCategory,
                  });
                  // pageData('', sortField, sort);
                  setPopOver(!popOver);
                }}
                open={popOver}
                onClose={setPopOver}
                onReset={() => {
                  setFilters({
                    type: '',
                    sortField: 'ingredient_name',
                    sort: ['asc'],
                    ingredientType: '',
                    subCategory: '',
                  });
                  setPopOver(!popOver);
                  pageData(1, {
                    type: '',
                    sortField: 'ingredient_name',
                    sort: ['asc'],
                  });
                }}
              >
                <FilterOutlined
                  size={100}
                  className={`inventory-filter-icon ${
                    filters.sortField !== 'ingredient_name' &&
                    filters.type === ''
                      ? 'inventory-filter-selected'
                      : ''
                  }`}
                  onClick={() => setPopOver(!popOver)}
                />
              </InventoryFilter>
            </Col>
            <Col lg={5} md={12}>
              <div className="search-wrapper inventory-search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>
                {/* <div className="exportButton" onClick={handleCSV}>
                  <img src={Export} alt="" />
                  <div className="exportText"> Export CSV</div>
                </div> */}
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={24} md={24}>
              {!loader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={items}
                  dataSource={sortData ? [...sortData] : []}
                  columns={selectedDbId === 2 ? columnsForUsDb : columns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin spinning={loader} className="recipeLoader" />
                </div>
              )}

              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};

export default InventoryListPage;
