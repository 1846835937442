/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Spin } from 'antd';
import { Page } from '../../components';

const SubscriptionCheck = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
      navigate('/app/brands', { replace: true });
    }, 500);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="loader-wrapper">
      <Spin spinning={loader} className="recipeLoader">
        <Page title="Subcription Check">
          <></>
        </Page>
      </Spin>
    </div>
  );
};

export default SubscriptionCheck;
