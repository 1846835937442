import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, Input, Checkbox, Spin, Tooltip } from 'antd';
import {
  EditOutlined,
  CheckCircleOutlined,
  // CheckCircleOutlined,
  CloseCircleOutlined,
  // QuestionCircleOutlined,
} from '@ant-design/icons';
// import CompanyLight from '../../assets/images/CompanyLight.png';
// import Brand1 from '../../assets/images/Brand1.png';
// import tickIcon from '../../assets/images/tickIcon.png';
// import Subtract from '../../assets/images/Subtract.png';
import './ingredientListDialog.scss';
import Attributes from '../../redux/middleware/attributes';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import RecipeApiActions from '../../redux/middleware/recipe-api';
import SubMenu from '../../assets/images/SubMenu.svg';

type Props = {
  open: boolean;
  handleClose: any;
  data: any;
  component?: any;
  handleMarkRecipe?: any;
  loader?: boolean;
  errorList?: any;
  errorStatus?: any;
};
const IngendientListDialog = (props: Props) => {
  let {
    open,
    handleClose,
    data,
    handleMarkRecipe,
    loader,
    component,
    errorList,
    errorStatus,
  } = props;

  // const [update, setUpdate] = useState(false);
  const [defaultAllergens, setDefaultAllergens] = useState<any[]>();
  const [allergens, setAllergens] = useState<any[]>(
    data?.allergens?.filter((allergen: any) => allergen?.is_exist == true)
  );
  const [unit, setUnit] = useState<any>([]);
  const [updateAllegens, setUpdateAllergens] = useState(false);
  // const [updateIngredients, setUpdateIngredients] = useState(false);
  const [updateNutritions, setUpdateNutritions] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [nutritions, setNutritions] = useState<any[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [allergensData, setAllergensData] = useState(data?.allergens);
  const [variationId, setVariationId] = useState('');
  const [prevNutritions, setPrevNutritions] = useState<any[]>(
    data?.nutritions?.map((nutrition: any) => ({
      name: nutrition?.nutrition,
      quantity: nutrition?.value,
      status: nutrition?.is_verified,
    }))
  );
  const [unMatched, setUnMatched] = useState(false);
  const [statuses, setStatuses] = useState<any[]>();

  const fetchStatus = async () => {
    const { data, success } = await Attributes.getStatus();
    if (success) setStatuses(data);
  };

  const fetchUnits = async () => {
    const response = await SubRecipeActions.getSubRecipeUnits();

    if (response?.success)
      setUnit(
        response?.data?.find(
          (unitObj: any) =>
            unitObj?.unit == data?.rb_unit ||
            unitObj?.storage_unit == data?.rb_unit
        )
      );
  };

  const fetchVariation = async () => {
    const variationData = await RecipeApiActions.getVariations(3);
    setVariationId(
      variationData?.data?.items
        ?.map((variation: any) => variation?.id)
        ?.toString()
    );
  };

  useEffect(() => {
    fetchStatus();
    fetchUnits();
    fetchVariation();
  }, []);

  useEffect(() => {
    setAllergens(
      data?.allergens?.filter((allergen: any) => allergen?.is_exist == true)
    );
    setDefaultAllergens(
      data?.allergens?.filter((allergen: any) => allergen?.is_exist == true)
    );
    setAllergensData(data?.allergens);
    setNutritions(data?.nutritions);
    setUnMatched(
      data?.ingredients?.some(
        (ingredient: any) => ingredient?.ingredient_name == null
      )
    );
  }, [data]);

  const handleCloseNutritions = (type: string) => {
    if (type == 'cancel')
      setNutritions(
        prevNutritions?.map((prevData: any) => ({
          nutrition: prevData?.name,
          value: prevData?.quantity,
          is_verified: prevData?.status,
        }))
      );
    else
      setPrevNutritions(
        nutritions?.map((nutrition: any) => ({
          name: nutrition?.nutrition,
          quantity: nutrition?.value,
          status: nutrition?.is_verified,
        }))
      );
    setUpdateNutritions(!updateNutritions);
  };

  return (
    <div className="IngendientListDialog">
      <Modal
        title={
          <div className="title font">
            {component == 'r&d_recipe'
              ? 'R&D Recipe Approval'
              : 'R&D Sub Recipe Approval'}
          </div>
        }
        centered
        open={open}
        onCancel={handleClose}
        width={608}
        footer={null}
        className="approvalRecipeDialogContainer"
      >
        <Spin spinning={loader} size="large">
          <div className="approvalModalHeaderAlign">
            <div className="col1 font">
              <img src={data?.thumbnail} width="52px" height="52px" />
              <div className="subCol1 font recipe-name-container-width">
                <span className="col1Text font">
                  {data?.recipe_name?.length > 22 ? (
                    <Tooltip
                      title={
                        <div>
                          <div className="tootipText">{data?.recipe_name}</div>
                        </div>
                      }
                    >
                      {data?.recipe_name?.substring(0, 22)}
                      {data?.recipe_name?.length > 22 ? <span>...</span> : null}
                    </Tooltip>
                  ) : (
                    data?.recipe_name
                  )}{' '}
                </span>
                <div className="subTextCol1 font">
                  {/* <img src={CompanyLight} alt="" className="companyLight" /> */}
                  <div className="email categoryNameApprovalModal">
                    {data?.category_name}
                  </div>
                </div>
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    Error: {errorList['recipe_name']}
                  </div>
                )}
              </div>
            </div>
            {unMatched ? (
              <div className="font unmatched-ingredients-danger-message">
                You cannot approve this recipe. All ingredients are not matched.
              </div>
            ) : (
              <div className="approvalUpdateButtonContainer">
                <Button
                  className="updateButtonApproval borderRejectRecipeColor"
                  onClick={() => {
                    handleMarkRecipe(
                      {
                        status:
                          statuses?.find(
                            (filterStatus: any) =>
                              filterStatus?.status_slug?.toLowerCase() ==
                              'rejected'
                          )?.id || 0,
                        recipe_type_id: data?.recipe_type_id,
                        calorie: nutritions[0]?.value,
                        carb: nutritions[1]?.value,
                        protein: nutritions[2]?.value,
                        fat: nutritions[3]?.value,
                        allergens_id: defaultAllergens
                          ?.map((allergen: any) => allergen?.id)
                          ?.toString(),
                        unit_id: 1,
                      },
                      data?.rb_id
                    );
                  }}
                >
                  Reject
                </Button>
                <Button
                  className="updateButtonApproval borderApproveRecipeColor"
                  onClick={() => {
                    handleMarkRecipe(
                      {
                        status:
                          statuses?.find(
                            (filterStatus: any) =>
                              filterStatus?.status_slug?.toLowerCase() ==
                              'success'
                          )?.id || 0,
                        recipe_type_id: data?.recipe_type_id,
                        calorie: nutritions[0]?.value,
                        carb: nutritions[1]?.value,
                        protein: nutritions[2]?.value,
                        fat: nutritions[3]?.value,
                        order_type_id: variationId,
                        // remove duplicate values from allergens array
                        allergens_id: [
                          ...new Set(
                            allergens?.map((allergen: any) => allergen?.id)
                          ),
                        ]?.toString(),
                        unit_id: component == 'r&d_recipe' ? 1 : unit?.id,
                      },
                      data?.rb_id
                    );
                  }}
                >
                  Accept
                </Button>
              </div>
            )}
          </div>

          <ul className="ingredientListMenu">
            <li>
              <div className="title titleWithIconAlign">
                <div>Ingredients List</div>{' '}
                {/* <EditOutlined
                className="ApprovalFieldsEditIcon"
                onClick={() => setUpdateIngredients(!updateIngredients)}
              /> */}
              </div>
              <ul className="content">
                <li className="content-list">
                  <Row>
                    <Col md={12} className="LimitedAccessCol ">
                      <p>R&D Recipe Ingredients</p>
                    </Col>
                    {/* <Col md={8} className="center-text-align-approval">
                    <p>Quantity</p>
                  </Col> */}

                    <Col
                      md={12}
                      className="LimitedAccessCol tableTickCrossIcons"
                    >
                      <p>Matched Ingredients</p>
                    </Col>
                  </Row>
                </li>
                {data.ingredients?.map((ingredient: any, index: number) => (
                  <li className="content-list" key={index}>
                    <Row>
                      <Col md={12}>
                        <p>
                          {ingredient?.rb_ingredient_name}{' '}
                          {ingredient?.ingredient_type?.toLowerCase() ==
                            'sub_recipe' && (
                            <img
                              src={SubMenu}
                              width={15}
                              alt=""
                              className="subRecipeIcons spacingForImg"
                              // onClick={() => {
                              //   handleingredient(record?.sku);
                              // }}
                            />
                          )}
                        </p>
                      </Col>
                      <Col
                        md={12}
                        className={`tableTickCrossIcons ${'center-text-align-approval'}`}
                      >
                        <p>{ingredient?.ingredient_name}</p>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="title ingredientListMenu ingredientListDialogAllergenTitle titleWithIconAlign">
                <div>Nutritions List</div>
                {updateNutritions ? (
                  <div className="cancel-editted-icon-container">
                    <CloseCircleOutlined
                      className="ingredient-unmatach-select-icons ingredient-selection-cancel-icon-color"
                      onClick={() => {
                        handleCloseNutritions('cancel');
                      }}
                    />

                    <CheckCircleOutlined
                      // style={{ color: '#53fa23', cursor: 'pointer' }}
                      className="ingredient-unmatach-select-icons ingredient-selection-success-icon-color"
                      onClick={() => {
                        handleCloseNutritions('continue');
                      }}
                    />
                  </div>
                ) : (
                  <EditOutlined
                    className="ApprovalFieldsEditIcon"
                    onClick={() => {
                      // console.log({ ...defaultData, nutritions: nutritions });
                      setUpdateNutritions(!updateNutritions);
                    }}
                  />
                )}
              </div>
              <ul className="content">
                <li className="content-list">
                  <Row>
                    <Col md={12}>
                      <p>Nutritions</p>
                    </Col>

                    {/* <Col md={8} className="LimitedAccessCol tableTickCrossIcons">
                    <p>R&D Inventory</p>
                  </Col> */}
                    <Col
                      md={12}
                      className="LimitedAccessCol tableTickCrossIcons"
                    >
                      <p>Values</p>
                    </Col>
                  </Row>
                </li>
                {nutritions?.map((nutritionObj: any, index: number) => (
                  <li className="content-list" key={index}>
                    <Row>
                      <Col md={12}>
                        <p>{nutritionObj?.nutrition}</p>
                      </Col>

                      {/* <Col md={8} className="tableTickCrossIcons">
                      <p>
                        {nutritionObj?.perm_status_id == 1 ? (
                          <img src={tickIcon} alt="" />
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p>
                      <p>{nutritionObj?.value_tbh}</p>
                    </Col> */}

                      <Col md={12} className="tableTickCrossIcons">
                        {/* <p>
                        {nutritionObj?.perm_status_id == 2 ? (
                          <img src={tickIcon} alt="" />
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p> */}
                        {updateNutritions ? (
                          <Input
                            placeholder={`Enter ${nutritionObj?.nutrition}`}
                            onChange={(e: any) => {
                              const dataArray = nutritions;
                              dataArray[index].value = +e.target.value;
                              // console.log(dataArray);
                              setNutritions([...dataArray]);
                            }}
                            type="number"
                            value={nutritionObj?.value}
                            required
                          />
                        ) : (
                          <p>{nutritionObj?.value.toFixed(2)}</p>
                        )}
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="title ingredientListDialogAllergenTitle titleWithIconAlign">
                <div>Allergens</div>
                {updateAllegens ? (
                  <div className="cancel-editted-icon-container">
                    <CloseCircleOutlined
                      className="ingredient-unmatach-select-icons ingredient-selection-cancel-icon-color"
                      onClick={() => {
                        setAllergens(
                          allergens?.map((filteredAllergen: any) =>
                            allergensData?.find(
                              (innerData: any) =>
                                innerData?.name == filteredAllergen?.name
                            )
                          )
                        );
                        setUpdateAllergens(!updateAllegens);
                      }}
                    />

                    <CheckCircleOutlined
                      // style={{ color: '#53fa23', cursor: 'pointer' }}
                      className="ingredient-unmatach-select-icons ingredient-selection-success-icon-color"
                      onClick={() => {
                        setAllergensData(
                          allergensData?.map((allergen: any) => {
                            const checkAllergen = allergens?.find(
                              (innerData: any) =>
                                innerData?.name == allergen?.name
                            );
                            if (checkAllergen == undefined) {
                              return allergen;
                            } else {
                              return checkAllergen;
                            }
                          })
                        );
                        // setAllergensData(
                        //   [...allergens, ...allergensData].filter(
                        //     (value, index, self) =>
                        //       index ===
                        //       self.findIndex(
                        //         (t) =>
                        //           t.place === value.place && t.name === value.name
                        //       )
                        //   )
                        // );
                        setUpdateAllergens(!updateAllegens);
                      }}
                    />
                  </div>
                ) : (
                  <EditOutlined
                    className="ApprovalFieldsEditIcon"
                    onClick={() => {
                      // [...allergens, ...allergensData]     set updatedData when click on Tick Icon
                      // [...allergensData, ...allergens]     set previousData when click on Close Icon
                      // console.log(
                      //   [...allergens, ...allergensData].filter(
                      //     (value, index, self) =>
                      //       index ===
                      //       self.findIndex(
                      //         (t) =>
                      //           t.place === value.place && t.name === value.name
                      //       )
                      //   )
                      // );

                      // console.log([...allergensData, ...allergens]);
                      setUpdateAllergens(!updateAllegens);
                    }}
                  />
                )}
              </div>
              <ul className="content">
                <li className="content-list">
                  <Row>
                    <Col md={12}>
                      <p>Allergen</p>
                    </Col>

                    {/* <Col md={8} className="LimitedAccessCol tableTickCrossIcons">
                    <p>R&D Inventory</p>
                  </Col> */}
                    <Col
                      md={12}
                      className="LimitedAccessCol tableTickCrossIcons"
                    >
                      <p>Mark / UnMark</p>
                    </Col>
                  </Row>
                </li>
                {allergensData?.map((data: any, index: number) => (
                  <li className="content-list" key={index}>
                    <Row>
                      <Col md={12}>
                        <p>{data?.name}</p>
                      </Col>

                      {/* <Col md={8} className="tableTickCrossIcons">
                      <p>
                        {data?.perm_status_id == 1 ? (
                          <img src={tickIcon} alt="" />
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p>
                      <p>{data?.verified == 1 ? 'Yes' : 'No'}</p>
                    </Col> */}

                      <Col md={12} className="tableTickCrossIcons">
                        {/* <p>
                        {data?.perm_status_id == 2 ? (
                          <img src={tickIcon} alt="" />
                        ) : (
                          <img src={Subtract} alt="" />
                        )}
                      </p> */}
                        {updateAllegens ? (
                          <Checkbox
                            // disabled={recordIndex == index ? false : true}
                            onChange={(e: any) => {
                              if (
                                defaultAllergens?.find(
                                  (obj: any) => obj?.name == data?.name
                                ) == undefined
                              ) {
                                if (e.target.checked) {
                                  const extractedData = allergensData?.find(
                                    (extractData: any) =>
                                      extractData?.name == data?.name
                                  );
                                  setAllergens([
                                    ...allergens,
                                    { ...extractedData, is_exist: 1 },
                                  ]);
                                } else {
                                  setAllergens(
                                    allergens?.filter(
                                      (removeObj: any) =>
                                        removeObj?.name != data?.name
                                    )
                                  );
                                }
                              }
                            }}
                            checked={
                              allergens?.find(
                                (checkData: any) =>
                                  checkData?.name == data?.name
                              )?.is_exist == 1
                            }
                          />
                        ) : (
                          <p>{data?.is_exist == 1 ? 'Yes' : 'No'}</p>
                        )}
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </Spin>
      </Modal>
    </div>
  );
};

export default IngendientListDialog;
