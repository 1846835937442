import React, { useEffect } from 'react';
import Delete from '../../assets/images/Delete.svg';
import Success from '../../assets/images/succesResponse.png';
import './style.scss';
import { Select } from 'antd';
type Props = {
  image: any;
  imageObject: any;
  handleDelete: any;
  handleChange: any;
  untaggedRecipeData: any;
};
const UploadImageStepTwo = (props: Props) => {
  let { image, imageObject, handleChange, handleDelete, untaggedRecipeData } =
    props;

  const handleSize = (data: any) => {
    const size = data / 1024 / 1024;
    return size;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <div className="Label">Image uploaded </div>
      <div className="Container">
        <div className="Subcontainer">
          {image.length !== 0 &&
            image.map((data: any, index: number) => {
              return (
                <div className="uploadedimage" key={index}>
                  <div className="uploadRecipeImgWrapperStepTwo">
                    <img src={data} width="52px" height="52px" />
                    <div className="text">
                      <span className="textSizeGap">
                        {data.name}
                        {imageObject[index]?.data?.name}
                      </span>
                      <span className="uploadImageSize">
                        {Number(
                          handleSize(imageObject[index]?.data?.size).toFixed(2)
                        )}
                        mb
                      </span>
                    </div>
                  </div>
                  <div className="tagDropdown">
                    <Select
                      placeholder="Select Tag Recipe"
                      onChange={(e) => handleChange(e, index, 'tag_id')}
                      value={imageObject[index]?.data?.tagname}
                      options={untaggedRecipeData}
                    />
                  </div>
                  {imageObject[index]?.is_upload ? (
                    <div className="deletebutton">
                      <img src={Success} height="20" width="20" />
                    </div>
                  ) : (
                    <div className="deletebutton">
                      <img
                        src={Delete}
                        width={15}
                        onClick={() => {
                          handleDelete(index);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UploadImageStepTwo;
