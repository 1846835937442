import React from 'react';
import './styles.scss';

export default function Policy() {
  return (
    <div className="staticContainer">
      <header>
        <h1>Privacy Policy For ChefAdmin</h1>
      </header>
      <p>
        At ChefAdmin, we take your privacy seriously. This Privacy Policy
        outlines the information we collect, how we use it, and how we protect
        your information. By using ChefAdmin, you agree to this Privacy Policy.
      </p>
      <br />
      <h3>Information We Collect</h3>
      <p>
        We collect personal information that you voluntarily provide to us when
        you create an account or use our services, such as your name, email
        address, and phone number. We also collect information about your usage
        of our services, including log data, IP address, device type, and
        browser type.
      </p>
      <br />
      <h3>How We Use Your Information</h3>
      <p>
        We use your information to provide you with our services, improve our
        services, and communicate with you about updates or changes to our
        services. We may also use your information for analytics purposes, to
        better understand how our services are used, and to improve our
        business.
      </p>
      <br />
      <h3>How We Protect Your Information</h3>
      <p>
        We take appropriate measures to protect your information from
        unauthorized access, disclosure, alteration, or destruction. We have
        implemented appropriate physical, electronic, and managerial procedures
        to safeguard and secure the information we collect.
      </p>
      <br />
      <h3>Sharing Your Information</h3>
      <p>
        We do not share your personal information with third parties, except as
        required by law or as necessary to provide our services to you. We may
        share your information with our service providers or business partners
        who assist us in providing our services to you.
      </p>
      <br />
      <h3>Cookies</h3>
      <p>
        We use cookies to collect information about your usage of our services.
        Cookies are small data files stored on your device that allow us to
        recognize your device when you use our services. You can manage or
        delete cookies through your browser settings.
      </p>
      <br />
      <h3>Your Rights</h3>
      <p>
        You have the right to access, correct, update, or delete your personal
        information. You can also object to our processing of your personal
        information or request that we restrict the processing of your personal
        information.
      </p>
      <br />
      <h3>Changes to this Privacy Policy</h3>
      <p>
        We reserve the right to update or modify this Privacy Policy at any
        time. We will notify you of any changes to this Privacy Policy by
        posting the updated Privacy Policy on our website. Your continued use of
        our services after any changes to this Privacy Policy will constitute
        your acceptance of the changes. If you have any questions or concerns
        about this Privacy Policy or our privacy practices, please contact us at{' '}
        <a href="mailto:privacy@chefadmin.com">privacy@chefadmin.com</a>
      </p>
    </div>
  );
}
