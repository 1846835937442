import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import ArrowDown from '../../../assets/svg/arrowDown.svg';
import { useSelector } from 'react-redux';
import { DatePicker, Space } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';

import './Filter.scss';
import moment from 'moment';

type Props = {
  action: Function;
  apiCall: Function;
};

const Filter = (props: Props) => {
  const { Panel } = Collapse;

  const { RangePicker } = DatePicker;

  /* Waste Methods States */
  const [checkedListWasteMethod, setCheckedListWasteMethod] = useState<
    CheckboxValueType[]
  >([]);

  const [WasteMethod, setWasteMethod] = useState([{ label: '', value: '' }]);

  const [WasteMethodOptions, setWasteMethodOptions] = useState([
    { label: '', value: '' },
  ]);

  const [WasteMethodFilters, setWasteMethodFilters] = useState([
    { label: '', value: '' },
  ]);

  /* Waste Type States */
  const [checkedListWasteType, setCheckedListWasteType] = useState<
    CheckboxValueType[]
  >([]);

  const [WasteType, setWasteType] = useState([{ label: '', value: '' }]);

  const [WasteTypeOptions, setWasteTypeOptions] = useState([
    { label: '', value: '' },
  ]);

  const [WasteTypeFilters, setWasteTypeFilters] = useState([
    { label: '', value: '' },
  ]);

  /* Waste Reason States */
  const [checkedListWasteReason, setCheckedListWasteReason] = useState<
    CheckboxValueType[]
  >([]);

  const [WasteReason, setWasteReason] = useState([{ label: '', value: '' }]);

  const [WasteReasonOptions, setWasteReasonOptions] = useState([
    { label: '', value: '' },
  ]);

  const [WasteReasonFilters, setWasteReasonFilters] = useState([
    { label: '', value: '' },
  ]);

  const [showButton, setShowButton] = useState(false);
  const [pillVlues, setPillVlues] = useState([{ label: '', value: '' }]);
  const [allowFilterRequest, setAllowFilterRequest] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [startDate, setStartDate] = useState('' as any);
  // eslint-disable-next-line no-unused-vars
  const [endDate, setEndDate] = useState('' as any);

  const { currentBrand } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    getFilters();
  }, [currentBrand]);

  useEffect(() => {
    if (WasteMethodFilters?.length > 3) {
      setWasteMethodOptions(WasteMethodFilters?.slice(0, 3));
    } else {
      setWasteMethodOptions(WasteMethodFilters);
    }

    if (WasteTypeFilters?.length > 3) {
      setWasteTypeOptions(WasteTypeFilters?.slice(0, 3));
    } else {
      setWasteTypeOptions(WasteTypeFilters);
    }

    if (WasteReasonFilters?.length > 3) {
      setWasteReasonOptions(WasteReasonFilters?.slice(0, 3));
    } else {
      setWasteReasonOptions(WasteReasonFilters);
    }
  }, [WasteMethodFilters, WasteTypeFilters, WasteReasonFilters]);

  const getFilters = async () => {
    const response = await props.apiCall();
    const { success, data } = response;
    if (success) {
      const { waste_method, waste_reason, waste_type } = data;
      setWasteMethodFilters(waste_method);
      setWasteTypeFilters(waste_type);
      setWasteReasonFilters(waste_reason);
    }
  };

  const sendAppliedFilterBack = (start?: any, end?: any) => {
    const filter = {
      waste_type_id: checkedListWasteType?.join(','),
      reason_id: checkedListWasteReason?.join(','),
      waste_method_id: checkedListWasteMethod?.join(','),
      startDate: start ? moment(start).format('YYYY-MM-DD') : '',
      endDate: end ? moment(end).format('YYYY-MM-DD') : '',
    };
    props.action(filter);
  };

  useEffect(() => {
    if (pillVlues.length > 2) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    if (allowFilterRequest) {
      sendAppliedFilterBack();
    }
  }, [pillVlues]);

  function onChangeWasteMethodValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListWasteMethod(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = WasteMethodOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setWasteMethod([...arr, { label: '', vale: '' }]);
    setPillVlues([...arr, ...WasteType, ...WasteReason]);
  }

  function onChangeWasteTypeValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListWasteType(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = WasteTypeOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setWasteType([...arr, { label: '', vale: '' }]);
    setPillVlues([...arr, ...WasteMethod, ...WasteReason]);
  }

  function onChangeWasteReasonValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListWasteReason(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = WasteReasonOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setWasteReason([...arr, { label: '', vale: '' }]);
    setPillVlues([...arr, ...WasteMethod, ...WasteType]);
  }

  const handleClear = () => {
    setAllowFilterRequest(true);
    setPillVlues([
      {
        label: '',
        value: '',
      },
    ]);
    setCheckedListWasteMethod([]);
    setCheckedListWasteType([]);
    setCheckedListWasteReason([]);
    setWasteMethod([
      {
        label: '',
        value: '',
      },
    ]);
    setWasteType([
      {
        label: '',
        value: '',
      },
    ]);
    setWasteReason([
      {
        label: '',
        value: '',
      },
    ]);
    setStartDate('');
    setEndDate('');
  };

  const handleFilter = (pill: any) => {
    setAllowFilterRequest(true);
    setPillVlues((current) =>
      current.filter((item) => {
        return item?.label !== pill.label;
      })
    );

    let WasteMethodFound = WasteMethod.some(
      (element) => element.label === pill.label
    );

    if (WasteMethodFound) {
      let WasteMethodArr = [...WasteMethod];
      WasteMethodArr = WasteMethodArr.filter((item) => {
        return item.label !== pill.label;
      });
      setWasteMethod(WasteMethodArr);
      setCheckedListWasteMethod(WasteMethodArr.map((c) => c.value));
    }

    let WasteTypeFound = WasteType.some(
      (element) => element.label === pill.label
    );

    if (WasteTypeFound) {
      let WasteTypeArr = [...WasteType];
      WasteTypeArr = WasteTypeArr.filter((item) => {
        return item.label !== pill.label;
      });
      setWasteType(WasteTypeArr);
      setCheckedListWasteType(WasteTypeArr.map((c) => c.value));
    }

    let WasteReasonFound = WasteReason.some(
      (element) => element.label === pill.label
    );

    if (WasteReasonFound) {
      let WasteReasonArr = [...WasteReason];
      WasteReasonArr = WasteReasonArr.filter((item) => {
        return item.label !== pill.label;
      });
      setWasteReason(WasteReasonArr);
      setCheckedListWasteReason(WasteReasonArr.map((c) => c.value));
    }
  };

  const handleMore = (option: string) => {
    switch (option) {
      case 'WasteMethod':
        setWasteMethodOptions(WasteMethodFilters);
        break;
      case 'WasteReason':
        setWasteReasonOptions(WasteReasonFilters);
        break;
      case 'WasteType':
        setWasteTypeOptions(WasteTypeFilters);
        break;
      default:
    }
  };

  const handleLess = (option: string) => {
    switch (option) {
      case 'WasteMethod':
        setWasteMethodOptions(WasteMethodFilters.slice(0, 3));
        break;
      case 'WasteReason':
        setWasteReasonOptions(WasteReasonFilters.slice(0, 3));
        break;
      case 'WasteType':
        setWasteTypeOptions(WasteTypeFilters.slice(0, 3));
        break;
      default:
    }
  };

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      setStartDate(dates[0]);
      setEndDate(dates[1]);

      sendAppliedFilterBack(dates[0], dates[1]);
    } else {
      console.log('Clear');
    }
  };

  return (
    <div className="container filterContainer" style={{ minHeight: '765px' }}>
      <div className="filterScroll">
        <div className="topHeading">
          {pillVlues?.length > 3 ? 'Selected Filter' : 'Filter'}
        </div>
        {pillVlues?.map((pill, index) =>
          pill.label !== '' ? (
            <div className="pill" key={index}>
              <div className="filterTitle">{pill?.label}</div>
              <div className="tagStyling" onClick={() => handleFilter(pill)}>
                <CloseOutlined twoToneColor={'white'} className="iconStyling" />
              </div>
            </div>
          ) : null
        )}

        {showButton ? (
          <div className="linkText" onClick={handleClear}>
            Clear all
          </div>
        ) : null}
        <Collapse
          bordered={false}
          defaultActiveKey={['1', '2', '3', '4']}
          ghost
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? <img src={ArrowDown} /> : <img src={ArrowDown} />
          }
        >
          <Panel className="panelStyling" key="1" header="Date">
            <Space direction="vertical" size={12}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
                onChange={onChange}
              />
            </Space>
          </Panel>

          {WasteMethodOptions && (
            <Panel className="panelStyling" key="2" header="Waste Method">
              <Checkbox.Group
                options={WasteMethodFilters}
                defaultValue={[]}
                value={checkedListWasteMethod}
                onChange={onChangeWasteMethodValue}
              />
              {WasteMethodOptions.length > 3 ? (
                <div
                  className="btnShow"
                  onClick={() => handleLess('WasteMethod')}
                >
                  ShowLess
                </div>
              ) : (
                <div
                  className={
                    WasteMethodFilters.length > 3 ? 'btnShow' : 'btnHide'
                  }
                  onClick={() => handleMore('WasteMethod')}
                >
                  ShowMore
                </div>
              )}{' '}
            </Panel>
          )}
          {WasteTypeOptions && (
            <Panel className="panelStyling" key="3" header="Waste Type">
              <Checkbox.Group
                options={WasteTypeOptions}
                defaultValue={[]}
                value={checkedListWasteType}
                onChange={onChangeWasteTypeValue}
              />
              {WasteTypeOptions.length > 3 ? (
                <div
                  className="btnShow"
                  onClick={() => handleLess('WasteType')}
                >
                  ShowLess
                </div>
              ) : (
                <div
                  className={
                    WasteTypeFilters.length > 3 ? 'btnShow' : 'btnHide'
                  }
                  onClick={() => handleMore('WasteType')}
                >
                  ShowMore
                </div>
              )}{' '}
            </Panel>
          )}
          {WasteReasonOptions && (
            <Panel className="panelStyling" key="4" header="Waste Reason">
              <Checkbox.Group
                options={WasteReasonOptions}
                defaultValue={[]}
                value={checkedListWasteReason}
                onChange={onChangeWasteReasonValue}
              />
              {WasteReasonOptions.length > 3 ? (
                <div
                  className="btnShow"
                  onClick={() => handleLess('WasteReason')}
                >
                  ShowLess
                </div>
              ) : (
                <div
                  className={
                    WasteReasonFilters.length > 3 ? 'btnShow' : 'btnHide'
                  }
                  onClick={() => handleMore('WasteReason')}
                >
                  ShowMore
                </div>
              )}{' '}
            </Panel>
          )}
        </Collapse>
      </div>
      <div className="buttonContainer">
        <button className="resetBtn" onClick={handleClear}>
          Reset Filter
        </button>
      </div>
    </div>
  );
};

export default Filter;
