import React from 'react';
import { Modal } from 'antd';
import './deleteConfirmation.scss';

type Props = {
  handleDeleteCancel: any;
  handleDeleteContinue: any;
  open: boolean;
  message: any;
};

const DeleteConfirmation = (props: Props) => {
  let { handleDeleteCancel, handleDeleteContinue, open, message } = props;
  return (
    <Modal
      title={<div className="title font">Confirmation</div>}
      centered
      open={open}
      onCancel={handleDeleteCancel}
      width={480}
      footer={null}
      className="deleteConfirmationContainer"
    >
      <div className="deleteDialogText">{message}</div>
      <div className="butonGroupDialog">
        <div className="wizard-button" onClick={() => handleDeleteCancel()}>
          Cancel
        </div>
        <div
          className="continue wizard-button"
          onClick={() => handleDeleteContinue()}
        >
          Continue
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
