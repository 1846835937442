/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Modal, Select } from 'antd';
import Utils from '../../redux/utils';
import VariationActions from '../../redux/middleware/variation';
import './style.scss';
type Props = {
  handleDialog: any;
  open: boolean;
  name?: any;
  list?: any[];
};

const VariationCreateModal = (props: Props) => {
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  const shop = Utils.getCurrentShop();
  const user = Utils.getCurrentUser();
  let { handleDialog, open, name, list } = props;
  const [variationName, setVariationName] = React.useState(name);
  const [variationType, setVariationType] = useState<any>(
    list?.find((variation: any) => variation?.name?.toLowerCase() == 'size')?.id
  );

  const handleSubmit = async () => {
    // let response;
    // handleFetchCategory(response);
    // if (response?.success) {
    //   handleDialog(false, response?.data);
    // }
    const param = {
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id || 0,
      variation_type_id: variationType || 1,
      name: variationName,
      created_by: user?.id,
    };

    try {
      const response = await VariationActions.createVariation(param);
      // setLoader(false);
      if (!response?.success) {
        console.log(response?.success);
      } else {
        handleDialog(false, response?.data);
        // navigate('/app/variations');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/variations');
        //   },
        //   type: 'success',
        //   messageArray: [`Your ${variation} Variation has been Created`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleName = (data: string) => {
    setVariationName(data);
  };

  return (
    <div className="VariationCreateModal">
      <Modal
        title={<div className="title font">Add New Variation</div>}
        centered
        open={open}
        onCancel={() => handleDialog(false, {})}
        width={608}
        footer={null}
      >
        <div className="Modal">
          <div className="ingredientCategoryContainerWidth">
            <div className="">
              <div className="wizardLabel">
                Variation Type <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={'Select Variation Type'}
                onChange={(e) => setVariationType(e)}
                style={{ width: 430 }}
                optionFilterProp="children"
                value={variationType}
                disabled
              >
                {list?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="wizardSubContainer case">
              <div className="variationModalwizardLabel">
                Variation Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Menu Category Name"
                size="large"
                value={variationName}
                onChange={(e) => {
                  handleName(e.target.value);
                }}
                required
              />
            </div>
          </div>
          <div className="butonGroup">
            <div
              className="wizard-button"
              onClick={() => handleDialog(false, {})}
            >
              Back
            </div>
            <div
              className={
                variationName !== ''
                  ? 'continue wizard-button'
                  : 'continue wizard-button variation-disable-button'
              }
              onClick={() => variationName !== '' && handleSubmit()}
            >
              Submit
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VariationCreateModal;
