/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message, Alert, Spin } from 'antd';
import { Breadcrumb, Page } from '../../components';
import WastageForm from './wastageForm';
import WastageActions from '../../redux/middleware/wastage';
import { useSelector } from 'react-redux';
import './styles.scss';
import Utils from '../../redux/utils';
import moment from 'moment';
import { checkAuthentication } from '../../helper/checkAuthentication';

const Wizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [stepper, setStepper] = React.useState(0);
  const [apiLoader, setApiLoader] = useState(false);
  const [variationCheck, setVariationCheck] = useState(false);

  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      recipe_name: '',
      unit: '',
      reason: 'Please Select',
      variation: 'Please Select',
    },
  ]);

  const [unit, setUnit] = useState([]);
  const [index, setIndex] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [alerts, setAlerts] = useState(false);

  const [wasteTypes, setWasteTypes] = useState([]);
  const [wasteReasons, setWasteReasons] = useState([]);
  const [ingredientVariation, setIngredientVariation] = useState([]);

  const [wastageBy, setWastageBy] = useState(1);
  const [wastageFor, setWastageFor] = useState('brand');
  const [businessDate, setBusinessDate] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentShop?.shop_id);

  // useEffect(() => {
  //   console.log('currentShop', currentShop);

  //   if (update && currentBrandId !== currentShop?.shop_id) {
  //     navigate('/app/requisitions');
  //   }
  // }, [currentBrand, currentShop]);

  React.useEffect(() => {
    checkAuthentication(user, navigate);
    fetchWasteTypes();
    fetchWasteReasons();
  }, []);

  const fetchWasteTypes = async () => {
    try {
      const response = await WastageActions.getWasteType();
      const { success, data = [] } = response;
      if (success) {
        const options = data && data.slice(0, 2);
        setWasteTypes(options);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const fetchWasteReasons = async () => {
    try {
      const response = await WastageActions.getWasteReason();
      const { success, data = [] } = response;
      if (success) {
        setWasteReasons(data);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleBackStepper = () => {
    navigate('/app/wastage');
  };

  const removeIngredientName = (index: number) => {
    let dataArray = ingredients;
    dataArray[index].ingredient_name = '';
    setIngredients(() => [...dataArray]);
  };

  const validateFields = () => {
    if (businessDate === '') {
      return false;
    }

    for (let index = 0; index < ingredients.length; index++) {
      if (
        !(ingredients[index].id > -1) ||
        ingredients[index]?.reason_id == undefined
      ) {
        return false;
      }
    }

    return true;
  };

  const handleContinue = async () => {
    const user = await Utils.getCurrentUser();
    const isValid = validateFields();
    if (isValid) {
      setVisible(false);
      console.log('wastageFor', wastageFor);
      const payload = {
        waste_type_id: wastageBy,
        waste_method_id: 4,
        total_items: 1,
        company_id: Utils.getCurrentCompany()?.id,
        shop_id: Utils.getCurrentShop()?.shop_id,
        brand_id: Utils.getCurrentBrand()?.brand_id,
        created_by: Utils.getCurrentUser()?.id,
        source:
          user?.roles[0]?.id === 3
            ? wastageFor
            : user?.roles[0]?.id === 4
            ? 'shop'
            : 'brand',
        // source: user?.roles[0]?.id === 4 ? 'shop' : 'brand',
        ingredients:
          wastageBy === 1
            ? ingredients.map((item) => ({
                ingredient_id: item?.id,
                unit_id: item?.unit_id,
                quantity: item?.quantity,
                reason_id: item?.reason_id,
                cash_value: handlePayloadForCashValue(
                  item?.quantity,
                  item?.unit,
                  item?.unit_cost
                ),
                item_type: item?.item_type,
                unit_cost: item?.unit_cost,
                business_date: moment(businessDate).format('YYYY-MM-DD'),
              }))
            : ingredients.map((item) => ({
                ingredient_id: item?.id,
                unit_id: 1,
                quantity: item?.quantity,
                reason_id: item?.reason_id,
                cash_value: item?.quantity * item?.total_cost,
                unit_cost: item?.total_cost,
                business_date: moment(businessDate).format('YYYY-MM-DD'),
                item_type: item?.item_type,
              })),
      };

      const response = await WastageActions.addWastage(payload);
      const { success } = response;
      if (success) {
        navigate('/app/wastage');
      }
    } else {
      setVisible(true);
    }
  };

  const handlePayloadForCashValue = (qty: any, unit: any, unit_cost: any) => {
    switch (unit) {
      case 'grams':
        return (qty / 1000) * unit_cost;
      case 'ml':
        return (qty / 1000) * unit_cost;
      case 'piece':
        return qty * unit_cost;
    }
  };

  const handleIngredientName = (
    data: string,
    variation: any,
    index: number
  ) => {
    if (data !== '') {
      let dataArray = ingredients;
      if (wastageBy === 1) {
        dataArray[index].ingredient_name = data;
      } else {
        dataArray[index].recipe_name = data;
        if (variation?.length !== 0) {
          setVariationCheck(true);
          setIngredientVariation(variation);
        } else {
          setIngredientVariation([]);
          setVariationCheck(false);
        }
      }

      setIngredients(() => [...dataArray]);
    } else {
      setIngredients([
        {
          ingredient_id: -1,
          unit_id: 1,
          quantity: '',
          ingredient_name: '',
          recipe_name: '',
          unit: '',
          reason: 'Please Select',
          variation: 'Please Select',
        },
      ]);
    }
  };
  const handleRemoveIngredient = (index: number) => {
    let DataArray = ingredients;
    DataArray.splice(index, 1);
    setIngredients([...DataArray]);
  };

  const handleIngredientWeight = (data: any, unit: any, index: number) => {
    let dataArray = ingredients;
    // console.log(data, unit, index);
    if (data === 0) {
      dataArray[index].quantity = '';
      // dataArray[index].unit = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index].quantity = data;
      dataArray[index].unit = unit;
      setIngredients(() => [...dataArray]);
    }
  };

  const handleIngredientReason = (data: any, index: number) => {
    let dataArray = ingredients;
    dataArray[index].reason = data?.reason;
    dataArray[index].reason_id = data?.id;

    setIngredients(() => [...dataArray]);
  };

  const handleIngredientVariation = (data: any, index: number) => {
    let dataArray = ingredients;
    dataArray[index].variation = data?.variation;
    dataArray[index].id = data?.id;
    setIngredients(() => [...dataArray]);
  };

  const AddIngredient = () => {
    setIngredients((prevArray) => [
      ...prevArray,
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: '',
        reason: 'Please Select',
        variation: 'Please Select',
        buisnessDate: '',
      },
    ]);
  };

  const handleIngredient = (data: any, index: number) => {
    let dataArray = ingredients;

    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredients(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setVisible(false);
      setAlerts(true);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredients([...dataArray]);
    } else {
      const unitObject = unit.filter(
        (element: any) => element?.id == data?.unit_id
      );
      dataArray[index] = {
        ...data,
        quantity: '',
        reason: 'Please Select',
        variation: 'Please Select',
      };
      setIngredients(() => [...dataArray]);
    }
  };
  const handleWastageFor = (toggle: any) => {
    setWastageFor(toggle);
    setIngredients([
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        recipe_name: '',
        unit: '',
        reason: 'Please Select',
        variation: 'Please Select',
      },
    ]);
  };

  const handleWastageBy = (toggle: any) => {
    setWastageBy(toggle);
    setIngredients([
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        recipe_name: '',
        unit: '',
        reason: 'Please Select',
        variation: 'Please Select',
      },
    ]);
  };

  return (
    <Page title="Wizard">
      {contextHolder}
      <Breadcrumb
        heading="Add New Wastage"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/wastage');
        }}
      />
      <div>
        {visible && (
          <Alert
            message="Error"
            description={'Please complete all required fields to proceed'}
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}

        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
      </div>
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <div className="wizardContainer">
          <WastageForm
            wasteType={wasteTypes}
            wasteReason={wasteReasons}
            handleIndex={(data: any) => setIndex(data)}
            handleIngredient={handleIngredient}
            handleRemoveIngredient={handleRemoveIngredient}
            AddIngredient={AddIngredient}
            removeIngredientName={removeIngredientName}
            handleIngredientName={handleIngredientName}
            handleIngredientWeight={handleIngredientWeight}
            handleIngredientReason={handleIngredientReason}
            index={index}
            handleIngredientVariation={handleIngredientVariation}
            ingredients={ingredients}
            update={''}
            variationCheck={variationCheck}
            ingredientVariation={ingredientVariation}
            errorList={errorList}
            errorStatus={errorStatus}
            wastageBy={wastageBy}
            setWastageBy={(toggle: any) => handleWastageBy(toggle)}
            wastageFor={wastageFor}
            setWastageFor={(toggle: any) => handleWastageFor(toggle)}
            businessDate={businessDate}
            setBusinessDate={setBusinessDate}
            setApiLoader={setApiLoader}
          />
          <div
            className={`butonGroup wastage-btn ${
              stepper == 1 && 'wizardSubContainer-wrapper'
            }`}
          >
            <div className="wizard-button" onClick={() => handleBackStepper()}>
              Back
            </div>
            <div className={`continue wizard-button`} onClick={handleContinue}>
              Continue
            </div>
          </div>
        </div>
      </Spin>
    </Page>
  );
};

export default Wizard;
