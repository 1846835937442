import React from 'react';
import { Popover } from 'antd';
import Shellfish from '../../assets/images/shellfish.svg';
import Gluten from '../../assets/images/gluten.svg';
import Treenuts from '../../assets/images/nuts.svg';
import Peanuts from '../../assets/images/peanuts2.svg';
import Soybean from '../../assets/images/soy.svg';
import Egg from '../../assets/images/egg.svg';
import Fish from '../../assets/images/fish.svg';
import Milk from '../../assets/images/bottle.svg';
import ChartPie from '../charts/pie';

type Props = {
  popUp: any;
  setPopUp: any;
  index: number;
  data: any;
  children: any;
  component?: any;
};
const handleAlergenIcons = (id: any) => {
  switch (+id) {
    case 1:
      return <img src={Milk} width={25} />;
    case 2:
      return <img src={Egg} width={25} />;
    case 3:
      return <img src={Fish} width={25} />;
    case 4:
      return <img src={Shellfish} width={25} />;
    case 5:
      return <img src={Treenuts} width={25} />;
    case 6:
      return <img src={Peanuts} width={25} />;
    case 7:
      return <img src={Soybean} width={25} />;
    case 8:
      return <img src={Gluten} width={25} />;
    default:
      return 'No Allergens';
  }
};

const DonutChart = (props: Props) => {
  let { setPopUp, popUp, index, data, component } = props;
  return (
    <div>
      <Popover
        content={
          <div className="popUp-recipe-Container">
            {/* <DemoPie ingredient={data} component={component} /> */}
            <ChartPie component={component} data={data} />
            <div className="allergen font">Allergens</div>
            <div className="subrecipe-allergens-icons">
              {typeof data?.allergens_id === 'string' &&
              data.allergens_id.trim() !== '' ? (
                data.allergens_id.split(',').map((allergen: any) => {
                  return handleAlergenIcons(allergen);
                })
              ) : (
                <span>No Allergens</span>
              )}
            </div>
          </div>
        }
        title="Details"
        trigger="click"
        open={popUp == index}
        onOpenChange={() => {
          if (popUp != null) setPopUp(null);
          else setPopUp(index);
        }}
      >
        {props.children}
      </Popover>
    </div>
  );
};

export default DonutChart;
