import { Input } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './styles.scss';

type Props = {
  action: Function;
  placeholderText?: string;
  additioanlClassName?: string;
  refresh?: any;
  keyword?: any;
  setKeyword?: any;
};

const SearchBox = (props: Props) => {
  let { keyword, setKeyword } = props;
  const applyAction = () => {
    props.action(keyword);
  };

  return (
    <Input
      onPressEnter={applyAction}
      placeholder={props.placeholderText}
      className={`b2 search-box ${props.additioanlClassName}`}
      prefix={<SearchOutlined />}
      value={keyword}
      onChange={(e) => {
        setKeyword(e.target.value);
        if (e.target.value === '') props.action('');
      }}
      allowClear={{
        clearIcon: (
          <CloseOutlined
            onClick={() => {
              props?.action('');
              // props.refresh();
            }}
          />
        ),
      }}
    />
  );
};

export default SearchBox;
