import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin, Select } from 'antd';
import './style.scss';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
} from '../../components';
import UploadRecipeApi from '../../redux/middleware/uploadImageApi';
import moment from 'moment';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const UploadSubRecipeForRB = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();

  const [current, setCurrent] = useState(1);
  const [taggedImageData, setTaggedImageData] = useState([{}]);
  const [untaggedImageData, setUntaggedImageData] = useState([]);
  const [untaggedRecipeData, setUntaggedRecipeData] = useState([]);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20, 50];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);
  useEffect(() => {
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, check]);
  useEffect(() => {
    if (!check) getUntaggedRecipes();
  }, [check]);

  const getUntaggedRecipes = async () => {
    const untaggedRecipes = await UploadRecipeApi.getUntaggedRecipes(
      'sub_recipe'
    );
    if (untaggedRecipes == undefined || !untaggedRecipes?.success)
      setUntaggedRecipeData([]);
    else setUntaggedRecipeData(untaggedRecipes?.data?.items);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>Image Name</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">
                  {/* {record?.recipe_name} */}
                  Tenderloin-beef.jpg
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Tagged</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.name}</div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
        </div>
      ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>Image Name</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.image} width="52px" height="52px" />
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title">{record?.name}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Tagged</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          <Select
            size={'large'}
            placeholder={'-'}
            onChange={(e) => {
              handleMenu(e, record);
            }}
            style={{ width: 200 }}
            optionFilterProp="children"
          >
            {untaggedRecipeData?.map((data: any, menuIndex: number) => (
              <Select.Option value={data?.recipe_id} key={menuIndex}>
                {data.recipe_name}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol col3 col4">
          {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
        </div>
      ),
    },
  ];

  const handleMenu = async (value: any, data: any) => {
    const payload = {
      recipe_id: value,
      type: 'sub_recipe',
    };
    const assignTag = await UploadRecipeApi.assignUntaggedImage(
      payload,
      data?.id
    );
    let responseDialogData;
    if (assignTag === undefined || !assignTag?.success) {
      responseDialogData = {
        type: 'error',
        messageArray: [`Validation Failed!`, 'Something Went Wrong'],
        OnClick: () => {},
      };
      ResponseDialog(responseDialogData);
    } else pageData();

    //   responseDialogData = {
    //     type: 'success',
    //     messageArray: [`Congratulation!`, ` ${assignTag?.message}`],
    //     OnClick: () => {
    //       pageData();
    //     },
    //   };
    // ResponseDialog(responseDialogData);
  };
  const pageData = async () => {
    setRecipeLoader(true);
    const imagesData = check
      ? await UploadRecipeApi.getTaggedImages(dataLength, current, 'sub_recipe')
      : await UploadRecipeApi.getUntaggedImages(
          dataLength,
          current,
          'sub_recipe'
        );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalUntaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedImageData(imagesData?.data?.items);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUntaggedImageData(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);

    const searchResultofRecipes = check
      ? await UploadRecipeApi.searchUploadImages(
          'get_tagged_images',
          dataLength,
          current,
          keyword,
          'sub_recipe'
        )
      : await UploadRecipeApi.searchUploadImages(
          'get_untagged_images',
          dataLength,
          current,
          keyword,
          'sub_recipe'
        );
    if (searchResultofRecipes === undefined) {
      setTotal(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
      setTotalUntaggedImage(0);
    } else {
      if (check) {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTaggedImageData(searchResultofRecipes?.data?.items);
      } else {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTotalUntaggedImage(searchResultofRecipes?.data?.pagination?.total);
        setUntaggedImageData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Upload Images"
        breadcrumbIcon
        iconAction={() => {}}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={10}></Col>
          <Col lg={8} />
          <Col lg={6}>
            <SearchBox action={onSearch} placeholderText="Search..." />
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="uploadImagesWrapper">
            <div className="uploadImageMenuContainer">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div onClick={() => setCheck(true)} className="text menustyle">
                  Matched Images
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text menustyle">UnMatched Image</div>
                <div
                  className={`counter ${
                    +totalUntaggedImage >= 10 && 'largeCounter'
                  }`}
                >
                  {totalUntaggedImage}
                </div>
              </div>
            </div>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={''}
                dataSource={
                  check ? [...taggedImageData] : [...untaggedImageData]
                }
                columns={check ? taggedColumns : unTaggedColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default UploadSubRecipeForRB;
