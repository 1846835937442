import { Alert, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponseDialog, Stepper } from '../../components';
import {
  Page,
  Breadcrumb,
  UploadImageStepOne,
  UploadImageStepTwo,
} from '../../components';
import UploadRecipeApi from '../../redux/middleware/uploadImageApi';
import './style.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';
import Utils from '../../redux/utils';
const UploadImageWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const [stepper, setStepper] = useState<number>(0);
  const [image, setImage] = React.useState<any>([]);
  const [imageObject, setImageObject] = React.useState<any[]>([]);
  const [untaggedRecipeData, setUntaggedRecipeData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const handleImage = (url: any, data: any) => {
    setImage((prevArray: any) => [...prevArray, ...url]);
    const data1 = data?.target?.files;
    let res = Object.keys(data?.target?.files).map((key: any) => data1[key]);

    setImageObject((prev: any) => {
      return [
        ...prev,
        ...res.map((data: any) => {
          return { data, is_upload: false, tag_id: '' };
        }),
      ].flat();
    });
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    getUntaggedRecipes();
  }, []);

  const getUntaggedRecipes = async () => {
    const untaggedRecipes = await UploadRecipeApi.getUntaggedRecipes('recipe');
    if (untaggedRecipes == undefined || !untaggedRecipes?.success)
      setUntaggedRecipeData([]);
    else
      setUntaggedRecipeData(
        untaggedRecipes?.data?.items?.map((data: any) => ({
          value: data?.recipe_id,
          label: data.recipe_name,
        }))
      );
  };

  const handleDelete = (index: number) => {
    let DataArray = image;
    let dataObjectArray = imageObject;
    DataArray.splice(index, 1);
    dataObjectArray.splice(index, 1);
    setImage([...DataArray]);
    setImageObject([...dataObjectArray]);
  };
  const handleChange = async (data: any, index: number, key: string) => {
    let DataArray = imageObject;

    const uploadImage = await UploadRecipeApi.uploadRecipeImages(
      data,
      DataArray[index]?.data,
      'recipe'
    );

    if (uploadImage == undefined || !uploadImage?.success) {
      setImageObject([...DataArray]);
    } else {
      DataArray[index][key] = data;
      DataArray[index]['is_upload'] = true;
      setImageObject([...DataArray]);
    }
  };
  const handleBackStepper = () => {
    if (stepper > 0) {
      setRecipeLoader(false);

      setStepper(stepper - 1);
    } else navigate('/app/uploadimage');
  };
  const handleContinue = (currentStep: any) => {
    console.log(currentStep);

    setRecipeLoader(true);
    if (stepper == 0 && image?.length != 0) {
      setRecipeLoader(false);
      setStepper(currentStep);
      setVisible(false);
    } else if (stepper == 1) {
      setVisible(false);
      let untaggedUploadImages = imageObject?.filter(
        (data: any) => !data?.is_upload
      );
      if (untaggedUploadImages?.length != 0) {
        // let responseDialogData = {
        //   type: 'info',
        //   messageArray: [`We are uploading your Images . Kindly Wait !!!`],
        //   OnClick: () => {},
        // };
        // ResponseDialog(responseDialogData);
        const checkResponse = untaggedUploadImages?.map(async (data: any) => {
          const uploadImage = await UploadRecipeApi.uploadRecipeImages(
            '',
            data?.data,
            'recipe'
          );
          if (uploadImage == undefined || !uploadImage?.success) return false;
          else true;
        });
        if (checkResponse?.some((data) => !data)) {
          let responseDialogData = {
            type: 'error',
            messageArray: [
              `Something Went Wrong`,
              'Some of the images are not uploaded',
            ],
            OnClick: () => {
              navigate('/app/uploadimage');
            },
          };
          setRecipeLoader(false);
          ResponseDialog(responseDialogData);
        } else if (checkResponse?.some((data) => data)) {
          setRecipeLoader(false);
          navigate('/app/uploadimage');
        }
        //   responseDialogData = {
        //     type: 'success',
        //     messageArray: [` Your images are uploaded successfully`],
        //     OnClick: () => {
        //       navigate('/app/uploadimage');
        //     },
        //   };
        // ResponseDialog(responseDialogData);

        // setStepper(stepper + 1);
      } else {
        setRecipeLoader(false);

        navigate('/app/uploadimage');
        setVisible(false);
      }
    } else {
      setRecipeLoader(false);

      setVisible(true);
      window.scrollTo(0, 0);
    }
  };

  const handleStepper = (value: number) => {
    if (value > stepper) handleContinue(value);
    else setStepper(value);
  };

  return (
    <div className="uploadimageblock">
      <Breadcrumb
        heading="Upload Images"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/uploadimage');
        }}
      />
      <Page title="Upload Image">
        {visible && (
          <Alert
            message="Error"
            description={'Please complete all required fields to proceed'}
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}
        <Stepper
          stepper={stepper}
          component="UploadImage"
          onStepperChange={handleStepper}
        />
        <div className="wizardContainer">
          {stepper === 0 ? (
            <UploadImageStepOne
              image={image}
              imageObject={imageObject}
              handleImage={handleImage}
              handleDelete={handleDelete}
            />
          ) : (
            <UploadImageStepTwo
              image={image}
              imageObject={imageObject}
              handleDelete={handleDelete}
              handleChange={handleChange}
              untaggedRecipeData={untaggedRecipeData}
            />
          )}
          {recipeLoader ? (
            <div className="loader-wrapper uploadimageloader">
              <Spin spinning={recipeLoader} className="recipeLoader" />
            </div>
          ) : null}
          <div className="butonGroup">
            <div className="wizard-button" onClick={handleBackStepper}>
              Back
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleContinue(stepper + 1)}
            >
              Continue
            </div>
          </div>
        </div>
      </Page>
    </div>
  );
};

export default UploadImageWizard;
