/* eslint-disable no-unused-vars */
import { useState } from 'react';
import {
  MenuProps,
  Radio,
  Dropdown as DropDown,
  RadioChangeEvent,
  DatePicker,
  Space,
  DatePickerProps,
} from 'antd';
import Dropdown from './ingredientDropdown';
// import Delete from '../../../assets/images/Delete.svg';
import ArrowDown from '../../../assets/images/ArrowDown.png';
import './styles.scss';
// import { handleFloat } from '../../../helper/sortData';
import { RangePickerProps } from 'antd/lib/date-picker';
import Utils from '../../../redux/utils';
import ResponseDialog from '../../../components/ResopnseDialog';
import dayjs from 'dayjs';
type Props = {
  wasteType: any;
  wasteReason: any;
  handleIndex: any;
  handleIngredient: any;
  handleRemoveIngredient: any;
  ingredients: any;
  AddIngredient: any;
  handleIngredientName: any;
  handleIngredientWeight: any;
  handleIngredientReason: any;
  removeIngredientName?: any;
  index: any;
  update: any;
  errorList?: any;
  errorStatus?: boolean;
  wastageBy: any;
  setWastageBy: any;
  wastageFor: any;
  setWastageFor: any;
  setBusinessDate: any;
  variationCheck: any;
  handleIngredientVariation: any;
  ingredientVariation: any;
  businessDate: any;
  setApiLoader?: any;
};

const WastageForm = (props: Props) => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  let {
    update,
    wasteType,
    wasteReason,
    wastageBy,
    setWastageBy,
    wastageFor,
    setWastageFor,
    handleIngredient,
    ingredients,
    handleIngredientName,
    handleIngredientWeight,
    handleIngredientReason,
    handleIngredientVariation,
    setBusinessDate,
    variationCheck,
    ingredientVariation,
    setApiLoader,
  } = props;

  const onChangeBrandBranch = (e: RadioChangeEvent) => {
    if (shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      return;
    }
    setWastageFor(e.target.value);
  };

  const onChange = (e: RadioChangeEvent) => {
    setWastageBy(e.target.value);
  };

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    setBusinessDate(dateString);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf('day');
  };

  const items: MenuProps['items'] = wasteReason?.map((data: any) => {
    return {
      key: `${data?.id}`,
      label: <div className="dropdownOptions">{data?.reason}</div>,
    };
  });

  const itemsForVariation: MenuProps['items'] = ingredientVariation?.map(
    (data: any) => {
      return {
        key: `${data?.id}`,
        label: <div className="dropdownOptions">{data?.variation}</div>,
      };
    }
  );

  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [ingredientData] = useState(ingredients || []);

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    const reasonObject: any = wasteReason.filter(
      (data: any) => data?.id == option
    );
    handleIngredientReason(reasonObject[0], ingredientIndex);
  };

  const handleDropDownClickForVariation: MenuProps['onClick'] = async (
    e: any
  ) => {
    let option = +e.key;
    const reasonObject: any = ingredientVariation.filter(
      (data: any) => data?.id == option
    );

    handleIngredientVariation(reasonObject[0], ingredientIndex);
  };

  const DropDownmenuProps = {
    items: items,
    onClick: handleDropDownClick,
  };

  const DropDownmenuPropsForVariation = {
    items: itemsForVariation,
    onClick: handleDropDownClickForVariation,
  };

  return (
    <div className="recipeBuilderWizardStepTwo">
      {user?.roles[0]?.id === 3 && (
        <div className="wizardDropdown">
          <div className="wizardLabel">
            Wastage For <span className="sterik">*</span>
          </div>
          <Radio.Group
            disabled={update}
            onChange={onChangeBrandBranch}
            value={wastageFor}
          >
            <Radio value="brand">Brand</Radio>
            <Radio value="shop">Branch</Radio>
          </Radio.Group>
        </div>
      )}
      <div className="wizardDropdown">
        <div className="wizardLabel">
          Wastage By <span className="sterik">*</span>
        </div>
        <Radio.Group onChange={onChange} value={wastageBy}>
          {wasteType &&
            wasteType.map((item: any, index: number) => (
              <Radio key={index} value={item?.id}>
                {item?.waste_type}
              </Radio>
            ))}
        </Radio.Group>
      </div>
      <div>
        <div className={'wizardSubContainer'}>
          <div className="wizardIngredientsLabel">
            <span className="wastage-IngredientsLabel">
              <span className="wizardLabel">
                Add Ingredients <span className="sterik">*</span>
              </span>
            </span>
            <div className="OtherLabelContainer">
              <span className="label1">
                <span className="wizardLabel">
                  Qty <span className="sterik">*</span>
                </span>
              </span>
              {variationCheck ? (
                <span className="label3">
                  <span className="wizardLabel">
                    Variation <span className="sterik">*</span>{' '}
                  </span>
                </span>
              ) : null}

              {wastageBy == 1 && (
                <span className="label2">
                  <span className="wizardLabel">Unit</span>
                </span>
              )}
              <span className="label3">
                <span className="wizardLabel">
                  Business Date <span className="sterik">*</span>{' '}
                </span>
              </span>

              <span className="label4">
                <span className="wizardLabel">
                  Reason<span className="sterik">*</span>
                </span>
              </span>
            </div>
          </div>

          {ingredients?.map((data: any, index: number) => {
            return (
              <div className="reasonContainer" key={index}>
                <Dropdown
                  handleIngredientName={(text: any, variation: any) => {
                    handleIngredientName(text, variation, index);
                  }}
                  ingredientName={
                    wastageBy === 1 ? data?.ingredient_name : data?.recipe_name
                  }
                  ingredientData={ingredientData}
                  wasteType={wasteType}
                  handleIngredient={(data: any) => {
                    handleIngredient(data, index);
                  }}
                  type_id={wastageBy}
                  setApiLoader={setApiLoader}
                  tag={wastageFor}
                />

                <div
                  className={
                    wastageBy === 1
                      ? 'reasonSubQTYContainer'
                      : 'reasonSubQTYContainer-without-right-border'
                  }
                >
                  <input
                    className="reasonValue"
                    placeholder="0"
                    type={'number'}
                    value={data?.quantity}
                    onChange={(e) => {
                      if (+e.target.value <= 999999) {
                        handleIngredientWeight(
                          +e.target.value,
                          data?.unit,
                          index
                        );
                      }
                    }}
                  />
                </div>
                {wastageBy == 2 && variationCheck ? (
                  <div className="reasonSubContainerForVariation">
                    <DropDown
                      menu={DropDownmenuPropsForVariation}
                      trigger={['click']}
                      placement="bottomRight"
                      arrow={{ pointAtCenter: true }}
                    >
                      <a onClick={() => setIngredientIndex(index)}>
                        <Space>
                          <div>{data?.variation}</div>{' '}
                          <img src={ArrowDown} alt="" />
                        </Space>
                      </a>
                    </DropDown>
                  </div>
                ) : null}

                <div
                  className="reasonSubContainer"
                  style={{
                    paddingLeft: wastageBy === 1 ? '16px' : '0',
                  }}
                >
                  {wastageBy === 1 && (
                    <input
                      className="reasonValue"
                      placeholder=""
                      value={
                        wastageBy === 1
                          ? data.unit
                          : // ? handleFloat(data?.unit).toString()
                            // : ''
                            'N/A'
                      }
                      readOnly
                    />
                  )}

                  <div
                    className={
                      wastageBy === 1
                        ? 'dateContainer'
                        : 'dateContainer-without-border'
                    }
                  >
                    <Space direction="vertical" className="businessDatePicker">
                      <DatePicker
                        onChange={onDateChange}
                        disabledDate={disabledDate}
                      />
                    </Space>
                  </div>
                  <div className="reasonBoxWrapper">
                    <div className="reasonBox">
                      <DropDown
                        menu={DropDownmenuProps}
                        trigger={['click']}
                        placement="bottomRight"
                        arrow={{ pointAtCenter: true }}
                      >
                        <a onClick={() => setIngredientIndex(index)}>
                          <Space>
                            <div>{data?.reason}</div>{' '}
                            <img src={ArrowDown} alt="" />
                          </Space>
                        </a>
                      </DropDown>
                    </div>
                  </div>
                </div>
                {/* <img
                  src={Delete}
                  width={15}
                  alt=""
                  className="deleteButon"
                  onClick={() => {
                    handleIndex(index);
                    handleDeleteContinue(index);
                    // data?.ingredient_name == ''
                    //   ? handleDeleteContinue(index)
                    //   : setOpenDelete(true);
                  }}
                /> */}
              </div>
            );
          })}
          {/* {errorStatus && (
          <div className="subRecipeErrorMessages">
            {errorList['recipe_type_id'][0]}
          </div>
        )} */}
        </div>

        {/* <div className="addButton">
          <div onClick={AddIngredient} className="addButton-subContainer">
            + Add New Row
          </div>
      </div> */}
      </div>
    </div>
  );
};

export default WastageForm;
