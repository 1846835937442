import React from 'react';
import { Modal } from 'antd';

type Props = {
  component?: any;
  handleClose?: any;
  open?: any;
};

const LoginResponseModal = (props: Props) => {
  let { handleClose, open } = props;

  return (
    <Modal
      open={open}
      title={<div className="title font">Response</div>}
      centered
      onCancel={() => handleClose()}
      footer={null}
    >
      <h1>Congratulations!</h1>
      <p>We have successfully retrieved your Login Details</p>
      {/* <p>User Name:</p>
      <p>User Name:</p>
      <p>User Name:</p>
      <p>User Name:</p>
      <p>User Name:</p>
 */}
      <p>
        Remaining work is in process and will be concluded alongside payment GW
        integration.
      </p>
    </Modal>
  );
};

export default LoginResponseModal;
