import React, { useState } from 'react';
import useClickListener from '../../../helper/outsideClickListener';
import ArrowDown from '../../../assets/images/ArrowDown.png';
// import Profile from '../../../assets/images/Profile.png';
//import SuperAdminProfile from '../../../assets/images/gologo.jpg';
// import Logout from '../../../assets/images/Logout.png';
import {
  // UserOutlined,
  LogoutOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import Utils from '../../../redux/utils';
// import UsersApi from '../../../redux/middleware/users';
// import { UserPersonalInfo } from '../../../components';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileDropdown = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'profileDropdown',
  });
  const user = Utils.getCurrentUser();
  const userSubs = Utils.getUserSubscriptions();
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);
  // const [dialogData, setDialogData] = useState({});
  // const [openDialog, setOpenDialog] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);

  const logoutUser = () => {
    Utils.setCurrentUser();
    Utils.setCurrentToken();
    setIsComponentVisible(false);
    const location = window.location;
    window.location.href = location.origin;
  };

  // const getUserDetails = async () => {
  //   const user = Utils.getCurrentUser();
  //   setLoader(true);
  //   const data = await UsersApi.getUserById(user?.id);
  //   setLoader(false);
  //   setDialogData(data?.data);
  //   setOpenDialog(true);
  // };

  // const handleClose = () => {
  //   setOpenDialog(false);
  //   navigate('/app/priceplan');
  // };

  return (
    <div ref={ref} className="headBlock">
      {/*<div className="profileImage">
        <img
          src={
            user?.user_type === 'Super-Admin'
              ? SuperAdminProfile
              : Utils.getCurrentCompany()?.thumbnail || Profile
          }
          alt=""
          className="subHeadImage"
        />
      </div> */}
      <div
        className="profileHead pointer"
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
        }}
      >
        <div>
          <div className=" headFont">{user?.name}</div>
          <div className="headButton">
            <div className="headButtonText headFont profileSubText">
              {user?.user_type}
            </div>
          </div>
        </div>
        <img src={ArrowDown} alt="" />
      </div>
      {isComponentVisible && (
        <div className="customLogoutDropdown">
          <div className="subcustomLogoutDropdown">
            {/* <div className="subcustomLogoutDropdownTitle font">
              <div>
                <img
                  src={Utils.getCurrentCompany()?.thumbnail}
                  alt=""
                  className="profileIcon"
                />
              </div>
              <div>{Utils.getCurrentCompany()?.company_name}</div>
            </div> */}
            <Spin spinning={loader}>
              {/* <div
                className="goto-profile-submit-button"
                onClick={() => {
                  setLoader(true);
                  navigate('/app/userPersonalInfo');
                  setLoader(false);

                  setIsComponentVisible(!isComponentVisible);

                  // getUserDetails();
                }}
              >
                <UserOutlined />
                <div>{t('moveProfile')}</div>
              </div> */}

              {user?.roles[0]?.id === 2 && userSubs?.billing_url && (
                <a
                  className="goto-profile-submit-button billingBtn"
                  href={userSubs?.billing_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <PayCircleOutlined />
                  <div>{t('billing')}</div>
                </a>
              )}
              <div
                className="goto-profile-submit-button"
                onClick={() => logoutUser()}
              >
                <LogoutOutlined />
                <div>{t('logout')}</div>
              </div>
              <div className="linkgroup">
                <a href="/privacy-policy" target={'_blank'}>
                  {t('policy')}
                </a>
                <b>.</b>

                <a href="/data-integrity" target={'_blank'}>
                  {t('integrity')}
                </a>
              </div>
            </Spin>

            {/* <div className="brandBlock">
              <div className="brandBlockTitle font">BRAND</div>
              <div className="brandBlockText">
                {Utils.getCurrentBrand()?.brand_name}
              </div>
            </div>
            <div className="brandBlock">
              <div className="brandBlockTitle font">BRANCH</div>
              <div className="brandBlockText">
                {Utils.getCurrentShop()?.shop_name || ''}
              </div>
            </div> */}
          </div>

          {/* <div className="logoutBlock" onClick={logoutUser}>
            <img src={Logout} alt="" />
            <div>Logout</div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
