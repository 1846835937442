/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Checkbox,
  Select,
  Modal,
  Radio,
  RadioChangeEvent,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListCount,
  ListViewWithoutExpand,
  ResponseDialog,
  Button,
  DetailDialog,
  DeleteConfirmation,
} from '../../components';
import { useSelector } from 'react-redux';
import SupplierApi from '../../redux/middleware/supplier';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import './CountStock.scss';
import { formatNumber, handleFloat } from '../../helper/sortData';
import { checkAuthentication } from '../../helper/checkAuthentication';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import SubMenu from '../../assets/images/SubMenu.svg';
import ProductionIcon from '../../assets/icons/sidebar/Production.svg';
import Sustainable from '../../assets/icons/sidebar/Wastage.svg';

const CountStock = () => {
  const navigate = useNavigate();
  const shop = Utils.getCurrentShop();
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [stockCount, setStockCount] = useState([{}]);
  const [ingredientList, setIngredientList] = useState([]);
  const [search, setSearch] = useState('');
  const [keyword, setKeyword] = useState('');
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [stockLoader, setStockLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [allowRequest, setAllowRequest] = useState(false);
  const PageDataLength = [6, 10, 20, 50];
  const [loader, setLoader] = useState(false);
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const brandList = Utils.getBrandList();
  const [brands, setBrands] = useState<any[] | string>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [subCategory, setSubCategory] = useState<any[]>([]);
  const [sortedSubCategory, setSortedSubCategory] = useState<any[]>([]);
  const [stockCountRecord, setStockCountRecord] = useState<any>({});
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  // eslint-disable-next-line no-unused-vars
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [confirmation, setConfirmation] = useState(false);
  const [countStockFor, setCountStockFor] = useState('brand');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const brand = Utils.getCurrentBrand();
    checkAuthentication(user, navigate);
    setBrands([brand?.brand_id]);
    if (user?.roles[0]?.id === 3) {
      setBrands('shop');
    }
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Stock Count'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    fetchStatus();
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current && brands?.length) {
      pageData();
      setKeyword('');
    }
  }, [dataLength, current, currentBrand, brands, subCategory]);

  useEffect(() => {
    if (dataLength && current && brands?.length) {
      setSubCategory([]);
      fetchSubCategories();
      setKeyword('');
    }
  }, [dataLength, current, currentBrand, brands]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(
        data?.find((type: any) => type?.slug == 'stock_adjustment')
      );
    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const onChangeBrandBranch = (e: RadioChangeEvent) => {
    if (shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      return;
    }
    setCountStockFor(e.target.value);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const fetchDialogData = async () => {
    const { data, success } = await SupplierApi.getInventorySupplyById(
      stockCountRecord
    );
    setLoader(false);
    if (success) {
      setDialogData(data);
      setOpenDialog(true);
    }
  };

  const handleChangeData = (value: any, index: any) => {
    const ingredientsReplicate = dialogData?.ingredients;
    ingredientsReplicate[index] = {
      ...ingredientsReplicate[index],
      transfer_quantity: value,
      deficit:
        +ingredientsReplicate[index]?.current_balance > value
          ? +ingredientsReplicate[index]?.current_balance - +value
          : 0,
      cash_impact:
        +ingredientsReplicate[index]?.current_balance > value
          ? (+ingredientsReplicate[index]?.current_balance - +value) *
            ingredientsReplicate[index]?.unit_cost
          : 0,
    };
    setDialogData({ ...dialogData, ingredients: ingredientsReplicate });
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const unTaggedColumns = [
    {
      title: (_: any, record: any) => (
        <div className="tableTitle">
          <Checkbox
            onChange={(e: any) => onSelectAllIngredients(e, ingredientList)}
            checked={visible}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            onChange={(e: any) => onChangeIngredients(e, record)}
            checked={
              ingredients?.findIndex(
                (ingredient: any) => ingredient?.id == record?.id
              ) != -1
            }
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SKU</span>
        </div>
      ),
      dataIndex: 'sku_id',
      key: 'sku_id',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title stockCount-alignment-left">
                  {record?.sku_id}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title stockCount-alignment-left">
                  {record?.ingredient_name}
                </span>
                <div>
                  <Tooltip
                    title={
                      <div className="ingredient-verify-icon-tooltip">
                        <div className="tootipText">Sub Recipe</div>
                      </div>
                    }
                    className="table-title"
                  >
                    {record?.ingredient_type?.toLowerCase() == 'sub_recipe' && (
                      <img
                        src={SubMenu}
                        width={15}
                        alt=""
                        className="subRecipeIcons IconsAlignment"
                        onClick={() => {
                          // handleingredient(record?.sku);
                        }}
                      />
                    )}
                  </Tooltip>
                  <Tooltip
                    title={
                      <div className="ingredient-verify-icon-tooltip">
                        <div className="tootipText">Production</div>
                      </div>
                    }
                    className="table-title"
                  >
                    {record?.ingredient_type?.toLowerCase() == 'production' && (
                      <img
                        src={ProductionIcon}
                        width={15}
                        alt=""
                        className="subRecipeIcons spacingForImg"
                      />
                    )}
                  </Tooltip>
                  <Tooltip
                    title={
                      <div className="ingredient-verify-icon-tooltip">
                        <div className="tootipText">Sustainable</div>
                      </div>
                    }
                    className="table-title"
                  >
                    {record?.ingredient_type?.toLowerCase() ==
                      'sustainable' && (
                      <img
                        src={Sustainable}
                        width={20}
                        height={20}
                        alt=""
                        className="subRecipeIcons ingredient-sustainable-icon-space sustainableIconAlignment"
                      />
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-storageInput">
          {record?.ingredient_category}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CURRENT BALANCE</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing stockCount-storageInput">
          {/* <Input
            placeholder="Enter Cost"
            type="number"
            value={recordIndex == index && totalCost}
            disabled
            onChange={(e) => setTotalCost(+e.target.value)}
          /> */}
          {formatNumber(record?.current_balance)}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.storage_unit}</div>
      ),
    },
  ];

  const onSelectAllIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setVisible(true);
      setIngredients(data);
    } else {
      setVisible(false);
      setIngredients([]);
    }
  };

  const onChangeIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setIngredients([...ingredients, data]);
    } else {
      setIngredients(
        ingredients?.filter((ingredient: any) => ingredient?.id != data?.id)
      );
    }
  };

  const handleBrand = (value: any) => {
    setBrands(value);
  };

  const pageData = async () => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    const tag =
      user?.roles[0]?.id === 3
        ? brands === 'shop'
          ? 'shop'
          : 'brand'
        : 'brand';
    const ingredient_category_id = subCategory;

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = await SubRecipeActions.getAllSubRecipeInventory(
      3,
      current,
      dataLength,
      user?.roles[0]?.id === 4 ? brands?.toString() : brand?.brand_id,
      shop?.shop_id ? shop?.shop_id : '',
      ingredient_category_id,
      '',
      '',
      tag
    );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setTotalIngredients(imagesData?.data?.pagination?.total);
      setIngredientList(imagesData?.data?.items);
    }
    setStockLoader(false);
  };

  const onSearch = async (keyword: string) => {
    let request_id;
    const tag =
      user?.roles[0]?.id === 3
        ? brands === 'brand'
          ? 'brand'
          : 'shop'
        : brands;
    setSearch(keyword);
    setStockLoader(true);
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find(
        (supply: any) => supply?.slug == 'stock_adjustment'
      );
    const imagesData = await SubRecipeActions.getCountStockSearchInventory(
      3,
      keyword,
      tag
    );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setTotalIngredients(imagesData?.data?.pagination?.total);
      setIngredientList(imagesData?.data?.items);
    }
    setStockLoader(false);
  };

  const SubmitStockCount = () => {
    setModalVisible(true);
    // setConfirmation(true);
  };

  const handleSubmit = () => {
    handleConfirmation(false);
  };

  const handleConfirmation = async (update: boolean) => {
    const user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const role_id = user?.roles[0]?.id;

    const payload = {
      request_type_id: update ? dialogData?.request_type_id : requestType?.id,
      request_status_id: update ? dialogData?.request_status_id : status?.id,
      brand_id: update ? dialogData?.brand_id : brand?.brand_id,
      shop_id: update ? dialogData?.shop_id : shop?.shop_id,
      company_id: update ? dialogData?.company_id : company?.id,
      created_by: user?.id,
      total_cash_impact: update
        ? +handleFloat(
            +dialogData?.ingredients
              .map((data: any) => +data?.cash_impact)
              .reduce((partialSum: any, a: any) => partialSum + a, 0)
          )
        : // dialogData?.ingredients
          //     .map((data: any) => +data?.transfer_quantity * +data?.deficit)
          //     .reduce((partialSum: any, a: any) => partialSum + a, 0)
          0,
      po_from: update
        ? dialogData?.po_from
        : role_id === 3
        ? countStockFor
        : role_id === 4
        ? 'shop'
        : role_id === 5
        ? 'brand'
        : '',
      ingredients: update
        ? dialogData?.ingredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: data?.transfer_quantity,
            deficit: data?.deficit,
            cash_impact: handleFloat(data?.cash_impact),
          }))
        : ingredients?.map((data: any) => ({
            ingredient_id: data?.ingredient_id || data?.id,
            request_quantity: 0,
            deficit: 0,
            cash_impact: 0,
          })),
    };

    try {
      let responseDialogData;
      setLoader(true);
      const response = update
        ? await SupplierApi.updateInventorySupply(payload, stockCountRecord)
        : await SupplierApi.createInventorySupply(payload);
      setLoader(false);
      setIngredients([]);
      if (response == undefined || !response?.success) {
        responseDialogData = {
          OnClick: () => {
            setOpenDialog(false);
          },
          type: 'error',
          messageArray: [
            // `Your Stock Count`,
            // ` has not been ${update ? 'Updated' : 'Created'}`,
            response?.error?.request_status_id,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        if (update) {
          setOpenDialog(false);
          pageData();
        } else navigate('/app/stockTaking');
        // let responseDialogData = {
        //   OnClick: () => {
        //     if (update) {
        //       setOpenDialog(false);
        //       pageData();
        //     } else setCheck(true);
        //   },
        //   type: 'success',
        //   messageArray: [
        //     `Your Stock Count`,
        //     ` has been ${update ? 'Updated' : 'Created'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }

    setConfirmation(false);
  };

  const fetchSubCategories = async () => {
    const SubCategories =
      await MenuCategoryActions.getSubCategoriesForCountStock(5, 500);

    if (SubCategories?.success) {
      setSubCategories(SubCategories?.data?.items);
      setSortedSubCategory(SubCategories?.data?.items);
    }
  };

  const handleCancel = () => {
    setConfirmation(false);
  };

  const handleBrandBranch = (value: any) => {
    setBrands(value);
  };

  const handleSubCategoryChange = (value: any) => {
    setSubCategory(value);
  };

  const onSearchSubCategory = (value: any) => {
    const filteredArray = subCategories.filter((subCategoryObj: any) =>
      subCategoryObj?.sub_category.toLowerCase().includes(value)
    );
    setSortedSubCategory([...filteredArray]);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Count Stock"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/inventory');
        }}
        button={() => {}}
        perm_status={permissionStatus}
      />
      {openDialog && (
        <DetailDialog
          open={openDialog}
          handleClose={handleClose}
          data={dialogData}
          handleChangeData={handleChangeData}
          component={'stock_count'}
          handleResponse={SubmitStockCount}
        />
      )}
      <DeleteConfirmation
        handleDeleteCancel={handleCancel}
        handleDeleteContinue={handleSubmit}
        open={confirmation}
        message={`Are you sure you want to Activate Stock Take of ${ingredients?.length} Items?`}
      />

      <Spin spinning={loader} size="large">
        <Page title="Count Stock">
          {/* <div className="stockCountHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div> */}
          <Row className="listingTopFilter">
            <Col lg={2} md={12}>
              <div style={{ display: 'flex', marginRight: '100px' }}>
                <ListCount
                  count1={totalIngredients}
                  title1="Total Stock"
                  component={'Count Stock List'}
                />
              </div>
            </Col>
            <Col lg={12} md={24}>
              {/* <div className="dropdown-filter-label-container">
                <div className="type">Type</div>
                <div className="category">Category</div>
                <div>Sub Category</div>
              </div> */}
              <div className="brand-filter-container">
                {user?.roles[0]?.id === 3 ? (
                  <Select
                    className="top-bar-filter"
                    style={{ width: '30%' }}
                    placeholder="Select option"
                    maxTagCount="responsive"
                    value={brands}
                    onChange={(value: any) => {
                      handleBrandBranch(value);
                    }}
                    optionFilterProp="children"
                  >
                    <Select.Option value="brand">Brand</Select.Option>
                    <Select.Option value="shop">Branch</Select.Option>
                  </Select>
                ) : null}
                <Select
                  className="top-bar-filter"
                  mode="multiple"
                  onSearch={onSearchSubCategory}
                  style={{ width: '30%' }}
                  placeholder="Select Category"
                  onChange={handleSubCategoryChange}
                  optionLabelProp="label"
                  value={subCategory}
                >
                  {sortedSubCategory?.map(
                    (data: any, subCategoryIndex: number) => (
                      <Select.Option
                        value={data?.id}
                        key={subCategoryIndex}
                        label={data.sub_category}
                      >
                        {data.sub_category}
                      </Select.Option>
                    )
                  )}
                </Select>
                {user?.roles[0]?.id === 4 ? (
                  <Select
                    mode="multiple"
                    style={{ width: '30%' }}
                    placeholder="Select Brand"
                    maxTagCount="responsive"
                    value={brands}
                    onChange={(value: any) => {
                      handleBrand(value);
                    }}
                    optionFilterProp="children"
                  >
                    {brandList?.map((brand: any, index: number) => (
                      <Select.Option key={index} value={brand?.brand_id}>
                        {brand?.brand_name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : null}
              </div>
            </Col>

            <Col lg={10}>
              <div className="search-wrapper">
                <div className="search-box">
                  <SearchBox
                    keyword={keyword}
                    setKeyword={setKeyword}
                    action={onSearch}
                    placeholderText="Search..."
                  />
                </div>

                {!!ingredients.length && (
                  <Button
                    text="Activate Stock Take"
                    className="theme-button recipeBtn"
                    // icon={<PlusOutlined />}
                    handleButtonClick={() => {
                      SubmitStockCount();
                    }}
                    shape="round"
                    size="middle"
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="CountStock-list-wrapper">
              {!stockLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={''}
                  dataSource={[...ingredientList]}
                  columns={unTaggedColumns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    spinning={stockLoader}
                    className="recipeLoader"
                    size="large"
                  />
                </div>
              )}
              <Modal
                title="Stock Count"
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
              >
                <div className="wizardDropdown stock-count">
                  <div className="stock-count">
                    Stock Count For <span className="sterik">*</span>
                  </div>
                  <Radio.Group
                    onChange={onChangeBrandBranch}
                    value={countStockFor}
                  >
                    <Radio value="brand">Brand</Radio>
                    <Radio value="shop">Branch</Radio>
                  </Radio.Group>
                </div>
                <div>
                  <Button
                    text="Continue"
                    className="theme-button recipeBtn"
                    handleButtonClick={() => {
                      setConfirmation(true);
                    }}
                    shape="round"
                    size="middle"
                  />
                </div>
              </Modal>
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </React.Fragment>
  );
};
export default CountStock;
