/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { message, Alert, Spin } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RcFile } from 'antd/lib/upload';
import RecipeActions from '../../redux/middleware/recipe';
import IngredientActions from '../../redux/middleware/ingredient';
import {
  Page,
  Stepper,
  StepOne,
  StepTwo,
  StepThree,
  ResponseDialog,
  Breadcrumb,
} from '../../components';
import './rdSubRecipe.scss';
import Attributes from '../../redux/middleware/attributes';
import { loadImage } from '../../helper/loadDefaultImage';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';

type Category = {
  id: number;
  category_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type Database = {
  value: any;
  label: any;
  key: any;
};

type allergens = {
  id: number;
  name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type additionalInfo = {
  id: number;
  attribute_name: string;
  status: number;
  created_at: string;
  updated_at: string;
};

type recipes = {
  id: number;
  recipe_type: string;
  status: number;
  created_at: string;
  updated_at: string;
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const RDSubRecipeWizard = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id, guest_recipe_limit } = state;
  const [allergenData, setAlergenData] = useState<any[]>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [image, setImage] = React.useState('');
  const [subRecipeUnit, setSubRecipeUnit] = useState<any>({});
  const [yeild, setYeild] = useState<any>('');
  const [imageObject, setImageObject] = React.useState({});
  const [statuses, setStatuses] = useState<any[]>();
  const [status, setStatus] = useState<any>();
  const [category, setCategory] = React.useState<Category>({
    id: 1,
    category_name: 'Sub Recipe',
    status: 1,
    created_at: Date().toString(),
    updated_at: Date().toString(),
  });
  const [recipe, setRecipe] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [menu, setMenu] = React.useState<recipes>({
    created_at: '2022-04-22T22:52:41.000000Z',
    id: 2,
    recipe_type: 'Sub Recipe',
    status: 1,
    updated_at: '2022-04-22T22:52:41.000000Z',
  });
  const [subRecipe, setSubRecipe] = React.useState<recipes>();
  const [allergensByDefault, setAllergensByDefault] = useState<any>([]);
  const [stepper, setStepper] = React.useState(0);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [apiLoader, setApiLoader] = useState(false);
  // Step 2 States

  const [database, setDatabase] = useState<Database>({
    value: 1,
    label: 'My Market List',
    key: 3,
  });
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      unit: '',
      multiplier: 0,
    },
  ]);
  const [unit, setUnit] = useState([]);
  const [index, setIndex] = useState(0);
  // step 3
  // const [stepDeleteDialog, setStepDeleteDialog] = useState(false);
  const [allergen, setAllergen] = useState<allergens[]>([]);
  const [addinfo, setAddinfo] = useState<additionalInfo[]>([]);
  const [steps, setSteps] = useState(['']);
  const [stepIndex, setStepIndex] = useState(0);
  const [calory, setCalory] = useState(0);
  const [carb, setcarb] = useState(0);
  const [fat, setFat] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [parentSubCategory, setParentSubCategory] = useState([]);
  const [subCategory, setSubCategory] = useState<any>({
    sub_category: '',
    id: -1,
  });
  const [protein, setProtein] = useState(0);
  const [recipeNote, setRecipeNote] = useState('');
  const [video, setVideo] = useState('');
  const [prep, setPrep] = useState('');
  const [serving, setServing] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [alerts, setAlerts] = useState(false);
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/r&d-subrecipe');
    }
  }, [currentBrand, currentShop]);

  // const [, setBinaryData] = useState<any>();

  useEffect(() => {
    setIngredients([
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: '',
        multiplier: 0,
      },
    ]);
    setSubCategory({
      sub_category: '',
      id: -1,
    });
  }, [currentBrand]);

  const fetchDataById = async (id: any) => {
    const data = await RecipeActions.getDetailRecipe(id);

    if (data) {
      setApiLoader(false);
    }
    setImage(data?.data?.general?.thumbnail);
    setYeild(data?.data?.general?.yield);
    setSubRecipeUnit({
      unit: data?.data?.general?.unit,
      id: data?.data?.general?.unit_id,
    });
    // setCategory({
    //   id: data?.data?.general?.category_id,
    //   category_name: data?.data?.general?.category_name,
    //   status: 1,
    //   created_at: Date().toString(),
    //   updated_at: Date().toString(),
    // });
    setSubCategory({
      sub_category: data?.data?.general?.category_name,
      id: data?.data?.general?.category_id,
    });
    setRecipe(data?.data?.general?.recipe_name);
    setDescription(data?.data?.general?.description);
    setMenu({
      id: data?.data?.general?.recipe_type_id,
      recipe_type: data?.data?.general?.recipe_type,
      status: 1,
      created_at: Date().toString(),
      updated_at: Date().toString(),
    });
    setIngredients(data?.data?.ingredients);

    // setDatabase(
    //   data?.data[0]?.ingredients.length
    //     ? {
    //         value: databaseObject?.data?.status,
    //         label: databaseObject?.data?.db_name,
    //         key: databaseObject?.data?.id,
    //       }
    //     : {
    //         value: 0,
    //         label: 'Select database here',
    //         key: 3,
    //       }
    // );

    setAllergen(data?.data?.general?.allergens);
    setSteps(data?.data?.general?.steps);
    setCalory(data?.data?.general?.calorie);
    setFat(data?.data?.general?.fat);
    setcarb(data?.data?.general?.carb);
    setProtein(data?.data?.general?.protein);
    setPrep(data?.data?.general?.prep_time);
    setServing(data?.data?.general?.servings);
    setRecipeNote(data?.data?.general?.recipe_note);
    setVideo(data?.data?.general?.video_url);
    setAddinfo(data?.data?.general?.additional_attributes);
    setStatus(data?.data?.general?.status);
  };
  const fetchAllergen = async () => {
    const data = await Attributes.getAllergens();
    setAlergenData(data?.data);
  };

  const fetchStatus = async () => {
    const { data, success } = await Attributes.getStatus();
    if (success) setStatuses(data);
  };

  // const fetchDefaultImage = async () => {
  //   const defaultImage = await loadImage();
  //   setBinaryData(defaultImage);
  // };
  const checkGuestUserLimit = () => {
    if (guest_recipe_limit == 5) {
      let responseDialogData = {
        type: 'error',
        messageArray: [
          `Your recipe creation limit is reached out.`,
          `You can create only 5 recipes in free trial`,
        ],
        OnClick: () => {
          navigate('/app/userPersonalInfo');
        },
      };
      ResponseDialog(responseDialogData);
    }
  };

  useEffect(() => {
    // fetchDefaultImage();
    checkAuthentication(user, navigate);
    checkGuestUserLimit();
    if (update) {
      setApiLoader(true);
      fetchDataById(id);
    }
    fetchAllergen();
    fetchUnit();
    fetchStatus();
  }, []);

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject({});
    }
  };

  //   const checkCategory = async () => {
  //     // const menuCategories = await Attributes.getMenuCategory(company?.id);

  //     const getMenuCategory = menuCategories?.data?.items.filter(
  //       (data: any) => data?.name.toLowerCase() == 'sub recipe'
  //     );
  //     // setLoader(false);
  //     if (
  //       menuCategories?.data?.items?.length > 0 &&
  //       getMenuCategory?.length > 0
  //     ) {
  //       setCategory(getMenuCategory[0]);
  //     } else {
  //       let responseDialogData = {
  //         type: 'error',
  //         messageArray: [`Please create Menu Category, before!`],
  //         OnClick: () => {
  //           navigate('/app/subrecipe');
  //         },
  //       };
  //       ResponseDialog(responseDialogData);
  //     }
  //   };

  const handleDescription = (data: string) => {
    setDescription(data);
  };

  const handleSubCategory = (data: any) => {
    setSubCategory(data);
  };

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const handleBackStepper = (index: number) => {
    if (index == -1) {
      navigate('/app/r&d-subrecipe');
    }
    if (stepper !== 0) setStepper(index);
    setVisible(false);
    setAlerts(false);
  };

  const checkEmptyIngredients = (index: number) => {
    if (index == 2) {
      const wrongIngredient = ingredients.filter((data: any) => {
        if (
          data?.ingredient_id == -1 ||
          data?.quantity == '' ||
          data?.quantity == '0'
        )
          return { unit: data?.carb_per_unit, qty: data?.quantity };
      });
      if (wrongIngredient.length) {
        setMessages([
          'Please ensure all required fields are filled out correctly, and unused fields are removed.',
        ]);
        setAlerts(true);
        setVisible(false);
        return true;
      } else {
        return false;
      }
    }
  };

  const handleNextStepper = async (index: number) => {
    const user = Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const company = await Utils.getCurrentCompany();
    const checkIngredient = checkEmptyIngredients(index);

    if (checkIngredient) {
      return;
    }
    // if (
    //   stepper == 1 &&
    //   menu?.recipe_type === 'Sub Recipe' &&
    //   ingredients.length == 1
    // ) {
    //   setAlertMessage('Please add two or more Ingredient  ');
    //   setVisible(true);
    //   setAlerts(false);

    //   window.scrollTo(0, 0);
    //   return;
    // }

    if (
      (stepper == 0 &&
        (!category || recipe.length == 0 || menu?.recipe_type == undefined)) ||
      (stepper == 1 &&
        (database?.label == 'Select database here' ||
          ingredients.length == 0 ||
          ingredients[0]?.ingredient_name == '')) ||
      (stepper == 2 &&
        (yeild == '' ||
          (subRecipeUnit &&
            Object.keys(subRecipeUnit).length === 0 &&
            Object.getPrototypeOf(subRecipeUnit) === Object.prototype)))
    ) {
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    } else {
      if (index <= 3) {
        setAlerts(false);
        setVisible(false);
        setStepper(index);
        window.scrollTo(0, 0);
      }
    }
    if (index == 3 && !update) {
      setErrorList({});
      setErrorStatus(false);
      const params = {
        general: {
          recipe_name: recipe,
          recipe_type_id: menu?.id,
          category: subCategory?.sub_category,
          category_id: subCategory?.id,
          unit: subRecipeUnit?.unit,
          unit_id: subRecipeUnit?.id,
          description: description,
          brand_id: brand?.brand_id || 0,
          company_id: company?.id,
          shop_id: shop?.id || 0,
          created_by: user?.id,
          status:
            statuses?.find(
              (filterStatus: any) =>
                filterStatus?.status_slug?.toLowerCase() == 'pending'
            )?.id || 0,
          is_verified: calory > 0 && protein > 0 && carb > 0 && fat > 0 ? 1 : 0,
          verified_by: 'Head of Culinary',
        },
        ingredients: ingredients.map((data: any) => {
          return {
            database_id: data?.database_id,
            ingredient_id: data?.id,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            ingredient_name: data?.ingredient_name,
            unit: data?.unit,
          };
        }),

        production: {
          steps: steps,
          allergens_id: allergen.map((data) => data?.id).toString(),
          prep_time: prep,
          servings: 0,
          yield: yeild,
          calorie: calory,
          protein: protein,
          carb: carb,
          fat: fat,
          recipe_note: recipeNote,
          feedback: 'Loreim ipsum is simply dummy text',
          nutrition_facts: 'Loreim ipsum is simply dummy text',
          additional_attribute: addinfo
            .map((data) => data?.attribute_name)
            .toString(),
          additional_attribute_id: addinfo?.map((data) => data?.id).toString(),
          video_url: video,
        },
      };

      try {
        let responseDialogData;
        setApiLoader(true);
        const response = await RecipeActions.createRecipe(
          params,
          imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
            ? {}
            : imageObject
        );
        setApiLoader(false);
        if (!response?.success) {
          // setMessages(response?.error?.recipe_name);
          // setVisible(false);
          // setAlerts(true);
          setErrorList(response?.error);
          setErrorStatus(true);
          responseDialogData = {
            OnClick: () => {
              setStepper(0);
            },
            type: 'error',
            messageArray: [
              'Validation Failed!',
              `${response?.error?.recipe_name}`,
            ],
          };
          ResponseDialog(responseDialogData);
        } else {
          navigate('/app/r&d-recipe'); // changed /app/r&d-subrecipe
          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/r&d-subrecipe');
          //   },
          //   type: 'success',
          //   messageArray: [` Your ${recipe}`, ` has been Created`],
          // };
          // ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    } else if (index == 3 && update) {
      setErrorList({});
      setErrorStatus(false);

      const params = {
        general: {
          recipe_name: recipe,
          recipe_type_id: menu?.id,
          category: subCategory?.sub_category,
          category_id: subCategory?.id,
          unit: subRecipeUnit?.unit,
          unit_id: subRecipeUnit?.id,
          description: description,
          brand_id: brand?.brand_id || 0,
          company_id: company?.id,
          shop_id: shop?.id || 0,
          created_by: user?.id,
          status,
          is_verified: calory > 0 && protein > 0 && carb > 0 && fat > 0 ? 1 : 0,
          verified_by: 'Head of Culinary',
        },
        ingredients: ingredients.map((data: any) => {
          return {
            database_id: data?.database_id,
            ingredient_id: data?.ingredient_id || data?.id,
            unit_id: data?.unit_id,
            quantity: data?.quantity,
            ingredient_name: data?.ingredient_name,
            unit: data?.unit,
          };
        }),

        production: {
          thumbnail:
            imageObject &&
            Object.keys(imageObject).length === 0 &&
            Object.getPrototypeOf(imageObject) === Object.prototype
              ? image?.split('/').pop()
              : '',
          steps: steps,
          allergens_id: allergen.map((data) => data?.id).toString(),
          prep_time: prep,
          servings: 0,
          yield: yeild,
          calorie: calory,
          protein: protein,
          carb: carb,
          fat: fat,
          recipe_note: recipeNote,
          feedback: 'Loreim ipsum is simply dummy text',
          nutrition_facts: 'Loreim ipsum is simply dummy text',
          additional_attribute: addinfo
            .map((data) => data?.attribute_name)
            .toString(),
          additional_attribute_id: addinfo.map((data) => data?.id).toString(),
          video_url: video,
        },
      };
      let responseDialogData;
      setApiLoader(true);
      const response = await RecipeActions.updateRecipe(
        params,
        imageObject &&
          Object.keys(imageObject).length === 0 &&
          Object.getPrototypeOf(imageObject) === Object.prototype
          ? {}
          : imageObject,
        'PUT',
        id
      );
      setApiLoader(false);
      if (!response?.success) {
        // setMessages(response?.error?.recipe_name);
        // setVisible(false);
        // setAlerts(true);
        if (response != undefined) {
          setErrorList(response?.error);
          setErrorStatus(true);
        }
        responseDialogData = {
          OnClick: () => {
            setStepper(0);
          },
          type: 'error',
          messageArray: [
            'Validation Failed!',
            `${response?.error?.recipe_name}`,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        navigate('/app/r&d-recipe');

        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/r&d-subrecipe');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${recipe}`, ` has been Updated`],
        // };
        // ResponseDialog(responseDialogData);
      }
    }
  };

  const onChangeMenu = (e: CheckboxChangeEvent, data: recipes) => {
    setMenu(data);
  };
  const onChangeRecipe = (e: CheckboxChangeEvent, data: recipes) => {
    setSubRecipe(data);
  };

  const handleChange = (data: any) => {
    setCategory(data);
  };

  const handleUnit = (data: any) => {
    setSubRecipeUnit(unit?.find((unitObj: any) => unitObj?.id == data));
  };

  const handleYeild = (data: any) => {
    setYeild(data);
  };
  const calculateNutritionValues = () => {
    let c_carb = 0;
    let c_fat = 0;
    let c_protein = 0;
    let c_calorie = 0;

    for (let index = 0; index < ingredients.length; index += 1) {
      const { quantity } = ingredients[index];
      const {
        protein_per_unit,
        carb_per_unit,
        calories_per_unit,
        fat_per_unit,
      } = ingredients[index];
      c_carb += carb_per_unit * parseFloat(quantity);
      c_fat += fat_per_unit * parseFloat(quantity);
      c_protein += protein_per_unit * parseFloat(quantity);
      c_calorie += calories_per_unit * parseFloat(quantity);
    }

    setcarb(c_carb);
    setProtein(c_protein);
    setFat(c_fat);
    setCalory(c_calorie);
  };

  const handleContinue = (index: number) => {
    if (index == 2) calculateNutritionValues();
    if (index <= 3) handleNextStepper(index);
  };

  //Step 2

  const fetchUnit = async () => {
    const data = await IngredientActions.getUnits();
    setUnit(data?.data);
  };

  const handleIngredientName = (data: string, index: number) => {
    let dataArray = ingredients;
    if (database?.label === 'Select database here') {
      dataArray[index].ingredient_name = '';
      messageApi.open({
        type: 'warning',
        content: 'Before selecting ingredients, Please Select Database!',
      });
    } else {
      dataArray[index].ingredient_name = data;
    }

    setIngredients(() => [...dataArray]);
  };

  const removeIngredientName = (index: number) => {
    let dataArray = ingredients;
    dataArray[index].ingredient_name = '';
    setIngredients(() => [...dataArray]);
  };

  const handleRemoveIngredient = (index: number) => {
    let DataArray = ingredients;
    DataArray.splice(index, 1);
    setIngredients([...DataArray]);
  };

  const handleIngredientWeight = (data: any, index: number) => {
    let dataArray = ingredients;
    if (data === 0) {
      dataArray[index].quantity = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index].quantity = data;
      setIngredients(() => [...dataArray]);
    }
  };
  const handleIngredientWeightUnit = (data: any, index: number) => {
    let dataArray = ingredients;
    dataArray[index].unit = data?.unit;
    dataArray[index].unit_id = data?.id;
    dataArray[index].multiplier = data?.multiplier;

    setIngredients(() => [...dataArray]);
  };

  const AddIngredient = () => {
    setIngredients((prevArray) => [
      ...prevArray,
      {
        ingredient_id: -1,
        unit_id: 1,
        quantity: '',
        ingredient_name: '',
        unit: 'Kg',
        multiplier: 0,
      },
    ]);
  };

  const autoFetchAllergen = (dataSource: any) => {
    let arr = dataSource.map((data: any) => {
      if (data?.ingredient_name != '' || data?.net_price != 0)
        return data.allergens_id;
    });
    arr = arr
      .filter(function (element: any) {
        return element !== undefined;
      })
      ?.filter((data: any) => data !== null);
    arr = arr.map((data: any) => data.split(',')).flat();
    let allergenId = [...new Set(arr)];
    const FetchAllergen = allergenId.map((data) => {
      let fetchObject = allergenData?.filter((element) => element?.id == data);
      return fetchObject;
    });
    setAllergensByDefault(FetchAllergen.flat());
    setAllergen(FetchAllergen.flat());
  };

  const handleIngredient = (data: any, index: number) => {
    let dataArray = ingredients;
    const checkDuplicateData = dataArray.some(
      (ingredient) => ingredient?.id === data?.id
    );

    const checkDuplicateField =
      dataArray.findIndex(
        (ingredientObject) => ingredientObject?.id === data?.id
      ) === index;

    if (!checkDuplicateField && checkDuplicateData) {
      dataArray.splice(index, 1);
      setIngredients(() => [...dataArray]);
      setMessages([
        'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
      ]);
      setVisible(false);
      setAlerts(true);
      window.scrollTo(0, 0);
      return;
    } else if (checkDuplicateField && checkDuplicateData) {
      setIngredients([...dataArray]);
    } else {
      const unitObject = unit.filter(
        (element: any) => element?.id == data?.unit_id
      );
      dataArray[index] = {
        ...data,
        quantity: '',
        multiplier: unitObject[0]['multiplier'],
      };
      autoFetchAllergen(dataArray);
      setIngredients(() => [...dataArray]);
    }
  };

  const handleChangeDatabase = (value: string | string[], data: any) => {
    setDatabase(data);
  };

  //step 3

  const handleallergenCheck = (e: CheckboxChangeEvent, data: any) => {
    const { checked } = e.target;
    if (
      allergensByDefault.find((val: any) => val?.id == data?.id) == undefined
    ) {
      if (checked) {
        setAllergen((prev) => [...prev, data]);
      } else {
        let dataArray = allergen;
        const updated = dataArray.filter((val) => val?.id !== data?.id);
        setAllergen([...updated]);
      }
    } else {
      return;
    }
  };
  const handleaddionalinfocheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    data: additionalInfo
  ) => {
    const { checked } = e.target;

    if (checked) {
      setAddinfo((prev) => [...prev, data]);
    } else {
      let dataArray = addinfo;
      const updated = dataArray.filter((val) => val?.id !== data?.id);

      setAddinfo([...updated]);
    }
  };
  const addSteps = () => {
    setSteps((prev) => [...prev, '']);
  };

  const handleChangeStep = (text: any, index: number) => {
    let DataArray = steps;
    DataArray[index] = text;
    setSteps([...DataArray]);
  };
  const handleDeleteStep = () => {
    let DataArray = steps;
    DataArray.splice(stepIndex, 1);
    setSteps([...DataArray]);
    // setStepDeleteDialog(false);
  };

  const handleStepper = (value: number) => {
    if (value > stepper) handleContinue(value);
    else setStepper(value);
  };

  return (
    <Page title="RDSubRecipeWizard">
      {contextHolder}
      <Breadcrumb
        heading="Add New R&D Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/r&d-subrecipe');
        }}
      />
      <div className="alertMessage">
        {visible && (
          <Alert
            message="Error"
            description={
              alertMessage
                ? alertMessage
                : 'Please complete all required fields to proceed'
            }
            type="error"
            showIcon
            closable
            className="wizardAlert"
            afterClose={() => setVisible(false)}
          />
        )}

        {alerts &&
          messages?.map((data: any, index: any) => (
            <Alert
              message="Error"
              description={data}
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setAlerts(false)}
              key={index}
            />
          ))}
      </div>
      <Stepper
        stepper={stepper}
        component="recipeBuilder"
        onStepperChange={handleStepper}
      />
      {
        <Spin
          size="large"
          className="loadIngredient recipeBuilderloaderHeight"
          spinning={apiLoader}
        >
          {' '}
          <div className="wizardContainer">
            {stepper === 0 ? (
              <StepOne
                component="rd-subrecipe"
                onChangeMenu={onChangeMenu}
                onChangeRecipe={onChangeRecipe}
                handleChange={handleChange}
                // handleContinue={handleContinue}
                handleBackStepper={handleBackStepper}
                parentSubCategory={parentSubCategory}
                handleSubCategory={handleSubCategory}
                subCategory={subCategory}
                handleRecipe={handleRecipe}
                handleDescription={handleDescription}
                handleImage={handleImage}
                getBase64={getBase64}
                stepper={stepper}
                image={image}
                category={category?.category_name}
                recipe={recipe}
                menu={menu}
                subRecipe={subRecipe}
                description={description}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : stepper === 1 ? (
              <StepTwo
                database={database}
                handleIndex={(data: any) => setIndex(data)}
                handleIngredient={handleIngredient}
                removeIngredientName={removeIngredientName}
                handleRemoveIngredient={handleRemoveIngredient}
                AddIngredient={AddIngredient}
                handleChangeDatabase={handleChangeDatabase}
                handleIngredientName={handleIngredientName}
                handleIngredientWeight={handleIngredientWeight}
                handleIngredientWeightUnit={handleIngredientWeightUnit}
                index={index}
                ingredients={ingredients}
                update={update}
                unit={unit}
                errorList={errorList}
                errorStatus={errorStatus}
              />
            ) : (
              <StepThree
                component={'R&D Sub Recipe'}
                handleallergenCheck={handleallergenCheck}
                handleaddionalinfocheck={handleaddionalinfocheck}
                handleDeleteStep={handleDeleteStep}
                // stepDeleteDialog={stepDeleteDialog}
                // handleDeleteDialog={(data: boolean) =>
                //   setStepDeleteDialog(data)
                // }
                handleChangeStep={handleChangeStep}
                handleIndex={(data: number) => setStepIndex(data)}
                addSteps={addSteps}
                stepIndex={stepIndex}
                steps={steps}
                allergen={allergen}
                addinfo={addinfo}
                calory={calory}
                fat={fat}
                carb={carb}
                protein={protein}
                recipeNote={recipeNote}
                video={video}
                prep={prep}
                serving={serving}
                errorList={errorList}
                errorStatus={errorStatus}
                subRecipeUnit={subRecipeUnit}
                yeild={yeild}
                handlecalory={(e: any) => setCalory(+e)}
                handlecarb={(e: any) => setcarb(+e)}
                handlefat={(e: any) => setFat(+e)}
                handleprotein={(e: any) => setProtein(+e)}
                handleNote={(e: any) => setRecipeNote(e)}
                handlevideo={(e: any) => setVideo(e)}
                handleprep={(e: any) => setPrep(e)}
                handleserving={(e: any) => setServing(e)}
                handleUnit={handleUnit}
                handleYeild={handleYeild}
                unit={unit}
              />
            )}

            <div
              className={`butonGroup ${
                stepper == 1 && 'wizardSubContainer-wrapper'
              }`}
            >
              <div
                className="wizard-button"
                onClick={() => handleBackStepper(stepper - 1)}
              >
                Back
              </div>
              <div
                className={`continue wizard-button`}
                onClick={() => handleContinue(stepper + 1)}
              >
                Continue
              </div>
            </div>
          </div>
        </Spin>
      }
    </Page>
  );
};

export default RDSubRecipeWizard;
