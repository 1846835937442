import React from 'react';
import { Button } from 'antd';

type ButtonProp = {
  type?:
    | 'link'
    | 'text'
    | 'ghost'
    | 'default'
    | 'primary'
    | 'dashed'
    | undefined;
  handleButtonClick?: () => void;
  shape?: 'default' | 'circle' | 'round';
  size?: 'large' | 'middle' | 'small';
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  text: string;
  focus?: boolean | undefined;
  title?: string;
};

const Buttons = (props: ButtonProp) => {
  return (
    <Button
      onClick={props.handleButtonClick}
      className={props.className}
      loading={props.loading}
      disabled={props.disabled}
      shape={props.shape}
      icon={props.icon}
      size={props.size}
      type={props.type}
      autoFocus={props.focus}
      title={props.title}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && props.handleButtonClick) {
          props.handleButtonClick();
        }
      }}
    >
      {props.text}
    </Button>
  );
};

export default Buttons;
