import React, { useState } from 'react';
import { Modal, Select, Spin } from 'antd';
import './style.scss';
import { Button, ResponseDialog } from '../../../components';
import BranchActions from '../../../redux/middleware/branches';

type Props = {
  open: boolean;
  handleClose: any;
  branch_id: any;
};

const TaggingDialog = (props: Props) => {
  let { open, handleClose, branch_id } = props;
  const [selectedBranch, setSelectedBranch] = useState(null as any);
  const [list, setList] = useState([]);

  React.useEffect(() => {
    if (open) {
      setSelectedBranch(null);
      getFoodicsBranches();
    }
  }, [branch_id, open]);

  const getFoodicsBranches = async () => {
    const response = await BranchActions.getFoodicsBranches();
    const items = response.data;
    setList(items);
  };

  const handleChange = (value: string) => {
    setSelectedBranch(value);
  };

  const resetFormOnClose = () => {
    !loader && handleClose();
  };

  const onSubmit = async () => {
    try {
      setLoader(true);
      const response = await BranchActions.setFTBranch(
        branch_id,
        selectedBranch
      );
      setLoader(false);
      const { success, message } = response;
      if (success) {
        resetFormOnClose();
      } else {
        const responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Tagging Failed!', `${message}`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
      const responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          'Tagging Failed!',
          `Somthing went wrong, please try later`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [loader, setLoader] = useState(false);

  return (
    <Modal
      title={<div className="title font">Foodics Tagging</div>}
      centered
      open={open}
      onCancel={resetFormOnClose}
      width={480}
      footer={null}
      className="DialogWrapper taggingContainer"
    >
      <Spin spinning={loader} size="large">
        <div>
          <div className="wizardSubContainer">
            <div className="wizardLabel">
              {'Select Foodics Branch'} <span className="sterik">*</span>
            </div>
            <Select
              size={'large'}
              placeholder={'Select Branch'}
              onChange={handleChange}
              value={selectedBranch?.name}
              style={{ width: '100%' }}
            >
              {list?.map((data: any, index: number) => (
                <Select.Option value={data?.id} key={index}>
                  {data.name}
                </Select.Option>
              ))}
            </Select>
            <div className="taggingBtnContainer">
              <Button
                text={'Submit'}
                className="theme-button recipeBtn"
                handleButtonClick={onSubmit}
                shape="round"
                size="middle"
                disabled={selectedBranch == null}
              />
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default TaggingDialog;
