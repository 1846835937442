import { baseURL } from '../../config/constant';
import Utils from '../utils';

const bulkUploadCashPurchase = async (file, tag, vendor) => {
  const brand = await Utils.getCurrentBrand();
  const shop = await Utils.getCurrentShop();
  const user = await Utils.getCurrentUser();
  const formData = new FormData();
  formData.set('csv', file);
  formData.set('brand_id', brand?.brand_id);
  formData.set('shop_id', shop?.shop_id);
  if (user?.roles[0]?.id === 3) {
    formData.set('tag', tag);
    formData.set('vendor_name', vendor);
  } else {
    formData.set('tag', 'brand');
  }
  formData.set('request_type_id', 4);
  formData.set('request_status_id', 8);
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/bulk_upload_supply`,
      headers: {
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'POST',
      data: formData,
    };
    const apiResponse = await Utils.CallApi(apiOptions);

    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const UploadCashPurchase = {
  bulkUploadCashPurchase,
};

export default UploadCashPurchase;
