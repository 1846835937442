import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb } from '../../components';
import './styles.scss';
import Wizard from './wizard';

const SubscriptionWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;

  return (
    <React.Fragment>
      <Breadcrumb
        heading={'Add New Subscription'}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/company');
        }}
      />
      <Wizard id={id} update={update} />
    </React.Fragment>
  );
};

export default SubscriptionWizard;
