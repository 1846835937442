import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components';
import Utils from '../../redux/utils';
import { message } from 'antd';
import LeadGenerationApi from '../../redux/middleware/leadGeneration';
import './comingsoon.scss';

const ComingSoon = () => {
  const location = useLocation();
  const permissions = Utils.getCurrentMenu();
  const [messageApi, contextHolder] = message.useMessage();

  const contactUs = async () => {
    const user = await Utils.getCurrentUser();
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const defaultPerms = await Utils.getCurrentUserPermission();
    const data = await permissions?.filter((permission: any) => {
      return (
        permission?.childRouteName?.filter((child: any) => {
          return (
            `/${child?.path?.toLowerCase()}` ==
            location.pathname?.toString()?.toLowerCase()
          );
        })?.length > 0
      );
    });
    const getRouteObject =
      data?.length &&
      data[0]?.childRouteName?.find(
        (child: any) =>
          `/${child?.path?.toLowerCase()}` ==
          location.pathname?.toString()?.toLowerCase()
      );
    const screen = defaultPerms?.find(
      (data: any) => data?.permission == getRouteObject?.routeName
    );
    const key = 'updatable';
    const payload = {
      company_id: company?.id,
      brand_id: brand?.brand_id || 0,
      shop_id: shop?.id || 0,
      created_by: user?.id,
      screen_id: screen?.permission_id,
      description: screen?.permission,
    };

    try {
      messageApi.open({
        key,
        type: 'loading',
        content: 'Loading...',
      });
      const response = await LeadGenerationApi.createLead(payload);
      if (response?.success) {
        messageApi.open({
          key,
          type: 'success',
          content: response?.message,
          duration: 2,
        });
      } else {
        messageApi.open({
          key,
          type: 'error',
          content: 'The given data was invalid.',
          duration: 2,
        });
      }
    } catch (e) {
      messageApi.open({
        key,
        type: 'error',
        content: 'Something Went Wrong!',
        duration: 1,
      });
    }
  };
  return (
    <div
      className="comingSoonDialogContainer"
      style={{ height: window.innerHeight / 1.05 }}
    >
      {/* <img
        src={require('../../assets/icons/recipeLoader.gif')}
        alt="loading..."
        className=" gifWidth"
      /> */}
      {contextHolder}
      <Button
        text="Contact Us"
        className="theme-button recipeBtn buttonTextSize"
        handleButtonClick={() => contactUs()}
        shape="round"
        size="large"
      />
      {/* <h1 className={`messageText comingSoon typewriter`}>
        Get in touch with us at info@chefadmin.com and Show your Interest. Thank
        you.
      </h1> */}
    </div>
  );
};

export default ComingSoon;
