/* eslint-disable no-unused-vars */
import { baseURL } from '../../config/constant';
import Utils from '../utils';

const fetchDashboardInfo = async (
  brand_id,
  shop_id,
  start_date,
  end_date,
  duration,
  tag = ''
) => {
  const company = await Utils.getCurrentCompany();
  const user = await Utils.getCurrentUser();
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/dashboard?company_id=${company?.id}&role_id=${user?.roles[0]?.id}&brand_id=${brand_id}&branch_id=${shop_id}&tag=${tag}&duration=${duration}&start_date=${start_date}&end_date=${end_date}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const fetchCompanyDetails = async () => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/company_details`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const DashBoardActions = {
  fetchDashboardInfo,
  fetchCompanyDetails,
};

export default DashBoardActions;
