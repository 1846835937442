import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Breadcrumb, VariationStep } from '../../components';
import VariationActions from '../../redux/middleware/variation';
// import './variationWizard.scss';
import Utils from '../../redux/utils';
import { Alert, Spin } from 'antd';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const sizesWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'variationPage',
  });
  const { update, id } = state;
  const company = Utils.getCurrentCompany();
  const [category, setCategory] = React.useState<any>({});
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [variation, setVariation] = React.useState('');
  const [variationType, setVariationType] = React.useState('');
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  useEffect(() => {
    setVariationType(state?.id);
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/r&d-subrecipe');
    }
  }, [currentBrand, currentShop]);

  const fetchDataById = async (id: any) => {
    const data = await VariationActions.getDetailvariations(id);
    setLoader(false);
    if (data) {
      setCategory(data?.data?.variation_type_id);
      setVariation(data?.data?.name);
    }
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, [update]);

  const handleRecipe = (data: string) => {
    setVariation(data);
  };

  const handleVariationChange = (data: any) => {
    setCategory(data);
  };

  const handleContinue = async () => {
    setErrorList({});
    setErrorStatus(false);
    let user = await Utils.getCurrentUser();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    if (
      // (category &&
      //   Object.keys(category).length === 0 &&
      //   Object.getPrototypeOf(category) === Object.prototype) ||
      variation == ''
    ) {
      setVisible(true);
      window.scrollTo(0, 0);
    } else {
      setVisible(false);

      setLoader(true);
      if (update === true) {
        const param = {
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          variation_type_id: 1,
          name: variation,
          created_by: user?.id,
        };

        const response = await VariationActions.updateVariation(param, id);
        setLoader(false);
        if (!response?.success) {
          if (response != undefined) {
            setErrorList(response?.error);
            setErrorStatus(true);
          }
        } else {
          navigate('/app/sizes');

          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/variations');
          //   },
          //   type: 'success',
          //   messageArray: [` Your ${variation}- has been Updated`],
          // };
          // ResponseDialog(responseDialogData);
        }
      } else {
        const param = {
          company_id: company?.id,
          brand_id: brand?.brand_id,
          shop_id: shop?.id || 0,
          variation_type_id: 1,
          name: variation,
          created_by: user?.id,
        };

        try {
          const response = await VariationActions.createVariation(param);
          setLoader(false);
          if (!response?.success) {
            if (response != undefined) {
              setErrorList(response?.error);
              setErrorStatus(true);
            }
          } else {
            navigate('/app/sizes');

            // responseDialogData = {
            //   OnClick: () => {
            //     navigate('/app/variations');
            //   },
            //   type: 'success',
            //   messageArray: [`Your ${variation} Variation has been Created`],
            // };
            // ResponseDialog(responseDialogData);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={t('add_new_variation')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/sizes');
        }}
      />

      {visible && (
        <Alert
          message="Error"
          description="Please complete all required fields to proceed"
          type="error"
          showIcon
          closable
          className="wizardAlert"
          afterClose={() => setVisible(false)}
        />
      )}

      <Spin spinning={loader} size="large">
        <Page title="Add New Size Variation">
          <div className="wizardContainer">
            <VariationStep
              handleVariationChange={handleVariationChange}
              variation={variation}
              type={category}
              handleRecipe={handleRecipe}
              errorList={errorList}
              errorStatus={errorStatus}
              VariationTypeId={variationType}
            />
            <div className="butonGroup">
              <div
                className="wizard-button"
                onClick={() => navigate('/app/sizes')}
              >
                {t('back')}
              </div>
              <div className="continue wizard-button" onClick={handleContinue}>
                {t('continue')}{' '}
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default sizesWizard;
