import React, { useEffect } from 'react';
// import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Row, Form, Input, notification } from 'antd';
import menuItems from '../../components/menu';
import {
  Button,
  SignUpModal,
  // FacebookLoginButton,
  // GoogleLoginButton,
  LoginWithEmailModal,
} from '../../components';
// import Gmail from '../../assets/images/Gmail.jpg';
// import SideImage from '../../assets/images/logo.png';
// import SideImage from '../../assets/svg/authSideImage.svg';
// import LoginScreen from '../../assets/images/authSideImage2.png';
import Logo from '../../assets/svg/logo.svg';
import { useDispatch } from 'react-redux';
import {
  loginFailed,
  loginPending,
  loginSuccess,
} from '../../redux/reducers/authSlice';
import './styles.scss';
import AuthActions from '../../redux/middleware/auth';
import Utils from '../../redux/utils';
import { LoginResponse } from './types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gapi } from 'gapi-script';
import { Footer } from 'antd/lib/layout/layout';
// import type { NotificationPlacement } from 'antd/es/notification';

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isSignup = searchParams.get('signup');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [verify, setVerify] = React.useState(false);
  const [verifyForMessage, setVerifyForMessage] = React.useState(false);
  const [activeForEmail, setActiveForEmail] = React.useState(false);
  const [activeForVerify, setActiveForVerify] = React.useState(false);
  const [activeForPassword, setActiveForPassword] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogForLogin, setOpenDialogForLogin] = React.useState(
    isSignup ? true : false
  );
  const [message, setMessage] = React.useState('');
  const [isSignupSuccess, setSignupSuccess] = React.useState(false);
  // const clientId =
  //   '830095529175-3qiln6vo73sgc5ljh2oj6nnugbv25r06.apps.googleusercontent.com';

  // const clientId =
  //   '126383349172-p2dblgmrueis9q97gv4drakcssg9liid.apps.googleusercontent.com';

  const clientId =
    '126383349172-5ft17el2s3gr54afeqal2p01novqq1sc.apps.googleusercontent.com';

  React.useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  /* const [description] = React.useState(
    ". "
  ); */

  // const getDescription = () => (
  //   <>
  //     <p>{'Welcome to ChefAdmin!'}</p>
  //     <p>{`We're excited to have you here. Our platform is designed to simplify your operations and help you manage your business more efficiently. With ChefAdmin, you can track your recipes, manage inventory, monitor costs, and more.`}</p>
  //     <p>{`We're confident that you'll find our platform intuitive and easy to use. Thank you for choosing ChefAdmin, and we look forward to supporting your business!`}</p>
  //   </>
  // );

  // const openNotification = (
  //   placement: NotificationPlacement,
  //   type: string,
  //   errorMessage?: any
  // ) => {
  //   if (type === 'success') {
  //     api.success({
  //       message: `Login Successful`,
  //       placement,
  //       icon: <CheckCircleFilled className="successIcon" />,
  //     });
  //   }
  //   if (type === 'error') {
  //     let message;
  //     if (errorMessage?.email) {
  //       message = errorMessage?.email;
  //     }
  //     if (errorMessage?.password) {
  //       message = errorMessage?.password;
  //     }
  //     api.error({
  //       message,
  //       placement,
  //       icon: <ExclamationCircleFilled className="failedIcon" />,
  //     });
  //   }
  // };

  const onSubmit = async (e?: any) => {
    if (e) e.preventDefault();
    setActiveForEmail(false);
    setActiveForPassword(false);
    if (!email || !password) {
      return;
    }
    dispatch(loginPending());
    try {
      const apiResponse: any = await AuthActions.UserLogin({ email, password });
      if (apiResponse.success) {
        const { data, token } = apiResponse as LoginResponse;
        // openNotification('bottomRight', 'success');
        dispatch(loginSuccess());
        Utils.setCurrentUser(data);
        Utils.setUserRole(data.roles[0]);
        Utils.setUserSubscriptions(data.subscription);
        const menuPermission = data.permissions;

        const MenuItems: any = [];
        for (let index = 0; index < menuItems.length; index += 1) {
          const filterItem = menuPermission.find(
            (x: any) =>
              x.parent_screen === menuItems[index].routeName.substring(0, -1) ||
              x.parent_screen.includes(menuItems[index].routeName)
          );
          if (filterItem) {
            const filterNav = menuItems[index];
            if (!filterNav.childRouteName) {
              MenuItems.push(filterNav);
            } else {
              const subItems = [];
              for (let i = 0; i < filterNav.childRouteName.length; i += 1) {
                const { routeName } = filterNav.childRouteName[i];
                const filterSubItem = menuPermission.find(
                  (x: any) => x.permission === routeName
                );
                if (filterSubItem) {
                  subItems.push(filterNav.childRouteName[i]);
                }
              }
              MenuItems.push({ ...filterNav, subs: subItems });
            }
          }
        }

        Utils.setCompanyList(data?.company_tree);
        Utils.setCurrentMenu(MenuItems);
        Utils.setCurrentUserPermission(data.permissions);
        Utils.setCurrentToken(token?.access_token);

        Utils.setCurrentCompany(data.company_tree[0]);
        if (data.company_tree && data.company_tree[0]) {
          if (
            data.company_tree[0].brands &&
            data.company_tree[0].brands.length
          ) {
            Utils.setBrandList(data.company_tree[0].brands);
            Utils.setCurrentBrand(data.company_tree[0].brands[0]);
          }
          if (
            // data.company_tree[0].brands &&
            data?.company_tree[0].shops &&
            data?.company_tree[0].shops.length
          ) {
            Utils.setShopList(data.company_tree[0]?.shops);
            Utils.setCurrentShop(data.company_tree[0].shops[0]);
          }
        }

        navigate('/app');
      } else {
        const payload = {
          message: apiResponse.message,
        };
        if (apiResponse?.error && apiResponse?.error?.password) {
          setActiveForPassword(true);
          setMessage(apiResponse?.error?.password);
        } else if (apiResponse?.error && apiResponse?.error?.subscription) {
          setActiveForPassword(true);
          setMessage(apiResponse?.error?.subscription);
        } else if (apiResponse?.error && apiResponse?.error?.email) {
          setActiveForEmail(true);
          setMessage(apiResponse?.error?.email);
        } else {
          setVerify(true);
          setVerifyForMessage(true);
          setMessage(apiResponse?.error?.email);
        }

        // openNotification('bottomRight', 'error', apiResponse?.error);

        dispatch(loginFailed(payload));
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

  const onVerifyEmail = async () => {
    if (!email) {
      return false;
    }
    try {
      const apiResponse = await AuthActions.VerifyEmail(email);
      if (apiResponse?.success) {
        setActiveForVerify(true);
        setVerifyForMessage(false);
        setMessage(apiResponse?.message);
      } else {
        setMessage('Failed to verify email. Please Try Again ');
      }
    } catch (error) {
      dispatch(loginFailed(error));
    }
  };

  const handleForgotPasswordNavigation = () => {
    navigate('forgot-password');
  };

  // eslint-disable-next-line no-unused-vars
  const handleSignUp = () => {
    setOpenDialog(true);
  };

  const handlemodal = () => {
    setOpenDialog(false);
    // pageData();
  };

  const handlemodalforlogin = () => {
    setOpenDialogForLogin(false);
  };
  const handleLoginWithEmail = () => {
    setOpenDialogForLogin(true);
  };

  const handleLogin = () => {
    onSubmit();
  };

  const onSignupSuccess = (status: boolean, email: string) => {
    setSignupSuccess(status);
    setEmail(email);
  };

  return (
    <React.Fragment>
      {openDialog ? (
        <SignUpModal
          handleDialog={handlemodal}
          open={openDialog}
          modalData={[]}
        />
      ) : null}
      {openDialogForLogin ? (
        <LoginWithEmailModal
          handleDialog={handlemodalforlogin}
          open={openDialogForLogin}
          onCheckVerify={setVerify}
          onSignupSuccess={onSignupSuccess}
          modalData={[]}
          component="Email-Auth"
        />
      ) : null}
      {contextHolder}
      <Row className="authContainer" style={{ height: window.innerHeight }}>
        <Col lg={7} className="leftSide">
          <div className="sideLogoContainer">
            <img src={Logo} height={50} alt={'logo'} className="sideLogo" />
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="email"
              // rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <label className="b2 upload-label">
                Email <span className="upload-span">*</span>
              </label>
              <Input
                className="login-input"
                type="email"
                placeholder="Enter email here"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
                disabled={isSignupSuccess}
              />
              {activeForEmail ? (
                <div className="alignment error">
                  <label className="b upload-label ">{message}</label>
                </div>
              ) : null}

              {activeForVerify ? (
                <div className="alignment successForEmail">
                  <label className="b upload-label ">{message}</label>
                </div>
              ) : null}

              {verifyForMessage ? (
                <div className="spacing">
                  <label className="b2 upload-label error">
                    Your email is not verified. Please re-verify email.
                  </label>
                </div>
              ) : null}
            </Form.Item>
            {!verify ? (
              <Form.Item
                name="password"
                // rules={[
                //   { required: true, message: 'Please input your Password!' },
                // ]}
              >
                <label className="b2 upload-label">
                  Password <span className="upload-span">*</span>
                </label>
                <Input.Password
                  className="login-input"
                  type="password"
                  placeholder="Enter password here"
                  visibilityToggle={{
                    visible: passwordVisible,
                    onVisibleChange: setPasswordVisible,
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit(e);
                    }
                  }}
                />
                {activeForPassword ? (
                  <div className="alignment error">
                    <label className="b upload-label ">{message}</label>
                  </div>
                ) : null}
              </Form.Item>
            ) : null}
            {verify ? null : (
              <Form.Item className="textRight">
                <a
                  className="b2"
                  href=""
                  onClick={handleForgotPasswordNavigation}
                >
                  Forgot password
                </a>
              </Form.Item>
            )}
            {verify ? null : (
              <Form.Item>
                {/* <button
                  // size="large"
                  // shape="round"
                  // text="Login"
                  onClick={(e) => handleLogin(e)}
                  // type="primary"
                  className="theme-button login-btn"
                  // focus
                >
                  Login
            </button> */}
                <Button
                  size="large"
                  shape="round"
                  text="Login"
                  handleButtonClick={handleLogin}
                  type="primary"
                  className="theme-button login-btn"
                />
              </Form.Item>
            )}
            {verify ? (
              <Form.Item>
                <Button
                  size="large"
                  shape="round"
                  text={!isSignupSuccess ? 'Reverify Email' : 'Resend Email'}
                  handleButtonClick={onVerifyEmail}
                  type="primary"
                  className="theme-button login-btn"
                />
              </Form.Item>
            ) : null}

            {/* verify ? null : (
              <Form.Item className="textRight">
                <div className="b2" onClick={handleSignUp}>
                  Dont have an account?{' '}
                  <span>
                    <a>Click here</a>
                  </span>
                </div>
              </Form.Item>
            ) */}
            {isSignupSuccess ? (
              <>
                <p className="text-center">
                  Verification email is sent to your mailing address. Please
                  verify your identity.
                </p>
                <p className="text-center">
                  Click on <b>Resend email</b> button manually.If not received
                </p>
              </>
            ) : (
              <>
                <div className="or-seperator">
                  <i>or</i>
                </div>
                {/* <p className="text-center">Login with your social media account</p> 
            <p className="text-center">Sign-up using</p> */}
                <div className="butStyling">
                  <Button
                    size="large"
                    text="Sign up"
                    handleButtonClick={handleLoginWithEmail}
                    type="primary"
                    className="theme-button sign-up-btn"
                  />
                  {/*<div>
                <GoogleLoginButton />
              </div>
              <div>
                <FacebookLoginButton />
              </div> 
              <div>
                 <button onClick={handleLoginWithEmail} className="email-btn">
                  <img src={Gmail} width={20} height={20} />
                  Email
                  </button> 
              </div> */}
                </div>
              </>
            )}

            <Footer className="login_Footer">
              <a href="/privacy-policy" target={'_blank'}>
                Privacy Policy
              </a>
              <a href="/data-integrity" target={'_blank'}>
                Data Integrity
              </a>
            </Footer>
          </Form>
        </Col>
        {/* <Col lg={16} className="rightSide">
          <div className="login-right-side">
            <div className="title-description-container">
              <div className="login-welcome-title">Welcome</div>
              <div className="login-right-side-description">
                {getDescription()}
              </div>
              <div className="loginsideImage">
                <img src={LoginScreen} alt="sideImage" />
              </div>
            </div>
          </div>
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default Login;
