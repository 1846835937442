import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page, Breadcrumb } from '../../components';
import SupplierActions from '../../redux/middleware/supplier';
import BuyingUnitActions from '../../redux/middleware/buyingUnit';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import './buyingUnitWizard.scss';
import Utils from '../../redux/utils';
import { Input, Spin, Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { checkAuthentication } from '../../helper/checkAuthentication';

const BuyingUnitWizard = () => {
  // eslint-disable-next-line no-unused-vars
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);
  const [apiLoader, setApiLoader] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [supplierId, setSupplierId] = useState<any>();
  const [errorStatus, setErrorStatus] = React.useState(false);
  const { update, id } = state;
  const [recipe, setRecipe] = React.useState('');
  const [unit, setUnit] = useState<any>([]);
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: 0, key: 0 },
  ]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'buyingUnitPage',
  });

  const fetchDataById = async (id: any) => {
    const data = await BuyingUnitActions.getBuyingUnitById(id);
    if (data) {
      setLoader(false);
    }
    setRecipe(data?.data?.buying_unit);
    setSupplierId(data?.data?.supplier_id);
    const mappedUnits = data?.data?.storage_unit
      ?.map((unit: any) => unit?.storage_unit)
      .join(', ');
    setUnit({
      label: mappedUnits?.split(','),
    });
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    getSupplier();
    fetchUnits();
    if (update) {
      setLoader(true);
      fetchDataById(id);
    }
  }, []);

  const handleRecipe = (data: string) => {
    setRecipe(data);
  };

  const getSupplier = async () => {
    const brand = Utils.getCurrentBrand();
    const supplierData = await SupplierActions.getSupplier(
      500,
      1,
      brand?.brand_id,
      ''
    );
    setSuppliers(
      supplierData?.data?.items?.map((data: any) => {
        return {
          label: data?.supplier_name,
          value: data?.id,
        };
      })
    );
  };

  const handleSupplier = (value: any) => {
    setSupplierId(value);
    // const data: any = suppliers.filter((data: any) => {
    //   return data.value === value;
    // });
    //   setBrandId(data.flat());
  };

  const handleChangeUOM = (value: string | string[]) => {
    setUnit(value);
  };

  const handleContinue = async () => {
    const company = Utils.getCurrentCompany();
    setErrorList({});
    setErrorStatus(false);
    if (update === true) {
      const param = {
        company_id: company?.id,
        buying_unit: recipe,
        supplier_id: supplierId,
        storage_unit: unit,
      };
      // let responseDialogData;
      setApiLoader(true);

      const response = await BuyingUnitActions.updateBuyingUnit(id, param);

      setApiLoader(false);

      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        navigate('/app/buyingUnit');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/buyingUnit');
        //   },
        //   type: 'success',
        //   messageArray: [` Your ${recipe}`, ` has been Updated`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } else {
      const param = {
        company_id: company?.id,
        buying_unit: recipe,
        supplier_id: supplierId,
        storage_unit: unit,
      };
      // let responseDialogData;
      setApiLoader(true);

      try {
        const response = await BuyingUnitActions.addBuyingUnit(param);

        setApiLoader(false);

        if (!response?.success) {
          setErrorList(response?.error);
          setErrorStatus(true);
        } else {
          navigate('/app/buyingUnit');
          // responseDialogData = {
          //   OnClick: () => {
          //     navigate('/app/buyingUnit');
          //   },
          //   type: 'success',
          //   messageArray: [` Your ${recipe} buying unit`, ` has been Created`],
          // };
          // ResponseDialog(responseDialogData);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchUnits = async () => {
    const data = await SubRecipeActions.getStorageUnits();
    const option: SelectProps['options'] = data?.data?.map((unit: any) => {
      return {
        value: unit.id,
        label: unit.storage_unit,
        key: unit.id,
      };
    });
    setOption(option);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={t('add_new_buying')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/buyingUnit');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Buying Unit">
          {/* <div className="alertMessage">
          {visible && (
            <Alert
              message="Error"
              description="Please Complete required fields!"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}

          {error &&
            errorMessage?.map((data: any, index: any) => (
              <Alert
                message="Error"
                description={data}
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setError(false)}
                key={index}
              />
            ))}
        </div> */}
          {loader ? (
            <Spin
              spinning={loader}
              size="large"
              className="loadIngredient recipeBuilderloaderHeight"
            />
          ) : (
            <div className="wizardContainer">
              <div className="ingredientCategoryContainerWidth">
                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    {t('supplier_label')} <span className="sterik">*</span>
                  </div>
                  {/* <Input
                    placeholder="Enter Variation Name"
                    size="large"
                    value={recipe}
                    onChange={(e) => {
                      handleRecipe(e.target.value);
                    }}
                    required
                    className={errorList['name']?.length > 0 ? 'borderRed' : ''}
                  /> */}
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('select_placeholder')}
                    value={supplierId}
                    onChange={handleSupplier}
                    options={suppliers}
                  />

                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['name']}
                    </div>
                  )}
                </div>
                <div className="wizardSubContainer wrapper">
                  <div className="wizardLabel">
                    {t('buying_unit_label')} <span className="sterik">*</span>
                  </div>
                  <Input
                    placeholder={t('input_placeholder')}
                    size="large"
                    value={recipe}
                    onChange={(e) => {
                      handleRecipe(e.target.value);
                    }}
                    required
                    className={
                      errorList['buying_unit']?.length > 0 ? 'borderRed' : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['buying_unit']}
                    </div>
                  )}
                </div>
                <div className="wizardSubContainer">
                  <div className="wizardLabel">
                    {t('storage_unit_label')} <span className="sterik">*</span>
                  </div>
                  <Select
                    mode="multiple"
                    size={'large'}
                    placeholder="Please Select Storage Unit"
                    onChange={handleChangeUOM}
                    style={{ width: '100%' }}
                    options={option}
                    value={unit?.label}
                    className={
                      errorList['unit_id']?.length > 0
                        ? 'createIngredientBorderRed'
                        : ''
                    }
                  />
                  {errorStatus && (
                    <div className="subRecipeErrorMessages">
                      {errorList['unit_id']}
                    </div>
                  )}
                </div>
              </div>
              <div className="butonGroup">
                <div
                  className="wizard-button"
                  onClick={() => navigate('/app/buyingUnit')}
                >
                  {t('back')}{' '}
                </div>
                <div
                  className="continue wizard-button"
                  onClick={handleContinue}
                >
                  {t('continue')}
                </div>
              </div>
            </div>
          )}
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default BuyingUnitWizard;
