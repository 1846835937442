/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, PaginationProps, Spin, Select, Tooltip, Button } from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  // Button,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  // ResponseDialog,
  // IngendientListDialog,
} from '../../components';
import RdMatchingApi from '../../redux/middleware/rdmatching';
import IngredientActions from '../../redux/middleware/ingredient';
// import moment from 'moment';
import { useSelector } from 'react-redux';
// import Edit from '../../assets/images/Edit.svg';
// import UpDownArrow from '../../assets/images/UpDownArrow.png';
// import More from '../../assets/images/More.png';
// import Eye from '../../assets/images/Eye.svg';
import './ingredientlist.scss';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const IngredientList = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();

  const [current, setCurrent] = useState(1);
  const [taggedImageData, setTaggedImageData] = useState([{}]);
  // const [dialog, setDialog] = useState(false);
  const [untaggedImageData, setUntaggedImageData] = useState([]);
  const [untaggedRecipeData, setUntaggedRecipeData] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalUntaggedImage, setTotalUntaggedImage] = useState(0);
  const [totalTaggedImage, setTotalTaggedImage] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [check, setCheck] = useState(true);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const PageDataLength = [6, 10, 20, 50];
  const [recordIndex, setRecordIndex] = useState(-1);
  const [loader, setLoader] = useState(false);
  const [ingredient, setIngredient] = useState<any>('');
  const [matchedIngredientId, setMatchedIngredientId] = useState<any>();
  const [error, setError] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [errorList, setErrorList] = useState<any>({});
  const [search, setSearch] = useState('');
  const { currentCompany } = useSelector((state: any) => state.Auth);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const { currentShop } = useSelector((state: any) => state.Auth);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Upload Images'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
      fetchIngredientDataCount();
    }
  }, [dataLength, current, check, currentCompany, currentBrand]);
  useEffect(() => {
    setCurrent(1);
    getUntaggedRecipes();
  }, [check]);

  const fetchIngredientDataCount = async () => {
    const { data, success } = await RdMatchingApi.getMAtchUnMatch(
      dataLength,
      check ? 'no' : 'yes',
      current,
      search
    );
    if (success)
      check
        ? setTotalUntaggedImage(data?.pagination?.total)
        : setTotalTaggedImage(data?.pagination?.total);
    else check ? setTotalUntaggedImage(0) : setTotalTaggedImage(0);
  };

  const getUntaggedRecipes = async () => {
    const untaggedRecipes = await RdMatchingApi.getUnmatchdropdown();
    if (untaggedRecipes == undefined || !untaggedRecipes?.success)
      setUntaggedRecipeData([]);
    else {
      // const dataArray =
      //   [
      //     { ingredient_name: 'None', ingredient_category: '', id: 0 },
      //     ...untaggedRecipes.data.items,
      //   ] || [];
      setUntaggedRecipeData([
        { ingredient_name: 'None', ingredient_category: '', id: 0 },
        ...untaggedRecipes.data.items,
      ]);
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  // const items: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  //   {
  //     key: '2',
  //     label: <div className="dotOption">Edit</div>,
  //     icon: <img src={Edit} width={15} />,
  //   },
  // ];
  // const itemsForUntagged: MenuProps['items'] = [
  //   {
  //     key: '1',
  //     label: <div className="dotOption">Details</div>,
  //     icon: <img src={Eye} width={15} className="iconDropdown" />,
  //   },
  // ];

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setCurrent(1);
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   switch (option) {
  //     case 1:
  //       // handleDialogData();
  //       setDialog(true);
  //       break;
  //     case 2:
  //       // setUpdate(true);
  //       // setOpenIngredientDialog(true);
  //       break;
  //     default:
  //   }
  // };

  const taggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D Ingredients</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="ingredientlist-column-font">
            {record?.ingredient_name?.length > 22 ? (
              <Tooltip
                title={
                  <div>
                    <div className="tooltipHead">Name</div>
                    <div className="tootipText">{record?.ingredient_name}</div>
                  </div>
                }
              >
                {record?.ingredient_name?.substring(0, 22)}
                {record?.ingredient_name?.length > 22 ? <span>...</span> : null}
              </Tooltip>
            ) : (
              record?.ingredient_name
            )}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D Category</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing ingredientlist-column-font">
          {record?.category_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Name</div>
                  <div className="tootipText">{record?.category_name}</div>
                </div>
              }
            >
              {record?.category_name?.substring(0, 22)}
              {record?.category_name?.length > 22 ? <span>...</span> : null}
            </Tooltip>
          ) : (
            record?.category_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Source</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing ingredientlist-column-font">
          {record?.source}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Inventory Ingredient</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing ingredientlist-column-font">
          {recordIndex === record?.id ? (
            <Select
              size={'large'}
              showSearch
              placeholder={'-'}
              value={record?.matched_ingredient_name}
              disabled={recordIndex == record?.id ? false : true}
              onChange={(e) => {
                const getIngredient = untaggedRecipeData.find(
                  (data: any) => data?.id == e
                ) || { ingredient_name: '' };
                setIngredient(getIngredient);
                record.matched_ingredient_name = getIngredient?.ingredient_name;
                record.matched_ingredient_id = e;
              }}
              style={{ width: 200 }}
              optionFilterProp="children"
            >
              {untaggedRecipeData?.map((data: any, menuIndex: number) => (
                <Select.Option value={data?.id} key={menuIndex}>
                  {data?.ingredient_name}
                </Select.Option>
              ))}
            </Select>
          ) : record?.matched_ingredient_name?.length > 22 ? (
            <Tooltip
              title={
                <div>
                  <div className="tooltipHead">Name</div>
                  <div className="tootipText">
                    {record?.matched_ingredient_name}
                  </div>
                </div>
              }
            >
              {record?.matched_ingredient_name?.substring(0, 22)}
              {record?.matched_ingredient_name?.length > 22 ? (
                <span>...</span>
              ) : null}
            </Tooltip>
          ) : (
            record?.matched_ingredient_name
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Category</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing ingredientlist-column-font">
          {recordIndex == record?.id ? (
            <Tooltip
              title={
                <div className="font">
                  {ingredient?.ingredient_category ||
                    ingredient?.matched_ingredient_category}
                </div>
              }
            >
              {
                // ingredient?.ingredient_category != undefined &&
                ingredient?.ingredient_category?.length > 12
                  ? ingredient?.ingredient_category?.substring(0, 10) + '...'
                  : ingredient?.ingredient_category
                // ||
                //   (ingredient?.matched_ingredient_category != undefined &&
                //     ingredient?.matched_ingredient_category?.length > 12)
                // ? ingredient?.matched_ingredient_category?.substring(0, 10) +
                //   '...'
                // : ingredient?.matched_ingredient_category
              }
            </Tooltip>
          ) : record?.matched_ingredient_category?.length > 12 ? (
            <Tooltip
              title={
                <div className="font">
                  {record?.matched_ingredient_category}
                </div>
              }
            >
              {record?.matched_ingredient_category?.substring(0, 10) + '...'}
            </Tooltip>
          ) : (
            record?.matched_ingredient_category
          )}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],

      render: (_: any, record: any) =>
        recordIndex == record?.id ? (
          <div
            className={`${
              loader ? 'loader-enable-supplier' : 'continue'
            } Supplier-ingredient-editButton`}
            onClick={() => {
              handleMenu(record?.matched_ingredient_id, record);
            }}
          >
            <Spin spinning={loader} size="default">
              UPDATE
            </Spin>
          </div>
        ) : (
          <div
            className={`continue Supplier-ingredient-editButton`}
            onClick={() => {
              setRecordIndex(record?.id);
              setIngredient({
                ...record,
                ingredient_category: record?.matched_ingredient_category,
              });
            }}
          >
            EDIT
          </div>
        ),
    },
  ];
  const unTaggedColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>R&D Ingredient</span>
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],

      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing ">
            {record?.ingredient_name?.length > 22 ? (
              <Tooltip
                title={
                  <div>
                    <div className="tooltipHead">Name</div>
                    <div className="tootipText">{record?.ingredient_name}</div>
                  </div>
                }
              >
                {record?.ingredient_name?.substring(0, 22)}
                {record?.ingredient_name?.length > 22 ? <span>...</span> : null}
              </Tooltip>
            ) : (
              record?.ingredient_name
            )}
          </div>
        );
      },
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>R&D Category</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col3 cellSpacing ">
            {/* {record?.category_name} */}
            {record?.category_name?.length > 22 ? (
              <Tooltip
                title={
                  <div>
                    <div className="tooltipHead">Name</div>
                    <div className="tootipText">{record?.category_name}</div>
                  </div>
                }
              >
                {record?.category_name?.substring(0, 22)}
                {record?.category_name?.length > 22 ? <span>...</span> : null}
              </Tooltip>
            ) : (
              record?.category_name
            )}
          </div>
        );
      },
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>Source</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing ingredientlist-column-font">
          {record?.source ? record?.source : 'N/A'}
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>Inventory Ingredient</span>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing unMatch-ingredient-empty ingredientlist-column-font">
          <Select
            size={'large'}
            placeholder={'-'}
            value={
              recordIndex == record?.id ? ingredient?.ingredient_name : null
            }
            // disabled={recordIndex == record?.id ? false : true}
            onChange={(e) => {
              const getIngredient = untaggedRecipeData.find(
                (data: any) => data?.id == e
              ) || { ingredient_name: '', ingredient_category: '' };
              setIngredient(getIngredient);
              record.matched_ingredient_name = getIngredient?.ingredient_name;
              record.matched_ingredient_id = e;
              handleMenu(record?.matched_ingredient_id, record);
            }}
            style={{ width: 200 }}
            optionFilterProp="children"
            showSearch
            className={
              error['ingredient']?.length > 0 && recordIndex == record?.id
                ? 'dropdownUnitColorRed'
                : ''
            }
          >
            {untaggedRecipeData
              ?.filter(
                (filteredObj: any) => filteredObj?.ingredient_name != 'None'
              )
              ?.map((data: any, menuIndex: number) => (
                <Select.Option value={data?.id} key={menuIndex}>
                  {data?.ingredient_name}
                </Select.Option>
              ))}
          </Select>
          {error['ingredient']?.length > 0 && recordIndex == record?.id && (
            <div className="subRecipeErrorMessages">{error['ingredient']}</div>
          )}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>Category</span>
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return recordIndex == record?.id ? (
          <div className="col3 cellSpacing cellSpacingIngredientTable ingredientlist-column-font">
            {ingredient?.ingredient_category?.length > 12 ? (
              <Tooltip
                title={
                  <div className="font">{ingredient?.ingredient_category}</div>
                }
              >
                {ingredient?.ingredient_category?.substring(0, 10) + '...'}
              </Tooltip>
            ) : (
              ingredient?.ingredient_category
            )}
          </div>
        ) : null;
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div>
          <Button
            className="theme-button recipeBtn"
            shape="round"
            size="middle"
            onClick={() => {
              handleSubmit(record);
              getUntaggedRecipes();
              record.matched_ingredient_name = record?.ingredient_name;
              record.matched_ingredient_category = record?.category_name;
              record.matched_ingredient_id = record?.id;
            }}
          >
            Push To Inventory
          </Button>
        </div>
      ),
    },

    // {
    //   title: '',
    //   dataIndex: 'created_at',
    //   key: 'created_at',
    //   responsive: ['md'],

    //   render: (_: any, record: any) =>
    //     recordIndex == record?.id ? (
    //       <div
    //         className={`${
    //           loader ? 'loader-enable-supplier' : 'continue'
    //         } Supplier-ingredient-editButton`}
    //         onClick={() => {
    //           handleMenu(record?.matched_ingredient_id, record);
    //         }}
    //       >
    //         <Spin spinning={loader} size="default">
    //           UPDATE
    //         </Spin>
    //       </div>
    //     ) : (
    //       <div
    //         className={`continue Supplier-ingredient-editButton`}
    //         onClick={() => {
    //           setRecordIndex(record?.id);
    //           setIngredient('');
    //           setError({});
    //         }}
    //       >
    //         EDIT
    //       </div>
    //     ),
    // },
  ];

  const handleSubmit = async (data: any) => {
    setErrorStatus(false);
    setErrorList({});
    setError(false);
    let param: any = {
      unit_id: data?.unit_id || null,
      waste: 0,
      par_level: 0,
      ingredient_category_id: data?.category_id,
      ingredient_name: data?.ingredient_name,
      product_code: '',
      company_id: company?.id,
      brand_id: Utils.getCurrentBrand()?.brand_id,
      calorie: 0,
      carb: 0,
      protein: 0,
      fat: 0,
      allergens_id: '',
      is_verified: 1,
      verified_by: 'Head Of Culinary',
      created_by: user?.id,
    };
    try {
      let responseDialogData;
      setLoader(true);
      let response: any;
      response = await IngredientActions.createNonRbInventory(param);
      setLoader(false);
      if (!response?.success) {
        // setErrorList(response?.error || { ingredient_name: response?.message });
        // setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'Ingredient Not pushed',
            response?.error?.ingredient_name,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        responseDialogData = {
          OnClick: () => {
            handleMatchAndPushToInventory(response?.data?.id, data);
          },
          type: 'success',
          messageArray: [`Ingredient has been pushed to Inventory`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMatchAndPushToInventory = async (value: any, data: any) => {
    if (value == null) {
      setError({ ingredient: 'Ingredient is required for matching' });
      return;
    }
    setLoader(true);
    const payload = {
      matched_ingredient_id: value,
      is_matched: value == 0 ? 0 : 1,
    };
    const assignTag = await RdMatchingApi.updateUnmatch(payload, data?.id);
    let responseDialogData;
    setLoader(false);
    if (assignTag === undefined || !assignTag?.success) {
      setRecordIndex(-1);
      responseDialogData = {
        type: 'error',
        messageArray: [`Ingredient not Matched!`, 'Something Went Wrong'],
        OnClick: () => {},
      };
    } else {
      responseDialogData = {
        type: 'success',
        messageArray: [`Ingredient Matched and Pushed to Inventory!`],
        OnClick: () => {},
      };
      getUntaggedRecipes();
      setRecordIndex(-1);
      setIngredient('');
      pageData();
      fetchIngredientDataCount();
    }
  };

  const handleMenu = async (value: any, data: any) => {
    const rd_unit = untaggedRecipeData?.find(
      (ingredientObj: any) => ingredientObj?.id === value
    )?.storage_unit;
    // console.log(
    //   untaggedRecipeData?.find(
    //     (ingredientObj: any) => ingredientObj?.id === value
    //   ),
    //   data
    // );
    if (
      rd_unit == data?.unit ||
      (rd_unit?.toLowerCase() == 'kg' && data?.unit == 'grams') ||
      (rd_unit?.toLowerCase() == 'ltr' && data?.unit == 'ml')
    ) {
      if (value == null) {
        setError({ ingredient: 'Ingredient is required for matching' });
        return;
      }

      setLoader(true);
      const payload = {
        matched_ingredient_id: value,
        is_matched: value == 0 ? 0 : 1,
      };
      const assignTag = await RdMatchingApi.updateUnmatch(payload, data?.id);
      // let responseDialogData;
      setLoader(false);
      if (assignTag === undefined || !assignTag?.success) {
        setRecordIndex(-1);

        // responseDialogData = {
        //   type: 'error',
        //   messageArray: [`Validation Failed!`, 'Something Went Wrong'],
        //   OnClick: () => {},
        // };
      } else {
        getUntaggedRecipes();
        setRecordIndex(-1);
        setIngredient('');
        pageData();
        fetchIngredientDataCount();
      }
      // ResponseDialog(responseDialogData);
    } else {
      let responseDialogData = {
        type: 'error',
        messageArray: [`Unit of both ingredients are not matched!`],
        OnClick: () => {
          getUntaggedRecipes();
          setRecordIndex(-1);
          setIngredient('');
          // pageData();
        },
      };
      ResponseDialog(responseDialogData);
    }
  };
  const pageData = async () => {
    setRecipeLoader(true);
    const imagesData = check
      ? await RdMatchingApi.getMAtchUnMatch(dataLength, 'yes', current, keyword)
      : await RdMatchingApi.getMAtchUnMatch(dataLength, 'no', current, keyword);
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      check ? setTotalTaggedImage(0) : setTotalUntaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(imagesData?.data?.pagination?.total);
        setTaggedImageData(imagesData?.data?.items);
        setTotalTaggedImage(imagesData?.data?.pagination?.total);
      } else {
        setTotal(imagesData?.data?.pagination?.total);
        setTotalUntaggedImage(imagesData?.data?.pagination?.total);
        setUntaggedImageData(imagesData?.data?.items);
      }
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    const searchResultofRecipes = check
      ? await RdMatchingApi.searchMAtchUnMatch(dataLength, 'yes', keyword)
      : await RdMatchingApi.searchMAtchUnMatch(dataLength, 'no', keyword);
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setTotal(0);
      check ? setTotalTaggedImage(0) : setTotalUntaggedImage(0);
      setTaggedImageData([]);
      setUntaggedImageData([]);
    } else {
      if (check) {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTaggedImageData(searchResultofRecipes?.data?.items);
        setTotalTaggedImage(searchResultofRecipes?.data?.pagination?.total);
      } else {
        setTotal(searchResultofRecipes?.data?.pagination?.total);
        setTotalUntaggedImage(searchResultofRecipes?.data?.pagination?.total);
        setUntaggedImageData(searchResultofRecipes?.data?.items);
      }
    }
    setRecipeLoader(false);
  };
  // const actionButton = (
  //   <Button
  //     text="Add New"
  //     className="theme-button recipeBtn"
  //     icon={<PlusOutlined />}
  //     handleButtonClick={() => {
  //       navigate('wizard', { state: { update: false, id: '' } });
  //     }}
  //     shape="round"
  //     size="middle"
  //   />
  // );
  // const handleClose = () => {
  //   setDialog(false);
  // };
  return (
    <React.Fragment>
      <Breadcrumb
        heading="R&D Matching"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        perm_status={permissionStatus}
      />
      {/* <IngendientListDialog
        open={dialog}
        handleClose={handleClose}
        // data={data}
        handleMarkRecipe={() => {}}
      /> */}
      <Page title="Matching Ingredient List">
        <Row className="listingTopFilter">
          <Col lg={10}></Col>
          <Col lg={8} />
          <Col lg={6}>
            <SearchBox
              keyword={keyword}
              setKeyword={setKeyword}
              action={onSearch}
              placeholderText="Search..."
            />
          </Col>
        </Row>

        <Row gutter={12} className="listingTopFilter">
          <Col lg={24} md={16} className="ingredient-list-wrapper">
            <div className="ingredient-list-menu-container">
              <div className={check === true ? 'menu' : 'menuLight'}>
                <div
                  onClick={() => setCheck(true)}
                  className="text ingredient-list-menu-style"
                >
                  Matched
                </div>
                <div
                  className={`ingredient-list-counter ${
                    +totalTaggedImage >= 10 && 'ingredient-list-large-counter'
                  }`}
                >
                  {totalTaggedImage}
                </div>
              </div>

              <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text ingredient-list-menu-style">UnMatched</div>
                <div
                  className={`ingredient-list-counter ${
                    +totalUntaggedImage >= 10 && 'ingredient-list-large-counter'
                  }`}
                >
                  {totalUntaggedImage}
                </div>
              </div>
            </div>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={''}
                dataSource={
                  check ? [...taggedImageData] : [...untaggedImageData]
                }
                columns={check ? taggedColumns : unTaggedColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default IngredientList;
