import React, { useRef } from 'react';
import { Input } from 'antd';
import Delete from '../../assets/images/Delete.svg';

type Props = {
  recipe: any;
  index: any;
  handleAddRecipeStep: any;
  handleStepValue: any;
  handleDeleteRecipeStep: any;
};

const CookingMethodSteps = (props: Props) => {
  let {
    recipe,
    index,
    handleAddRecipeStep,
    handleStepValue,
    handleDeleteRecipeStep,
  } = props;

  let inputRefs = useRef<any>(recipe?.steps?.map(() => React.createRef()));
  const handleKeyDown = (e: any, index: number, stepIndex: number) => {
    if (e.key === 'Enter') {
      handleAddRecipeStep(recipe, index);
      setTimeout(() => {
        inputRefs.current[stepIndex + 1]?.focus();
      }, 200);
    }
  };
  return recipe?.steps?.map((step: any, stepIndex: number) => {
    return (
      <div key={stepIndex} className="form-group">
        <span>{stepIndex + 1}</span>
        <Input
          type="text"
          value={step}
          onChange={(e) => handleStepValue(e, recipe, stepIndex)}
          onKeyDown={(e: any) => handleKeyDown(e, index, stepIndex)}
          ref={(el) => (inputRefs.current[stepIndex] = el)}
        />
        <button onClick={() => handleDeleteRecipeStep(recipe, stepIndex)}>
          <img src={Delete} width={15} alt="" />
        </button>
      </div>
    );
  });
};

export default CookingMethodSteps;
