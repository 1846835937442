/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import './signUp.scss';
import AuthActions from '../../redux/middleware/auth';
import ResponseDialog from '../ResopnseDialog';

type Props = {
  handleDialog: any;
  open: boolean;
  modalData: any;
};

const SignUpModal = (props: Props) => {
  const { TextArea } = Input;
  let { handleDialog, open } = props;
  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [description, setDescription] = useState<any>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = async () => {
    setErrorList({});
    setErrorStatus(false);
    setSpinner(true);

    const params = {
      full_name: name,
      phone: phone,
      email: email,
      comment: description,
      company: company,
    };

    const emptyAllFields = () => {
      setName('');
      setPhone('');
      setDescription('');
      setEmail('');
      setCompany('');
    };
    try {
      let responseDialogData;
      const response = await AuthActions.UserSignUp(params);
      if (response?.success) {
        handleDialog();
        emptyAllFields();
      }
      if (!response?.success) {
        setSpinner(false);
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'SignUp Failed!',
            `${
              response?.error?.email ||
              response?.error?.full_name ||
              response?.error?.company ||
              response?.error?.comment ||
              response?.error?.description
            }`,
          ],
        };
        ResponseDialog(responseDialogData);
      } else {
        setSpinner(false);

        responseDialogData = {
          OnClick: () => {
            handleDialog();
            emptyAllFields();
          },
          type: 'success',
          messageArray: [` Your User`, ` has been Created`],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }

    // const response = await AuthActions.UserSignUp(params);
    // response?.success && handleDialog(false, {});
  };
  return (
    <Modal
      title={<div className="title font">Sign Up</div>}
      centered
      open={open}
      onCancel={handleDialog}
      width={608}
      footer={null}
    >
      <Spin spinning={spinner} size="default" className="recipeLoader">
        <div className="MenuModal">
          <div className="menuSubContainer ingredientSubContainermargin">
            <div className="ingredientLabel">
              Your Name <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input name here"
              size="large"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </div>
          <div className="menuSubContainer ingredientSubContainermargin">
            <div className="ingredientLabel">
              Your Company <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input company here"
              size="large"
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
          </div>
          <div className="menuSubContainer ingredientSubContainermargin">
            <div className="ingredientLabel">
              Email <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input email here"
              size="large"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div className="menuSubContainer ingredientSubContainermargin">
            <div className="ingredientLabel">
              Contact Number <span className="sterik">*</span>
            </div>
            <Input
              placeholder="Input number here"
              size="large"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className="menuSubContainer">
            <div className="ingredientLabel">
              Description <span className="sterik">*</span>
            </div>
            <TextArea
              rows={6}
              style={{ height: 100 }}
              className="text-Area"
              placeholder="Input description here"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className="butonGroup">
            <div className="continue wizard-button" onClick={handleSubmit}>
              Submit
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default SignUpModal;
