/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  ListCount,
  SearchBox,
  Button,
  ListViewWithoutExpand,
  UserAccessDialog,
} from '../../components';
import UserAccessApi from '../../redux/middleware/userAccess';
import { sortNumber, sortedData } from '../../helper/sortData';
import Clock from '../../assets/images/Clock.png';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import More from '../../assets/images/More.png';
import moment from 'moment';
import DownGreen from '../../assets/images/DownGreen.png';
import DownRed from '../../assets/images/DownRed.png';
import { useNavigate } from 'react-router-dom';
import './userAccess.scss';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const items: MenuProps['items'] = [
  {
    key: '2',
    label: <div className="dotOption">Edit</div>,
    icon: <img src={Edit} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const UserAccess = () => {
  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [roles, setRoles] = useState<any>([]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState(2);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [update, setUpdate] = useState(false);
  const [id, setid] = useState();

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const items1: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="dotOption"
          onClick={(e) => {
            const status = 'Active';

            handlestatus(id, status);
          }}
        >
          Active
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div
          className="dotOption deleteText"
          onClick={() => {
            const status = 'Non-active';
            handlestatus(id, status);
          }}
        >
          Non-active
        </div>
      ),
    },
  ];

  const handlestatus = (id: any, key: string) => {
    if (key === 'Active') {
      UserAccessApi.disableUserAccess(id, '1');
      pageData();
    } else {
      UserAccessApi.disableUserAccess(id, '0');
      pageData();
    }
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="userAccessCol3 cellSpacing">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>PERMISSION</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('permission', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'permission',
      key: 'permission',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="userAccessCol3 cellSpacing">{record?.permission}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>ROLE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('role', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'role',
      key: 'role',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="userAccessCol3">{record?.role}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('perm_status', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'perm_status',
      key: 'perm_status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.perm_status}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>STATUS</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('status', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          overlayStyle={{ height: '100px' }}
          menu={menuDropdownProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={(e) => {
              setid(record.id);
            }}
          >
            <Space>
              <div
                className={
                  record?.status === 1
                    ? 'statusContainerActive'
                    : 'statusContainerNonActive'
                }
              >
                <div className="status">
                  {record?.status == 1 ? 'Active' : 'Non-active'}
                </div>
                <img src={record?.status === 1 ? DownGreen : DownRed} alt="" />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="userAccessTableCol">
            <img src={Clock} alt="" className="icons" />
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={{
            onClick: handleDropDownClick,
            items: items,
          }}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a
            onClick={() => {
              setSelectedMenu(record?.id);
            }}
          >
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'User Access'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
    fetchRoles();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, selectedRole]);

  const fetchRoles = async () => {
    const data = await UserAccessApi.getAllRoles();
    setRoles(data?.data?.items || []);
  };

  const handleActiveTab = (role: any) => {
    setSelectedRole(role?.id);
    setCurrent(1);
  };

  const handleDialog = () => {
    setOpenDialog(false);
    setUpdate(false);
  };

  const handleDialogOnSuccess = () => {
    setOpenDialog(false);
    setUpdate(false);
    pageData();
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 2:
        setUpdate(true);
        setOpenDialog(true);
        break;
      default:
    }
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  // const handleMenuDropdown: MenuProps['onClick'] = (e: any) => {
  //   setDataLength(PageDataLength[+e?.key]);
  // };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuDropdownProps = {
    items: items1,
    // onClick: handleMenuDropdown,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        setOpenDialog(true);
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setLoader(true);
    const id = selectedRole;
    const variationData = await UserAccessApi.getUserAccessList(
      id,
      dataLength,
      current
    );
    if (variationData === undefined || !variationData?.success) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(variationData?.data?.total_access);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setLoader(true);
    const id = selectedRole;
    const searchResultofVariations =
      await UserAccessApi.getUserAccessListBySearch(id, dataLength, keyword);
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setCount(searchResultofVariations?.data?.total_access);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      {openDialog ? (
        <UserAccessDialog
          handleDialogOnSuccess={handleDialogOnSuccess}
          handleDialog={handleDialog}
          open={openDialog}
          update={update}
          id={selectedMenu}
        />
      ) : null}

      <Breadcrumb
        heading="User Access"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="User Access">
        <Row className="listingTopFilter">
          <Col lg={18} md={24}>
            <div>
              <ListCount count1={count} title1="Total Access" />
              <div className="uploadAccessMenuContainer">
                {roles?.map((roleObject: any, index: any) => {
                  return (
                    <>
                      {roleObject?.name !== 'Super-Admin' ? (
                        <div
                          key={index}
                          className={
                            selectedRole === roleObject?.id
                              ? 'menu'
                              : 'menuLight'
                          }
                        >
                          <div
                            onClick={() => handleActiveTab(roleObject)}
                            className="text menustyle"
                          >
                            {roleObject?.name}
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col lg={6} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!loader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={sortData ? [...sortData] : []}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={loader} className="loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default UserAccess;
