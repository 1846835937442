import React, { useState } from 'react';
import { Col, Row, Spin, Card } from 'antd';
import Logo from '../../assets/svg/logo.svg';
import { useSearchParams } from 'react-router-dom';
import StripeProvider from '../UserPersonalInfo/PricePlans/paymentProvider';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import Utils from '../../redux/utils';
import SubscriptionApi from '../../redux/middleware/subscription';
import './confirmationSubscription.scss';

// eslint-disable-next-line no-unused-vars
const PaymentForm = ({ handleSubmit }: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<any>(null);

  const onProceedPayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // No return_url needed
      },
      redirect: 'if_required', // This will prevent automatic redirects
    });

    if (error) {
      setError(error);
      // console.error('Payment failed:', error);
    } else if (setupIntent && setupIntent.status === 'succeeded') {
      // console.log('Payment successful:', paymentIntent);
      const { payment_method } = setupIntent;
      await handleSubmit(payment_method);
    }
  };

  return (
    <div>
      <div className="paymentCardContainer">
        <PaymentElement />
      </div>
      {error ? (
        <div className="error">{error.message}</div>
      ) : (
        <div className="butonGroupDialog">
          <div className="continue wizard-button" onClick={onProceedPayment}>
            Proceed
          </div>
        </div>
      )}
    </div>
  );
};

const ConfirmationPage = () => {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');
  const packageId = searchParams.get('user_package_id');
  const [isLoading, setIsLoading] = useState(false);
  const [packageInfo, setPackageInfo] = useState<any>(null);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    setIsLoading(true);
    if (token) {
      Utils.setCurrentToken(token);
      setTimeout(() => {
        getSelectedPlan();
      }, 2000);
    }
  }, [token]);

  const getSelectedPlan = async () => {
    const response = await SubscriptionApi.getUserPackage(packageId);
    const { success } = response;

    if (success) {
      const { data } = response;
      if (data) setPackageInfo(data);
      setIsLoading(false);
    }
  };

  const onProceedPaymentWithCard = async (payment_method: any) => {
    if (!packageInfo) return;
    const params = {
      packageId,
      payment_method,
      token,
    };

    const response = await SubscriptionApi.UpdateEnterprisePlan(params);
    const { success, message } = response;
    if (success) {
      Utils.setCurrentToken();
      setError(null);
      const location = window.location;
      window.location.href = location.origin;
    } else {
      setError(message);
    }
  };

  return (
    <React.Fragment>
      <Row
        className="EnterpriseConfirmPage"
        style={{ height: window.innerHeight - 50 }}
      >
        <Col className="leftSideForConfirmPage">
          <div className="sideLogoContainer">
            <img src={Logo} height={50} alt={'logo'} className="sideLogo" />
          </div>

          <Spin spinning={isLoading}>
            {!isLoading && (
              <>
                <div className="enterpriseContainer">
                  <Card className="UserPlanCard">
                    <h3 className="title">Enterprise Plan</h3>
                    <h1 className="package">
                      {`USD ${packageInfo?.price}`}
                      <sub className="interval-text">per Month</sub>
                    </h1>
                    <h5 className="featureTitle">Offered Plan Details</h5>
                    <ul className="features p-0">
                      <li>No of Days Trial: {packageInfo?.trial_period}</li>
                      <li>Brand Limit: {packageInfo?.brand_limit}</li>
                      <li>Branch Limit: {packageInfo?.shop_limit}</li>
                      <li>CK Limit: {packageInfo?.ck_limit}</li>
                      <li>Subscription Cycle: Monthly</li>
                    </ul>
                  </Card>
                  {packageInfo && packageId && (
                    <div className="paymentContainer">
                      <StripeProvider
                        selectedPlan={{
                          amount: packageInfo?.price,
                          currency: 'usd',
                        }}
                      >
                        <PaymentForm handleSubmit={onProceedPaymentWithCard} />
                      </StripeProvider>
                      {error && (
                        <div>
                          <p className="validationError">{error}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </Spin>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConfirmationPage;
