import React, { useState } from 'react';
import { Modal } from 'antd';

type Props = {
  component?: any;
};

const CountDown = (props: Props) => {
  let { component } = props;
  const [open, setOpen] = useState(true);

  setTimeout(() => {
    setOpen(false);
    // instance.update({
    //   content: `This modal will be destroyed after ${secondsToGo} second.`,
    // });
  }, 6000);

  //   setTimeout(() => {
  //     clearInterval(timer);
  //     instance.destroy();
  //   }, secondsToGo * 1000);
  return (
    <Modal
      open={open}
      title={<div className="title font">Instruction</div>}
      centered
      onCancel={() => setOpen(false)}
      footer={null}
    >
      {component === 'login' ? (
        <>
          <h1>Congratulations!</h1>
          <p>We have successfully retrieved your Login Details</p>
          <p>User Name:</p>
          <p>User Name:</p>
          <p>User Name:</p>
          <p>User Name:</p>
          <p>User Name:</p>

          <p>
            Remaining work is in process and will be concluded alongside payment
            GW integration.
          </p>
        </>
      ) : (
        <>
          <p>{`Note: Any new categories in your bulk upload file will be created automatically. Please refer to the sample file for the correct format`}</p>
          {/* <p>{`Also, to get understanding of Units to be used, check out the  sample file option.`}</p> */}
        </>
      )}
    </Modal>
  );
};

export default CountDown;
