import React from 'react';
import { Modal, Spin } from 'antd';
import './orderListDialog.scss';
// import { handleFloat } from '../../helper/sortData';

type Props = {
  handleCancel: any;
  open: boolean;
  component?: any;
  ingredients?: any[];
  recipes?: any[];
  handleContinue?: any;
  loader?: boolean;
  errorList?: any;
  error?: boolean;
};

const OrderListDialog = (props: Props) => {
  let {
    handleCancel,
    open,
    component,
    ingredients,
    recipes,
    handleContinue,
    loader,
    errorList,
    error,
  } = props;
  const handleOrderList = () => {
    return ingredients?.map((data: any, index: number) => (
      <div className="gramContainer" key={index}>
        <div className="recipeField-subContainer panel-recipe-name-field">
          {data?.ingredient_name}
        </div>

        <div className="productionPlan-gramSubContainer">
          <input
            className="productionPlan-gramValue"
            placeholder="0"
            type={'number'}
            disabled
            value={data?.quantity}
            // onChange={(e) => {
            //   if (+e.target.value <= 999) {
            //     handleRecipeIngredient(
            //       +e.target.value,
            //       index,
            //       ingredientIndex
            //     );
            //   }
            // handleIngredientWeight(+e.target.value, index);
            // }}
          />
          <input
            className="productionPlan-gramValue"
            placeholder="0"
            type={'number'}
            disabled
            value={parseInt(data?.current_balance)}
          />
          <div className="productionPlan-gramBoxWrapper">
            <div className="productionPlan-gramBox">
              {/* <Dropdown
                              menu={DropDownmenuProps}
                              trigger={['click']}
                              placement="bottomRight"
                              arrow={{ pointAtCenter: true }}
                            >
                              <a>
                                <Space>
                                  <div>{ingredient?.unit}</div>{' '}
                                  <img src={ArrowDown} alt="" />
                                </Space>
                              </a>
                            </Dropdown> */}
              <div>{data?.unit}</div>
            </div>
          </div>
        </div>
      </div>
    ));
  };
  const handleProductionSummary = () => {
    return recipes?.map((data: any, index: number) => (
      <div className="gramContainer" key={index}>
        <div className="recipeField-subContainer panel-recipe-name-field">
          {data?.recipe_name}
        </div>

        <div className="gramSubContainer">
          <input
            className="gramValue"
            placeholder="0"
            type={'number'}
            disabled
            value={data?.quantity}
          />
          <div className="gramBoxWrapper">
            <div className="gramBox">
              <div>{data?.unit}</div>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <Modal
      title={
        <div className="title font">
          {component == 'orderList' ? 'Order List' : 'Confirmation'}
        </div>
      }
      centered
      open={open}
      onCancel={handleCancel}
      width={560}
      footer={null}
      className={`OrderListDialogContainer ${
        component == 'orderList' ? 'orderListWidthDialogContainer' : ''
      }`}
    >
      <Spin spinning={loader}>
        <div className="productionPlan-modal-ingredient-container">
          <div className="order-list-dialog-body-heading productionPlan-ingredient-label">
            {component == 'orderList' ? 'Ingredients' : 'Recipes'}
          </div>
          {component == 'orderList' && (
            <div className="order-list-dialog-body-heading productionPlan-required-label">
              Required
            </div>
          )}
          {component == 'orderList' && (
            <div className="order-list-dialog-body-heading productionPlan-required-label">
              Current
            </div>
          )}
          {component == 'orderList' && (
            <div className="order-list-dialog-body-heading ">Unit</div>
          )}
        </div>
        {component == 'productionPlan'
          ? handleProductionSummary()
          : handleOrderList()}
        {error &&
          errorList['quantity']?.map((eachError: any, index: number) => (
            <div className="errorMessage-container" key={index}>
              {eachError}
            </div>
          ))}
        {component == 'productionPlan' && (
          <div className="font-light butonGroup">
            {/* <div
              // className="continue wizard-button"
              onClick={() => handleCancel()}
            >
              Cancel
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleContinue(false)}
            >
              Continue
            </div>{' '} */}
            <div
              className="continue wizard-button"
              onClick={() => handleContinue(true)}
            >
              Confirm & Update Inventory
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default OrderListDialog;
