/* eslint-disable no-unused-vars */
import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

interface stackedBarHorizontalProps {
  data: any[];
  source?: string;
  isMulti?: boolean;
  brandcount?: number;
}

const StackedBarHorizontal = (props: stackedBarHorizontalProps) => {
  const { data, source } = props;

  function formatNumberWithEllipsis(number: any) {
    const numberStr = number.toString();
    if (numberStr.length > 5) {
      return numberStr.substring(0, 5) + '...';
    }
    return numberStr;
  }

  const chartWidth = source === 'dayWiseWastage' ? 460 : 460; // width changed from 960
  const chartHeight = 240;

  if (!data || data.length === 0) {
    return <div className="no-data-graph">No data to display</div>;
  }

  const daysOfWeek = ['M', 'T', 'W', 'Th', 'F', 'S', 'Sn'];
  const filledData = daysOfWeek.map((day) => {
    const dayData = data.find((d) => d.name === day) || { name: day };
    return dayData;
  });

  const processedData = filledData.map((dayData) => {
    const newData: { [key: string]: any } = { name: dayData.name };
    for (let i = 1; i <= 5; i++) {
      const labelKey = `label${i}`;
      const valueKey = `value${i}`;
      if (dayData[valueKey] !== undefined) {
        newData[labelKey] = dayData[labelKey];
        newData[valueKey] = dayData[valueKey];
      }
    }
    return newData;
  });

  const valueKeys = Array.from({ length: 5 }, (_, i) => `value${i + 1}`);
  const bars = valueKeys.map((key, index) => {
    const barColor = ['#d62728', '#a5a5a5', '#f9ab01', '#2ca02c', '#1f77b4'][
      index % 5
    ];
    return <Bar key={key} dataKey={key} stackId="stack" fill={barColor} />;
  });

  return (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={processedData}
      layout="vertical"
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis type="number" />
      <YAxis dataKey="name" type="category" tick={{ fontSize: 9 }} />
      <Tooltip />
      <Legend />
      {bars}
    </BarChart>
  );
};

export default StackedBarHorizontal;
