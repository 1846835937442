export const focusNextElement = () => {
  const activeElement = document.activeElement as HTMLElement;
  if (!activeElement) return;
  const focusableElements = Array.from<HTMLElement>(
    document.querySelectorAll(
      'input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])'
    )
  ).filter(
    (el) =>
      !el.hasAttribute('disabled') &&
      !el.hasAttribute('hidden') &&
      el.offsetParent !== null
  );
  const antButtons = Array.from<HTMLElement>(
    document.querySelectorAll('.ant-btn')
  ).filter((el) => !el.hasAttribute('disabled') && el.offsetParent !== null);
  const allFocusableElements = [
    ...new Set([...focusableElements, ...antButtons]),
  ];
  const currentIndex = allFocusableElements.indexOf(activeElement);
  if (currentIndex >= 0) {
    const nextIndex = (currentIndex + 1) % allFocusableElements.length;
    allFocusableElements[nextIndex].focus();
  }
};

export const focusPreviousElement = () => {
  const activeElement = document.activeElement as HTMLElement;
  if (!activeElement) return;
  const focusableElements = Array.from<HTMLElement>(
    document.querySelectorAll(
      'input, button, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  ).filter(
    (el) =>
      !el.hasAttribute('disabled') &&
      !el.hasAttribute('hidden') &&
      el.offsetParent !== null
  );
  const currentIndex = focusableElements.indexOf(activeElement);
  if (currentIndex >= 0) {
    const previousElement =
      focusableElements[currentIndex - 1] ||
      focusableElements[focusableElements.length - 1];
    previousElement.focus();
  }
};

export const enableKeyboardNavigation = () => {
  document.addEventListener('keydown', (event) => {
    const activeElement = document.activeElement as HTMLElement;
    switch (event.key) {
      case 'Tab':
        if (event.shiftKey) {
          event.preventDefault();
          focusPreviousElement();
        } else {
          event.preventDefault();
          focusNextElement();
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        focusNextElement();
        break;
      case 'ArrowUp':
        event.preventDefault();
        focusPreviousElement();
        break;
      case 'Enter':
        if (activeElement.tagName === 'BUTTON') {
          event.preventDefault();
          activeElement.click();
        } else if (activeElement.tagName === 'INPUT') {
          const inputElement = activeElement as HTMLInputElement;
          if (inputElement.type === 'submit') {
            event.preventDefault();
            inputElement.click();
          }
        }
        break;
      default:
        break;
    }
  });
};

enableKeyboardNavigation();
