import React from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
import { Button } from '../../components';
// import SideImage from '../../assets/svg/authSideImage.svg';
// import LoginScreen from '../../assets/images/authSideImage2.png';
import Logo from '../../assets/svg/logo.svg';
import './resetPassword.scss';
import { useNavigate } from 'react-router-dom';
import AuthActions from '../../redux/middleware/auth';

const resetPassword = () => {
  const [, contextHolder] = notification.useNotification();
  const [email, setEmail] = React.useState('');
  const [active, setActive] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const navigate = useNavigate();
  // const getDescription = () => (
  //   <>
  //     <p>{'Welcome to ChefAdmin!'}</p>
  //     <p>{`We're excited to have you here. Our platform is designed to simplify your operations and help you manage your business more efficiently. With ChefAdmin, you can track your recipes, manage inventory, monitor costs, and more.`}</p>
  //     <p>{`We're confident that you'll find our platform intuitive and easy to use. Thank you for choosing ChefAdmin, and we look forward to supporting your business!`}</p>
  //   </>
  // );

  const onSubmit = async () => {
    if (!email) {
      return false;
    }
    try {
      const response = await AuthActions.ForgotPassword(email);
      if (response?.success) {
        setActive(true);
        setMessage(
          'Congrats! We have send you instructions to reset your password at mail'
        );
        setTimeout(() => {
          navigate('/auth');
        }, 7000);
      } else {
        setMessage(
          'Failed! We are unable send you instructions. Please Try Again '
        );
        setTimeout(() => {
          navigate('/auth');
        }, 7000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <Row className="resetContainer" style={{ height: window.innerHeight }}>
        <Col lg={7} className="leftSideForResetPassword">
          <div className="sideLogoContainer">
            <img src={Logo} height={50} alt={'logo'} className="sideLogo" />
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <div className="alignment">
              <label className="b2 upload-label ">
                Enter your email address and we will send you instructions to
                reset your password
              </label>
            </div>

            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <label className="b2 upload-label">
                Enter Email <span className="upload-span">*</span>
              </label>
              <Input
                className="login-input"
                type="email"
                placeholder="Enter email here"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                shape="round"
                text="Continue"
                handleButtonClick={onSubmit}
                type="primary"
                className="theme-button login-btn"
                focus
              />
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                shape="round"
                text="Back"
                handleButtonClick={() => navigate('/')}
                type="primary"
                className=" login-btn forget-password-back"
                focus
              />
            </Form.Item>
            {active ? (
              <div className="alignment success">
                <label className="b upload-label">{message}</label>
              </div>
            ) : (
              <div className="alignment error">
                <label className="b upload-label">{message}</label>
              </div>
            )}
          </Form>
        </Col>
        {/* <Col lg={16} className="rightSide">
          <div className="login-right-side">
            <div className="title-description-container">
              <div className="login-welcome-title">Welcome</div>
              <div className="login-right-side-description">
                {getDescription()}
              </div>
              <div className="loginsideImage">
                <img src={LoginScreen} alt="sideImage" />
              </div>
            </div>
          </div>
        </Col>  */}
      </Row>
    </React.Fragment>
  );
};

export default resetPassword;
