import React, { useState } from 'react';
import { Row, Col, Spin, Switch } from 'antd';
import PlanCard from './PlanCard';
import { Modal } from 'antd';
import './styles.scss';
import SubscriptionApi from '../../redux/middleware/subscription';
import Utils from '../../redux/utils';
import { ResponseDialog } from '../../components';

export interface PlanCardType {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: [];
  nickname: null;
  product: {
    id: string;
    object: string;
    active: boolean;
    attributes: [];
    created: number;
    default_price: string;
    description: string;
    livemode: boolean;
    metadata: [];
    name: string;
    package_dimensions: null;
    shippable: null;
    statement_descriptor: null;
    tax_code: null;
    type: string;
    unit_label: null;
    updated: 1690896822;
    url: null;
  };
  tiers_mode: null;
  transform_usage: null;
  trial_period_days: null;
  usage_type: string;
  payment_link: string;
}
type Props = {
  open: boolean;
  handleClose: any;
  showText?: any;
  type?: any;
};
const PricePlan = (props: Props) => {
  let { open, handleClose, showText, type } = props;
  const [Loader, setLoader] = useState(false);
  const [highlight, setHighlight] = useState(false);
  const [planDataMonthly, setPlanDataMonthly] = useState([]);
  const [planDataYearly, setPlanDataYearly] = useState([]);
  const [planData, setPlanData] = useState([]);
  const user = Utils.getCurrentUser();

  React.useEffect(() => {
    handleSubscriptionPlans();
  }, []);

  const handleSubscriptionPlans = async () => {
    setLoader(true);
    const data = await SubscriptionApi.getSubscriptionPlans();
    if (data === undefined || !data?.success) {
      setPlanData([]);
      setPlanDataMonthly([]);
      setPlanDataYearly([]);
    } else {
      setPlanData(data?.data?.month);
      setPlanDataMonthly(data?.data?.month);
      setPlanDataYearly(data?.data?.year);
    }
    setLoader(false);
  };

  const handleChangePeriod = (e: any) => {
    if (e) {
      setPlanData(planDataYearly);
      setHighlight(true);
    } else {
      setPlanData(planDataMonthly);
      setHighlight(false);
    }
  };

  const handleChangePlan = async (id: any) => {
    setLoader(true);
    const param = {
      plan_id: id,
      user_id: user?.id,
    };
    const data = await SubscriptionApi.changePlans(param);
    if (data?.success) {
      let responseDialogData = {
        OnClick: () => {},
        type: 'success',
        messageArray: [data?.message],
      };
      ResponseDialog(responseDialogData);
    } else {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [data?.message],
      };
      ResponseDialog(responseDialogData);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <Modal
        title={<div className="title font">Subscription Plans</div>}
        centered
        open={open}
        onCancel={handleClose}
        width={1608}
        closable={showText ? false : true}
        footer={null}
      >
        {showText ? (
          <div
            className="headerPricePlan"
            style={{ padding: '0px 15px', maxWidth: '90%' }}
          >
            <div className="pricePlanMainHeading">
              <Row>
                <p>Please Subscribe the Plan to enjoy the Services</p>
              </Row>
            </div>
            <div className="secondSubHeadContainer">
              <p
                className={
                  highlight
                    ? 'pricePlanHeading'
                    : 'pricePlanHeadingWithYellowBackgound'
                }
              >
                Monthly
              </p>
              <Switch
                size="default"
                onChange={(val: any) => handleChangePeriod(val)}
              />
              <p
                className={
                  highlight
                    ? 'pricePlanHeadingWithYellowBackgound'
                    : 'pricePlanHeading'
                }
              >
                Yearly
              </p>
            </div>
          </div>
        ) : null}

        {!Loader ? (
          <div
            style={{ padding: '50px 15px', maxWidth: '90%', margin: 'auto' }}
          >
            <Row className="PricePlan">
              {planData.map((val, id) => {
                return (
                  <Col key={id.toString()} xl={7} lg={12} md={24}>
                    <PlanCard
                      planCardData={val}
                      handleChangePlan={handleChangePlan}
                      type={type}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <div className="loader-wrapper">
            <Spin spinning={Loader} className="Loader" size="large" />
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default PricePlan;
