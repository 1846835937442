import React, { useEffect } from 'react';
import { Col, Row, Form, notification } from 'antd';
// import SideImage from '../../assets/svg/authSideImage.svg';
import Logo from '../../assets/svg/logo.svg';
import './confirmation.scss';
import { useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
  const [, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/auth');
    }, 4000);
  }, []);

  return (
    <React.Fragment>
      {contextHolder}
      <Row
        className="authContainerForConfirmPage"
        style={{ height: window.innerHeight }}
      >
        <Col lg={7} className="leftSideForConfirmPage">
          <div className="sideLogoContainer">
            <img src={Logo} height={50} alt={'logo'} className="sideLogo" />
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
          >
            <div className="alignment">
              <label className="b2 upload-label success">
                Congratulations! your password has been updated
              </label>
            </div>
          </Form>
        </Col>
        {/* <Col lg={16} className="rightSide">
          <img src={SideImage} alt={'sideImage'} />
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default ConfirmationPage;
