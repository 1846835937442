/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import './styles.scss';
type Props = {
  CardData: any;
  onPressItem: any;
  selectedPlan?: any;
  subscriptionStatus?: any;
};

const SubscriptionCard = (props: Props) => {
  // eslint-disable-next-line no-unused-vars
  let { CardData, onPressItem, selectedPlan, subscriptionStatus } = props;
  const isSelected = selectedPlan?.id === CardData.id;

  const SubscriptionCardData = CardData?.product;

  if (!CardData || !SubscriptionCardData) return null;

  const handleClick = () => {
    if (subscriptionStatus !== 'trialing' || isSelected) {
      onPressItem(CardData);
    }
  };

  return (
    <div
      className={`SubscriptionCard ${
        isSelected ? 'SubscriptionCard-Active' : ''
      }`}
      id={String(SubscriptionCardData.key)}
      onClick={handleClick}
    >
      {isSelected && <h2 className="selectedPlan">Selected</h2>}
      <Card>
        <h3 className="title">{SubscriptionCardData?.name}</h3>
        <p className="packageDetail">{SubscriptionCardData?.description}</p>
        <h1 className="package">
          {SubscriptionCardData?.name === 'Enterprise' ? (
            'Customized Plan'
          ) : (
            <>
              {CardData?.currency + ' ' + CardData?.amount}
              <sub className="interval-text">per Month</sub>
            </>
          )}
        </h1>
        <h5 className="featureTitle">Offered Features</h5>
        <ul className="features p-0">
          {SubscriptionCardData?.features.map((feature: any, index: number) => (
            <li key={index}>{feature?.name}</li>
          ))}
        </ul>
      </Card>
    </div>
  );
};

export default SubscriptionCard;
