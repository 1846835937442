import { useState, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import { Dropdown, Button, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import ProfileDropdown from './subComponent/ProfileDropdown';
import ArrowDownBlack from '../../assets/svg/arrowDownblack.svg';
import './style.scss';
import Utils from '../../redux/utils';
import { NotificationOutlined } from '@ant-design/icons';
import { Confirmation, FeedBackDialog } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
  UpdateBrand,
  UpdateCompany,
  UpdateShop,
} from '../../redux/reducers/authSlice';
import BranchActions from '../../redux/middleware/branches';
import { useTranslation } from 'react-i18next';
// import { FeedBackDialog } from '../../components';

const GlobalHead = () => {
  const { disableBrand } = useSelector((state: any) => state.Recipe);
  const { i18n } = useTranslation();
  const user = Utils.getCurrentUser();
  const brandList = Utils.getBrandList();
  const companyList = Utils.getCompanyList();
  const shopList = Utils.getShopList();
  const dispatch = useDispatch();
  // const [feedback, setFeedBack] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [company, setCompany] = useState(Utils.getCurrentCompany());
  const [brand, setBrand] = useState(Utils.getCurrentBrand());
  const [branch, setBranch] = useState(Utils.getCurrentShop());
  const [confirm, setConfirm] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [shops, setShops] = useState([]);
  // const feedbackBtn = () => {
  //   setFeedBack(!feedback);
  // };

  const blockSelectionRoutes = [
    '/app/Dashboard',
    '/app/reports',
    '/app/inventory',
  ];

  const isBlockSelection = blockSelectionRoutes.includes(
    window.location.pathname
  );

  useEffect(() => {
    if (Utils.getCurrentCompany()?.company_name !== company?.company_name) {
      Utils.setCurrentCompany(company);
      Utils.setBrandList(company?.brands);
      setBrand(company?.brands[0]);
      dispatch(UpdateCompany(company));
    }
  }, [company]);

  useEffect(() => {
    if (Utils.getCurrentBrand()?.brand_name !== brand?.brand_name) {
      if (brand?.shops) {
        setBranch(brand?.shops[0]);
      }
      Utils.setCurrentBrand(brand);
      dispatch(UpdateBrand(brand));
    }
    user?.roles[0]?.id === 3 && handleBrandHasBranch(brand?.brand_id);
  }, [brand]);

  useEffect(() => {
    Utils.setCurrentShop(branch);
    dispatch(UpdateShop(branch));
  }, [branch]);

  const item1: MenuProps['items'] =
    brandList &&
    brandList.map((item: any) => {
      return {
        key: item?.brand_id,
        label: (
          <div
            className="dotOptionMenu"
            onClick={() => {
              disableBrand ? EnableConfirmModal(item) : setBrand(item);
            }}
          >
            <img src={item?.thumbnail} className="menuImage" />
            {item?.brand_name}
          </div>
        ),
        // icon: <img src={item?.thumbnail} className="menuImage" />,
      };
    });

  const item2: MenuProps['items'] =
    companyList &&
    companyList.map((item: any) => {
      return {
        key: item?.company_id,
        label: (
          <div className="dotOptionMenu" onClick={() => setCompany(item)}>
            {item?.company_name}
          </div>
        ),
        icon: <img src={item?.thumbnail} className="menuImage" />,
      };
    });

  let item3: MenuProps['items'] =
    user?.roles[0]?.id === 3
      ? shops &&
        shops?.map((item: any) => {
          return {
            key: item?.shop_id,
            label: (
              <div
                className="dotOptionMenu"
                // onClick={() => {
                //   // setBranch(item);
                // }}
              >
                {item?.shop_name}
              </div>
            ),
          };
        })
      : shopList &&
        [
          ...new Map(
            shopList.map((item: any) => [item?.shop_id, item])
          ).values(),
        ].map((item: any) => ({
          key: item?.shop_id,
          label: <div className="dotOptionMenu">{item?.shop_name}</div>,
        }));

  const handleBrandHasBranch = async (id: any) => {
    const { data, success } = await BranchActions.GetBranchFromBrand(id);
    if (success) {
      setShops(data);
      setBranch(data[0]);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleMenuClick = async (e: any) => {};

  const handleBranchClick = async (e: any) => {
    setBranch(
      user?.roles[0]?.id === 3
        ? shops?.find((data: any) => data?.shop_id === +e?.key) || {}
        : shopList?.find((data: any) => data?.shop_id === +e?.key) || {}
    );
  };

  const menuProps = {
    items: item2,
    onClick: handleMenuClick,
  };

  const menuProps2 = {
    items: item1,
    onClick: handleMenuClick,
  };

  const menuProps3 = {
    items: item3,
    onClick: handleBranchClick,
  };

  const [feedback, setFeedBack] = useState(false);
  const feedbackBtn = () => {
    setFeedBack(!feedback);
  };

  const handleClose = () => {
    setFeedBack(false);
  };

  const EnableConfirmModal = (item: any) => {
    setConfirm(true);
    setSelectedBrand(item);
  };

  const handleReset = () => {
    setConfirm(false);
    setBrand(selectedBrand);
  };

  // eslint-disable-next-line no-unused-vars
  const handleLanguange = (toggle: boolean) => {
    if (toggle) i18n.changeLanguage('arabic');
    else i18n.changeLanguage('en');
  };

  const ShowDropdown = () => (
    <>
      {shopList && shopList.length > 0 && (
        <div
          className={
            user?.user_type === 'Super-Admin'
              ? 'branchBlockWidthHidden'
              : 'branchBlockWidth'
          }
        >
          <div className="font headTitle">BRANCH</div>
          <Dropdown
            arrow={false}
            overlayClassName="DropdownHeader scrollable-dropdown"
            menu={menuProps3}
            trigger={['click']}
            placement="bottomRight"
            disabled={shopList?.length <= 1 || isBlockSelection}
          >
            <div className="headButton">
              <div className="headButtonText headFont">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">Branch</div>
                      <div className="tootipText">{branch?.shop_name}</div>
                    </div>
                  }
                >
                  {formatNumberWithEllipsis(branch?.shop_name)}
                </Tooltip>
              </div>{' '}
              <img src={ArrowDownBlack} alt="" className="pointer" />
            </div>
          </Dropdown>
        </div>
      )}
      {brandList && brandList.length > 0 && (
        <div
          className={
            user?.user_type === 'Super-Admin'
              ? 'brandBlockWidthHidden'
              : 'brandBlockWidth'
          }
        >
          <div className="headBlock headImage">
            <img src={brand?.thumbnail} alt="" className="subHeadImage" />
            <div>
              <div className="font headTitle">BRAND</div>
              <Dropdown
                overlayClassName="DropdownHeader scrollable-dropdown"
                menu={menuProps2}
                trigger={['click']}
                placement="bottomRight"
                arrow={false}
                disabled={brandList?.length <= 1 || isBlockSelection}
              >
                <div className="headButton">
                  <div className="headButtonText headFont">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">Brand</div>
                          <div className="tootipText">{brand?.brand_name}</div>
                        </div>
                      }
                    >
                      {formatNumberWithEllipsis(brand?.brand_name)}
                    </Tooltip>
                  </div>
                  <img src={ArrowDownBlack} alt="" className="pointer" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const ShowDropdownForCompanyManager = () => (
    <>
      {brandList && brandList.length > 0 && (
        <div>
          <div className="headBlock headImage">
            <img src={brand?.thumbnail} alt="" className="subHeadImage" />
            <div>
              <div className="font headTitle">BRAND</div>
              <Dropdown
                overlayClassName="DropdownHeader scrollable-dropdown"
                menu={menuProps2}
                trigger={['click']}
                placement="bottomRight"
                arrow={false}
                disabled={brandList?.length <= 1 || isBlockSelection}
              >
                <div className="headButton">
                  <div className="headButtonText headFont">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">Brand</div>
                          <div className="tootipText">{brand?.brand_name}</div>
                        </div>
                      }
                    >
                      {formatNumberWithEllipsis(brand?.brand_name)}
                    </Tooltip>
                  </div>
                  <img src={ArrowDownBlack} alt="" className="pointer" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      )}
      {shops && shops.length > 0 && (
        <div
          className={
            user?.user_type === 'Super-Admin'
              ? 'branchBlockWidthHidden'
              : 'branchBlockWidth'
          }
        >
          <div className="font headTitle">BRANCH</div>
          <Dropdown
            arrow={false}
            overlayClassName="DropdownHeader scrollable-dropdown"
            menu={menuProps3}
            trigger={['click']}
            placement="bottomRight"
            disabled={shops?.length <= 1 || isBlockSelection}
          >
            <div className="headButton">
              <div className="headButtonText headFont">
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">Branch</div>
                      <div className="tootipText">{branch?.shop_name}</div>
                    </div>
                  }
                >
                  {formatNumberWithEllipsis(branch?.shop_name)}
                </Tooltip>
              </div>{' '}
              <img src={ArrowDownBlack} alt="" className="pointer" />
            </div>
          </Dropdown>
        </div>
      )}
    </>
  );

  function formatNumberWithEllipsis(number: any = '') {
    const numberStr = number.toString();
    if (numberStr.length > 9) {
      return numberStr.substring(0, 9) + '...';
    }
    return numberStr;
  }

  return (
    <div className="headcontainer">
      {confirm && (
        <Confirmation
          handleCancel={() => {
            setConfirm(false);
          }}
          handleContinue={handleReset}
          open={confirm}
          message={`Your form will reset.\nDo you want to continue?`}
        />
      )}
      <div className="subHeadContainer">
        <div
          className={
            user?.user_type === 'Super-Admin'
              ? 'companyBlockWidthHidden'
              : 'companyBlockWidth'
          }
        >
          <div className="headBlock headImage">
            {/* <div className="headDiv headImage">
              <img src={company?.thumbnail} alt="" className="subHeadImage" />
            </div> */}
            <img src={company?.thumbnail} alt="" className="subHeadImage" />
            <div>
              <div className="font headTitle">COMPANY</div>
              <Dropdown
                overlayClassName="DropdownHeader"
                menu={menuProps}
                trigger={['click']}
                placement="bottomRight"
                arrow={false}
                disabled={companyList?.length <= 1 || isBlockSelection}
              >
                <div className="headButton">
                  <div className="headButtonText headFont">
                    <Tooltip
                      title={
                        <div>
                          <div className="tooltipHead">Company</div>
                          <div className="tootipText">
                            {company?.company_name}
                          </div>
                        </div>
                      }
                    >
                      {formatNumberWithEllipsis(company?.company_name)}
                    </Tooltip>
                  </div>
                  {/* <img src={ArrowDownBlack} alt="" className="pointer" /> */}
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
        {user?.roles[0]?.id === 3
          ? ShowDropdownForCompanyManager()
          : ShowDropdown()}
      </div>
      <div className="secondSubHeadContainer">
        {/* <Switch
          checkedChildren="en"
          unCheckedChildren="ara"
          size="default"
          onChange={(val: any) => handleLanguange(val)}
        /> */}
        <div className="feedBackButton">
          <Tooltip
            title={
              <div>
                <div>Give FeedBack</div>
                {/* <div className="tootipText">{record?.description}</div> */}
              </div>
            }
          >
            {' '}
            <Button onClick={() => feedbackBtn()}>
              <NotificationOutlined />
              {/* Give FeedBack */}
            </Button>
          </Tooltip>
          <FeedBackDialog
            feedback={feedback}
            feedbackBtn={feedbackBtn}
            handleClose={handleClose}
          />
        </div>
        <ProfileDropdown />
      </div>
      {/* <FeedBackDialog feedback={feedback} feedbackBtn={feedbackBtn} /> */}
    </div>
  );
};

export default GlobalHead;
