/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Breadcrumb, CountDown, ResponseDialog } from '../../components';
import { Row, Col, Select, Spin } from 'antd';
import detailImg1 from '../../assets/images/detail-img1.png';
import detailImg2 from '../../assets/images/detail-img2.png';
import fileIcon from '../../assets/images/excelIcon.png';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import Delete from '../../assets/images/Delete.svg';
import { RcFile } from 'antd/lib/upload';
import MarketListForRB from '../../redux/middleware/marketListForRecipeBuilder';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import BulkUpload from '../../redux/middleware/bulkUploadRDSubRecipe';
import { checkAuthentication } from '../../helper/checkAuthentication';

const UploadMarketListForRB = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const items: any = [];
  const array = [
    { value: 'csvFile', label: 'CSV File' },
    { value: 'csvMinimal', label: 'CSV Minimal' },
    { value: 'csvFull', label: 'CSV Full' },
  ];
  const fileRef = React.useRef<HTMLInputElement>(null);

  const { CSVReader } = useCSVReader();
  const [stepper, setStepper] = useState(0);
  const [visible, setVisible] = useState(false);
  const [tableData, setTableData] = useState<any>('');
  const [Variation, setVariation] = useState<any>(array[1]);
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [errorList, setErrorList] = useState('');
  const [loader, setLoader] = useState(false);
  // const [showRecipeList, setShowRecipeList] = useState(false);
  const [fileInfo, setFileInfo] = useState<any>({});

  const screenPermission = Utils.getScreenPermission('R&D Market List');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  const handleImage = (url: any, data: any) => {
    setTableData(data?.target?.files[0]);
  };
  const handleBack = () => {
    if (stepper > 0) setStepper(stepper - 1);
    navigate('/app/R&DmarketList');
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleContinue = async () => {
    /* if (stepper == 0) setStepper(stepper + 1);
    setShowRecipeList(true); */

    try {
      setLoader(true);
      // let responseDialogData;
      const response = await BulkUpload.uploadIngredients(
        tableData,
        'recipe_builder'
      );
      setLoader(false);
      if (!response?.success || response == undefined) {
        if (response != undefined) setErrorList(response.error);
      } else {
        // setErrorList('');
        navigate('/app/R&DmarketList');

        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/R&DmarketList');
        //   },
        //   type: 'success',
        //   messageArray: [` Your listing has been added`],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeUOM = (data: any) => {
    setVariation(data);
  };

  return (
    <div>
      <Breadcrumb
        heading="RB Upload MarketList"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/R&DmarketList');
        }}
      />
      {/* showRecipeList ? (
        <UploadRecipeList
          items={items}
          dataSource={[...tableData]}
          columns={columns}
        />
      ) : ( */}
      {<CountDown />}
      <Row>
        <Col lg={24} md={24}>
          <Spin spinning={loader} size="large">
            <div className="marketListBlockForRB">
              {/* <Stepper stepper={stepper} component="s" /> */}
              <div className="wizardSubContainer checkBox">
                <div className="wizardLabel">File Attachment</div>

                {tableData ? (
                  <div className="imageUpload">
                    <div className="imageWrapper">
                      <div>
                        <img src={fileIcon} alt="" style={{ width: '100%' }} />
                        <h4> {tableData?.name} </h4>
                        <p>{formatFileSize(tableData?.size)}</p>
                      </div>
                      <div
                        className="deleteButton"
                        onClick={() => handleImage('', [])}
                      >
                        <img src={Delete} width={15} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="formgroup formgroupStepOne">
                    <div className="label-wrapper">
                      <label>
                        <p className="ant-upload-text ">
                          Drop your csv file here or browse
                        </p>
                        <p className="ant-upload-hint">Minimum file 2 MB</p>
                        <input
                          ref={fileRef}
                          type="file"
                          accept=".csv"
                          onChange={async (e: any) => {
                            if (e.currentTarget.files[0]?.type == 'text/csv') {
                              const response = await getBase64(
                                e.currentTarget.files[0]
                              );
                              if (response) handleImage(response, e);
                            } else {
                              setVisible(true);
                            }
                          }}
                          // disabled={!validateActionPermission()}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {/* <div className="dropDown-wrapper">
                <label>
                  CSV File <span>*</span>
                </label>
                <Select
                  size={'large'}
                  value={Variation}
                  placeholder="Select Variation"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={array}
                  disabled
                />
              </div> */}
                {errorList && (
                  <Row>
                    <Col lg={24} md={24}>
                      <div className="ErrorContainer">
                        {Utils.renderErrorItem(errorList).map(
                          (item: any, index: any) => (
                            <p
                              key={index}
                              className={
                                item?.includes('Success')
                                  ? 'SuccessContainerRDRecipe'
                                  : ''
                              }
                            >
                              {item}
                            </p>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
                <div className="butonGroup">
                  <div className="wizard-button" onClick={handleBack}>
                    Back
                  </div>
                  <div
                    className="continue wizard-button"
                    onClick={handleContinue}
                  >
                    Continue
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Col>
        <Col lg={12} md={24} style={{ display: 'none' }}>
          <div className="fileDetailBlock">
            <ol className="fileDetailList">
              <li>
                <div className="list-content">
                  <span>1.</span>
                  <p>
                    Download <b>CSV File</b>, you can choose <b>CSV Minimal</b>{' '}
                    or
                    <b>CSV Full</b>
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>2.</span>
                  <p>
                    Download <b>Get Marketlist</b> File
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>3.</span>
                  <p>
                    Open the <b>CSV File</b> and Input ingredients based on
                    <b>The Marketlist Data</b>. Make sure the ingredients is
                    suitable with the
                    <b>Marketlist Data</b>.
                  </p>
                </div>

                <div className="listImg">
                  <Row>
                    <div className="imgWrapper">
                      <img src={detailImg1} alt="" />
                    </div>
                    <div className="imgWrapper">
                      <img src={detailImg2} alt="" />
                    </div>
                  </Row>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>4.</span>
                  <p>
                    Upload the <b>CSV File</b> on the
                    <b>Upload Recipes’s Menu Download</b>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UploadMarketListForRB;
