import React from 'react';
import { Row, Col } from 'antd';
import { Page, Breadcrumb } from '../../components';
import DashboardTabs from './components/tabs';
import './style.scss';

const Dashboard = () => {
  return (
    <div className="Dashboard">
      <Breadcrumb heading="Dashboard" breadcrumbIcon iconAction={() => {}} />

      <Page title="Dashboard">
        <Row>
          <Col lg={24} className="dashboardTabContainer">
            <DashboardTabs />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default Dashboard;
