/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
  ResponseDialog,
  MenuBar,
} from '../../components';
import { sortNumber, sortedData } from '../../helper/sortData';
import Delete from '../../assets/images/Delete.svg';
import Clock from '../../assets/images/Clock.png';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import './menuCategory.scss';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';

const items: MenuProps['items'] = [
  {
    key: '3',
    label: <div className="dotOptionBuilder">Edit</div>,
    icon: <img src={Edit} width={15} />,
  },
  {
    type: 'divider',
  },
  {
    key: '4',
    label: <div className="dotOptionBuilder deleteText">Delete</div>,
    icon: <img src={Delete} width={15} />,
  },
];

const PageDataLength = [6, 10, 20, 50];

const MenuCategoryPage = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('translation', {
    keyPrefix: 'categoryPage',
  });
  const [current, setCurrent] = useState(0);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [VariationId, setVariationId] = useState('');
  const [dialogData, setDialogData] = useState<any>({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [brandChangeCheck, setBrandChangeCheck] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [categoryPills, setCategoryPills] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId, setCurrentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      const desiredOrder = [
        'Kitchen Section',
        'Menu Category',
        'Sub Recipe Category',
        'Ingredient Category',
      ];
      const sortedData = data.sort((a: any, b: any) => {
        return (
          desiredOrder.indexOf(b.parent_category) -
          desiredOrder.indexOf(a.parent_category)
        );
      });
      setCategoryPills([...sortedData]);
      if (searchParams?.get('id') !== null) {
        setSelectedCategory(
          sortedData?.find(
            (category: any) => category?.id == searchParams?.get('id')
          )
        );
      } else {
        // selectedCategory &&
        //   Object.keys(selectedCategory).length === 0 &&
        //   Object.getPrototypeOf(selectedCategory) === Object.prototype &&
        setSelectedCategory(sortedData[0]);
      }
    }
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Menu Category'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    fetchParentCategories();
    return;
  }, []);

  useEffect(() => {
    if (
      dataLength &&
      current
      // &&
      // !(
      //   selectedCategory &&
      //   Object.keys(selectedCategory).length === 0 &&
      //   Object.getPrototypeOf(selectedCategory) === Object.prototype
      // )
      // &&
      // !currentBrand?.brand_id
    )
      pageData();
  }, [dataLength, current]);

  useEffect(() => {
    if (currentBrand?.brand_id && currentBrandId !== currentBrand?.brand_id) {
      setBrandChangeCheck(true);
      fetchParentCategories();
    }
  }, [currentBrand]);

  useEffect(() => {
    if (brandChangeCheck && selectedCategory?.id) {
      current !== 1 ? setCurrent(1) : pageData();
      setBrandChangeCheck(false);
    } else if (selectedCategory?.id) setCurrent(1);
  }, [selectedCategory]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOptionBuilder">{data}</div>,
    };
  });

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  // const columns = [
  //   {
  //     title: () => (
  //       <div className="tableTitleBuilder">
  //         <span>NO</span>
  //         <img
  //           src={UpDownArrow}
  //           className="titleArrowBuilder"
  //           onClick={() => {
  //             handleSort('id', 'number');
  //             setAscend(!ascend);
  //           }}
  //         />
  //       </div>
  //     ),
  //     dataIndex: 'id',
  //     key: 'id',
  //     responsive: ['md'],
  //     render: (data: any, record: any) => (
  //       <div className="col3 cellSpacingBuilder">{record?.id}</div>
  //     ),
  //   },
  //   {
  //     title: () => (
  //       <div className="tableTitleBuilder">
  //         <span>CATEGORY NAME</span>
  //         <img
  //           src={UpDownArrow}
  //           className="titleArrowBuilder"
  //           onClick={() => {
  //             handleSort('name', 'string');
  //             setAscend(!ascend);
  //           }}
  //         />
  //       </div>
  //     ),
  //     dataIndex: 'name',
  //     key: 'name',
  //     responsive: ['md'],
  //     render: (_: any, record: any) => (
  //       <div className="col3 cellSpacingBuilder override-width-column ">
  //         {record?.name?.length > 22 ? (
  //           <Tooltip
  //             title={
  //               <div>
  //                 <div className="tootipText">{record?.name}</div>
  //               </div>
  //             }
  //           >
  //             {record?.name?.substring(0, 22)}
  //             {record?.name?.length > 22 ? <span>...</span> : null}
  //           </Tooltip>
  //         ) : (
  //           record?.name
  //         )}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: () => (
  //       <div className="tableTitleBuilder">
  //         <span>CREATED</span>

  //         <img
  //           src={UpDownArrow}
  //           className="titleArrowBuilder"
  //           onClick={() => {
  //             handleSort('created_at', 'string');
  //             setAscend(!ascend);
  //           }}
  //         />
  //       </div>
  //     ),
  //     dataIndex: 'created_at',
  //     key: 'created_at',
  //     responsive: ['md'],
  //     render: (data: any, record: any) => (
  //       <div className="tableColBuilder col3 col4Builder override-width-column ">
  //         <img src={Clock} alt="" className="icons" />
  //         {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
  //       </div>
  //     ),
  //   },
  //   {
  //     title: '',
  //     dataIndex: 'created_at',
  //     key: 'created_at',
  //     responsive: ['md'],
  //     render: (data: any, record: any) => (
  //       <Dropdown
  //         menu={DropDownmenuProps}
  //         trigger={['click']}
  //         placement="bottomRight"
  //         arrow={{ pointAtCenter: true }}
  //       >
  //         <a onClick={() => setSelectedMenu(record)}>
  //           <Space>
  //             <div className="dots">
  //               <img src={More} />
  //             </div>
  //           </Space>
  //         </a>
  //       </Dropdown>
  //     ),
  //   },
  // ];

  const ingredientColumns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('no')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any, index: any) => (
        <div className="col3 cellSpacing">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('category_name_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('sub_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_category',
      key: 'sub_category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol  override-width-column">
          {/* {record?.name || record?.sub_category} */}
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Name</div>
                <div className="tootipText">
                  {record?.name || record?.sub_category}
                </div>
              </div>
            }
          >
            {(record?.name || record?.sub_category)?.length > 20
              ? (record?.name || record?.sub_category)?.substring(0, 20) + '...'
              : record?.name || record?.sub_category}
          </Tooltip>
        </div>
      ),
    },

    {
      title: () => (
        <div className="tableTitle">
          <span>{t('created_at')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="tableCol  override-width-column">
          {/* <img src={Clock} alt="" className="icons" /> */}
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Created At</div>
                <div className="tootipText">
                  {moment(UTCtoLocal(record?.created_at)).format(
                    'DD-MM-YYYY HH:mm:ss'
                  )}
                </div>
              </div>
            }
          >
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </Tooltip>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  const setSelectedMenu = (Variation: any) => {
    setDialogData(Variation);
    setVariationId(Variation?.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const navigateToWizard = (update: boolean) => {
    navigate('wizard', {
      state: {
        update,
        id: update ? VariationId : '',
        selectedCategory,
      },
    });
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigateToWizard(true);
        break;

      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteContinue = async () => {
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    setOpenDeleteDialog(false);
    const params = {
      parent_category_id: dialogData?.parent_category_id,
      sub_category: dialogData?.sub_category,
      company_id: company?.id,
      brand_id: brand?.brand_id,
    };
    const response = await MenuCategoryActions.deleteSubCategory(
      params,
      dialogData?.id
    );
    if (response?.success) pageData();
    else {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [response?.message],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text={t('add_new')}
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigateToWizard(false);
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setRecipeLoader(true);
    const recipeData = await MenuCategoryActions.getSubCategories(
      selectedCategory?.id,
      dataLength,
      current,
      keyword
    );
    setCurrentBrandId(currentBrand?.brand_id);

    if (recipeData?.data === undefined || !recipeData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.sub_category_count);
      setTotal(recipeData?.data?.pagination?.total);

      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    const searchResultofRecipes = await MenuCategoryActions.searchSubCategories(
      selectedCategory?.id,
      dataLength,
      keyword
    );

    if (
      searchResultofRecipes?.data === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.sub_category_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleMenuFilter = async (filter: any) => {
    setRecipeLoader(true);
    const recipeData = await MenuCategoryActions.getSubCategories(
      filter,
      dataLength,
      current,
      search
    );

    if (recipeData?.data === undefined || !recipeData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.sub_category_count);
      setTotal(recipeData?.data?.pagination?.total);

      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  return (
    <div className="R&D Menu Category">
      <Breadcrumb
        heading={t('CategoryName')}
        breadcrumbIcon
        iconAction={() => {}}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={`Are you sure want to delete ${
          dialogData?.category_name || dialogData?.sub_category
        }?`}
      />
      <Page title="Category">
        <Row className="listingTopFilter">
          <Col lg={12} md={24}>
            <ListCount count1={recipeCount} title1={t('total_SubCategory')} />
          </Col>
          <Col lg={12} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col>
          <div>
            <Col lg={21} md={18}>
              {/* {categoryPills?.map((data: any, index: any) => (
                <div
                  className={
                    selectedCategory?.id == data?.id ? 'menu' : 'menuLight'
                  }
                  key={index}
                >
                  <div
                    onClick={() => setSelectedCategory(data)}
                    className="text rd-category-list-menu-style"
                  >
                    {data?.parent_category}
                  </div>
                </div>
              ))} */}
              <MenuBar
                component="category"
                category={selectedCategory?.id}
                handleSelectedPill={handleMenuFilter}
                allowMorePills={false}
                handleSelectedCategory={(data: any) => {
                  setCurrent(1);
                  setSelectedCategory(data);
                }}
                pillRender={currentBrandId}
                categoryPills={categoryPills}
              />

              {/* <div
                className={!check ? 'menu' : 'menuLight'}
                onClick={() => setCheck(false)}
              >
                <div className="text rd-category-list-menu-style">
                  Ingredient Category
                </div>
              </div> */}
            </Col>
          </div>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={sortData ? [...sortData] : []}
                columns={ingredientColumns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={recipeLoader} className="recipeLoader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default MenuCategoryPage;
