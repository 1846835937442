/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import './style.scss';
import { Breadcrumb, CountDown, ResponseDialog } from '../../components';
import {
  Row,
  Col,
  Alert,
  Spin,
  Collapse,
  Radio,
  RadioChangeEvent,
  Input,
} from 'antd';
import fileIcon from '../../assets/images/excelIcon.png';
import arrowDown from '../../assets/images/DownArrow.png';
import arrowLeft from '../../assets/images/ArrowLeft.png';
import { formatFileSize } from 'react-papaparse';
import Delete from '../../assets/images/Delete.svg';
import { RcFile } from 'antd/lib/upload';
import Utils from '../../redux/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import BulkUpload from '../../redux/middleware/bulkUploadRDSubRecipe';
import bulkUploadCashPurchase from '../../redux/middleware/cashPurchase';
import { checkAuthentication } from '../../helper/checkAuthentication';

const UploadRecipe = () => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { component } = state;
  const { Panel } = Collapse;
  const array = [
    { value: 'csvFile', label: 'CSV File' },
    { value: 'csvMinimal', label: 'CSV Minimal' },
    { value: 'csvFull', label: 'CSV Full' },
  ];
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [visible, setVisible] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [tableData, setTableData] = useState<any>('');
  const [Variation, setVariation] = useState<any>(array[1]);
  const [vendorError, setVendorError] = useState('');
  const [loader, setLoader] = useState(false);
  const [hideBtn, setHideBtn] = useState(false);
  const [errorList, setErrorList] = useState('');
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [CPBulkUploadFor, setCPBulkUploadFor] = useState('shop');
  const [vendor, setVendor] = React.useState('');

  const screenPermission = Utils.getScreenPermission('Upload Recipes');
  const validateActionPermission = () => {
    if (screenPermission) {
      // eslint-disable-next-line camelcase
      const { perm_status_id, perm_create } = screenPermission;

      // eslint-disable-next-line camelcase
      if (perm_status_id === 1 || perm_create === 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    checkAuthentication(user, navigate);
    if (component === 'cash_purchase' && shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      navigate('/app/cashPurchase');
    }
  }, []);

  const handleImage = (url: any, data: any) => {
    setTableData(data?.target?.files[0]);
  };
  const handleBack = () => {
    component === 'recipe'
      ? navigate('/app/recipes')
      : component === 'sub_recipe'
      ? navigate('/app/subrecipe')
      : component === 'cash_purchase'
      ? navigate('/app/cashPurchase')
      : null;
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleContinue = async () => {
    try {
      setVisible(false);
      setLoader(true);
      let response;
      if (component === 'cash_purchase') {
        if (vendor.trim() === '') {
          setVendorError('Vendor Name is missing');
          setLoader(false);
          return;
        } else {
          setVendorError('');
        }
        response = await bulkUploadCashPurchase.bulkUploadCashPurchase(
          tableData,
          CPBulkUploadFor,
          vendor
        );
      } else {
        response = await BulkUpload.uploadRecipes(tableData, 'company');
      }
      setLoader(false);
      if (response == undefined || !response?.success) {
        setTableData('');
        if (response != undefined) setErrorList(response.error);
        if (response?.error?.messages?.length <= 3) {
          setHideBtn(true);
        } else {
          setHideBtn(false);
        }
      } else {
        switch (component) {
          case 'recipe':
            navigate('/app/recipes');
            break;
          case 'sub_recipe':
            navigate('/app/subrecipe');
            break;
          case 'cash_purchase':
            navigate('/app/cashPurchase');
            break;
          default:
            break;
        }
        setTableData('');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeUOM = (data: any) => {
    setVariation(data);
  };

  const onChangeBrandBranch = (e: RadioChangeEvent) => {
    setCPBulkUploadFor(e.target.value);
  };

  return (
    <div>
      <Breadcrumb
        heading={
          component === 'recipe'
            ? 'Upload Recipes'
            : component === 'sub_recipe'
            ? 'Upload Sub Recipes'
            : component === 'cash_purchase'
            ? 'Upload Cash Purchase'
            : ''
        }
        breadcrumbIcon
        iconAction={() => {
          handleBack();
        }}
      />
      {component !== 'cash_purchase' ? <CountDown /> : null}
      <Spin spinning={loader} size="large">
        <Row>
          <Col lg={24} md={24}>
            {visible && (
              <Alert
                message="Error"
                description={
                  'File format is Invalid. Please Select Correct File!'
                }
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setVisible(false)}
              />
            )}
            <div className="imgUploadBlock">
              <div className="wizardSubContainer checkBox">
                {user?.roles[0]?.id === 3 && component === 'cash_purchase' && (
                  <>
                    <div className="wizardDropdown cash-purchase-bulk">
                      <div className="wizardLabel">
                        Bulk Upload For <span className="sterik">*</span>
                      </div>
                      <Radio.Group
                        onChange={onChangeBrandBranch}
                        value={CPBulkUploadFor}
                      >
                        <Radio value="shop">Branch</Radio>
                        <Radio value="brand">Brand</Radio>
                      </Radio.Group>
                    </div>
                    <div className="wizardSubContainer cash-purchase-bulk">
                      <div className="wizardLabel">
                        Vendor Name <span className="sterik">*</span>
                      </div>
                      <Input
                        placeholder="Enter Vendor Name"
                        size="large"
                        value={vendor}
                        onChange={(e: any) => setVendor(e.target.value)}
                        className={vendorError ? 'input-error' : ''}
                        required
                      />
                      <p className="error-vendor">{vendorError}</p>
                    </div>
                  </>
                )}
                <div className="wizardLabel">File Attachment</div>

                {tableData ? (
                  <div className="imageUpload">
                    <div className="imageWrapper">
                      <div>
                        <img src={fileIcon} alt="" style={{ width: '100%' }} />
                        <h4> {tableData?.name} </h4>
                        <p>{formatFileSize(tableData?.size)}</p>
                      </div>
                      <div
                        className="deleteButton"
                        onClick={() => handleImage('', [])}
                      >
                        <img src={Delete} width={15} alt="" />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="formgroup formgroupStepOne">
                    <div className="label-wrapper">
                      <label>
                        <p className="ant-upload-text ">
                          Drop your csv file here or browse
                        </p>
                        <p className="ant-upload-hint">
                          Minimum file 2 MB (.csv)
                        </p>
                        <input
                          ref={fileRef}
                          type="file"
                          accept=".csv"
                          onChange={async (e: any) => {
                            if (e.currentTarget.files[0]?.type == 'text/csv') {
                              const response = await getBase64(
                                e.currentTarget.files[0]
                              );
                              if (response) handleImage(response, e);
                            } else {
                              setVisible(true);
                            }
                          }}
                          // disabled={!validateActionPermission()}
                        />
                      </label>
                    </div>
                  </div>
                )}
                {/* <div className="dropDown-wrapper">
                <label>
                  CSV File <span>*</span>
                </label>
                <Select
                  size={'large'}
                  value={Variation}
                  placeholder="Select Variation"
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={array}
                  disabled
                />
              </div> */}
                {errorList &&
                  (errorList.length <= 3 ? (
                    <div className="ErrorContainer">
                      {Utils.renderErrorItem(errorList)?.map(
                        (error: any, index: any) => (
                          <p
                            className={
                              error.includes('Success')
                                ? 'SuccessContainerRDRecipe'
                                : ''
                            }
                            key={index}
                          >
                            {error}
                          </p>
                        )
                      )}
                    </div>
                  ) : (
                    <div className="ErrorContainer">
                      {Utils.renderErrorItem(errorList)
                        ?.slice(0, 3)
                        .map((error: any, index: any) => (
                          <p
                            className={
                              error.includes('Success')
                                ? 'SuccessContainerRDRecipe'
                                : ''
                            }
                            key={index}
                          >
                            {error}
                          </p>
                        ))}
                      {showAllErrors && (
                        <div>
                          {Utils.renderErrorItem(errorList)
                            ?.slice(3)
                            .map((error: any, index: any) => (
                              <p
                                className={
                                  error.includes('Success')
                                    ? 'SuccessContainerRDRecipe'
                                    : ''
                                }
                                key={index}
                              >
                                {error}
                              </p>
                            ))}
                        </div>
                      )}
                      <div>
                        <div
                          className="wizard-button upload-errors"
                          onClick={() => setShowAllErrors(!showAllErrors)}
                          hidden={hideBtn}
                        >
                          {showAllErrors ? 'Hide' : 'Show more'}
                          <img
                            src={showAllErrors ? arrowDown : arrowLeft}
                            alt={
                              showAllErrors ? 'Expand Icon' : 'Collapse Icon'
                            }
                            className="expand-icon"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="butonGroup">
                  <div className="wizard-button" onClick={handleBack}>
                    Back
                  </div>
                  <div
                    className="continue wizard-button"
                    onClick={handleContinue}
                  >
                    Continue
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {/* <Col lg={12} md={24} style={{ display: 'none' }}>
          <div className="fileDetailBlock">
            <ol className="fileDetailList">
              <li>
                <div className="list-content">
                  <span>1.</span>
                  <p>
                    Download <b>CSV File</b>, you can choose <b>CSV Minimal</b>{' '}
                    or
                    <b>CSV Full</b>
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>2.</span>
                  <p>
                    Download <b>Get Marketlist</b> File
                  </p>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>3.</span>
                  <p>
                    Open the <b>CSV File</b> and Input ingredients based on
                    <b>The Marketlist Data</b>. Make sure the ingredients is
                    suitable with the
                    <b>Marketlist Data</b>.
                  </p>
                </div>

                <div className="listImg">
                  <Row>
                    <div className="imgWrapper">
                      <img src={detailImg1} alt="" />
                    </div>
                    <div className="imgWrapper">
                      <img src={detailImg2} alt="" />
                    </div>
                  </Row>
                </div>
              </li>
              <li>
                <div className="list-content">
                  <span>4.</span>
                  <p>
                    Upload the <b>CSV File</b> on the
                    <b>Upload Recipes’s Menu Download</b>
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </Col> */}
        </Row>
      </Spin>
    </div>
  );
};

export default UploadRecipe;
