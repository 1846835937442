/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, PaginationProps, Spin, Space } from 'antd';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  SearchBox,
  CompanyList,
  ListCount,
} from '../../components';
import { sortNumber, sortedData } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import { useSelector } from 'react-redux';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';
import SignUpsApi from '../../redux/middleware/signUps';
import './styles.scss';
import Utils from '../../redux/utils';

const SignUps = () => {
  const user = Utils.getCurrentUser();
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation('translation', {
    keyPrefix: 'companyPage',
  });
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([]);
  const [signUpsData, setSignUpsData] = useState([]);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(1000);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [Loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const { currentCompany } = useSelector((state: any) => state.Auth);

  React.useEffect(() => {
    if (currentCompany) {
      pageData();
    }
  }, [currentCompany]);
  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const columns: any = [
    {
      title: () => (
        <div className="tableTitle">
          <span>ID</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle username">
          <span>USER NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('full_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'full_name',
      key: 'full_name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.full_name}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>EMAIL</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('email', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'email',
      key: 'email',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.email}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CONTACT</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('phone', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'phone',
      key: 'phone',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="country">{record?.phone}</div>
      ),
    },
  ];

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const pageData = async () => {
    setLoader(true);
    const response = await SignUpsApi.getSignUps(dataLength);
    if (response?.success) {
      setCount(response?.data?.length);
      setSignUpsData(response?.data);
      setSortData(response?.data);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={'User Sign Ups'}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
      />
      <Page title="Dashboard">
        <Row className="listingTopFilter">
          <Col lg={20} md={24}>
            <ListCount count1={count} title1={'Total Sign Ups'} />
          </Col>
          {/* <Col lg={4} md={24}>
            <div className="lead-search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  action={onSearch}
                  setKeyword={setKeyword}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col> */}
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!Loader ? (
              <div className="companyTable">
                <CompanyList
                  items={signUpsData}
                  dataSource={[...sortData] || []}
                  columns={columns}
                  limit={dataLength}
                />
              </div>
            ) : (
              <div className="loader-wrapper">
                <Spin spinning={Loader} className="Loader" />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={''}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};
export default SignUps;
