/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Input, Modal, Select, SelectProps, Spin, Progress } from 'antd';
import IngredientActions from '../../redux/middleware/ingredient';
import './style.scss';
import useClickListener from '../../helper/outsideClickListener';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Edit from '../../assets/images/Edit.svg';
import CategoryModal from '../categoryModal';
import { handleFloat } from '../../helper/sortData';

type Props = {
  handleDialog: any;
  open: boolean;
  component?: string;
  name: any;
  update?: boolean;
  id?: any;
  blocked?: any;
  handleSuccesDialog?: any;
  handleIngredient?: any;
  handleEditForDialog?: any;
};

const IngredientDialog = (props: Props) => {
  let {
    handleDialog,
    handleEditForDialog,
    open,
    component,
    name,
    update,
    id,
    blocked,
    handleSuccesDialog,
    handleIngredient,
  } = props;

  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  const shop = Utils.getCurrentShop();

  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [percent, setPercent] = React.useState(0);
  const [errorList, setErrorList] = useState<any>({});
  const [ingredient, setIngredient] = useState(name);
  const [ingredientType, setIngredientType] = useState(name);
  const [category, setCategory] = useState('');
  const [categoryObject, setCategoryObject] = useState<any>();
  const [inUseIngredient, setInUseIngredient] = useState<any>();
  const [unit, setUnit] = useState<any>({});
  const [allergen, setAllergen] = useState<any[]>([]);
  const [callory, setCallory] = useState<any>();
  const [carb, setcarb] = useState<any>();
  const [fat, setFat] = useState<any>();
  const [protein, setProtein] = useState<any>();
  const [error, setError] = useState(false);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [sortCategory, setSortCategory] = useState<any[]>([]);
  const [waste, setWaste] = useState<number>();
  const [parLevel, setPARLevel] = useState<number>();
  const [loader, setLoader] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(1);

  const [allergenOptions, setAllergensOptions] = useState<
    SelectProps['options']
  >([{ value: '', label: 0, key: 0 }]);
  const [option, setOption] = useState<SelectProps['options']>([
    { value: '', label: 0, key: 0 },
  ]);

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();

    if (success) {
      const filterValue = data?.find(
        (x: any) =>
          (x?.slug === 'rb_ingredient_category' &&
            component == 'rb_marketlist') ||
          (x?.slug === 'rb_ingredient_category' &&
            component == 'recipe builder') ||
          (x?.slug === 'ingredient_category' && component == 'marketlist')
      )?.id;

      setSelectedCategory(filterValue);
    }
  };

  const fetchData = async () => {
    component == 'rb_marketlist';
    const ingredientObject =
      component == 'rb_marketlist'
        ? await IngredientActions.getRBInventoryById(id)
        : await IngredientActions.getInventoryById(id);
    if (component == 'rb_marketlist') {
      setAllergen(
        ingredientObject?.data[0]?.allergens_id
          ?.split(',')
          ?.map((data: any) => +data)
      );
      setIngredient(ingredientObject?.data[0]?.ingredient_name);
      setIngredientType(ingredientObject?.data[0]?.ingredient_type || '');
      setCategory(ingredientObject?.data[0]?.category_name);
      setCategoryObject({
        id: ingredientObject?.data[0]?.category_id,
        name: ingredientObject?.data[0]?.category_name,
      });
      setUnit({
        value: ingredientObject?.data[0]?.unit_id,
        label: ingredientObject?.data[0]?.unit,
        key: ingredientObject?.data[0]?.unit_id,
      });

      setCallory(ingredientObject?.data[0]?.calorie);
      setcarb(ingredientObject?.data[0]?.carb);
      setFat(ingredientObject?.data[0]?.fat);
      setProtein(ingredientObject?.data[0]?.protein);
      setWaste(ingredientObject?.data[0]?.waste);
      setPARLevel(ingredientObject?.data[0]?.par_level);
    } else {
      setInUseIngredient(ingredientObject?.data?.in_use);
      setIngredient(ingredientObject?.data?.ingredient_name);
      setIngredientType(ingredientObject?.data?.ingredient_type || '');
      setCategory(ingredientObject?.data?.ingredient_category);
      setCategoryObject({
        id: ingredientObject?.data?.ingredient_category_id,
        name: ingredientObject?.data?.ingredient_category,
      });
      setUnit({
        value: ingredientObject?.data?.unit_id,
        label: ingredientObject?.data?.storage_unit,
        key: ingredientObject?.data?.unit_id,
      });

      ingredientObject?.data?.allergens_id
        ? setAllergen(
            ingredientObject?.data?.allergens_id
              ?.split(',')
              ?.map((data: any) => +data)
          )
        : setAllergen([]);
      setCallory(ingredientObject?.data?.calorie);
      setcarb(ingredientObject?.data?.carb);
      setFat(ingredientObject?.data?.fat);
      setProtein(ingredientObject?.data?.protein);
      setWaste(ingredientObject?.data?.waste);
      setPARLevel(ingredientObject?.data?.par_level);
    }
    setLoader(false);
  };

  const fetchAllergens = async () => {
    const data = await IngredientActions.getAllergens();
    const allergenOptions: SelectProps['options'] = data?.data.map(
      (allergen: any) => {
        return {
          value: allergen.id,
          label: allergen.name,
          key: allergen.id,
        };
      }
    );
    setAllergensOptions(allergenOptions);
  };

  const fetchUnits = async () => {
    const data =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? await IngredientActions.getUnits()
        : await SubRecipeActions.getStorageUnits();
    const option: SelectProps['options'] =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? data?.data.map((unit: any) => {
            return {
              value: unit.id,
              label: unit.unit,
              key: unit.id,
            };
          })
        : data?.data?.map((unit: any) => {
            return {
              value: unit.id,
              label: unit.storage_unit,
              key: unit.id,
            };
          });
    setOption(option);
  };

  const fetchCategory = async () => {
    // const company = await Utils.getCurrentCompany();
    const data = await MenuCategoryActions.getSubCategories(
      selectedCategory,
      1000,
      1,
      ''
    );
    setCategoryData(
      data?.data?.items?.filter((val: any) => {
        return (
          val?.sub_category !== 'Production' &&
          val?.sub_category !== 'Sub Recipe'
        );
      })
    );
    setSortCategory(
      data?.data?.items?.filter((val: any) => {
        return (
          val?.sub_category !== 'Production' &&
          val?.sub_category !== 'Sub Recipe'
        );
      })
    );
  };

  useEffect(() => {
    fetchUnits();
    fetchAllergens();
    fetchParentCategories();

    if (update) {
      fetchData();
      setLoader(true);
    }
  }, []);

  useEffect(() => {
    if (selectedCategory !== 1) {
      fetchCategory();
    }
  }, [selectedCategory]);

  useEffect(() => {
    handleProgess();
  }, [callory, carb, fat, protein]);

  const handleProgess = () => {
    const nutritions = [callory, carb, fat, protein];
    const allZeroOrUndefined = nutritions.every(
      (value) => value === 0 || value === undefined
    );
    if (!allZeroOrUndefined) {
      const progressValues = nutritions.map((value: any) => {
        if (value != undefined) return 25;
        else return 0;
      });
      setPercent(
        progressValues.reduce((partialSum: number, a) => partialSum + a, 0)
      );
    }
  };

  const handleIngredientClick = (data: any) => {
    setCategory(data?.category_name || data?.name || data?.sub_category);
    setCategoryObject(data);
    setIsComponentVisible(!isComponentVisible);
  };

  const handleChangeUOM = (value: string | string[]) => {
    setUnit(option?.filter((data) => data?.value == value)[0] || {});
  };
  const handleChangeAllergen = (value: any) => {
    setAllergen(value);
  };

  const handleCategoryName = (e: any) => {
    setCategory(e.target.value);

    if (e.target.value != '') {
      let extractCategory =
        component == 'recipe builder' || component == 'rb_marketlist'
          ? categoryData.filter((item: any) => {
              return item.sub_category
                ?.toLowerCase()
                .includes(e.target.value?.toString().toLowerCase());
            })
          : categoryData.filter((item: any) => {
              return item?.sub_category
                ?.toLowerCase()
                .includes(e.target.value?.toString().toLowerCase());
            });
      setSortCategory(extractCategory);
    } else {
      setSortCategory(categoryData);
    }
  };
  const handlemodal = (toggle: boolean, data: any) => {
    setOpenDialog(toggle);
    setCategoryObject(data);
    setCategory(data?.sub_category);
    // handleSelectOption(data);
    fetchCategory();
  };
  const handleSubmit = async () => {
    // if (ingredient === '' || category === '' || unit === '') {
    //   setError(true);
    //   return;
    // }

    setErrorStatus(false);
    setErrorList({});
    setError(false);
    let param: any =
      component == 'recipe builder' || component == 'rb_marketlist'
        ? {
            category_id: categoryObject?.id,
            fdc_id: '',
            ingredient_name: ingredient,
            brand_id: brand?.brand_id,
            company_id: company?.id,
            shop_id: shop?.id || 0,
            unit_id: +unit?.value || 0,
            calorie: callory ? callory : 0,
            calorie_unit: 1,
            carb: carb ? carb : 0,
            carb_unit: 3,
            protein: protein ? protein : 0,
            protein_unit: 3,
            fat: fat ? fat : 0,
            fat_unit: 3,
            portion: 100,
            source_id: 3,
            // waste: waste || 0,
            portion_unit: 1,
            allergens_id: allergen?.length > 0 ? allergen.toString() : '',
            is_verified:
              callory == null ||
              callory == 0 ||
              carb == null ||
              carb == 0 ||
              fat == null ||
              fat == 0 ||
              protein == null ||
              protein == 0
                ? 0
                : 1,
            verified_by: 'Head Of Culinary',
            created_by: user?.id.toString(),
          }
        : {
            // supplier_id: 0,
            // buying_unit_id: 0,
            // buying_factor: 0,
            // is_default: 0,
            unit_id: +unit?.value || null,
            waste: waste || 0,
            par_level: parLevel || 0,
            ingredient_category_id: +categoryObject?.id,
            ingredient_name: ingredient,
            product_code: '',
            company_id: company?.id,
            brand_id: Utils.getCurrentBrand()?.brand_id,
            calorie: callory ? callory : 0,
            carb: carb ? carb : 0,
            protein: protein ? protein : 0,
            fat: fat ? fat : 0,
            allergens_id: allergen?.length > 0 ? allergen.toString() : '',
            // unit_cost: '0.00',
            is_verified:
              callory == null ||
              callory == 0 ||
              carb == null ||
              carb == 0 ||
              fat == null ||
              fat == 0 ||
              protein == null ||
              protein == 0
                ? 0
                : 1,
            verified_by: 'Head Of Culinary',
            created_by: user?.id,
          };

    try {
      // let responseDialogData;
      setLoader(true);
      let response: any;
      if (update) {
        response =
          component == 'rb_marketlist'
            ? await IngredientActions.updateInventory(param, id)
            : await IngredientActions.updateNonRbInventory(param, id);
      } else {
        response =
          component == 'recipe builder' || component == 'rb_marketlist'
            ? await IngredientActions.postInventory(param)
            : await IngredientActions.createNonRbInventory(param);
      }
      setLoader(false);
      if (!response?.success) {
        setErrorList(response?.error || { ingredient_name: response?.message });
        setErrorStatus(true);
      } else {
        handleSuccesDialog();
        // responseDialogData = {
        //   OnClick: () => {
        //     handleSuccesDialog();
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your item has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
        if (component == 'recipe builder') {
          handleIngredient(response?.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const fetchCategory = async () => {
  //   // const company = await Utils.getCurrentCompany();
  //   const { data, success } = await MenuCategoryActions.getParentCategories();
  //   if (success) {
  //     const filterValue = data?.find(
  //       (x: any) =>
  //         (x?.parent_category === 'Sub Recipe Category' &&
  //           component == 'sub_recipe') ||
  //         (x?.slug === 'prep_category' &&
  //           component == 'production') ||
  //         (x?.parent_category === 'R&D Menu Category' &&
  //           component == 'rdrecipe') ||
  //         (x?.parent_category === 'R&D Sub Recipe Category' &&
  //           component == 'rdsubrecipe') ||
  //         (x?.slug === 'menu' && component == 'recipe')
  //     )?.id;
  //     setParentCategory(filterValue);
  //     const subCategories = await MenuCategoryActions.getSubCategoriesForWizard(
  //       filterValue,
  //       100
  //     );
  //     setCategoryData(subCategories?.data?.items);
  //   }
  // };

  const handleComponent = () => {
    switch (component) {
      case 'recipe builder':
        return 'recipebuilder';
      case 'rb_marketlist':
        return 'rd_ingredient_category';
      default:
        return 'marketlist';
    }
  };

  return (
    <div className="">
      <div className="modal">
        {openDialog ? (
          <CategoryModal
            handleDialog={handlemodal}
            open={openDialog}
            component={
              // component == 'recipe builder' || component == 'rb_marketlist'
              //   ? 'recipebuilder'
              //   : 'marketlist'
              handleComponent()
            }
            parentCategory={selectedCategory}
            name={category}
            modalName="Ingredient"
          />
        ) : null}
      </div>

      <Modal
        title={<div className="title font">Create Ingredients</div>}
        centered
        open={open}
        onCancel={handleDialog}
        width={608}
        footer={null}
      >
        <Spin spinning={loader} size="large" className="loaderWidthHeight">
          <div className="ingredientDialog">
            <div className="ingredientSubContainer">
              <div className="ingredientLabel ingredientNameContainer">
                <div>
                  Ingredient Name <span className="sterik">*</span>
                </div>

                {component == 'marketlist' &&
                user?.roles[0]?.id === 3 &&
                update &&
                ingredientType == 'ingredient' ? (
                  <div className="dotOption">
                    {' '}
                    <img src={Edit} width={15} onClick={handleEditForDialog} />
                  </div>
                ) : null}
              </div>
              <Input
                placeholder="Input ingredient here"
                size="large"
                value={ingredient}
                disabled={blocked}
                onChange={(e) => setIngredient(e.target.value)}
                className={
                  errorList['ingredient_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_name']}
                </div>
              )}
            </div>
            <div className="ingredientSubContainer" ref={ref}>
              <div className="ingredientLabel">
                {component === 'rb_marketlist'
                  ? 'R&D Ingredient Category'
                  : component === 'marketlist'
                  ? 'Ingredient Category'
                  : 'Category'}
                <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Input category here"
                size="large"
                value={category}
                disabled={blocked}
                onChange={handleCategoryName}
                onClick={() => setIsComponentVisible(!isComponentVisible)}
                className={
                  errorList['ingredient_category_id']?.length > 0
                    ? 'borderRed'
                    : ''
                }
              />

              {isComponentVisible && (
                <ul className="dropdownIngredientCreate subMenu">
                  <li className="heading">
                    <h4 className="headingContainer">
                      <button
                        onClick={() => {
                          // handleCreateCategory(searchQuery);
                          setOpenDialog(true);
                        }}
                      >
                        {`Create ${category} to category`}
                      </button>
                    </h4>
                  </li>

                  {sortCategory?.map((category: any, index: number) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleIngredientClick(category)}
                      >
                        <p>
                          {category?.sub_category ||
                            category?.category_name ||
                            category?.name}{' '}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_category_id']}
                </div>
              )}
            </div>
            <div className="ingredientSubContainer UOMAllergen">
              <div className="spanWidth">
                <div className="ingredientLabel">
                  UOM <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  defaultValue={'Select UOM'}
                  onChange={handleChangeUOM}
                  style={{ width: '100%' }}
                  options={option}
                  value={unit?.label}
                  disabled={blocked || inUseIngredient === true}
                  className={
                    errorList['unit_id']?.length > 0
                      ? 'createIngredientBorderRed'
                      : ''
                  }
                />
                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['unit_id']}
                  </div>
                )}
              </div>
              <div className="spanWidth">
                <div className="ingredientLabel">
                  Allergens {/* <span className="sterik">*</span> */}
                </div>
                {/* <Select
              size={'large'}
              defaultValue={'Select Allergen'}
              onChange={handleChangeAllergen}
              style={{ width: '100%' }}
              options={allergenOptions}
            /> */}
                <Select
                  mode="multiple"
                  size={'large'}
                  placeholder="Select Allergen"
                  onChange={handleChangeAllergen}
                  style={{ width: '100%' }}
                  options={allergenOptions}
                  disabled={blocked}
                  value={allergen}
                />
                {/* {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['ingredient_category_id']}
                </div>
              )} */}
              </div>
            </div>
            {component !== 'recipe builder' && component !== 'rb_marketlist' && (
              <div className="ingredientSubContainer storageWaste">
                <div className="spanWidth">
                  <div className="ingredientLabel">Storage Waste %</div>
                  <Input
                    className="inputIngredients"
                    placeholder="Input Waste % here"
                    size="large"
                    type="number"
                    value={waste !== 0 ? waste : blocked ? waste : ''}
                    onChange={(e) => setWaste(+e.target.value)}
                    disabled={blocked}
                  />
                </div>
                {/* <div className="spanWidth">
                  <div className="ingredientLabel">PAR Level %</div>
                  <Input
                    className="inputIngredients"
                    placeholder="Input PAR Level % here"
                    size="large"
                    type="number"
                    value={parLevel !== 0 ? parLevel : blocked ? parLevel : ''}
                    onChange={(e) => setPARLevel(+e.target.value)}
                    disabled={blocked}
                  />
                </div> */}
              </div>
            )}
            <div className="ingredientSubContainer">
              <div className="additonal">
                <div>Calories {/* <span className="sterik">*</span> */}</div>
                <div>Carbs {/* <span className="sterik">*</span> */}</div>
                <div>Protein {/* <span className="sterik">*</span> */}</div>
                <div>Fat {/* <span className="sterik">*</span> */}</div>
              </div>
              <div
                className={`unitContainer ${
                  blocked ? 'unitContainerDisable' : ''
                }`}
              >
                <div className="borderContainer">
                  <div className="unitSubContainer">
                    <input
                      className="gramValue"
                      type="text"
                      value={callory}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d{1,3}(,\d{3})*(\d+)?(\.\d*)?$/.test(value) ||
                          value === ''
                        ) {
                          setCallory(value);
                        }
                      }}
                      onBlur={() => {
                        if (callory !== '') {
                          const cleanedValue = parseFloat(
                            callory.replace(/,/g, '')
                          );
                          if (!isNaN(cleanedValue)) {
                            setCallory(cleanedValue.toString());
                          }
                        }
                      }}
                      disabled={blocked}
                    />
                    <div className="unitColor">kcal</div>
                  </div>
                </div>
                <div className="borderContainer">
                  <div className="unitSubContainer">
                    <input
                      className="gramValue"
                      // placeholder="0"
                      type="text"
                      value={carb}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d{1,3}(,\d{3})*(\d+)?(\.\d*)?$/.test(value) ||
                          value === ''
                        ) {
                          setcarb(value);
                        }
                      }}
                      onBlur={() => {
                        if (carb !== '') {
                          const cleanedValue = parseFloat(
                            carb.replace(/,/g, '')
                          );
                          if (!isNaN(cleanedValue)) {
                            setcarb(cleanedValue.toString());
                          }
                        }
                      }}
                      disabled={blocked}
                    />
                    <div className="unitColor">g</div>
                  </div>
                </div>
                <div className="borderContainer">
                  <div className="unitSubContainer ">
                    <input
                      className="gramValue"
                      // placeholder="0"
                      type="text"
                      value={protein}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d{1,3}(,\d{3})*(\d+)?(\.\d*)?$/.test(value) ||
                          value === ''
                        ) {
                          setProtein(value);
                        }
                      }}
                      onBlur={() => {
                        if (protein !== '') {
                          const cleanedValue = parseFloat(
                            protein.replace(/,/g, '')
                          );
                          if (!isNaN(cleanedValue)) {
                            setProtein(cleanedValue.toString());
                          }
                        }
                      }}
                      disabled={blocked}
                    />
                    <div className="unitColor">g</div>
                  </div>
                </div>
                <div className="borderContainer">
                  <div className="unitSubContainer">
                    <input
                      className="gramValue"
                      // placeholder="0"
                      type="text"
                      value={fat}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          /^\d{1,3}(,\d{3})*(\d+)?(\.\d*)?$/.test(value) ||
                          value === ''
                        ) {
                          setFat(value);
                        }
                      }}
                      onBlur={() => {
                        if (fat !== '') {
                          const cleanedValue = parseFloat(
                            fat.replace(/,/g, '')
                          );
                          if (!isNaN(cleanedValue)) {
                            setFat(cleanedValue.toString());
                          }
                        }
                      }}
                      disabled={blocked}
                    />
                    <div className="unitColor">g</div>
                  </div>
                </div>
              </div>
              <div className="progressBarAlignment">
                <div className="label"> Per 100g</div>
                <div className="label">
                  {' '}
                  <Progress percent={percent} steps={4} strokeColor="green" />
                  <div
                    className={
                      percent === 100
                        ? 'labelProgressBarGreen'
                        : 'labelProgressBarRed'
                    }
                  >
                    Calorie Counted
                  </div>
                </div>
              </div>

              {/* {errorStatus && (
              <div className="subRecipeErrorMessages">
                {errorList['ingredient_category_id']}
              </div>
            )} */}
            </div>
            {error && <div className="error">*Fill All Feilds</div>}

            {blocked ? null : (
              <div className="butonGroup">
                {/* <div className="wizard-button" onClick={() => handleDialog({})}>
            Back
          </div> */}
                <div className="continue wizard-button" onClick={handleSubmit}>
                  Continue
                </div>
              </div>
            )}
          </div>
        </Spin>
      </Modal>
    </div>
  );
};

export default IngredientDialog;
