import React, { useState, useEffect } from 'react';
import Popover from 'antd/es/popover';
import { Radio, Select } from 'antd';
import './inventoryFilter.scss';
import MenuCategoryActions from '../../redux/middleware/menuCategory';

type Props = {
  children: any;
  onApply: any;
  open: boolean;
  onClose: any;
  onReset?: any;
};

const InventoryFilter = (props: Props) => {
  let { children, onApply, open, onClose, onReset } = props;
  const [columnName, setColumnName] = useState<any>({});
  const [categories, setCategories] = useState<any[]>([]);
  const [subCategory, setSubCategory] = useState<any[]>([]);
  const [subCategories, setSubCategories] = useState<any[]>([]);
  const [sortedSubCategory, setSortedSubCategory] = useState<any[]>([]);
  const [selectedIngredientType, setSelectedIngredientType] = useState<any[]>(
    []
  );
  const [types] = useState<any[]>([
    { label: 'Sub Recipe', value: 'sub_recipe' },
    { label: 'Production', value: 'production' },
    { label: 'Ingredients', value: 'ingredient' },
    { label: 'Sustainable', value: 'sustainable' },
  ]);
  const [filters, setFilters] = useState({
    type: '',
    sortField: 'ingredient_name',
    sort: 0,
  });
  const [sort, setSort] = useState('asc');
  const buttons = [
    { label: 'A to Z', value: 'asc' },
    { label: 'Z to A', value: 'desc' },
  ];

  const item1 = [
    // { label: 'Sku', value: 1, key: 'sku_id' },
    { label: 'Calorie Counted', value: 2, key: 'is_verified' },
    { label: 'Ingredient Type', value: 3, key: 'ingredient_type' },
    // { label: 'Unit', value: 4, key: 'unit_id' },
  ];

  useEffect(() => {
    fetchParentCategories();
  }, []);

  useEffect(() => {
    if (categories?.length) {
      setSortedSubCategory([]);
      fetchSubCategories();
    }
  }, [categories, selectedIngredientType]);

  const fetchSubCategories = async () => {
    const SubCategories =
      await MenuCategoryActions.getSubCategoriesForInventory(
        5,
        500,
        selectedIngredientType
      );

    if (SubCategories?.success) {
      setSubCategories(SubCategories?.data?.items);
      setSortedSubCategory(SubCategories?.data?.items);
    }
  };

  const fetchParentCategories = async () => {
    const { data, success } = await MenuCategoryActions.getParentCategories();
    if (success) {
      setCategories(
        data?.filter(
          (categoryObj: any) =>
            categoryObj?.slug === 'sub_recipe' ||
            categoryObj?.slug === 'prep_category' ||
            categoryObj?.slug === 'ingredient_category'
        )
      );
    }
  };

  const onSearchSubCategory = (value: any) => {
    const filteredArray = subCategories.filter((subCategoryObj: any) =>
      subCategoryObj?.sub_category.toLowerCase().includes(value)
    );
    setSortedSubCategory([...filteredArray]);
  };

  const handleSubCategoryChange = (value: any) => {
    setSubCategory(value);
  };

  return (
    <>
      <Popover
        placement="bottom"
        open={open}
        onOpenChange={() => onClose(!open)}
        content={
          <div>
            <div className="dropdown-filter-container filters">
              <Select
                className="ingredient-type"
                mode="multiple"
                style={{ width: 180 }}
                placeholder="Ingredient Type"
                onChange={(value: any) => {
                  setSelectedIngredientType(value);
                  // console.log('value ing type', value);
                  // pageData();
                  setFilters({
                    ...filters,
                    type: value,
                    sort: !filters?.sort ? 1 : filters?.sort,
                  });
                }}
                options={types}
              />
              <Select
                mode="multiple"
                onSearch={onSearchSubCategory}
                style={{ width: 180 }}
                placeholder="Sub Category"
                onChange={handleSubCategoryChange}
                optionLabelProp="label"
                value={subCategory}
              >
                {sortedSubCategory?.map(
                  (data: any, subCategoryIndex: number) => (
                    <Select.Option
                      value={data?.id}
                      key={subCategoryIndex}
                      label={data.sub_category}
                    >
                      {data.sub_category}
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
            <div className="inventoryFilter">
              <Select
                size={'large'}
                placeholder={'Sort By'}
                onChange={(e) => {
                  // console.log(e);
                  // console.log(item1);
                  setColumnName(item1?.find((item: any) => item?.value === +e));
                  // setColumnName(e);
                }}
                style={{ width: 180 }}
                optionFilterProp="children"
                value={columnName?.value || null}
                // options={item1}
              >
                {item1?.map((data: any, index: number) => (
                  <Select.Option key={index} value={data?.value}>
                    {data?.label}
                  </Select.Option>
                ))}
              </Select>
              <Radio.Group
                options={buttons}
                onChange={(e) => {
                  // console.log(e, e.target.value);
                  setSort(e.target.value);
                }}
                value={sort}
                optionType="button"
                className="inventoryFilter-Radio"
                disabled={!columnName?.value}
              />
              {/* <Checkbox
          // onChange={(e: any) => onSelectAllIngredients(e, ingredientList)}
          // checked={visible}
          >
            Verified
          </Checkbox> */}
            </div>
            <div className="inventoryFilter-Apply-container reset-btn">
              <div
                className="reset-filter-button"
                onClick={() => {
                  setSubCategory([]);
                  setColumnName({});
                  setSort('asc');
                  onReset();
                }}
              >
                Reset
              </div>
              <div
                className="inventoryFilter-Apply"
                onClick={() => {
                  let sortField;
                  // let sortOrder;
                  switch (columnName?.key) {
                    case 'is_verified':
                      sortField = ['is_verified', 'ingredient_name'];
                      break;
                    case 'ingredient_type':
                      sortField = ['ingredient_type', 'ingredient_name'];
                      break;
                    default:
                      sortField = ['ingredient_name'];
                  }
                  onApply(
                    sortField,
                    [sort],
                    selectedIngredientType,
                    subCategory
                  );
                }}
              >
                Apply
              </div>
            </div>
          </div>
        }
        title="Filter"
        trigger="click"
      >
        {children}
      </Popover>
      {/* <Select
        size={'large'}
        placeholder={'Select Column'}
        onChange={(e) => {
          console.log(e);
          setColumnName(e);
        }}
        style={{ width: 180 }}
        // optionFilterProp="children"
        value={columnName}
        options={item1}
      /> */}
    </>
  );
};

export default InventoryFilter;
