/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Checkbox, Collapse, Slider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import ArrowDown from '../../assets/svg/arrowDown.svg';
import { useSelector } from 'react-redux';

import './Filter.scss';

type Props = {
  action: Function;
  apiCall: Function;
  component?: string;
  maximumCalorie?: number;
};

const Filter = (props: Props) => {
  let { component, maximumCalorie } = props;
  const { Panel } = Collapse;
  const [checkedListCategory, setCheckedListCategory] = useState<
    CheckboxValueType[]
  >([]);
  const [checkedListCalories, setCheckedListCalories] = useState<
    CheckboxValueType[]
  >([]);
  // const [checkedListVerified, setCheckedListVerified] = useState<
  //   CheckboxValueType[]
  // >([]);
  const [checkedListAllergen, setCheckedListAllergen] = useState<
    CheckboxValueType[]
  >([]);

  const [checkedListType, setCheckedListType] = useState<CheckboxValueType[]>(
    []
  );

  const [showButton, setShowButton] = useState(false);
  const [pillVlues, setPillVlues] = useState([{ label: '', value: '' }]);
  const [category, setCategory] = useState([{ label: '', value: '' }]);
  const [calories, setCalories] = useState([{ label: '', value: '' }]);
  const [verified, setVerified] = useState([{ label: '', value: '' }]);
  const [allergen, setAllergen] = useState([{ label: '', value: '' }]);
  const [type, setType] = useState([{ label: '', value: '' }]);
  const [allowFilterRequest, setAllowFilterRequest] = useState(false);
  const [calorieRange, setCalorieRange] = useState<[number, number]>([0, 0]);
  const [calorieMaxRange, setCalorieMaxRange] = useState<number>(0);

  const [categoryOptions, setCategoryOptions] = useState([
    {
      value: '',
      label: '',
      count: '',
    },
  ]);
  const [caloriesOptions, setCalorieOptions] = useState([
    { label: '', value: '' },
  ]);
  const [allergenOptions, setAllergenOptions] = useState([
    { label: '', value: '' },
  ]);
  // const [verifiedOptions, setVerifiedOptions] = useState([
  //   { label: '', value: '' },
  // ]);

  const [categoryFilters, setCategoryFilters] = useState([
    { label: '', value: '', count: '' },
  ]);
  const [calorieFilters, setCalorieFilters] = useState([
    { label: '', value: '' },
  ]);
  const [allergenFilters, setAllergenFilters] = useState([
    { label: '', value: '' },
  ]);
  const [typeFilters, setTypeFilters] = useState([{ label: '', value: '' }]);
  // const [verifiedFilters, setVerifiedFilters] = useState([
  //   { label: '', value: '' },
  // ]);
  const { currentBrand } = useSelector((state: any) => state.Auth);

  const getMaxCalorieRange = (maxCalorie: number): number => {
    if (maxCalorie <= 1500) {
      return 1500;
    } else {
      const magnitude = Math.pow(10, Math.floor(Math.log10(maxCalorie)));
      return Math.ceil(maxCalorie / magnitude) * magnitude;
    }
  };

  const marks = {
    0: '0',
    [calorieMaxRange]: calorieMaxRange.toString(),
  };

  useEffect(() => {
    getFilters();
  }, [currentBrand]);

  useEffect(() => {
    const maxRange = getMaxCalorieRange(maximumCalorie ?? 0);
    setCalorieMaxRange(maxRange);
    setCalorieRange([0, maxRange]);
    if (component === 'menu') {
      if (calorieFilters?.length > 1) {
        handleResponseFilter();
      }
    } else {
      if (allergenFilters?.length > 1) {
        handleResponseFilter();
      }
    }
  }, [allergenFilters, maximumCalorie]);

  useEffect(() => {
    if (pillVlues.length < 5) {
      setShowButton(false);
    }
  });

  const getFilters = async () => {
    const filters = await props.apiCall();
    setCategoryFilters(filters?.data?.menu_category);
    setCalorieFilters(filters?.data?.calorie);
    // setVerifiedFilters(filters?.data?.is_verified || []);
    setAllergenFilters(filters?.data?.allergen_items);
    setTypeFilters(filters?.data?.recipe_type);
  };

  const handleResponseFilter = () => {
    // if (categoryFilters?.length > 3) {
    //   setCategoryOptions(categoryFilters?.slice(0, 3));
    // } else {
    //   setCategoryOptions(categoryFilters);
    // }
    setCategoryOptions(categoryFilters);
    if (calorieFilters?.length > 3) {
      setCalorieOptions(calorieFilters?.slice(0, 3));
    } else {
      setCalorieOptions(calorieFilters);
    }

    // if (verifiedFilters?.length > 3) {
    //   setVerifiedOptions(verifiedFilters?.slice(0, 3));
    // } else {
    //   setVerifiedOptions(verifiedFilters);
    // }

    // if (allergenFilters?.length > 3) {
    //   setAllergenOptions(allergenFilters?.slice(0, 3));
    // } else {
    //   setAllergenOptions(allergenFilters);
    // }
    setAllergenOptions(allergenFilters);
  };

  const sendAppliedFilterBack = () => {
    const filter = {
      category_id: checkedListCategory?.join(','),
      // is_verified: checkedListVerified?.join(','),
      calorie: checkedListCalories?.join(','),
      allergens_id: checkedListAllergen?.join(','),
      recipe_type: checkedListType?.join(','),
    };
    props.action(filter);
  };

  useEffect(() => {
    if (pillVlues.length > 3) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    if (allowFilterRequest) {
      sendAppliedFilterBack();
    }
  }, [pillVlues]);

  function onChangeCategoryValues(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListCategory(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = categoryOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setCategory([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...calories, ...verified, ...allergen, ...type]);
  }

  function onChangeTypeValues(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListType(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = typeFilters?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setType([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...calories, ...verified, ...allergen, ...category]);
  }

  function onChangeCaloriesValue(checkedValues: any) {
    setAllowFilterRequest(true);
    setCalorieRange(checkedValues);
    // let arr: any[] = [];
    setCheckedListCalories(checkedValues);
    // arr.push(...checkedValues);
    // checkedValues.map((item: any) => {
    //   let foundKey = caloriesOptions?.filter((x) => x.value === item);
    //   if (foundKey) {
    //   arr.push(...checkedValues);
    //   }
    // });
    const updatedCalories = checkedValues.map((value: any) => ({
      label: String(value),
      value: value,
    }));
    setCalories(updatedCalories);
    setPillVlues([
      ...updatedCalories,
      ...category,
      ...verified,
      ...allergen,
      ...type,
    ]);
  }

  function onChangeAllergenValue(checkedValues: any) {
    setAllowFilterRequest(true);
    let arr: any[] = [];
    setCheckedListAllergen(checkedValues);
    checkedValues.map((item: any) => {
      let foundKey = allergenOptions?.filter((x) => x.value === item);
      if (foundKey) {
        arr.push(...foundKey);
      }
    });
    setAllergen([...arr, { label: '', value: '' }]);
    setPillVlues([...arr, ...category, ...verified, ...calories, ...type]);
  }

  // function onChangeVerifiedValues(checkedValues: any) {
  //   setAllowFilterRequest(true);
  //   let arr: any[] = [];
  //   setCheckedListVerified(checkedValues);
  //   checkedValues.map((item: any) => {
  //     let foundKey = verifiedOptions?.filter((x) => x.value === item);
  //     if (foundKey) {
  //       arr.push(...foundKey);
  //     }
  //   });
  //   setVerified([...arr, { label: '', value: '' }]);
  //   setPillVlues([...arr, ...category, ...calories, ...allergen]);
  // }

  const handleClear = () => {
    if (pillVlues.length < 5) {
      setShowButton(false);
    }
    setAllowFilterRequest(true);
    setPillVlues([
      {
        label: '',
        value: '',
      },
    ]);
    setCheckedListCategory([]);
    setCheckedListCalories([]);
    setCheckedListType([]);
    // setCheckedListVerified([]);
    setCheckedListAllergen([]);
    setCalories([
      {
        label: '',
        value: '',
      },
    ]);
    setCategory([
      {
        label: '',
        value: '',
      },
    ]);
    setType([
      {
        label: '',
        value: '',
      },
    ]);
    setVerified([
      {
        label: '',
        value: '',
      },
    ]);
    setAllergen([
      {
        label: '',
        value: '',
      },
    ]);
    setCalorieRange([0, calorieMaxRange]);
  };

  const handleFilter = (pill: any) => {
    setAllowFilterRequest(true);
    setPillVlues((current) =>
      current.filter((item) => {
        return item?.label !== pill.label;
      })
    );
    let categoryFound = category.some(
      (element) => element.label === pill.label
    );
    if (categoryFound) {
      let catArr = [...category];
      catArr = catArr.filter((item) => {
        return item.label !== pill.label;
      });
      setCategory(catArr);
      setCheckedListCategory(catArr.map((c) => c.value));
    }
    let caloryFound = calories.some((element) => element.label === pill.label);
    if (caloryFound) {
      let calArr = [...calories];
      calArr = calArr.filter((item) => {
        return item.label !== pill.label;
      });
      setCalories(calArr);
      setCheckedListCalories(calArr.map((c) => c.value));
    }
    // let verifiedFound = verified.some(
    //   (element) => element.label === pill.label
    // );
    // if (verifiedFound) {
    //   let verArr = [...verified];
    //   verArr = verArr.filter((item) => {
    //     return item.label !== pill.label;
    //   });
    //   setVerified(verArr);
    //   setCheckedListVerified(verArr.map((c) => c.value));
    // }

    let allergenFound = allergen.some(
      (element) => element.label === pill.label
    );

    if (allergenFound) {
      let allergenArr = [...allergen];
      allergenArr = allergenArr.filter((item) => {
        return item.label !== pill.label;
      });
      setAllergen(allergenArr);
      setCheckedListAllergen(allergenArr.map((c) => c.value));
    }

    let typeFound = type.some((element) => element.label === pill.label);

    if (typeFound) {
      let typeArr = [...allergen];
      typeArr = typeArr.filter((item) => {
        return item.label !== pill.label;
      });
      setType(typeArr);
      setCheckedListType(typeArr.map((c) => c.value));
    }
  };

  const handleMore = (option: string) => {
    switch (option) {
      case 'Category':
        setCategoryOptions(categoryFilters);
        break;
      case 'Calories':
        setCalorieOptions(calorieFilters);
        break;
      // case 'Verified':
      //   setVerifiedOptions(verifiedFilters);
      //   break;
      case 'Allergen':
        setAllergenOptions(allergenFilters);
        break;

      default:
    }
  };

  const handleLess = (option: string) => {
    switch (option) {
      case 'Category':
        setCategoryOptions(categoryFilters.slice(0, 3));
        break;
      case 'Calories':
        setCalorieOptions(calorieFilters.slice(0, 3));
        break;
      // case 'Verified':
      //   setVerifiedOptions(verifiedFilters.slice(0, 3));
      //   break;
      case 'Allergen':
        setAllergenOptions(allergenFilters.slice(0, 3));
        break;

      default:
    }
  };

  return (
    <div className="container filterContainer" style={{ minHeight: '800px' }}>
      <div className="filterScroll">
        <div className="topHeading">
          {pillVlues?.length < 5 ? 'Select a Filter' : ' Selected Filter'}
        </div>
        {pillVlues?.map((pill, index) =>
          pill.label !== '' ? (
            <div className="pill" key={index}>
              <div className="filterTitle">{pill?.label}</div>
              <div className="tagStyling" onClick={() => handleFilter(pill)}>
                <CloseOutlined twoToneColor={'white'} className="iconStyling" />
              </div>
            </div>
          ) : null
        )}

        {showButton ? (
          <div className="linkText" onClick={handleClear}>
            Clear all
          </div>
        ) : null}
        <Collapse
          // className="filterScroll"
          bordered={false}
          defaultActiveKey={['1', '2', '4', '5']}
          ghost
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? <img src={ArrowDown} /> : <img src={ArrowDown} />
          }
        >
          {typeFilters && (
            <Panel className="panelStyling" key="5" header="Recipe Type">
              <Checkbox.Group
                options={typeFilters}
                defaultValue={['Apple']}
                value={checkedListType}
                onChange={onChangeTypeValues}
              />
              {/* typeFilters.length > 3 ? (
                <div className="btnShow" onClick={() => handleLess('Calories')}>
                  ShowLess
                </div>
              ) : (
                <div
                  className={typeFilters.length > 2 ? 'btnShow' : 'btnHide'}
                  onClick={() => handleMore('Calories')}
                >
                  ShowMore
                </div>
              ) */}{' '}
            </Panel>
          )}

          {component !== 'menu' && categoryOptions && (
            <Panel className="panelStyling" key="1" header="Category">
              <Checkbox.Group
                className="checkBoxStyling"
                options={categoryOptions.map((option) => ({
                  label: `${option.label} (${option.count})`,
                  value: option.value,
                }))}
                // options={categoryOptions}
                defaultValue={['Apple']}
                value={checkedListCategory}
                onChange={onChangeCategoryValues}
              />
              {categoryOptions.length > 3 ? (
                <div className="btnShow" onClick={() => handleLess('Category')}>
                  ShowLess
                </div>
              ) : (
                <div
                  className={categoryOptions.length > 2 ? 'btnShow' : 'btnHide'}
                  onClick={() => handleMore('Category')}
                >
                  ShowMore
                </div>
              )}
            </Panel>
          )}
          {/* {verifiedOptions ? (
            <Panel key="3" header="Calorie Counted">
              <Checkbox.Group
                options={verifiedOptions}
                defaultValue={['Apple']}
                value={checkedListVerified}
                onChange={onChangeVerifiedValues}
              />
              {verifiedOptions.length > 3 ? (
                <div className="btnShow" onClick={() => handleLess('Verified')}>
                  ShowLess
                </div>
              ) : (
                <div
                  className={verifiedOptions.length > 2 ? 'btnShow' : 'btnHide'}
                  onClick={() => handleMore('Verified')}
                >
                  ShowMore
                </div>
              )}{' '}
            </Panel>
          ) : null} */}
          {caloriesOptions && (
            <Panel className="panelStyling" key="2" header="Calories">
              <div>
                <Slider
                  range
                  min={0}
                  max={calorieMaxRange}
                  defaultValue={calorieRange}
                  onChange={onChangeCaloriesValue}
                  className="calories-slider"
                  marks={marks}
                />
                <div>
                  <p>
                    {calorieRange[0]} - {calorieRange[1]} kCal
                  </p>
                </div>
              </div>
              {/* <Checkbox.Group
                options={caloriesOptions}
                defaultValue={['Apple']}
                value={checkedListCalories}
                onChange={onChangeCaloriesValue}
              />
              {caloriesOptions.length > 3 ? (
                <div className="btnShow" onClick={() => handleLess('Calories')}>
                  ShowLess
                </div>
              ) : (
                <div
                  className={caloriesOptions.length > 2 ? 'btnShow' : 'btnHide'}
                  onClick={() => handleMore('Calories')}
                >
                  ShowMore
                </div>
              )} */}
            </Panel>
          )}

          {allergenOptions && (
            <Panel
              className="panelStyling"
              key="4"
              header="Allergens (Free From)"
            >
              <Checkbox.Group
                options={allergenOptions}
                defaultValue={['Apple']}
                value={checkedListAllergen}
                onChange={onChangeAllergenValue}
              />
              {allergenOptions.length > 3 ? (
                <div className="btnShow" onClick={() => handleLess('Allergen')}>
                  ShowLess
                </div>
              ) : (
                <div
                  className={allergenOptions.length > 2 ? 'btnShow' : 'btnHide'}
                  onClick={() => handleMore('Allergen')}
                >
                  ShowMore
                </div>
              )}{' '}
            </Panel>
          )}
        </Collapse>
      </div>
      <div className="buttonContainer">
        <button className="resetBtn" onClick={handleClear}>
          Reset Filter
        </button>
      </div>
    </div>
  );
};

export default Filter;
