import React, { useEffect } from 'react';
import { Col, Row, Form, Input, notification } from 'antd';
// import SideImage from '../../assets/images/authImage.png';
import Logo from '../../assets/svg/logo.svg';
import './confirmationForEmail.scss';
import { useNavigate } from 'react-router-dom';
import AuthActions from '../../redux/middleware/auth';
import { useSearchParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import { Button } from '../../components';

const ConfirmationEmailPage = () => {
  const [, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [token, setToken] = React.useState<any>('');
  const [id, setId] = React.useState<any>('');
  const [password, setPassword] = React.useState<any>('');
  const [email, setEmail] = React.useState<any>('');
  const [confirmPassword, setConfirmPassword] = React.useState<any>('');
  const [isSuccess, setSuccess] = React.useState(false);

  useEffect(() => {
    setToken(searchParams?.get('token'));
    setId(searchParams?.get('user_id'));
    setEmail(searchParams?.get('email'));
  }, []);

  const userVerificationRequest = async () => {
    try {
      const apiResponse = await AuthActions.VerifyUser(
        id,
        token,
        email,
        password
      );
      if (apiResponse?.success) {
        setSuccess(true);
        setMessage(apiResponse?.message);

        setTimeout(() => {
          navigate('/auth');
        }, 4000);
      } else {
        setMessage('Failed to verify user. Please Try Again');
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const onSubmit = () => {
    if (password !== confirmPassword) {
      setSuccess(false);
      setMessage('Password unmatched');
      return;
    }

    setMessage('');
    userVerificationRequest();
  };

  return (
    <React.Fragment>
      {contextHolder}
      <Row className="authContainer" style={{ height: window.innerHeight }}>
        <Col lg={7} className="leftSideForForgotPassword">
          <div className="sideLogoContainer">
            <img src={Logo} height={50} alt={'logo'} className="sideLogo" />
          </div>
          <Form
            name="normal_login"
            className="forgetPassword-form"
            initialValues={{ remember: true }}
          >
            {/* <label className="b2 upload-label">
              Email <span className="upload-span">*</span>
            </label> */}
            <Input
              className="login-input spacing"
              type="email"
              placeholder="Email"
              disabled={true}
              value={email}
              // onChange={(e) => setEmail(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSubmit();
                }
              }}
            />
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <label className="b2 upload-label">
                Enter Password <span className="upload-span">*</span>
              </label>
              <Input.Password
                className="login-input"
                type="password"
                placeholder="Enter password here"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <label className="b2 upload-label">
                Confirm Password <span className="upload-span">*</span>
              </label>
              <Input.Password
                className="login-input"
                type="password"
                placeholder="Enter confirm password here"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                size="large"
                shape="round"
                text="Continue"
                handleButtonClick={onSubmit}
                type="primary"
                className="theme-button login-btn"
                focus
              />
            </Form.Item>

            {isSuccess ? (
              <div className="alignment success">
                <label className="b upload-label ">{message}</label>
              </div>
            ) : (
              <div className="alignment error">
                <label className="b upload-label ">{message}</label>
              </div>
            )}
          </Form>
        </Col>
        {/* <Col lg={16} className="rightSide">
          <img src={SideImage} alt={'sideImage'} />
        </Col> */}
      </Row>
    </React.Fragment>
  );
};

export default ConfirmationEmailPage;
