/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Spin, Tooltip } from 'antd';
import SubMenu from '../../assets/images/SubMenu.svg';
import Print from '../../assets/images/Print.png';
import Allergens from '../../assets/images/allergens.svg';
import './style.scss';
import {
  formatNumberFloatValue,
  formatNumber,
  decimalCount,
} from '../../helper/sortData';

type Props = {
  open: boolean;
  handleClose: any;
  handleSubDialog: any;
  data: any;
  component: any;
  printApi?: any;
  variationName?: any;
};

const Dialog = (props: Props) => {
  let {
    open,
    handleClose,
    data,
    component,
    handleSubDialog,
    printApi,
    variationName,
  } = props;
  let ingredient = data?.data?.ingredients;
  let ingredientForMenu = data?.ingredients;
  const [Recipedata, setRecipeData] = useState<any>([]);
  const [sustainable, setSustainable] = useState<any>(null);
  const [loader, setLoader] = useState(false);

  const [reuseWastePercent, setReuseWastePercent] = useState<any>();
  const [reuseWasteValue, setReuseWasteValue] = useState<any>();
  const [compostableWastePercent, setCompostableWastePercent] = useState<any>();
  const [compostableWasteValue, setCompostableWasteValue] = useState<any>();

  useEffect(() => {
    if (sustainable) {
      handleWastePercent(sustainable?.waste);
    }
  }, [sustainable]);

  useEffect(() => {
    setSustainable(data?.data?.sustainable || null);
    if (component === 'menu') {
      setRecipeData(data);
    } else {
      setRecipeData(data?.data?.general);
    }
  }, [data]);

  const handleSubRecipeDetail = (ingredient: any) => {
    if (component === 'RecipeBuilder') {
      handleSubDialog(ingredient?.sub_recipe_id);
    } else {
      handleSubDialog(ingredient?.sku);
    }
  };

  const handleWastePercent = (value: any) => {
    if (value == 0) {
      setCompostableWastePercent(value);
      setCompostableWasteValue(0);
      setReuseWastePercent(0);
      setReuseWasteValue(0);
    }
    if (+value <= 100 && decimalCount(+value) <= 2) {
      const wasteQuantity =
        (ingredient[0]?.quantity / 100) * Number(data?.data?.general?.waste);
      const wasteReusePer = 100 - Number(value);

      setCompostableWastePercent(value);
      setReuseWastePercent(wasteReusePer);
      setReuseWasteValue((wasteQuantity / 100) * wasteReusePer);
      setCompostableWasteValue((wasteQuantity / 100) * Number(value));
    }
  };

  const handlePrintApi = async (id: any) => {
    switch (component) {
      case 'RecipeBuilder':
        return await printApi.getRecipeLink(id);
      case 'recipe':
        return await printApi.downloadRecipe(id);
      default:
        return { success: false, data: {} };
    }
  };

  const handlePrint = async (id: any) => {
    setLoader(true);
    const { data, success } = await handlePrintApi(id);
    setLoader(false);
    if (success) {
      // setPdf(data);
      openInNewTab(data);
    }
  };

  const handleRecipeId = () => {
    switch (component) {
      case 'RecipeBuilder':
        return Recipedata?.rb_id;
      case 'recipe':
        return Recipedata?.recipe_id;
      case 'subRecipe':
        return Recipedata?.recipe_id;
      default:
        return Recipedata?.id;
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Modal
      title={<div className="title font">Ingredient Details</div>}
      centered
      open={open}
      onCancel={() => {
        !loader && handleClose();
      }}
      width={480}
      footer={null}
      className="DialogWrapper"
    >
      <Spin spinning={loader} size="large">
        <div>
          {component !== 'subRecipe' && (
            <div className="cover-photo">
              <img
                src={Recipedata?.thumbnail}
                alt=""
                // width={'432px'}
                // height="240px"
                className="image"
              />
              <div className="imgtag">
                {component === 'RecipeBuilder'
                  ? Recipedata?.category_name
                  : Recipedata?.menu_category}
              </div>
            </div>
          )}
          <div className={'subhead'}>
            <div className={`${'subRecipe-Head'} font`}>
              {Recipedata?.recipe_name}{' '}
            </div>

            {/* <div
              onClick={() => handlePrint(handleRecipeId())}
              className="print-button-container"
            >
              <img src={Print} alt="" />
              <div className="subHead-right">Print</div>
            </div> */}
          </div>
          {Recipedata?.is_variation == 'yes' && (
            <div className="variation-container">
              <div className="font">Variation: </div>
              <div>{variationName}</div>
            </div>
          )}
          <div className="block3">
            <div className="ingredient">Ingredients</div>
            {component !== 'menu' ? (
              <div className="ingredient dialog-ingredient-quantity">
                QUANTITY
              </div>
            ) : null}
          </div>
          {component === 'recipe'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubDialog(ingredient?.sku)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            alt=""
                            width={15}
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                      <span>
                        {ingredient?.allergen && (
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Allergens</div>
                                <div className="tooltipText">
                                  {ingredient.allergen}
                                </div>
                              </div>
                            }
                          >
                            <img
                              src={Allergens}
                              width={15}
                              alt=""
                              className="subRecipeAllergenIcons"
                            />
                          </Tooltip>
                        )}
                      </span>
                    </div>

                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''}
          {component === 'menu'
            ? ingredientForMenu?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubDialog(ingredient?.sku)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            alt=""
                            width={15}
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                    </div>

                    {/* <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div> */}
                  </div>
                );
              })
            : ''}
          {component === 'subRecipe' || component === 'RecipeBuilder'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubRecipeDetail(ingredient)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            width={15}
                            alt=""
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                      <span>
                        {ingredient?.allergen && (
                          <Tooltip
                            title={
                              <div>
                                <div className="tooltipHead">Allergens</div>
                                <div className="tooltipText">
                                  {ingredient.allergen}
                                </div>
                              </div>
                            }
                          >
                            <img
                              src={Allergens}
                              width={15}
                              alt=""
                              className="subRecipeAllergenIcons"
                            />
                          </Tooltip>
                        )}
                      </span>
                    </div>
                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''}
          {component !== 'recipe' && component !== 'menu' ? (
            <div>
              <div className="step">Methods/Steps</div>
              {Recipedata?.steps?.map((step: string, index: number) => (
                <div className="step-gap" key={index}>
                  <div className="step-point step-number font">{index + 1}</div>
                  <div className="step-point step-number-text">{step}</div>
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
          {component === 'recipe' ? (
            <div>
              <div className="step">Methods/Steps</div>
              {data?.data?.cooking_methods?.length &&
                data?.data?.cooking_methods?.map((data: any, index: number) => {
                  return (
                    <div key={index}>
                      <div className="step">{data?.cooking_method}</div>
                      {data?.steps?.map((step: string, index: number) => (
                        <div className="step-gap" key={index}>
                          <div className="step-point step-number font">
                            {index + 1}
                          </div>
                          <div className="step-point step-number-text">
                            {step}
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              {/* {data?.data?.cooking_methods[0].steps.map(
              (step: string, index: number) => (
                <div className="step-gap" key={index}>
                  <div className="step-point step-number font">{index + 1}</div>
                  <div className="step-point step-number-text">{step}</div>
                </div>
              )
            )} */}
            </div>
          ) : (
            ''
          )}
          {sustainable != null && (
            <>
              <div className="subHead-left font">Sustainable</div>
              <div className="sutainable-block-container">
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">Ingredient Name</div>
                  <div className="sustainable-text">
                    {sustainable?.ingredient_name}
                  </div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">
                    Ingredient Category
                  </div>
                  <div className="sustainable-text">
                    {sustainable?.ingredient_category || 'None'}
                  </div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">Ingredient Type</div>
                  <div className="sustainable-text">
                    {sustainable?.ingredient_type}
                  </div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">
                    Compostable Waste %
                  </div>
                  <div className="sustainable-text">
                    {compostableWastePercent}
                  </div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">
                    Compostable Waste Value
                  </div>
                  <div className="sustainable-text">
                    {compostableWasteValue} {sustainable?.unit}
                  </div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">
                    Re-Useable Waste %
                  </div>
                  <div className="sustainable-text">{reuseWastePercent}</div>
                </div>
                <div className="sutainable-block-content">
                  <div className="sustainable-label font">
                    Re-Useable Waste Value
                  </div>
                  <div className="sustainable-text">
                    {reuseWasteValue} {sustainable?.unit}
                  </div>
                </div>
              </div>
            </>
          )}
          <>
            <div className="step">Macros</div>
            <div className="block block1">
              <div>
                <span className={`callories-number `}>
                  {formatNumberFloatValue(Recipedata?.calorie)}
                </span>
                <span className="font callories-unit">{'kcal'}</span>
              </div>
              <div>
                <span className={`carb-number `}>
                  {formatNumberFloatValue(Recipedata?.carb)}
                </span>
                <span className="font carb-unit">{'g'}</span>
              </div>
              <div>
                <span className={`fat-number `}>
                  {formatNumberFloatValue(Recipedata?.fat)}
                </span>
                <span className="font fat-unit">{'g'}</span>
              </div>
              <div>
                <span className={`font protein-number `}>
                  {formatNumberFloatValue(Recipedata?.protein)}
                </span>
                <span className="font protein-unit">{'g'}</span>
              </div>
            </div>
            <div className="block block2">
              <span className={'callories-text'}>calories</span>
              <span className={'callories-text'}>carbs</span>
              <span className={'callories-text'}>fats</span>
              <span className={'callories-text'}>proteins</span>
            </div>
          </>
          {/* {component === 'RecipeBuilder'
          ? ingredient?.map((ingredient: any, index: number) => {
              return (
                <div className="block4" key={index}>
                  <div className={`block4-text font `}>
                    {ingredient?.ingredient_name}
                  </div>
                  <div className="block4-weight">
                    {ingredient?.quantity + ' ' + ingredient?.unit}
                  </div>
                </div>
              );
            })
          : ''} */}
          {/* {component === 'production'
            ? ingredient?.map((ingredient: any, index: number) => {
                return (
                  <div className="block4" key={index}>
                    <div className={`block4-text font subRecipeDisplayIcon `}>
                      {ingredient?.ingredient_name}
                      <span onClick={() => handleSubDialog(ingredient?.sku)}>
                        {ingredient?.ingredient_type === 'sub_recipe' ? (
                          <img
                            src={SubMenu}
                            alt=""
                            width={15}
                            className="subRecipeIcons"
                          />
                        ) : null}
                      </span>
                    </div>

                    <div className="block4-weight">
                      {ingredient?.quantity + ' ' + ingredient?.unit}
                    </div>
                  </div>
                );
              })
            : ''} */}
          {/* {component === 'recipe'
          ? Recipedata.is_variation === 'yes'
            ? ''
            : ''
          : ''} */}
          <div className="allergen font">Allergens</div>
          <div className="allergen-text">
            {component === 'RecipeBuilder'
              ? Recipedata?.allergen
              : Recipedata?.allergen}{' '}
          </div>
          <div className="additional font">Additional Info</div>
          <div className="additional-text">
            {Recipedata?.additional_attribute}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default Dialog;
