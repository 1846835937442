import React, { useState } from 'react';
import { Input, Modal, Spin } from 'antd';
import { LoginResponseModal, ResponseDialog } from '../../components';
import AuthActions from '../../redux/middleware/auth';
import './loginWithEmailModal.scss';

type Props = {
  handleDialog: any;
  open: boolean;
  modalData: any;
  component?: any;
  onCheckVerify?: any;
  onSignupSuccess?: any;
};

const LoginWithEmailModal = (props: Props) => {
  let { handleDialog, open, onCheckVerify, onSignupSuccess } = props;
  // const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [name, setName] = useState<any>();
  const [email, setEmail] = useState<any>();
  // const [password, setPassword] = useState<any>();
  const [openResponse, setOpenResponse] = useState(false);
  const [phone, setPhone] = useState<any>();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [spinner, setSpinner] = useState(false);

  const emptyAllFields = () => {
    setName('');
    setPhone('');
    setEmail('');
    // setPassword('');
  };

  const handleSubmit = async () => {
    setErrorList({});
    setErrorStatus(false);

    const param = {
      username: name,
      email,
      password: '0000000000',
      phone,
      signup_source: 'email_address',
    };

    try {
      let responseDialogData;
      const response = await AuthActions.UserAuthLogin(param);
      if (response?.success) {
        onCheckVerify(true);
        onSignupSuccess(true, email);
        handleModalResponse();
      } else {
        setSpinner(false);
        setErrorList(response?.error);
        setErrorStatus(true);
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: [
            'SignUp Failed!',
            ...String(response?.error).split(','),
          ],
        };
        ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    emptyAllFields();
  }, []);

  const handleModalResponse = () => {
    setOpenResponse(false);
    handleDialog();
  };

  const handlePhone = (data: any) => {
    const phoneRegex = /^\+?[0-9]+$/;
    if (data.trim() === '') {
      setErrorList({ ...errorList, phone: '' });
      setErrorStatus(false);
    } else if (!phoneRegex.test(data.trim())) {
      setErrorList({ ...errorList, phone: 'Invalid phone number' });
      setErrorStatus(true);
    } else {
      setErrorList({ ...errorList, phone: '' });
      setErrorStatus(false);
    }
    setPhone(data.trim());
  };

  return (
    <>
      {openResponse ? (
        <LoginResponseModal
          component={'login'}
          handleClose={handleModalResponse}
          open={openResponse}
        />
      ) : null}

      <Modal
        title={<div className="title font">Sign Up</div>}
        centered
        open={open}
        onCancel={handleDialog}
        width={608}
        footer={null}
      >
        <Spin spinning={spinner} size="default" className="recipeLoader">
          <div className="MenuModal">
            <div className="menuSubContainer ingredientSubContainermargin">
              <div className="ingredientLabel">
                User Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Input name here"
                size="large"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>
            <div className="menuSubContainer ingredientSubContainermargin">
              <div className="ingredientLabel">
                Phone <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Input phone here"
                size="large"
                type="number"
                onChange={(e) => handlePhone(e.target.value)}
                value={phone}
              />
            </div>
            <div className="menuSubContainer ingredientSubContainermargin">
              <div className="ingredientLabel">
                Email <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Input email here"
                size="large"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>

            {/* <div className="menuSubContainer ingredientSubContainermargin">
              <div className="ingredientLabel">
                Password <span className="sterik">*</span>
              </div>
              <Input.Password
                placeholder="Input password here"
                size="large"
                type="password"
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
              </div> */}

            {errorStatus && (
              <div className="subRecipeErrorMessages">{errorList['phone']}</div>
            )}

            <div className="butonGroup">
              <div className="continue wizard-button" onClick={handleSubmit}>
                Submit
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default LoginWithEmailModal;
