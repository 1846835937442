/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  IconSwitcher,
  DeleteConfirmation,
} from '../../components';
import RecipeActions from '../../redux/middleware/recipe';
import { sortedData } from '../../helper/sortData';
import Fire from '../../assets/images/Fire.svg';
import More from '../../assets/images/More.png';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
import Utils from '../../redux/utils';
import { useSelector } from 'react-redux';
import './approval.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

const PageDataLength = [6, 10, 20, 50];

const Approval = () => {
  const user = Utils.getCurrentUser();
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [pdf, setPdf] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const items: MenuProps['items'] = [
    /*  {
      key: '2',
      label: (
        <a href={pdf} download>
          <div className="dotOption">Download</div>
        </a>
      ),
      icon: <img src={Download} />,
    }, */
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const itemsForReadOnlyUsers: MenuProps['items'] = [
    /* {
      key: '2',
      label: (
        <a href={pdf} download>
          <div className="dotOption">Download</div>
        </a>
      ),
      icon: <img src={Download} />,
    }, */
  ];
  const navigate = useNavigate();

  const [selectedRecipeId, setSelectedRecipeId] = useState('');
  const handleSort = (column: string) => {
    let arr = sortedData(ascend, sortData, column);
    setSortData(() => arr);
  };
  const setSelectedMenu = (recipe: any) => {
    setSelectedRecipeId(recipe.rb_id);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font">
            <img src={record?.thumbnail} width="52px" height="52px" />
            <div className="subCol1 font">
              <span
                className="col1Text font"
                onClick={() => {
                  setDialogData(record);
                  setOpenDialog(true);
                }}
              >
                {record?.recipe_name}
              </span>

              <div className="subTextCol1 font">
                {record?.is_verified ? (
                  <img
                    src={Fire}
                    width={15}
                    alt=""
                    className=" iconFireWhite"
                  />
                ) : (
                  <img src={Fire} width={15} alt="" className="subCol1Icon" />
                )}

                <span className="col1Text font">{record?.calorie}</span>
                <span className="calories font ">kcal</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('category_name');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col2">
          <div className="subCol2">{record?.category_name}</div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SERVINGS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('servings');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'servings',
      key: 'servings',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">{record?.servings + ' items'}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB RECIPE</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('sub_recipe_items');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3">
          {record?.sub_recipe_items}{' '}
          {+record?.sub_recipe_items > 1 ? 'items' : 'item'}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CREATED</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="col3 col4">
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Recipe Builder'
    );
    // setPermissionStatus(data[0]?.perm_status_id);
    setPermissionStatus(2);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const details = await RecipeActions.getDetailRecipe(data?.rb_id);
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    if (option == 2) {
      const data = await RecipeActions.getRecipeLink(selectedRecipeId);
      setPdf(data?.data[0]?.pdf);
    }
    switch (option) {
      case 3:
        navigate('wizard', { state: { update: true, id: selectedRecipeId } });
        break;
      case 4:
        setOpenDeleteDialog(true);
        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setOpenDeleteDialog(false);
    selectedRecipeId.length &&
      (await RecipeActions.delRecipe(selectedRecipeId));
    pageData();
  };

  const DropDownmenuProps = {
    items:
      permissionStatus && permissionStatus === 2
        ? items
        : itemsForReadOnlyUsers,
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const recipeData = await RecipeActions.getRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      0
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const recipeData = await RecipeActions.recipesFilters(
      dataLength,
      current,
      filters
    );

    if (recipeData == undefined || !recipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(recipeData?.data?.recipe_count);
      setIngredientCount(recipeData?.data?.ingredients_count);
      setTotal(recipeData?.data?.pagination?.total);
      setSortData(recipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setRecipeLoader(true);
    const searchResultofRecipes = await RecipeActions.searchRecipe(
      dataLength,
      current,
      keyword
    );
    if (searchResultofRecipes == undefined || !searchResultofRecipes?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }

    setRecipeLoader(false);
  };

  return (
    <div className="approvalPage">
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={() => {
          setOpenDialog(false);
          setOpenSubDialog(true);
        }}
        data={dialogData}
        component={'RecipeBuilder'}
      />
      <SubDialog
        open={openSubDialog}
        data={dialogData}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete recipe?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />

      <Breadcrumb
        heading="Approval"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Page title="Approval">
        <Row className="listingTopFilter">
          <Col md={24} lg={12}>
            <ListCount
              count1={recipeCount}
              title1="Total Approval"
              count2={ingrdientCount}
              title2="Total Ingredients"
            />
          </Col>
          <Col md={24} lg={12}>
            <div className="search-wrapper">
              <SearchBox action={onSearch} placeholderText="Search..." />
              <IconSwitcher listViewLayout={design} setListView={setDesign} />
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg={6} md={8}>
            <Filter
              action={pageDataFilter}
              apiCall={RecipeActions.getRecipesFilters}
            />
          </Col>
          <Col lg={18} md={16}>
            {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData || []}
                component={'recipeBuilder'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
                UTCtoLocal={UTCtoLocal}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}

            {design && !recipeLoader ? (
              <ListView
                limit={dataLength}
                items={items}
                dataSource={[...sortData] || []}
                columns={columns}
              />
            ) : (
              design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </div>
  );
};

export default Approval;
