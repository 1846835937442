import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Checkbox, Input, Select, Spin } from 'antd';
import {
  Page,
  Breadcrumb,
  ResponseDialog,
  Confirmation,
} from '../../components';
import './styles.scss';
import BranchActions from '../../redux/middleware/branches';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const BranchWizard = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'branch',
  });
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [brand, setBrand] = React.useState<any>([]);
  const [branchName, setBranchName] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [cityData, setCityData] = useState<any[]>([]);
  const [city, setCity] = useState<any>({});
  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [availableCK, setAvailableCK] = useState<any>();
  const [centralKitchen, setCentralKitchen] = useState(false);
  const [confirmation, setConfirmation] = useState(0); // 0 for false, 1 for true, 2 for false & show dropdown
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);
  const [filteredShops, setFilteredShops] = useState<any[]>();
  const [referenceShop, setReferenceShop] = useState('');
  const [updateDefaultCheck, setUpdateDefaultCheck] = useState<any>(null);
  const [ckShops, setCkShops] = useState<any[]>([]);
  const [taggedShop, setTaggedShop] = useState<any>();
  const [branch, setBranch] = useState<any>();
  const { currentShop, currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentBrand?.brand_id);

  const handleBack = () => {
    navigate('/app/branches');
  };

  const filterShops = async (is_ck: any) => {
    const { data, success } = await BranchActions.checkCentralKitchen(is_ck);

    if (success)
      setFilteredShops(
        data?.items?.map((data: any) => ({
          label: data?.shop_name,
          value: data?.id,
        }))
      );
  };

  const CKShops = async () => {
    const { data, success } = await BranchActions.checkCentralKitchen('yes');

    if (success)
      setCkShops(
        data?.items?.map((data: any) => ({
          label: data?.shop_name,
          value: data?.id,
        }))
      );
  };

  const checkBranchLimit = async () => {
    const { data, success } = await BranchActions.checkCentralKitchen('');
    if (success && data?.remaining_branch_limit <= 0 && !update) {
      let responseDialogData = {
        OnClick: () => {
          navigate('/app/branches');
        },
        type: 'error',
        messageArray: [
          'Your branch limit has been reached.',
          'Additional branches cannot be created.',
        ],
      };
      ResponseDialog(responseDialogData);
    }
    setAvailableCK(data);
  };

  const handleContinue = async () => {
    const company = await Utils.getCurrentCompany();
    if (
      branchName == '' ||
      address == '' ||
      brand?.length == 0 ||
      (city &&
        Object.keys(city).length === 0 &&
        Object.getPrototypeOf(city) === Object.prototype) ||
      (confirmation == 2 && referenceShop == '') ||
      (!centralKitchen && !taggedShop)
    ) {
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    }
    const payload = {
      brand_id: brand?.map((obj: any) => obj?.key),
      shop_name: branchName,
      city: city?.name,
      city_id: city?.id,
      // region: city?.region,
      // region_id: city?.region_id,
      company_id: company?.id,
      country_id: user?.country_id,
      address,
      is_ck: centralKitchen ? 1 : 0,
      ck_id: !centralKitchen ? taggedShop : 0,
      created_by: user?.id,
      reference_shop_id: id && !centralKitchen ? taggedShop : referenceShop,
      email,
      phone,
    };

    try {
      setApiLoader(true);
      let response;
      if (update) {
        response = await BranchActions.updateBranch(id, payload);
      } else {
        response = await BranchActions.addBranch(payload);
      }
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        // navigate('/app/branches');
        window.location.href = '/app/branches';
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onChangeCentralKitchen = (e: any) => {
    setConfirmation(0);
    if (
      availableCK?.total_ck == 1 &&
      availableCK?.remaining_ck <= 0 &&
      !e.target.checked &&
      e.target.checked != updateDefaultCheck
    ) {
      setConfirmation(1);
      filterShops('no');
      setIsChecked(e.target.checked);
    } else if (
      availableCK?.remaining_ck <= 0 &&
      e.target.checked &&
      e.target.checked != updateDefaultCheck
    ) {
      setConfirmation(1);
      filterShops('yes');
      setIsChecked(e.target.checked);
    } else {
      setCentralKitchen(e.target.checked);
    }
  };

  const handlePickerChange = (value: any) => {
    const detail: any = value.map((data: any) => {
      return brandList.filter((id: any) => {
        if (id.value == data) {
          return id;
        }
      });
    });
    const filteredBrands = detail.flat();
    setBrand(filteredBrands);
  };

  const handleCityChange = (value: string) => {
    const city = cityData?.find((data: any) => data?.id == value);
    setCity(city);
  };

  const handleChangeShop = (value: string) => {
    setReferenceShop(value);
  };

  const handleTaggedShop = (value: string) => {
    setTaggedShop(value);
  };

  const getBrandListing = async () => {
    const response = await BranchActions.getBrands(10000, 1);
    if (response?.success) {
      const { data } = response;
      const tempList =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          label: item?.name,
          value: item?.name,
          key: item?.brand_id,
        }));
      setBrandList(tempList);
    }
  };

  const getBranchInfo = async () => {
    const response = await BranchActions.getBranchById(id);
    if (response?.success) {
      setApiLoader(true);
      const { data } = response;
      setBranchName(data?.shop_name);
      setAddress(data?.address);
      setBrand(
        data?.brand_id?.map((obj: any) => ({
          label: obj?.brand_name,
          value: obj?.brand_name,
          key: obj?.brand_id,
        }))
      );
      setCity({
        name: data?.city_name,
        id: data?.city_id,
      });
      setPhone(data?.phone);
      setEmail(data?.email);
      setCentralKitchen(data?.is_ck == 1 ? true : false);
      setUpdateDefaultCheck(data?.is_ck == 1 ? true : false);
      setTaggedShop(data?.ck_id);
      setBranch(data);
      setApiLoader(false);
    } else {
      setApiLoader(true);
    }
  };

  const getCities = async () => {
    const response = await BranchActions.getCities(user?.country_id);
    if (response?.success) {
      setCityData(response?.data?.items);
    } else {
      setCityData([]);
    }
  };

  useEffect(() => {
    checkAuthentication(user, navigate);
    checkBranchLimit();
    getBrandListing();
    getCities();
    CKShops();
    if (update) {
      setApiLoader(true);
      getBranchInfo();
    }
  }, []);

  useEffect(() => {
    if (update && currentBrandId !== currentBrand?.brand_id) {
      navigate('/app/branches');
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (!update)
      if (ckShops?.length == 0) setCentralKitchen(true);
      else setCentralKitchen(false);
  }, [ckShops]);

  const handleCancel = () => {
    setConfirmation(0);
  };

  const handleConfirmation = () => {
    setCentralKitchen(isChecked);
    setConfirmation(2);
  };

  return (
    <React.Fragment>
      <Confirmation
        handleCancel={handleCancel}
        handleContinue={handleConfirmation}
        open={confirmation == 1 ? true : false}
        message={`Your central kitchen limit exceeded. Do you want to remove central kitchen limit from another branch?`}
      />
      <Breadcrumb
        heading={t('add_new_branch')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/branches');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title={t('add_new_branch')}>
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                {t('wizard_branch_name')} <span className="sterik">*</span>
              </div>
              <Input
                placeholder={t('branch_placeholder')}
                size="large"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
                className={
                  errorList['shop_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['shop_name']}
                </div>
              )}
            </div>

            <div className="wizardDropdown">
              <div className="wizardLabel">
                {t('wizard_city_label')} <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={t('city_placeholder')}
                onChange={handleCityChange}
                style={{ width: 560 }}
                value={city?.name}
                className={
                  errorList['city']?.length > 0 ? 'dropdownUnitColorRed' : ''
                }
              >
                {cityData?.map((data: any, index: number) => (
                  <Select.Option value={data?.id} key={index}>
                    {data.name}
                  </Select.Option>
                ))}
              </Select>
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['city']}
                </div>
              )}
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">
                {t('wizard_brand_label')} <span className="sterik">*</span>
              </div>
              <Select
                size={'large'}
                placeholder={t('brand_placeholder')}
                onChange={handlePickerChange}
                mode="multiple"
                style={{ width: 560 }}
                value={brand}
                options={brandList}
                className={
                  errorList['brand_id']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['brand_id']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">{t('wizard_number_label')}</div>
              <Input
                addonBefore={user?.country_code || '+971'}
                placeholder={t('number_placeholder')}
                value={phone}
                type="number"
                onChange={(e) => setPhone(e.target.value)}
                className={errorList['phone']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['phone']}
                </div>
              )}
            </div>

            <div className="wizardSubContainer">
              <div className="wizardLabel">{t('wizard_email_label')}</div>
              <Input
                placeholder={t('email_placeholder')}
                size="large"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                {t('wizard_address_label')} <span className="sterik">*</span>
              </div>
              <Input.TextArea
                placeholder={t('address_placeholder')}
                size="large"
                value={address}
                className={`BranchTextArea ${
                  errorList['shop_name']?.length > 0 ? 'borderRed' : ''
                }`}
                onChange={(e) => setAddress(e.target.value)}
                rows={3}
                required
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['address']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <Checkbox
                onChange={(e) => onChangeCentralKitchen(e)}
                value={centralKitchen}
                checked={centralKitchen}
              >
                <div className="wizardLabel">{t('wizard_central_kitchen')}</div>
              </Checkbox>
            </div>
            {confirmation == 2 && (
              <div className="wizardDropdown">
                <div className="wizardLabel">
                  Select Branch (To Remove it from Ck)
                  <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  placeholder={'Select Shop'}
                  onChange={handleChangeShop}
                  style={{ width: 560 }}
                  options={filteredShops}
                  value={referenceShop}
                  className={
                    errorList['reference_shop_id']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                />

                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['reference_shop_id']}
                  </div>
                )}
              </div>
            )}

            {!centralKitchen && ckShops?.length > 0 && (
              <div className="wizardDropdown">
                <div className="wizardLabel">
                  {t('wizard_tag_central')}
                  <span className="sterik">*</span>
                </div>
                <Select
                  size={'large'}
                  placeholder={t('central_kitchen_placeholder')}
                  onChange={handleTaggedShop}
                  style={{ width: 560 }}
                  options={ckShops?.filter(
                    (shop: any) => shop?.value != branch?.id
                  )}
                  value={taggedShop}
                  className={
                    errorList['reference_shop_id']?.length > 0
                      ? 'dropdownUnitColorRed'
                      : ''
                  }
                />

                {errorStatus && (
                  <div className="subRecipeErrorMessages">
                    {errorList['reference_shop_id']}
                  </div>
                )}
              </div>
            )}

            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                {t('wizard_back')}
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                {t('wizard_continue')}
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default BranchWizard;
