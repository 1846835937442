/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { Spin } from 'antd';
import useClickListener from '../../helper/outsideClickListener';
// import Verified from '../../assets/images/Verified.png';
import IngredientActions from '../../redux/middleware/ingredient';
import SubMenu from '../../assets/images/SubMenu.svg';
import {
  setRecipeIngredients,
  setDatabaseIngredient,
} from '../../redux/reducers/recipeSlice';
import { store } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import './Dropdown.scss';
import Fire from '../../assets/images/Fire.svg';
import Utils from '../../redux/utils';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import SupplierApi from '../../redux/middleware/supplier';
import Sustainable from '../../assets/images/sustainable.png';

type Props = {
  handleIngredientName?: any;
  ingredientName: any;
  openDialog?: any;
  ingredientData: any;
  database: any;
  handleIngredient: any;
  component?: string;
  supplier_id?: any;
  disabled?: boolean;
};

const DropdownComponent = (props: Props) => {
  let {
    handleIngredientName,
    ingredientName,
    openDialog,
    handleIngredient,
    database,
    component,
    supplier_id,
    disabled = false,
  } = props;
  const [showListViewOne, setShowListViewOne] = useState(false);
  const [changeDatabase, setChangeDatabase] = useState(false);
  const latestSearchRequestId = useRef(0);
  const ulRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [limit, setlimit] = useState(50);
  const [spinner, setSpinner] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState('');
  const { ref, isComponentVisible, setIsComponentVisible } =
    useClickListener(false);
  const dispatch = useDispatch();
  const state = store.getState();
  const [ingredients, setIngredients] = useState<any>([]);
  const [search, setSearch] = useState('');
  const [filterOptions] = useState([
    { key: 'A', value: 'A' },
    { key: 'B', value: 'B' },
    { key: 'C', value: 'C' },
    { key: 'D', value: 'D' },
    { key: 'E', value: 'E' },
    { key: 'F', value: 'F' },
    { key: 'G', value: 'G' },
    { key: 'H', value: 'H' },
    { key: 'I', value: 'I' },
    { key: 'J', value: 'J' },
    { key: 'K', value: 'K' },
    { key: 'L', value: 'L' },
    { key: 'M', value: 'M' },
    { key: 'N', value: 'N' },
    { key: 'O', value: 'O' },
    { key: 'P', value: 'P' },
    { key: 'Q', value: 'Q' },
    { key: 'R', value: 'R' },
    { key: 'S', value: 'S' },
    { key: 'T', value: 'T' },
    { key: 'U', value: 'U' },
    { key: 'V', value: 'V' },
    { key: 'W', value: 'W' },
    { key: 'X', value: 'X' },
    { key: 'Y', value: 'Y' },
    { key: 'Z', value: 'Z' },
  ]);

  const [searchResults, setSearchResults] = useState([
    {
      id: 3040,
      database_id: 2,
      category_id: 8,
      fdc_id: 167782,
      ingredient_length: 12,
      ingredient_name: 'Abiyuch, raw',
      brand_id: 0,
      company_id: 0,
      shop_id: 0,
      unit_id: 0,
      calorie: 69,
      calorie_unit: 1,
      calories_per_unit: 0.69,
      carb: 17.6,
      carb_unit: 2,
      carb_per_unit: 0.18,
      protein: 1.5,
      protein_unit: 2,
      protein_per_unit: 0.02,
      fat: 0.1,
      fat_unit: 2,
      fat_per_unit: 0,
      ingredient_type: '',
      portion: 100,
      portion_unit: 1,
      unit_val_portion: 1,
      allergens_id: '',
      is_verified: 1,
      verified_by: 'ChefAdmin',
      created_by: null,
      status: 1,
      created_at: null,
      updated_at: null,
      db_name: 'Global Ingredients List (With Calories)',
      category_name: 'Fruits and Fruit Juices',
      unit: null,
      calorie_unit_name: 'kcal',
      carb_unit_name: 'g',
      protein_unit_name: 'g',
      fat_unit_name: 'g',
      unit_val_portion_name: 'Kg',
      allergens: '',
    },
  ]);
  const { currentBrand } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    const selectedDb = state.Recipe.selectedDatabase;
    if (supplier_id) {
      setSearch(ingredientName);
      handleFilterIngredient('');
    }
    if (database?.label !== selectedDb) {
      dispatch(setDatabaseIngredient(database?.label));
      setChangeDatabase(true);
    }
  }, [database, supplier_id]);

  useEffect(() => {
    if (changeDatabase) {
      handleFilterIngredient('');
      setChangeDatabase(false);
    }
  }, [changeDatabase]);

  useEffect(() => {
    // const delayDebounceFn = setTimeout(() => {
    search == '' ? handleFilterIngredient('') : handleSearchIngredient(search);
    // }, 500);
    // return () => clearTimeout(delayDebounceFn);
  }, [currentBrand, search]);

  // const handleIngredientsData = async () => {
  //   const recipeIngredient = state.Recipe.ingredientsRecipe;
  //   if (recipeIngredient.length === 0) {
  //     handleFilterIngredient('a');
  //   } else {
  //     setIngredients(recipeIngredient);
  //     setSpinner(false);
  //   }
  // };

  // const handleIngredientsDataForPurchaseOrder = () => {
  //   handleFilterIngredient('a');
  // };

  const handleFilterIngredient = async (e: string) => {
    const currentRequestId = ++latestSearchRequestId.current;
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    setSpinner(true);
    try {
      const data =
        component == 'purchase order'
          ? await SubRecipeActions.getSubRecipeInventoryForPurchaseOrder(
              database?.key,
              e,
              supplier_id,
              shop?.shop_id ? shop?.shop_id : ''
            )
          : component == 'supply'
          ? await SupplierApi.getInventoryForRequisition(
              database?.key,
              e,
              brand?.brand_id ? brand?.brand_id : '',
              shop?.shop_id ? shop?.shop_id : ''
            )
          : database?.label === 'Company'
          ? await SubRecipeActions.getInventoryByCharacterForWizard(
              database?.key,
              e,
              component == 'modifier' ? 'brand' : '',
              1,
              1000
            )
          : await IngredientActions.getInventory(
              database?.key,
              e,
              company?.id,
              brand?.brand_id
            );
      if (data != undefined && data?.success) {
        if (currentRequestId === latestSearchRequestId.current) {
          setIngredients(data?.data?.items);
          dispatch(setRecipeIngredients(data?.data?.items));
        }
      } else {
        setIngredients([]);
        dispatch(setRecipeIngredients([]));
      }
    } catch (error) {
      console.error('Failed to fetch ingredients', error);
    } finally {
      if (currentRequestId === latestSearchRequestId.current) {
        setSpinner(false);
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  // const handleFilterIngredientWithPagination = async (e: string) => {
  //   const company = await Utils.getCurrentCompany();
  //   const shop = await Utils.getCurrentShop();
  //   const brand = await Utils.getCurrentBrand();
  //   setSpinner(true);
  //   const data =
  //     component == 'purchase order'
  //       ? await SubRecipeActions.getSubRecipeInventoryForPurchaseOrder(
  //           database?.key,
  //           e,
  //           supplier_id,
  //           shop?.shop_id ? shop?.shop_id : ''
  //         )
  //       : component == 'supply'
  //       ? await SupplierApi.getInventoryForRequisition(
  //           database?.key,
  //           e,
  //           brand?.brand_id ? brand?.brand_id : '',
  //           shop?.shop_id ? shop?.shop_id : ''
  //         )
  //       : database?.label === 'Company Inventory'
  //       ? await SubRecipeActions.getInventoryByCharacterForWizard(
  //           database?.key,
  //           e,
  //           component == 'modifier' ? 'brand' : '',
  //           page,
  //           limit
  //         )
  //       : await IngredientActions.getInventory(database?.key, e, company?.id);
  //   if (data != undefined && data?.success) {
  //     /* eslint-disable-next-line no-unsafe-optional-chaining */
  //     setIngredients((prevArray: any) => [...prevArray, ...data?.data?.items]);
  //     dispatch(setRecipeIngredients([...ingredients, data?.data?.items]));
  //   } else {
  //     setIngredients([...ingredients]);
  //     dispatch(setRecipeIngredients([...ingredients]));
  //   }
  //   setSpinner(false);
  // };

  const handleSearchIngredient = async (e: string) => {
    setSpinner(true);
    const currentRequestId = ++latestSearchRequestId.current;
    const company = await Utils.getCurrentCompany();
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    try {
      const data =
        component == 'purchase order'
          ? await SubRecipeActions.getSubRecipeInventoryForPurchaseOrder(
              database?.key,
              e,
              supplier_id,
              shop?.shop_id ? shop?.shop_id : ''
            )
          : component == 'supply'
          ? await SupplierApi.getInventoryForRequisition(
              database?.key,
              e,
              brand?.brand_id ? brand?.brand_id : '',
              shop?.shop_id ? shop?.shop_id : ''
            )
          : database?.label === 'Company Inventory'
          ? await SubRecipeActions.getInventoryByCharacterForWizard(
              database?.key,
              e,
              component == 'modifier' ? 'brand' : '',
              page,
              limit
            )
          : component == 'recipeBuilder' && database?.label === 'Company'
          ? await SubRecipeActions.getRandDInventory(database?.key, e)
          : component == 'recipeBuilder'
          ? await IngredientActions.getInventory(
              database?.key,
              e,
              company?.id,
              brand?.brand_id
            )
          : await IngredientActions.getInventory(database?.key, e, company?.id);
      if (data != undefined && data?.success) {
        if (currentRequestId === latestSearchRequestId.current) {
          setSearchResults(data?.data?.items);
          setIngredients(data?.data?.items);
        }
      } else setSearchResults([]);
    } catch (error) {
      console.error('Failed to fetch ingredients', error);
    } finally {
      if (currentRequestId === latestSearchRequestId.current) {
        setSpinner(false);
      }
    }
  };

  const handleClick = () => {
    setIsComponentVisible(!isComponentVisible);
    setShowListViewOne(true);
  };

  const handleChange = (e: any) => {
    handleIngredientName(e);
    if (!isComponentVisible && e != '') {
      setIsComponentVisible(true);
    }
    if (e !== '') {
      setShowListViewOne(false);
      // handleSearchIngredient(search);
    } else {
      setShowListViewOne(true);
      setSearchResults([]);
    }
  };

  // const handleSearchFunction = () => {
  //   handleChange(search);
  // };

  const handleCreateCategory = () => {
    setIsComponentVisible(false);
    openDialog();
  };

  const handleSelectOption = (ingredient: any) => {
    handleIngredientName(ingredient?.ingredient_name);
    // setSearch(ingredient?.ingredient_name);
    handleIngredient(ingredient);
    setIsComponentVisible(false);
  };

  const handleFilterOption = (filterOption: any) => {
    setSpinner(true);
    setSelectedFilter(filterOption?.key);
    handleFilterIngredient(filterOption?.key);
    setIngredients(ingredients);
  };

  const getDropdownPlaceholderLabel = () => {
    let label = 'Select an option or create one';
    if (database?.label !== 'My Market List') {
      label = 'Select an option';
    }

    return label;
  };

  // const scrollContainer = document.getElementById('scrollContainer');
  // const myList = document.getElementById('myList');

  // if (scrollContainer && myList) {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           // The end of the list is visible in the viewport
  //           console.log('Scroll end reached!');
  //           // You can trigger your desired action here
  //         }
  //       });
  //     },
  //     { root: scrollContainer, threshold: 1 }
  //   );

  //   observer.observe(myList);
  // } else {
  //   console.error('Scroll container or list element not found!');
  // }

  // eslint-disable-next-line no-unused-vars
  const handleScroll = (e: any) => {
    const ulElement: any = ulRef.current;
    if (ulElement) {
      const isAtBottom =
        ulElement.scrollHeight - ulElement.scrollTop ===
          ulElement.clientHeight ||
        Math.ceil(ulElement.scrollHeight - ulElement.scrollTop) ===
          ulElement.clientHeight;
      if (isAtBottom) {
        // setPage(page + 1);
        // handleFilterIngredientWithPagination('');
      }
    }

    // const bottom =
    //   e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    // // e.scrollTop + e.clientHeight === e.scrollHeight;
    // console.log('bottom', bottom);
    // if (bottom) {
    //   debugger;
    //   console.log('in bootom if');
    //   setPage(page + 1);
    //   await handleFilterIngredient('');
    // }
  };

  return (
    <div className="form-group" ref={ref}>
      <input
        type="text"
        placeholder={
          component == 'supply' || component == 'purchase order'
            ? 'Enter Item'
            : 'Enter Ingredients'
        }
        onClick={handleClick}
        onChange={(e) => {
          handleIngredientName(e.target.value);
          setSearch(e.target.value);
          handleChange(e.target.value);
        }}
        // onKeyUp={() => handleSearchFunction()}
        value={ingredientName}
        disabled={disabled}
      />
      {isComponentVisible ? (
        <ul className="dropdown-list" id="mylist">
          {showListViewOne ? (
            <li className="main-list">
              <div className="list-btn">
                {filterOptions.map((filterOption, index) => {
                  return (
                    <p
                      key={index}
                      className={
                        selectedFilter === filterOption?.key
                          ? 'active'
                          : 'cursor'
                      }
                      onClick={() => handleFilterOption(filterOption)}
                    >
                      {filterOption?.key}
                    </p>
                  );
                })}
              </div>
              <div className="subMenuWrapper">
                <ul className="subMenu" ref={ulRef} onScroll={handleScroll}>
                  <li>
                    <h4 className="placeholder">
                      {component !== 'purchase order'
                        ? getDropdownPlaceholderLabel()
                        : 'Select an option'}
                    </h4>
                  </li>
                  {selectedFilter && (
                    <li>
                      <h3>{selectedFilter ? selectedFilter : ''}</h3>
                    </li>
                  )}

                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="recipeLoader"
                    />
                  ) : (
                    ingredients.map((ingredient: any, index: number) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleSelectOption(ingredient)}
                        >
                          <p>
                            {ingredient?.ingredient_name}{' '}
                            {/* {ingredient?.is_verified ? (
                            <span className="optionIcon">
                              {' '}
                              <img src={Verified} />
                            </span>
                          ) : null} */}
                            {ingredient?.verified_by ==
                              'Global Ingredients List (With Calories)' && (
                              <img
                                src={Fire}
                                width={5}
                                height={10}
                                alt=""
                                className="iconFireWhite dropdown-verified-icon"
                              />
                            )}
                            {ingredient?.is_verified == 1 && (
                              // <span className="optionIcon">
                              <img
                                src={Fire}
                                width={20}
                                height={20}
                                alt=""
                                className="isVerifiedIconRB"
                              />
                            )}
                            {ingredient?.ingredient_type?.toLowerCase() ==
                              'sustainable' && (
                              <img
                                src={Sustainable}
                                width={30}
                                alt=""
                                className="subRecipeIcons ingredient-sustainable-icon-space cursorForIcon"
                              />
                            )}
                            {ingredient?.verified_by == 'sub_recipe' && (
                              <img
                                src={SubMenu}
                                width={15}
                                alt=""
                                className="subRecipeIcons cursorForIcon"
                              />
                            )}
                          </p>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </li>
          ) : (
            <li>
              <div className="searchMenuWrapper">
                <ul className="searchMenu" ref={ulRef} onScroll={handleScroll}>
                  {database.label !==
                    'Global Ingredients List (With Calories)' &&
                    database.label !== 'Company Inventory' &&
                    database.label !==
                      'Global Ingredients List (With Calories)' &&
                    component !== 'supply' &&
                    component == 'recipeBuilder' &&
                    database?.label !== 'Company' &&
                    database?.label !== 'Global' && (
                      <li className="create-category">
                        <button onClick={handleCreateCategory}>
                          Create {ingredientName} as an ingredient
                        </button>
                      </li>
                    )}
                  {spinner ? (
                    <Spin
                      spinning={spinner}
                      size="default"
                      className="loaderStyle"
                    />
                  ) : (
                    searchResults.map((searchResult, index) => {
                      return (
                        <li
                          key={index}
                          style={{ display: 'flex', gap: '5px' }}
                          onClick={() => handleSelectOption(searchResult)}
                        >
                          <p>{searchResult?.ingredient_name}</p>
                          {/* {searchResult?.is_verified ? (
                          <span className="optionIcon">
                            {' '}
                            <img src={Verified} />
                          </span>
                        ) : null} */}

                          {searchResult?.verified_by ==
                            'Global Ingredients List (With Calories)' && (
                            <span className="optionIcon">
                              <img
                                src={Fire}
                                width={5}
                                height={10}
                                alt=""
                                className="iconFireWhite dropdown-verified-icon"
                              />
                            </span>
                          )}
                          {searchResult?.is_verified == 1 && (
                            <img
                              src={Fire}
                              width={20}
                              height={20}
                              alt=""
                              className="isVerifiedIconRB"
                            />
                          )}
                          {searchResult?.ingredient_type?.toLowerCase() ==
                            'sustainable' && (
                            <img
                              src={Sustainable}
                              width={30}
                              alt=""
                              className="subRecipeIcons ingredient-sustainable-icon-space "
                            />
                          )}
                          {searchResult?.ingredient_type == 'sub_recipe' && (
                            <img
                              src={SubMenu}
                              width={15}
                              alt=""
                              className="subRecipeIcons"
                            />
                          )}
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </li>
          )}
        </ul>
      ) : null}
    </div>
  );
};

export default DropdownComponent;
