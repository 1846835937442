import { Col, Row, Spin, Button, Tooltip } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import Utils from '../../redux/utils';
import styles from './Page.module.scss';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  title: string;
  className: string;
  appendCommonTitle: boolean;
  loading?: boolean;
  screenName?: string;
};

export function PurePage(props: Props) {
  const {
    children,
    title,
    className,
    appendCommonTitle,
    screenName,
    ...otherProps
  } = props;

  const navigate = useNavigate();
  const user = Utils.getCurrentUser();
  const { company_profile_status } = user;

  const commonTitle = 'ChefAdmin';
  let pageTitle = title ? `${title} - ${commonTitle}` : commonTitle;

  if (!appendCommonTitle) {
    pageTitle = title;
  }

  return (
    <>
      {company_profile_status === 0 && screenName !== 'setupProfile' && (
        <div className={styles.profileCompletionBox}>
          <p>
            Thank you for signing up on ChefAdmin, kindly complete your profile
            to gain access.
          </p>
          <Tooltip
            title={
              'Add personal info, subscription plan and company details to complete your profile'
            }
          >
            <Button
              onClick={() => navigate('/app/userPersonalInfo')}
              className="theme-button"
            >
              Profile
            </Button>
          </Tooltip>
        </div>
      )}

      {company_profile_status === 1 &&
        user?.subscription?.status === 'past_due' && (
          <div className={styles.profileCompletionBox}>
            <p>{'Please add your payment info'}</p>
            <Tooltip
              title={'Add your payment info to continue using ChefAdmin'}
            >
              <Button
                onClick={() => {
                  if (user?.subscription?.status === 'past_due') {
                    window.location.href = user?.subscription?.billing_url;
                  }
                }}
                className="theme-button"
              >
                {'Add Payment Info'}
              </Button>
            </Tooltip>
          </div>
        )}
      <div
        {...otherProps}
        className={`${styles.container} ${className} ${
          (company_profile_status === 0 && screenName !== 'setupProfile') ||
          user?.subscription?.status === 'past_due'
            ? 'userRestrict'
            : ''
        }`}
      >
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {props.loading ? (
          <Row className={styles.centerLoader}>
            <Col>
              <p className={`l2 ${styles.placeholderText}`}>Please wait</p>
            </Col>
            <Col>
              <Spin size="large" />
            </Col>
          </Row>
        ) : (
          children
        )}
      </div>
    </>
  );
}

PurePage.defaultProps = {
  title: '',
  className: '',
  appendCommonTitle: true,
};

export default PurePage;
