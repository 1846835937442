import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Input, Select, Spin } from 'antd';
import { Page, Breadcrumb, ResponseDialog } from '../../components';
import { RcFile } from 'antd/lib/upload';
import Delete from '../../assets/images/Delete.svg';
import './approvalWizard.scss';
import UsersApi from '../../redux/middleware/users';
import ResourcesActions from '../../redux/middleware/resources';
import BrandActions from '../../redux/middleware/brands';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';

const ApprovalWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [image, setImage] = React.useState('');
  const [imageObject, setImageObject] = React.useState({});

  const fileRef = React.useRef<HTMLInputElement>(null);

  const [country, setCountry] = React.useState({} as any);
  const [, setCompany] = React.useState('' as any);
  const [, setCity] = React.useState('' as any);
  const [phone, handlePhone] = React.useState('' as any);
  const [brand, setBrand] = React.useState('');
  const [sublicense, setSubLicense] = React.useState();

  // const [, setCompanyList] = React.useState([]);
  // const [, setCountryList] = React.useState([]);
  const [branchLimits, setBranchLimits] = useState<any>([]);
  const [blocked] = useState(true);
  const [, setCityList] = React.useState([]);
  const user = Utils.getCurrentUser();
  const company_name = user?.company_name;
  const country_name = user?.country_name;

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState('+971');
  useEffect(() => {
    country &&
    Object.keys(country).length === 0 &&
    Object.getPrototypeOf(country) === Object.prototype
      ? null
      : setCountryCode(country?.value);
  }, [country]);
  const handleBack = () => {
    navigate('/app/brands');
  };

  // const handlePickerChange =
  //   (fieldName: string) => (value: string | string[], data: any) => {
  //     if (fieldName === 'country') setCountry(data);
  //     if (fieldName === 'city') setCity(data);
  //     if (fieldName === 'company') setCompany(data);
  //   };

  const handleImage = (url: any, data: any) => {
    if (url !== '') {
      const invalidSize = data?.target?.files[0].size / 1024 / 1024 > 2;
      if (invalidSize) {
        // eslint-disable-next-line no-alert
        alert('Invalid file size, Image should be less than 2 MB');
      } else {
        setImage(url);
        setImageObject(data?.target?.files[0]);
      }
    } else {
      setImage(url);
      setImageObject(data?.target?.files[0]);
    }
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  // const fetchUserCompany = async () => {
  //   const response = await UsersApi.getUserCompany();
  //   if (response?.success) {
  //     const { data } = response;
  //     setCompanyList(
  //       data?.items?.map((data: any) => {
  //         return {
  //           label: data?.company_name,
  //           value: data?.company_name,
  //           key: data?.id,
  //         };
  //       })
  //     );
  //   }
  // };

  // const getCountryListing = async () => {
  //   const response = await ResourcesActions.getCountries(500, 1);
  //   if (response?.success) {
  //     const { data } = response;
  //     const list =
  //       data &&
  //       data.items &&
  //       data.items.map((item: any) => ({
  //         value: item?.country_code,
  //         label: item?.country_name,
  //         key: item?.id,
  //       }));
  //     setCountryList(list);
  //   }
  // };

  const getCitiesListing = async () => {
    const response = await ResourcesActions.getCities(500, 1, user?.country_id);
    if (response?.success) {
      const { data } = response;
      const list =
        data &&
        data.items &&
        data.items.map((item: any) => ({
          value: item?.name,
          label: item?.name,
          key: item?.id,
        }));
      setCityList(list);
    }
  };

  const getBrandInfo = async () => {
    const response = await BrandActions.getBrandById(id);
    if (response?.success) {
      const { data } = response;
      setBrand(data?.name);
      setSubLicense(data?.sub_license);
      handlePhone(data?.phone);
      setCountry({
        label: data?.country,
        value: data?.country,
        key: data?.country_id,
      });
      setCity({
        label: data?.city,
        value: data?.city,
        key: data?.city_id,
      });
      setCompany({
        label: data?.company,
        value: data?.company,
        key: data?.company_id,
      });
      setImage(data?.thumbnail);
    }
  };

  const getCompanyById = async () => {
    const company = await Utils.getCurrentCompany();
    const response = await UsersApi.getUserCompanyById(company?.id);
    if (response?.success)
      setBranchLimits(
        response?.data?.available_sub_licenses?.map((data: any) => ({
          label: data,
          value: data,
        }))
      );
  };

  React.useEffect(() => {
    // fetchUserCompany();
    // getCountryListing();
    checkAuthentication(user, navigate);
    getCompanyById();

    if (update) {
      getBrandInfo();
    }
  }, []);

  React.useEffect(() => {
    getCitiesListing();
  }, [country]);

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    const user = await Utils.getCurrentUser();

    if (brand === '' || !sublicense || !phone) {
      setVisible(true);
      window.scrollTo(0, 0);
      return;
    }

    const payload = {
      company_id: user?.company_id,
      brand_name: brand,
      sub_license: sublicense,
      // city_id: null,
      country_id: user?.country_id,
      phone: phone.toString(),
      created_by: user?.id,
    };

    try {
      let responseDialogData;

      setApiLoader(true);
      let response;
      if (update) {
        const thumbnail = imageObject || image;
        response = await BrandActions.updateBrand(id, payload, thumbnail);
      } else {
        response = await BrandActions.addBrand(payload, imageObject);
      }
      setApiLoader(false);
      if (!response?.success) {
        responseDialogData = {
          OnClick: () => {},
          type: 'error',
          messageArray: ['Validation Failed!', 'Something Went Wrong'],
        };
        ResponseDialog(responseDialogData);
      } else {
        navigate('/app/brands');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/brands');
        //   },
        //   type: 'success',
        //   messageArray: [
        //     ` Your brand - ${brand}`,
        //     ` has been ${!update ? 'Created' : 'Updated'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Approval"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/brands');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Approval">
          <div className="alertMessage">
            {visible && (
              <Alert
                message="Error"
                description="Please complete all required fields to proceed"
                type="error"
                showIcon
                closable
                className="wizardAlert"
                afterClose={() => setVisible(false)}
              />
            )}

            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          <div className="wizardContainer">
            <div className="wizardSubContainer checkBox">
              <div className="wizardLabel">
                Thumbnail <span className="sterik">*</span>
              </div>

              {image ? (
                <div className="imageUpload">
                  <div className="imageWrapper">
                    <img src={image} alt="" className="imageHeight" />
                    <div
                      className="deleteButton"
                      onClick={() => handleImage('', {})}
                    >
                      <img src={Delete} width={15} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="formgroup formgroupStepOne">
                  <div className="label-wrapper">
                    <label>
                      <p className="ant-upload-text ">
                        Drop your image here or browse
                      </p>
                      <p className="ant-upload-hint">
                        {`Minimum file 2 MB (.png,.jpg,.jpeg)`}
                      </p>
                      <input
                        ref={fileRef}
                        type="file"
                        accept=".jpeg,jpg,.png"
                        onChange={async (e: any) => {
                          const response = await getBase64(
                            e.currentTarget.files[0]
                          );
                          if (response) handleImage(response, e);
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Company <span className="sterik">*</span>
              </div>
              {/* <Select
              size={'large'}
              placeholder={'Select brand'}
              value={company}
              onChange={handlePickerChange('company')}
              style={{ width: 560 }}
              options={companyList}
            /> */}
              <Input
                placeholder="Enter Company name"
                size="large"
                value={company_name}
                disabled={blocked}
                required
              />
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Brand Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Brand name"
                size="large"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
                required
              />
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Branch Limit <span className="sterik">*</span>
              </div>
              {/* <Input
                placeholder="Enter address"
                size="large"
                value={sublicense}
                onChange={(e) => setSubLicense(e.target.value)}
                required
              /> */}
              <Select
                size={'large'}
                placeholder={'Select brand'}
                value={sublicense}
                onChange={(value) => setSubLicense(value)}
                style={{ width: 560 }}
                options={branchLimits}
                disabled={update}
              />
            </div>
            <div className="wizardSubContainer">
              <div className="wizardLabel">
                Country <span className="sterik">*</span>
              </div>
              {/* <Select
              size={'large'}
              placeholder={'Select brand'}
              value={country}
              onChange={handlePickerChange('country')}
              style={{ width: 560 }}
              options={countryList}
            /> */}
              <Input
                placeholder="Enter Country name"
                size="large"
                value={country_name}
                disabled={blocked}
                required
              />
            </div>
            {/* <div className="wizardDropdown">
            <div className="wizardLabel">
              City <span className="sterik">*</span>
            </div>
            <Select
              showSearch
              size={'large'}
              placeholder={'Select city'}
              value={city}
              onChange={handlePickerChange('city')}
              style={{ width: 560 }}
              options={cityList}
            />
          </div> */}
            <div className="wizardSubContainer">
              <div className="wizardLabel">Phone Number</div>
              <Input
                addonBefore={countryCode}
                placeholder="Enter Phone Number"
                type="number"
                value={phone}
                onChange={(e) => handlePhone(e.target.value)}
              />
            </div>
            <div className="butonGroup">
              <div className="wizard-button" onClick={() => handleBack()}>
                Back
              </div>
              <div
                className="continue wizard-button"
                onClick={() => handleContinue()}
              >
                Continue
              </div>
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default ApprovalWizard;
