/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Input,
  Spin,
  Tooltip,
  Radio,
  Checkbox,
  RadioChangeEvent,
  // PaginationProps,
  // Dropdown as DropDown,
  // Space,
  // MenuProps,
} from 'antd';
import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import {
  Page,
  Breadcrumb,
  // Pagination,
  // ResponseDialog,
  Dropdown,
  IngredientDialog,
  ListViewWithoutExpand,
} from '../../components';
// import UsersApi from '../../redux/middleware/users';
// import BrandActions from '../../redux/middleware/brands';
// import IngredientActions from '../../redux/middleware/ingredient';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Delete from '../../assets/images/Delete.svg';
// import ArrowDown from '../../assets/images/ArrowDown.png';
// import Eye from '../../assets/images/Eye.svg';
import Utils from '../../redux/utils';
import './cashPurchaseWizard.scss';
import SupplierApi from '../../redux/middleware/supplier';
import { checkAuthentication } from '../../helper/checkAuthentication';
import ResponseDialog from '../../components/ResopnseDialog';
import { sortedData, sortNumber } from '../../helper/sortData';
import UpDownArrow from '../../assets/images/UpDownArrow.png';

const CashPurchaseWizard = () => {
  const user = Utils.getCurrentUser();
  const shop = Utils.getCurrentShop();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { update, id } = state;
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [visible, setVisible] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  const [sortData, setSortData] = useState([{}]);
  const [ascending, setAscending] = useState(true);
  // const [image, setImage] = React.useState('');
  // eslint-disable-next-line no-unused-vars
  // const [imageObject, setImageObject] = React.useState({});
  // const [country, setCountry] = React.useState({} as any);
  // const [, setCompany] = React.useState('' as any);
  // const [phone, handlePhone] = React.useState('' as any);
  const [receipt, setReceipt] = React.useState('');
  const [vendor, setVendor] = React.useState('');
  // const [email, setEmail] = React.useState('');
  // const [unit, setUnit] = useState<any>({});
  // const [, setSubLicense] = React.useState();
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [selectedItems, setSelectedItems] = useState<{ id: number }[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [, setCompanyList] = React.useState([]);
  // const [, setCountryList] = React.useState([]);
  // const [branchLimits, setBranchLimits] = useState<any>([]);

  const [errorMessage] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [ingredientIndex, setIngredientIndex] = useState<any>();
  const [ingredientName, setIngredientName] = useState('');
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  // const [option, setOption] = useState<SelectProps['options']>([
  //   { value: '', label: '', key: '' },
  // ]);
  // const [quantity, setQuantity] = React.useState<number>();
  const [ingredientData, setIngredientData] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>();
  const [ingredients, setIngredients] = useState<any[]>([
    {
      ingredient_id: -1,
      unit_id: 1,
      quantity: '',
      ingredient_name: '',
      storage_unit: '',
      multiplier: 0,
    },
  ]);
  const [cashPurchaseFor, setCashPurchaseFor] = useState('brand');
  const [dataLength, setDataLength] = useState(50);
  const [spinner, setSpinner] = useState(true);

  const { currentShop } = useSelector((state: any) => state.Auth);

  // const items: MenuProps['items'] = unit?.map((data: any) => {
  //   return {
  //     key: `${data?.id}`,
  //     label: <div className="dropdownOptions">{data?.unit}</div>,
  //   };
  // });

  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [currentBrandId] = useState(currentShop?.shop_id);
  // const PageDataLength = [6, 10, 20, 50];

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const onSelectMultiple = (e: CheckboxChangeEvent, record: any) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, record]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    }
  };

  const isItemSelected = (record: any) => {
    return selectAll || selectedItems.some((item) => item.id === record.id);
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedItems(ingredients);
      setSelectAll(true);
    } else {
      setSelectedItems([]);
      setSelectAll(false);
    }
  };

  const Columns = [
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>
            <Checkbox onChange={onSelectAll} checked={selectAll} />
          </span>
        </div>
      ),
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (record: any) => (
        <div className="col3 cellSpacing sub-view-align-adjustment">
          <Checkbox
            onChange={(e: any) => onSelectMultiple(e, record)}
            checked={isItemSelected(record)}
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle view-align-brand">
          <span>INGREDIENT NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('ingredient_name', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 cellSpacing font view-align-brand">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title alignment-left-column-values">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Name</div>
                        <div className="tootipText">
                          {record?.ingredient_name}
                        </div>
                      </div>
                    }
                  >
                    {record?.ingredient_name?.length > 20
                      ? record?.ingredient_name?.substring(0, 20) + '...'
                      : record?.ingredient_name}
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>QUANTITY</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('quantity', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing col-qty">
          <div className="gramSubContainer po-quantity">
            <input
              className="gramValue"
              placeholder="0"
              type={'number'}
              defaultValue={
                record.quantity ? Number(record?.quantity).toString() : '1'
              }
              onChange={(e) => {
                if (+e.target.value <= 99999)
                  handleIngredientWeight(+e.target.value, index, 'quantity');
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('storage_unit', 'string');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'storage_unit',
      key: 'storage_unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.storage_unit}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>NET COST</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit_cost', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'unit_cost',
      key: 'unit_cost',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing col-qty">
          <div className="cash-purchase-unit-cost-input col-input">
            <input
              className="gramValue"
              placeholder="0"
              type={'number'}
              defaultValue={
                record.unit_cost ? record?.unit_cost.toFixed(2).toString() : ''
              }
              onChange={(e) => {
                if (+e.target.value <= 99999)
                  handleIngredientWeight(+e.target.value, index, 'unit_cost');
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>VALUE</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('net_cost', 'number');
              setAscending(!ascending);
            }}
          />
        </div>
      ),
      dataIndex: 'net_cost',
      key: 'net_cost',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing col-qty">
          <div className="calorie-container-each-ingredient">
            <div className="calorie-each-value-container cash-purchase-net-cost-input">
              {isNaN(+record?.unit_cost * parseFloat(record?.quantity))
                ? formatNumber(+record?.unit_cost * 1)
                : formatNumber(+record?.unit_cost * record?.quantity)}
            </div>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (update && currentBrandId !== currentShop?.shop_id) {
      navigate('/app/cashPurchase');
    }
  }, [currentBrand, currentShop]);

  const onChangeBrandBranch = (e: RadioChangeEvent) => {
    if (shop?.is_ck === 0) {
      let responseDialogData;
      responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          `The ${shop?.shop_name} selected is not a Central Kitchen, so this operation is not allowed to this Branch`,
        ],
      };
      ResponseDialog(responseDialogData);
      return;
    }
    setCashPurchaseFor(e.target.value);
  };

  // const [messages, setMessages] = useState<any[]>([]);
  // const [alerts, setAlerts] = useState(false);
  // const [visible, setVisible] = useState(false);
  // const [unit, setUnit] = useState<any>([]);

  // const items: MenuProps['items'] = unit?.map((data: any) => {
  //   return {
  //     key: `${data?.id}`,
  //     label: <div className="dropdownOptions">{data?.unit}</div>,
  //   };
  // });

  // const fetchUnit = async () => {
  //   const data = await IngredientActions.getUnits();
  //   setUnit(data?.data);
  // };

  // const handleMenuClick: MenuProps['onClick'] = (e: any) => {
  //   setDataLength(PageDataLength[+e?.key]);
  // };

  // const DropDownmenuProps = {
  //   onClick: handleMenuClick,
  // };

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'cash_purchase'));

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  useEffect(() => {
    fetchStatus();
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    getIngredients();
  }, [currentBrand, cashPurchaseFor]);

  // const handlePickerChange =
  //   (fieldName: string) => (value: string | string[], data: any) => {
  //     if (fieldName === 'country') setCountry(data);
  //     if (fieldName === 'city') {
  //       setCity(data?.value);
  //     }
  //     if (fieldName === 'company') setCompany(data);
  //   };

  // const getCountryListing = async () => {
  //   const response = await ResourcesActions.getCountries(500, 1);
  //   if (response?.success) {
  //     const { data } = response;
  //     const list =
  //       data &&
  //       data.items &&
  //       data.items.map((item: any) => ({
  //         value: item?.country_code,
  //         label: item?.country_name,
  //         key: item?.id,
  //       }));
  //     setCountryList(list);
  //   }
  // };

  // const getBrandInfo = async () => {
  //   const response = await BrandActions.getBrandById(id);
  //   if (response?.success) {
  //     const { data } = response;

  //     setBrand(data?.name);
  //     setSubLicense(data?.sub_license);
  //     handlePhone(data?.phone);
  //     setEmail(data?.email);
  //     setCountry({
  //       label: data?.country,
  //       value: data?.country,
  //       key: data?.country_id,
  //     });
  //     setCompany({
  //       label: data?.company,
  //       value: data?.company,
  //       key: data?.company_id,
  //     });
  //     setImage(data?.thumbnail);
  //   }
  // };

  // const getCompanyById = async () => {
  //   const company = await Utils.getCurrentCompany();
  //   const response = await UsersApi.getUserCompanyById(company?.id);
  //   if (response?.success) {
  //     // setBranchLimits(
  //     //   response?.data?.available_sub_licenses?.map((data: any) => ({
  //     //     label: data,
  //     //     value: data,
  //     //   }))
  //     // );
  //   }
  // };

  const fetchDataById = async () => {
    setApiLoader(true);
    const { data, success } = await SupplierApi.getInventorySupplyById(id);

    if (success) {
      setIngredients(
        data?.ingredients?.map((ingredient: any) => ({
          ...ingredient,
          id: ingredient?.ingredient_id,
          quantity: +ingredient?.request_quantity,
        }))
      );
      setCashPurchaseFor(data?.cp_from);
      setVendor(data?.vendor_name);
      setReceipt(data?.receipt_no);
      setTotalPrice(data?.total_cost);
    }
    setApiLoader(false);
  };

  React.useEffect(() => {
    if (update) {
      fetchDataById();
    }
  }, []);

  //   const fetchUnits = async () => {
  //     const data =
  //       component == 'recipe builder' || component == 'rb_marketlist'
  //         ? await IngredientActions.getUnits()
  //         : await SubRecipeActions.getStorageUnits();
  //     const option: SelectProps['options'] =
  //       component == 'recipe builder' || component == 'rb_marketlist'
  //         ? data?.data.map((unit: any) => {
  //             return {
  //               value: unit.id,
  //               label: unit.unit,
  //               key: unit.id,
  //             };
  //           })
  //         : data?.data?.map((unit: any) => {
  //             return {
  //               value: unit.id,
  //               label: unit.storage_unit,
  //               key: unit.id,
  //             };
  //           });
  //     setOption(option);
  //   };

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    setErrorList({});
    setErrorStatus(false);
    const user = await Utils.getCurrentUser();
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const filteredIngredients = ingredients?.filter((data: any) =>
      selectedItems.some((item: any) => item.id === data.id)
    );

    const checkEmptyIngredient = filteredIngredients?.some(
      (data: any) =>
        data?.quantity == '' || data?.id == -1 || data?.ingredient_name == ''
    );

    if (brand === '' || !filteredIngredients?.length || checkEmptyIngredient) {
      setVisible(true);
      return;
    }

    const payload = {
      request_type_id: requestType?.id,
      request_status_id: status?.id,
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id,
      created_by: user?.id,
      receipt_no: receipt,
      total_cost: totalPrice,
      vendor_name: vendor,
      tag:
        user?.roles[0]?.name === 'Company Manager'
          ? cashPurchaseFor
          : user?.roles[0]?.name === 'Branch Manager'
          ? 'shop'
          : 'brand',
      ingredients: filteredIngredients?.map((data: any) => ({
        ingredient_id: data?.id,
        request_quantity: data?.quantity || 1,
        unit_cost: data?.unit_cost,
        total_cost: +data.unit_cost * +data?.quantity || +data?.unit_cost * 1,
      })),
    };

    // let responseDialogData;
    setApiLoader(true);

    try {
      const response = update
        ? await SupplierApi.updateInventorySupply(payload, id)
        : await SupplierApi.createInventorySupply(payload);
      setApiLoader(false);
      if (!response?.success) {
        setErrorList(response?.error);
        setErrorStatus(true);
      } else {
        navigate('/app/cashPurchase');
        // responseDialogData = {
        //   OnClick: () => {
        //     navigate('/app/cashPurchase');
        //   },
        //   type: 'success',
        //   messageArray: [
        //     `Your ${receipt}`,
        //     ` has been ${update ? 'Updated' : 'Created'}`,
        //   ],
        // };
        // ResponseDialog(responseDialogData);
      }
    } catch (e) {
      console.log(e);
    }

    // const payload = {
    //   company_id: user?.company_id,
    //   name: brand,
    //   city_id: 0,
    //   country_id: user?.country_id,
    //   phone: phone.toString(),
    //   email: email,
    //   created_by: user?.id,
    // };

    // try {
    //   let responseDialogData;

    //   setApiLoader(true);
    //   let response;
    //   if (update) {
    //     const thumbnail = imageObject || image;
    //     response = await BrandActions.updateBrand(id, payload, thumbnail);
    //   } else {
    //     response = await BrandActions.addBrand(payload, imageObject);
    //   }
    //   setApiLoader(false);
    //   if (!response?.success) {
    //     setErrorList(response?.error);
    //     setErrorStatus(true);
    //     // responseDialogData = {
    //     //   OnClick: () => {},
    //     //   type: 'error',
    //     //   messageArray: ['Validation Failed!', 'Something Went Wrong'],
    //     // };
    //     // ResponseDialog(responseDialogData);
    //   } else {
    //     responseDialogData = {
    //       OnClick: () => {
    //         navigate('/app/brands');
    //       },
    //       type: 'success',
    //       messageArray: [
    //         ` Your brand - ${brand}`,
    //         ` has been ${!update ? 'Created' : 'Updated'}`,
    //       ],
    //     };
    //     ResponseDialog(responseDialogData);
    //   }
    // } catch (e) {
    //   console.log(e);
    //   let responseDialogData = {
    //     OnClick: () => {},
    //     type: 'error',
    //     messageArray: ['Server Error!', 'Something Went Wrong'],
    //   };
    //   ResponseDialog(responseDialogData);
    // }
  };

  // const checkEmptyIngredients = (index: number) => {
  //   if (index == 2) {
  //     const wrongIngredient = ingredients.filter((data: any) => {
  //       if (
  //         data?.ingredient_id == -1 ||
  //         data?.quantity == '' ||
  //         data?.quantity == '0'
  //       )
  //         return { unit: data?.carb_per_unit, qty: data?.quantity };
  //     });
  //     if (wrongIngredient.length) {
  //       setMessages([
  //         "Please Select correct Ingredient. Quantity shouldn't be zero and please remove unused fields, if any.",
  //       ]);
  //       setAlerts(true);
  //       setVisible(false);
  //       window.scrollTo(0, 0);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // const removeIngredientName = (index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].ingredient_name = '';
  //   setIngredients(() => [...dataArray]);
  // };

  // const handleIngredientName = (data: string, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].ingredient_name = data;
  //   setIngredients(() => [...dataArray]);
  // };

  // const handleRemoveIngredient = (index: number) => {
  //   let DataArray = ingredients;
  //   DataArray.splice(index, 1);
  //   setIngredients([...DataArray]);
  //   handleTotalPrice(DataArray);
  //   // autoFetchAllergen(DataArray);
  // };

  const handleIngredientWeight = (data: any, index: number, label: string) => {
    let dataArray = ingredients;
    if (data === 0) {
      dataArray[index][label] = '';
      setIngredients(() => [...dataArray]);
    } else {
      dataArray[index][label] = data;
      setIngredients(() => [...dataArray]);
    }
    handleTotalPrice(dataArray);
  };
  // const handleIngredientWeightUnit = (data: any, index: number) => {
  //   let dataArray = ingredients;
  //   dataArray[index].unit = data?.unit;
  //   dataArray[index].unit_id = data?.id;
  //   dataArray[index].multiplier = data?.multiplier;

  //   setIngredients(() => [...dataArray]);
  // };

  const handleTotalPrice = (dataSource: Array<any>) => {
    let total = dataSource.map(
      (data) => Number(data?.unit_cost * parseFloat(data?.quantity)) || 0
    );
    const price = total.reduce((partialSum, a) => partialSum + a, 0);
    setTotalPrice(+Number(price)?.toFixed(2) || 0);
  };

  // const AddIngredient = () => {
  //   setIngredients((prevArray) => [
  //     ...prevArray,
  //     {
  //       ingredient_id: -1,
  //       unit_id: 1,
  //       quantity: '',
  //       ingredient_name: '',
  //       storage_unit: 'Kg',
  //       multiplier: 0,
  //     },
  //   ]);
  // };

  // const handleIngredient = (data: any, index: number) => {
  //   let dataArray = ingredients;

  //   const checkDuplicateData = dataArray.some(
  //     (ingredient) => ingredient?.id === data?.id
  //   );

  //   const checkDuplicateField =
  //     dataArray.findIndex(
  //       (ingredientObject) => ingredientObject?.id === data?.id
  //     ) === index;

  //   if (!checkDuplicateField && checkDuplicateData) {
  //     dataArray.splice(index, 1);
  //     setIngredients(() => [...dataArray]);
  //     // setMessages([
  //     //   'Duplication of ingredients is not allowed! The field automatically removed, when you try to duplicate ingredients.',
  //     // ]);
  //     // setVisible(false);
  //     // setAlerts(true);
  //     window.scrollTo(0, 0);
  //     return;
  //   } else if (checkDuplicateField && checkDuplicateData) {
  //     setIngredients([...dataArray]);
  //   } else {
  //     // const unitObject = unit.filter(
  //     //   (element: any) => element?.id == data?.unit_id
  //     // );
  //     dataArray[index] = {
  //       ...data,
  //       quantity: '',
  //     };
  //     // autoFetchAllergen(dataArray);
  //     setIngredients(() => [...dataArray]);
  //   }
  // };

  // const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
  //   let option = +e.key;
  //   const unitObject = unit.filter((data: any) => data?.id == option);
  //   handleIngredientWeightUnit(unitObject[0], ingredientIndex);
  // };

  // const DropDownmenuProps = {
  //   items: items,
  //   onClick: handleDropDownClick,
  // };

  const formatNumber = (value: any) => {
    if (value != null) {
      return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
    }
    return '';
  };

  // const fetchIngredients = async () => {
  //   const brand = await Utils.getCurrentBrand();
  //   const shop = await Utils.getCurrentShop();

  //   const { data, success } = await SubRecipeActions.getAllInventory(
  //     3,
  //     1,
  //     1000,
  //     brand?.brand_id || 0,
  //     shop?.shop_id || 0
  //   );
  //   if (success) setIngredientData(data?.items);
  // };

  // const handleDialog = () => {
  //   setOpen(false);
  //   fetchIngredients();
  //   removeIngredientName(ingredientIndex);
  // };

  // const handleDeleteContinue = (index: number) => {
  //   handleRemoveIngredient(index);
  // };

  // const onChange: PaginationProps['onChange'] = (page) => {
  //   setCurrent(page);
  // };

  const getIngredients = async () => {
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    setSpinner(true);
    const data = await SupplierApi.getInventoryForRequisition(
      3,
      '',
      brand?.brand_id ? brand?.brand_id : '',
      shop?.shop_id ? shop?.shop_id : ''
    );
    if (data != undefined && data?.success) {
      setIngredients(data?.data?.items);
      setSortData(data?.data?.items);
      setTotal(data?.data?.pagination?.total);
    } else {
      setIngredients([]);
      setSortData([]);
      setTotal(0);
    }
    setSpinner(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading="Add New Cash Purchase"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/cashPurchase');
        }}
      />
      <Spin
        size="large"
        className="loadIngredient recipeBuilderloaderHeight"
        spinning={apiLoader}
      >
        <Page title="Add New Cash Purchase">
          {visible && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setVisible(false)}
            />
          )}
          <div className="alertMessage">
            {error &&
              errorMessage?.map((data: any, index: any) => (
                <Alert
                  message="Error"
                  description={data}
                  type="error"
                  showIcon
                  closable
                  className="wizardAlert"
                  afterClose={() => setError(false)}
                  key={index}
                />
              ))}
          </div>

          {/* {open ? (
            <IngredientDialog
              handleDialog={handleDialog}
              handleSuccesDialog={handleDialog}
              open={open}
              component={'marketlist'}
              name={ingredientName}
              handleIngredient={(data: any) => {
                handleIngredient(data, ingredientIndex);
                setIngredientName(data?.ingredient_name);
              }}
            />
          ) : null} */}
          <div className="cash-purchase-wizard-container cp-wizard-container">
            {/* <div className="cash-purchase-wizard-container"> */}
            {user?.roles[0]?.id === 3 && (
              <div className="wizardDropdown cp-dropdown">
                <div className="wizardLabel">
                  Cash Purchase For <span className="sterik">*</span>
                </div>
                <Radio.Group
                  onChange={onChangeBrandBranch}
                  value={cashPurchaseFor}
                  disabled={update}
                >
                  <Radio value="brand">Brand</Radio>
                  <Radio value="shop">Branch</Radio>
                </Radio.Group>
              </div>
            )}

            {/* <div className="cash-purchase-wizard-container"> */}
            <div className="wizardSubContainer cp-wizardSubContainer">
              <div className="wizardLabel">
                Vendor Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Vendor Name"
                size="large"
                value={vendor}
                onChange={(e) => setVendor(e.target.value)}
                required
                className={
                  errorList['vendor_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['vendor_name']}
                </div>
              )}
            </div>
            <div className="wizardSubContainer cp-wizardSubContainer">
              <div className="wizardLabel">
                Receipt Number <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Receipt Number"
                size="large"
                value={receipt}
                onChange={(e) => setReceipt(e.target.value)}
                required
                className={
                  errorList['brand_name']?.length > 0 ? 'borderRed' : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['brand_name']}
                </div>
              )}
            </div>

            <div className="wizardSubContainer cp-wizardSubContainer">
              <div className="wizardLabel">
                Total Net Cost <span className="sterik">*</span>
              </div>
              <Input
                placeholder="0"
                size="large"
                value={formatNumber(totalPrice)}
                // onChange={(e) => setEmail(e.target.value)}
                disabled
                required
                className={errorList['email']?.length > 0 ? 'borderRed' : ''}
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['email']}
                </div>
              )}
            </div>
            {/* </div> */}
          </div>
          <Row gutter={12} className="listingTopFilter">
            <Col lg={24} md={16} className="Brands-list-wrapper">
              {!apiLoader ? (
                <ListViewWithoutExpand
                  className="custom-table"
                  limit={1000}
                  items={''}
                  dataSource={[...sortData]}
                  columns={Columns}
                  component="cash purchase"
                  scrollY={600}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin spinning={apiLoader} className="recipeLoader" />
                </div>
              )}
              {/* <Pagination
                total={total}
                menuProps={DropDownmenuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              /> */}
            </Col>
          </Row>
          <div className="butonGroup po-btn">
            <div
              className="wizard-button"
              onClick={() => navigate('/app/cashPurchase')}
            >
              Back
            </div>
            <div
              className="continue wizard-button"
              onClick={() => handleContinue()}
            >
              Continue
            </div>
          </div>
        </Page>
      </Spin>
    </React.Fragment>
  );
};

export default CashPurchaseWizard;
