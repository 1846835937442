import React, { useState, useEffect } from 'react';
// import indofoods from '../../assets/images/Indofoods.png';
// import tickIcon from '../../assets/images/tickIcon.png';
// import Subtract from '../../assets/images/Subtract.png';
import './UserPersonalInfo.scss';
import UsersApi from '../../redux/middleware/users';
import Utils from '../../redux/utils';
import { Breadcrumb, PricePlan } from '../../components';
import { useNavigate } from 'react-router-dom';
import { Input, Spin } from 'antd';
import { checkAuthentication } from '../../helper/checkAuthentication';
import SubscriptionApi from '../../redux/middleware/subscription';
import { useSearchParams } from 'react-router-dom';
import { ResponseDialog } from '../../components';

const UserInfo = () => {
  const user = Utils.getCurrentUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loader, setLoader] = useState(true);
  const [type, setType] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [sessionId, setSessionId] = React.useState<any>('');
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState<any>();
  const getUserDetails = async () => {
    const data = await UsersApi.getUserById(user?.id);
    setData(data?.data);
    setLoader(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handleCancelSubscription = async () => {
    setLoader(true);
    const param = { user_id: user?.id };
    const data = await SubscriptionApi.cancelSubscription(param);
    console.log('data', data);
    if (data?.success) {
      let responseDialogData = {
        OnClick: () => {},
        type: 'success',
        messageArray: [data?.message],
      };
      ResponseDialog(responseDialogData);
    } else {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [data?.message],
      };
      ResponseDialog(responseDialogData);
    }
    setLoader(false);
  };

  const handleClose = () => {
    setOpenDialog(!openDialog);
    setType('Change_Plan');
  };
  useEffect(() => {
    getUserDetails();
  }, [user?.id]);

  useEffect(() => {
    setSessionId(searchParams?.get('token'));
    if (searchParams?.get('token')) {
      handleSubscriptionById();
    }
  }, []);

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  const handleSubscriptionById = async () => {
    setLoader(true);
    const data = await SubscriptionApi.getSubscriptionById(user?.id);
    console.log('data of get subscription by id', data);
    if (data?.success && data?.data?.length > 0) {
      let responseDialogData = {
        OnClick: () => {},
        type: 'success',
        messageArray: ['User has Subscribed Succesfully'],
      };
      ResponseDialog(responseDialogData);
    } else {
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [data?.message],
      };
      ResponseDialog(responseDialogData);
    }
    setLoader(false);
  };

  return (
    <React.Fragment>
      <div className="personalInformation">
        <Breadcrumb
          heading="Profile"
          breadcrumbIcon
          iconAction={() => {
            navigate('/app');
          }}
        />
        <PricePlan open={openDialog} type={type} handleClose={handleClose} />
        <Spin spinning={loader}>
          <div className="UserPersonalInfo">
            <div className="Ingredient-head">Personal Information</div>
            <div className="wizardDropdown">
              <div className="label-after-heading">User Role</div>

              {/* <Select
                placeholder={'Select here'}
                // onChange={handleChangeRole}
                style={{ width: 560 }}
                // options={roleData}
                // value={userRole}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={data?.roles?.map((data: any) => data?.name).toString()}
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Company</div>

              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={data?.company_tree
                  ?.map((data: any) => data?.company_name)
                  .toString()}
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Name</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={data?.name}
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Phone</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={data?.phone}
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Email</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={data?.email}
              />
            </div>
            <div className="Ingredient-head">Package Detail</div>
            <div className="wizardDropdown">
              <div className="label-after-heading">Package Type</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={'Premium'}
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Package Expiry</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={'Select company here'}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value="25/10/2023"
              />
            </div>
            <div className="wizardDropdown">
              <div className="wizardLabel">Price</div>

              {/* <Select
                disabled
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Please select"
                // onChange={handleChangeDesgnation}
                // options={designationData}
                // value={designation}
              /> */}
              <Input
                size={'large'}
                placeholder={''}
                // onChange={handleCompany(companyObject?.id)}
                disabled
                style={{ width: 560 }}
                value={'$120'}
              />
            </div>
            {/* 
            // Todo : Hide Deactivate tab of Prod
            user?.roles[0]?.id === 2 ? (
              <div className="action-buttons-container">
                <div
                  className="deactivate"
                  onClick={() => {
                    handleCancelSubscription();
                  }}
                >
                  Deactivate
                </div>
                <div
                  className="continue wizard-button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Change Plan
                </div>
              </div>
                ) : null */}
          </div>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default UserInfo;
