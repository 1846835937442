/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import './styles.scss';

export default function Data() {
  return (
    <div className="staticContainer">
      <header>
        <h1>
          <u>Data Deletion Policy</u>
        </h1>
      </header>
      <br />
      <h2>Effective Date: [1st June 2023]</h2>
      <p>
        <b>
          <u>ChefAdmin</u>
        </b>{' '}
        is committed to protecting the privacy and personal data of our users.
        This Data Deletion Policy outlines our procedures for the deletion of
        user data collected through our platform when users sign up using their
        Facebook or Google accounts. By using our platform, you acknowledge that
        you have read and understood this policy and agree to its terms.
      </p>
      <br />
      <h3>
        <u>Data Collection and Storage</u>
      </h3>
      <p>
        When users sign up using their Facebook or Google accounts, we collect
        certain personal data necessary to create and manage their accounts.
        This data may include, but is not limited to, the user's name, email
        address, profile picture, and other relevant information made available
        through the respective social media platforms.
      </p>
      <br />
      <h3>
        <u>User Data Deletion Requests</u>
      </h3>
      <p>
        We respect our users' rights to privacy and provide options for data
        deletion. If you wish to have your data deleted from our platform, you
        can submit a deletion request by contacting our support team through the
        designated channels. Please provide us with the necessary information to
        identify your account accurately.
      </p>
      <br />
      <h3>
        <u>Processing and Verification of Deletion Requests</u>
      </h3>
      <p>
        Upon receiving a data deletion request, we will review and process the
        request within a reasonable timeframe. We may request additional
        information to verify your identity and ensure that the deletion request
        is genuine. We will only process requests related to the personal data
        associated with the user's Facebook or Google account used for signing
        up.
      </p>
      <br />
      <h3>
        <u>Deletion of User Data</u>
      </h3>
      <p>
        Once a deletion request is verified and approved, we will take
        reasonable measures to delete the user's personal data from our platform
        and associated systems. This process includes the permanent removal of
        user data from our databases, backups, and any other storage systems we
        use. Please note that the deletion of user data may take some time due
        to technical constraints, and residual copies may remain in our backups
        for a limited period.
      </p>
      <br />
      <h3>
        <u>Retention of Aggregated Data</u>
      </h3>
      <p>
        We may retain aggregated data that does not personally identify you or
        any individual user. This data may be used for analytical purposes or
        improving our platform's performance while ensuring compliance with
        privacy regulations.
      </p>
      <br />
      <h3>
        <u>Communication Regarding Deletion Requests</u>
      </h3>
      <p>
        We will make reasonable efforts to communicate with users regarding the
        status of their deletion requests. However, please note that certain
        circumstances, such as legal obligations or technical limitations, may
        prevent us from fulfilling the deletion request entirely.
      </p>
      <br />
      <h3>
        <u>Policy Updates</u>
      </h3>
      <p>
        We reserve the right to update this Data Deletion Policy from time to
        time. The updated version will be posted on our platform, and your
        continued use of our services after such changes will indicate your
        acceptance of the revised policy.
      </p>
      <p>
        If you have any questions or concerns about this Data Deletion Policy or
        our data practices, please contact our support team through the
        designated channels.
      </p>
    </div>
  );
}
