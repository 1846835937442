/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  Dropdown,
  Space,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  Page,
  Pagination,
  Breadcrumb,
  Button,
  ListCount,
  SearchBox,
  ListViewWithoutExpand,
  DeleteConfirmation,
} from '../../components';
import VariationActions from '../../redux/middleware/variation';
import { sortNumber, sortedData } from '../../helper/sortData';
import Delete from '../../assets/images/Delete.svg';
import Clock from '../../assets/images/Clock.png';
import More from '../../assets/images/More.png';
import Edit from '../../assets/images/Edit.png';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import moment from 'moment';
// import './variation.scss';
import { useSelector } from 'react-redux';
import Utils from '../../redux/utils';
import { checkAuthentication } from '../../helper/checkAuthentication';
import { useTranslation } from 'react-i18next';

const PageDataLength = [6, 10, 20, 50];

const OrderTypesPage = () => {
  const user = Utils.getCurrentUser();
  const { t } = useTranslation('translation', {
    keyPrefix: 'variationPage',
  });
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const items: MenuProps['items'] = [
    {
      key: '3',
      label: <div className="dotOption">{t('edit')}</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">{t('delete')}</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const [VariationId, setVariationId] = useState('');

  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  // React.useEffect(() => {
  //   pageData();
  // }, [currentBrand]);

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('id')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('id', 'number');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">{record?.id}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('variation_name_label')}</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing cursor">
          {/* {record?.name} */}
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Name</div>
                <div className="tootipText">{record?.name}</div>
              </div>
            }
          >
            {record?.name?.length > 20
              ? record?.name?.substring(0, 20) + '...'
              : record?.name}
          </Tooltip>
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('variation_type')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('variation_type', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'variation_type',
      key: 'variation_type',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="subrecipecol2">{record?.variation_type}</div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>{t('created')}</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('created_at', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Tooltip
          title={
            <div>
              <div className="tooltipHead">Created At</div>
              <div className="tootipText">
                {moment(UTCtoLocal(record?.created_at)).format(
                  'DD-MM-YYYY HH:mm:ss'
                )}
              </div>
            </div>
          }
        >
          <div className="tableCol">
            {/* <img src={Clock} alt="" className="icons" /> */}
            {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
          </div>
        </Tooltip>
      ),
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);
    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Variations'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const setSelectedMenu = (Variation: any) => {
    console.log('Variation', Variation);
    setVariationId(Variation.id);
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 3:
        navigate('wizard', { state: { update: true, id: VariationId } });
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    await VariationActions.deleteVariation(VariationId);
    setOpenDeleteDialog(false);
    pageData();
  };

  const DropDownmenuProps = {
    //items: permissionStatus && permissionStatus === 2 ? items : [],
    items,
    onClick: handleDropDownClick,
  };

  const actionButton = (
    <Button
      text={t('add_new')}
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '3' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const pageData = async () => {
    setRecipeLoader(true);
    const variationData = await VariationActions.getVariationByType(
      dataLength,
      current,
      keyword,
      3
    );
    if (variationData === undefined || !variationData?.success) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(variationData?.data?.variation_count);
      setTotal(variationData?.data?.pagination?.total);
      setSortData(variationData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    const searchResultofVariations = await VariationActions.searchVariation(
      dataLength,
      keyword
    );
    if (
      searchResultofVariations === undefined ||
      !searchResultofVariations?.success
    ) {
      setRecipeCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofVariations?.data?.variation_count);
      setTotal(searchResultofVariations?.data?.pagination?.total);
      setSortData(searchResultofVariations?.data?.items);
    }
    setRecipeLoader(false);
  };

  return (
    <React.Fragment>
      <Breadcrumb
        heading={t('order_type')}
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={'Are you sure want to delete order type?'}
      />
      <Page title="Order Type">
        <Row className="listingTopFilter">
          <Col lg={10} md={24}>
            <ListCount count1={recipeCount} title1={t('order_type')} />
          </Col>
          <Col lg={14} md={24}>
            <div className="search-wrapper">
              <div className="search-box">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText={t('search_placeholder')}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Col lg={24} md={24}>
            {!recipeLoader ? (
              <ListViewWithoutExpand
                limit={dataLength}
                items={items}
                dataSource={[...sortData]}
                columns={columns}
              />
            ) : (
              <div className="loader-wrapper">
                <Spin
                  spinning={recipeLoader}
                  className="recipeLoader"
                  size="large"
                />
              </div>
            )}
            <Pagination
              total={total}
              menuProps={menuProps}
              onChange={onChange}
              current={current}
              dataLength={dataLength >= total ? total : dataLength}
              handleLength={(data: number) => setDataLength(data)}
            />
          </Col>
        </Row>
      </Page>
    </React.Fragment>
  );
};

export default OrderTypesPage;
