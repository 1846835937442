/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  PaginationProps,
  Dropdown,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import type { MenuProps } from 'antd';
import {
  Page,
  GridView,
  ListView,
  Pagination,
  Filter,
  Breadcrumb,
  Button,
  Dialog,
  SubDialog,
  ListCount,
  SearchBox,
  ResponseDialog,
  DeleteConfirmation,
} from '../../components';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import { handleFloat, sortedData, sortNumber } from '../../helper/sortData';
import More from '../../assets/images/More.png';
import Delete from '../../assets/images/Delete.svg';
import Edit from '../../assets/images/Edit.png';
import Export from '../../assets/images/export.png';
import Attributes from '../../redux/middleware/attributes';
import Download from '../../assets/images/Download.svg';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
// import subRecipeVerified from '../../assets/images/subRecipeVerified.png';
// import subRecipeNotVerified from '../../assets/images/subRecipeNotVerified.png';
import Fire from '../../assets/images/Fire.svg';
import Serving from '../../assets/images/Serving.png';
import SubMenu from '../../assets/images/SubMenu.svg';
import Clock from '../../assets/images/Clock.png';
import Utils from '../../redux/utils';
import './styles.scss';
import DonutChart from '../../components/DonutChart';
import moment from 'moment';
import { checkAuthentication } from '../../helper/checkAuthentication';

const PageDataLength = [6, 10, 20, 50];

const SubRecipe = () => {
  const user = Utils.getCurrentUser();
  const [design, setDesign] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSubDialog, setOpenSubDialog] = useState(false);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([{}]);
  const [subrecipes, setSubrecipes] = useState<any>();
  const [ascend, setAscend] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [loader, setLoader] = useState(false);
  const [pdf, setPdf] = useState('');
  const [dialogData, setDialogData] = useState({});
  const [total, setTotal] = useState(0);
  const [permissionStatus, setPermissionStatus] = useState(0);
  const [recipeLoader, setRecipeLoader] = useState(false);
  const [recipeCount, setRecipeCount] = useState(0);
  const [ingrdientCount, setIngredientCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [id, setId] = useState('');
  const navigate = useNavigate();
  const [selectedSubRecipeId, setSelectedSubRecipeId] = useState('');
  const [popUp, setPopUp] = useState<any>(null);
  const [search, setSearch] = useState('');
  const { currentBrand, currentShop } = useSelector((state: any) => state.Auth);
  const [allowRequest, setAllowRequest] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [currentBrandId] = useState(currentBrand?.brand_id);
  useEffect(() => {
    if (currentBrandId !== currentBrand?.brand_id) {
      setKeyword('');
      setAllowRequest(true);
    }
  }, [currentBrand, currentShop]);

  useEffect(() => {
    if (allowRequest) {
      pageData();
      setAllowRequest(false);
    }
  }, [allowRequest]);

  const items: MenuProps['items'] = [
    {
      key: '2',
      label: (
        // <a href={pdf} download>
        <div className="dotOption">Download</div>
        // </a>
      ),
      icon: <img src={Download} width={15} />,
    },
    {
      key: '3',
      label: <div className="dotOption">Edit</div>,
      icon: <img src={Edit} />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <div className="dotOption deleteText">Delete</div>,
      icon: <img src={Delete} width={15} />,
    },
  ];

  const handleSort = (column: string, type: string) => {
    let arr =
      type == 'string'
        ? sortedData(ascend, sortData, column)
        : sortNumber(ascend, sortData, column);

    setSortData(() => arr);
  };
  const setSelectedMenu = (subrecipe: any) => {
    setSelectedSubRecipeId(subrecipe.sub_recipe_id);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const columns = [
    {
      title: () => (
        <div className="tableTitle">
          <span>SUB RECIPE NAME</span>
          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('recipe_name', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'recipe',
      key: 'recipe',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        return (
          <div className="subrecipecol1 font">
            <img
              src={record?.thumbnail}
              width="52px"
              height="52px"
              className="listView-image-align"
            />
            <div className="subrecipesubCol1 font">
              <div
                className="subrecipecol1Text font table-title1-hover alignment-left-column-values"
                onClick={() => {
                  handleingredient(record);
                }}
              >
                {/* {record?.recipe_name} */}
                {record?.recipe_name?.length > 22 ? (
                  <Tooltip
                    title={
                      <div>
                        <div className="tootipText">{record?.recipe_name}</div>
                      </div>
                    }
                  >
                    {record?.recipe_name?.substring(0, 22)}
                    {record?.recipe_name?.length > 22 ? <span>...</span> : null}
                  </Tooltip>
                ) : (
                  record?.recipe_name
                )}
              </div>
              <div className="col3 cellSpacing  common-flex-center calorie-serving-subrecipe-rd-gap">
                <div className="common-flex-center">
                  {' '}
                  <DonutChart
                    popUp={popUp}
                    setPopUp={setPopUp}
                    index={index}
                    data={record}
                    component={'listView'}
                  >
                    {record?.is_verified ? (
                      <img
                        src={Fire}
                        alt=""
                        className={'iconFireWhite IconsAlignment '}
                      />
                    ) : (
                      <img
                        src={Fire}
                        alt=""
                        className={'subCol1Icon IconsAlignment'}
                      />
                    )}
                  </DonutChart>
                  {handleFloat(record?.calorie)}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Servings</div>
                        <div className="tootipText">
                          {record?.servings} person serving
                        </div>
                      </div>
                    }
                  >
                    <img src={Serving} alt="" className="icons" />
                  </Tooltip>
                  {record?.servings}
                </div>
                <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        <div className="tooltipHead">Ingredients</div>
                        <div className="tootipText">
                          Total {record?.total_ingredients ?? 0} ingredients
                        </div>
                      </div>
                    }
                  >
                    <img src={SubMenu} width={15} alt="" className="icons" />
                  </Tooltip>
                  {record?.total_ingredients ?? 0}
                </div>
                {/* <div className="tableCol col3 common-flex-center">
                  <Tooltip
                    title={
                      <div>
                        {moment(UTCtoLocal(record?.created_at)).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </div>
                    }
                    className="common-flex-center"
                  >
                    <img src={Clock} alt="" className="icons" />
                  </Tooltip>
                </div> */}
              </div>
              <div className="column-created-on-field col3 ">
                <Tooltip
                  title={
                    <div>
                      {moment(UTCtoLocal(record?.created_at)).format(
                        'DD-MM-YYYY HH:mm:ss'
                      )}
                    </div>
                  }
                  className="common-flex-center"
                >
                  <img src={Clock} alt="" className="icons" />
                </Tooltip>
                <div>
                  {moment(UTCtoLocal(record?.created_at)).format('DD-MM-YYYY')}
                </div>
              </div>
              {/* <div className="subrecipesubTextCol1 font">
                <DonutChart
                  popUp={popUp}
                  setPopUp={setPopUp}
                  index={index}
                  data={record}
                >
                  {record?.is_verified ? (
                    <img
                      src={subRecipeVerified}
                      alt=""
                      className={'subRecipeVerifyIcon'}
                    />
                  ) : (
                    <img
                      src={subRecipeNotVerified}
                      alt=""
                      className={'subRecipeVerifyIcon'}
                    />
                  )}
                </DonutChart>

                <span className="col1Text font">
                  {handleFloat(record?.calorie)}
                </span>
                <span className="calories font ">kcal</span>
              </div> */}
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>CATEGORY</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('menu_category', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'menu_category',
      key: 'menu_category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="subrecipecol2">
          <Tooltip
            title={
              <div>
                <div className="tooltipHead">Category</div>
                <div className="tootipText">{record?.menu_category}</div>
              </div>
            }
          >
            {record?.menu_category?.substring(0, 22)}
            {record?.menu_category?.length > 22 ? <span>...</span> : null}
          </Tooltip>
        </div>
      ),
    },
    // {
    //   title: () => (
    //     <div className="tableTitle">
    //       <span>INGREDIENTS</span>

    //       <img
    //         src={UpDownArrow}
    //         className="titleArrow"
    //         onClick={() => {
    //           handleSort('total_ingredients', 'number');
    //           setAscend(!ascend);
    //         }}
    //       />
    //     </div>
    //   ),
    //   dataIndex: 'total_ingredients',
    //   key: 'total_ingredients',
    //   responsive: ['md'],
    //   render: (data: any, record: any) => (
    //     <div className="subrecipecol3">
    //       {handleFloat(record?.total_ingredients)} Ingredients
    //     </div>
    //   ),
    // },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNITS</span>

          <img
            src={UpDownArrow}
            className="titleArrow"
            onClick={() => {
              handleSort('unit', 'string');
              setAscend(!ascend);
            }}
          />
        </div>
      ),
      dataIndex: 'sub_recipe',
      key: 'sub_recipe',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return <div className="subrecipecol3">{record?.unit}</div>;
      },
    },
    {
      title: '',
      dataIndex: 'created_at',
      key: 'created_at',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <Dropdown
          menu={DropDownmenuProps}
          trigger={['click']}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a onClick={() => setSelectedMenu(record)}>
            <Space>
              <div className="dots">
                <img src={More} />
              </div>
            </Space>
          </a>
        </Dropdown>
      ),
    },
  ];
  useEffect(() => {
    checkAuthentication(user, navigate);

    let permissions = user?.permissions;
    const data = permissions?.filter(
      (permission: any) => permission?.permission === 'Sub Recipe'
    );
    data?.length && setPermissionStatus(data[0]?.perm_status_id);
    setCurrent(1);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current, currentBrand]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const handleingredient = async (data: any) => {
    const brand = await Utils.getCurrentBrand();
    const details = await SubRecipeActions.getIngredientDetail(
      data?.sub_recipe_id,
      brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const handleDropDownClick: MenuProps['onClick'] = async (e: any) => {
    let option = +e.key;
    switch (option) {
      case 2:
        {
          setLoader(true);
          // const data = await SubRecipeActions.downloadSubRecipe(selectedSubRecipeId);
          const data = subrecipes;
          if (selectedSubRecipeId && data && data.length > 0) {
            const selectedSubRecipe = data.find(
              (subrecipe: { sub_recipe_id: string }) =>
                subrecipe.sub_recipe_id === selectedSubRecipeId
            );
            if (selectedSubRecipe && selectedSubRecipe?.pdf_link) {
              const pdfLink = selectedSubRecipe?.pdf_link;
              setPdf(pdfLink);
              setLoader(false);
              openInNewTab(pdfLink);
            } else {
              setLoader(false);
            }
          } else {
            setLoader(false);
          }
        }
        break;

      case 3:
        navigate('wizard', {
          state: { update: true, id: selectedSubRecipeId },
        });
        break;

      case 4:
        setOpenDeleteDialog(true);

        break;
      default:
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };
  const handleDeleteContinue = async () => {
    setRecipeLoader(true);
    setOpenDeleteDialog(false);
    const response =
      selectedSubRecipeId.length &&
      (await SubRecipeActions.delSubRecipe(selectedSubRecipeId));
    if (response?.success === true) {
      pageData();
      setRecipeLoader(false);
    } else if (response?.success === false) {
      setRecipeLoader(false);
      const recipeNames = response?.error.messages
        .map((error: any) => error.recipe_name)
        .join(', ');
      let responseDialogData = {
        OnClick: () => {},
        type: 'error',
        messageArray: [
          'Error!',
          `This sub recipe is part of recipe name ${recipeNames}`,
        ],
      };
      ResponseDialog(responseDialogData);
    }
  };

  const DropDownmenuProps = {
    items: permissionStatus && permissionStatus === 2 ? items : [],
    onClick: handleDropDownClick,
  };

  const pageData = async () => {
    const brand = await Utils.getCurrentBrand();
    setRecipeLoader(true);
    const SubRecipeData = await SubRecipeActions.getSubRecipes(
      dataLength,
      current,
      currentBrand?.brand_id || brand?.brand_id,
      keyword
    );

    if (SubRecipeData == undefined || !SubRecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(SubRecipeData?.data?.recipe_count);
      setIngredientCount(SubRecipeData?.data?.ingredients_count);
      setTotal(SubRecipeData?.data?.pagination?.total);
      setSubrecipes(SubRecipeData?.data?.items);
      setSortData(SubRecipeData?.data?.items);
    }
    setRecipeLoader(false);
  };

  const pageDataFilter = async (filters: any) => {
    setRecipeLoader(true);
    const subRecipeData = await SubRecipeActions.subRecipesFilters(
      dataLength,
      current,
      filters
    );
    if (subRecipeData == undefined || !subRecipeData?.success) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(subRecipeData?.data?.recipe_count);
      setIngredientCount(subRecipeData?.data?.ingredients_count);
      setTotal(subRecipeData?.data?.pagination?.total);
      setSortData(subRecipeData?.data?.items);
    }

    setRecipeLoader(false);
  };

  const actionButton = (
    <Button
      text="Add New"
      className="theme-button recipeBtn"
      icon={<PlusOutlined />}
      handleButtonClick={() => {
        navigate('wizard', { state: { update: false, id: '' } });
      }}
      shape="round"
      size="middle"
    />
  );

  const onSearch = async (keyword: string) => {
    setSearch(keyword);
    setRecipeLoader(true);
    // if (keyword) {
    //   const results = sortData.filter(
    //     (obj: any) =>
    //       obj.recipe_name.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.menu_category.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.unit.toLowerCase().includes(keyword.toLowerCase()) ||
    //       obj.created_at?.includes(keyword)
    //   );
    //   setSortData(results);
    // } else {
    //   setSortData(subrecipes);
    // }

    const searchResultofRecipes = await SubRecipeActions.searchSubRecipe(
      dataLength,
      current,
      keyword
    );
    if (
      searchResultofRecipes === undefined ||
      !searchResultofRecipes?.success
    ) {
      setRecipeCount(0);
      setIngredientCount(0);
      setTotal(0);
      setSortData([]);
    } else {
      setRecipeCount(searchResultofRecipes?.data?.recipe_count);
      setIngredientCount(searchResultofRecipes?.data?.ingredients_count);
      setTotal(searchResultofRecipes?.data?.pagination?.total);
      setSortData(searchResultofRecipes?.data?.items);
    }
    setRecipeLoader(false);
  };

  const handleSubDialogOpen = async (id: any) => {
    const brand = await Utils.getCurrentBrand();

    const details = await SubRecipeActions.getIngredientDetail(
      id,
      brand?.brand_id
    );
    setDialogData(details);
    setOpenDialog(true);
  };

  const handleCSV = async () => {
    const response = await Attributes.exportCSV('SubRecipes');
    const { data } = response;
    window.open(data?.url);
  };

  return (
    <div className="subRecipePage">
      <DeleteConfirmation
        handleDeleteCancel={handleDeleteCancel}
        handleDeleteContinue={handleDeleteContinue}
        open={openDeleteDialog}
        message={
          <>
            Are you sure want to delete recipe?
            <br />
            <i>
              Note: This Delete action will automatically reflect on the
              Integrated platform (e.g. Foodics) as well
            </i>
          </>
        }
      />
      <Dialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleSubDialog={(id: any) => {
          setOpenDialog(false);
          setOpenSubDialog(true);
          setId(id);
        }}
        data={dialogData}
        // printApi={RecipeActions}
        component={'subRecipe'}
      />
      <SubDialog
        open={openSubDialog}
        data={id}
        handleSubDialog={() => {
          setOpenSubDialog(false);
          setOpenDialog(true);
        }}
        component={'subRecipe'}
        handleCloseAll={() => {
          setOpenSubDialog(false);
          setOpenDialog(false);
        }}
      />

      <Breadcrumb
        heading="Sub Recipe"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={actionButton}
        perm_status={permissionStatus}
      />
      <Spin spinning={loader} size="large">
        <Page title="Dashboard">
          <Row className="listingTopFilter">
            <Col lg={12}>
              <ListCount
                count1={recipeCount}
                title1="Total Sub Recipes"
                count2={ingrdientCount}
                title2="Total Ingredients"
              />
            </Col>
            <Col lg={12}>
              <div className="search-wrapper">
                <SearchBox
                  keyword={keyword}
                  setKeyword={setKeyword}
                  action={onSearch}
                  placeholderText="Search..."
                />
                <div className="exportButton" onClick={handleCSV}>
                  <img src={Export} alt="" />
                  <div className="exportText"> Export CSV</div>
                </div>
                {/* <IconSwitcher listViewLayout={design} setListView={setDesign} /> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6} md={8}>
              <Filter
                component="subRecipe"
                action={pageDataFilter}
                apiCall={SubRecipeActions.getSubRecipesFilters}
              />
            </Col>
            <Col lg={18} md={16}>
              {/* {!design && !recipeLoader ? (
              <GridView
                handledata={''}
                DropDownmenuProps={DropDownmenuProps}
                setSelectedMenu={setSelectedMenu}
                dataSource={sortData}
                component={'subRecipe'}
                handleDialog={''}
                handleOpen={(data: any) => {
                  handleingredient(data);
                }}
              />
            ) : (
              !design && (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )
            )} */}

              {/* {design && !recipeLoader ? ( */}
              {!recipeLoader ? (
                <ListView
                  limit={dataLength}
                  component="subRecipe"
                  items={items}
                  dataSource={[...sortData]}
                  columns={columns}
                  handleOpen={(data: any) => {
                    handleSubDialogOpen(data);
                  }}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin spinning={recipeLoader} className="recipeLoader" />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};

export default SubRecipe;
