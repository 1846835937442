import { baseURL } from '../../config/constant';
import Utils from '../../redux/utils';

const getSignUps = async (dataLength) => {
  try {
    const apiOptions = {
      endpoint: `${baseURL}/api/user_signup_request?limit=${dataLength}`,
      headers: {
        Authorization: `Bearer ${Utils.getCurrentToken()}`,
      },
      method: 'GET',
    };
    const apiResponse = await Utils.CallApi(apiOptions);
    return apiResponse.data;
  } catch (error) {
    return error;
  }
};

const SignUpsApi = {
  getSignUps,
};

export default SignUpsApi;
