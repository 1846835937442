import { useLocation, Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

type Props = {
  homepath: string;
  title: string;
};

const BreadCrumb = (props: Props) => {
  const location = useLocation();
  const breadCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split('/').filter((item) => item);

    return (
      <Breadcrumb>
        {pathnames.length > 0 ? (
          <Breadcrumb.Item className="b1 breadcrumb-link">
            <Link to="/app/dashboard">{props.homepath}</Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item className="b1 breadcrumb-link">
            {props.homepath}
          </Breadcrumb.Item>
        )}
        {pathnames.slice(1).map((name, index) => {
          const routeTo = `/app/${pathnames[1]}/`;
          const isLast = index === pathnames.slice(1).length - 1;
          return isLast ? (
            <Breadcrumb.Item key={index} className="b2">
              {props.title}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={index} className="b1 breadcrumb-link">
              <Link to={`${routeTo}`}>{name}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  return <>{breadCrumbView()}</>;
};

export default BreadCrumb;
