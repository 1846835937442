import { Card } from 'antd';
import Utils from '../../redux/utils';

type Props = {
  planCardData: any;
  handleChangePlan: any;
  type: any;
};

const PlanCard = (props: Props) => {
  let { planCardData } = props;
  const user = Utils.getCurrentUser();

  return (
    <div className="PlanCard" id={String(planCardData.id)}>
      <Card>
        <h3 className="title">{planCardData.product.name}</h3>
        <h1 className="package">
          {planCardData.currency + '' + planCardData.amount}
        </h1>
        {planCardData.id == user?.subscription[0]?.stripe_price && (
          <div className="subscribed-tag">Subscribed</div>
        )}
        <p className="description">{planCardData.product.description}</p>
        {/* {type === 'Change_Plan' ? (
          <div
            className="planBtn"
            onClick={() => handleChangePlan(planCardData.id)}
          >
            Get started
          </div>
        ) : (
          <a href={planCardData.payment_link}>
            <div className="planBtn">Get started</div>
          </a>
        )} */}
        <h3 className="featureTitle">Basic includes</h3>
        <ul className="features">
          <li>Connect one calender</li>
          <li>Create one active event type, and schedule unlimited meetings</li>
        </ul>
      </Card>
    </div>
  );
};

export default PlanCard;
