/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  ListViewWithoutExpand,
  MenuBar,
  Page,
  Pagination,
} from '../../components';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useNavigate, useLocation } from 'react-router-dom';
import Export from '../../assets/images/export.png';
import {
  handleFloatForSpecialCase,
  sortNumber,
  sortedData,
} from '../../helper/sortData';
import {
  Col,
  DatePicker,
  MenuProps,
  PaginationProps,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Switch,
  Divider,
  Tooltip,
} from 'antd';
import { useSelector } from 'react-redux';
import ReportsApi from '../../redux/middleware/reports';
import DashBoardActions from '../../redux/middleware/dashboard';
import UsersApi from '../../redux/middleware/users';
import UpDownArrow from '../../assets/images/UpDownArrow.png';
import Utils from '../../redux/utils';
import './reports.scss';
import { checkAuthentication } from '../../helper/checkAuthentication';

interface Brand {
  id: string;
  brand_id?: string;
  brand_name: string;
  has_shops?: {
    id: any;
    shop_name: string;
  }[];
}

interface Branch {
  id: string;
  shop_id?: string;
  shop_name: string;
  has_brands?: { id: any; brand_name: string }[];
}

interface ShopBrands {
  id?: string;
  brand_id?: string;
  shop_id?: string;
  shop_name?: string;
  brand_name?: string;
  user_brand_status?: boolean;
  user_shop_status?: boolean;
}

const Reports = () => {
  const { RangePicker } = DatePicker;
  const user = Utils.getCurrentUser();
  const company = Utils.getCurrentCompany();
  const navigate = useNavigate();
  const location = useLocation();
  const fromComponent = location.state?.from;
  const reportsType = location.state?.selectedFilter;
  const { currentBrand } = useSelector((state: any) => state.Auth);
  const [topBarList, setTopBarList] = useState<any>({});
  const [start_date, setStart_date] = useState<Moment>(
    moment().startOf('month')
  );
  const [end_date, setEnd_date] = useState<Moment>(moment().endOf('month'));
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [shopBrands, setShopBrands] = useState<ShopBrands[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>(['ALL']);
  const [selectedBranches, setSelectedBranches] = useState<string[]>(['ALL']);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [current, setCurrent] = useState(1);
  const [sortData, setSortData] = useState([] as Array<Object>);
  const [ascending, setAscending] = useState(true);
  const [dataLength, setDataLength] = useState(50);
  const [loader, setLoader] = useState(false);
  const [, setCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [tabsValue, setTabsValue] = useState<any>([]);
  const [tabPills, setTabPills] = useState<any[]>([]);
  const [profitData, setProfitData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<any>({});
  const [reportType, setReportType] = useState<any>();
  const [currentBrandId, setCurrentBrandId] = useState(currentBrand?.brand_id);
  const PageDataLength = [6, 10, 20, 50];
  const allBrandIDs =
    user?.roles[0]?.id === 2
      ? brands.map((brand) => brand?.id)
      : company?.brands.map((brand: any) => brand?.brand_id);

  const allBranchIDs =
    user?.roles[0]?.id === 2
      ? branches.map((branch) => branch?.id)
      : company?.shops.map((shop: any) => shop?.shop_id);

  useEffect(() => {
    checkAuthentication(user, navigate);
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      setReportType(location.state?.selectedFilter);
      pageData();
    }
  }, [dataLength, currentBrand]);

  const handleSort = (column: string, type: string) => {
    if (sortData.length < 1) return;

    let arr =
      type == 'string'
        ? sortedData(ascending, sortData, column)
        : sortNumber(ascending, sortData, column);

    setSortData(() => arr);
  };

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const getColumns = (data: any) => {
    const keys = data?.length > 0 ? Object.keys(data[0]) : [];
    return keys.map((key: string) => {
      const formattedTitle = key.replace(/_/g, ' ').toUpperCase();
      return {
        title: () => (
          <div className="tableTitle">
            <span>{formattedTitle}</span>
            <img
              src={UpDownArrow}
              className="titleArrow"
              onClick={() => {
                handleSort(key, typeof data[0][key]);
                setAscending(!ascending);
              }}
            />
          </div>
        ),
        dataIndex: key,
        key: key,
        render: (value: any) => {
          let columnValue = value;
          let tooltipContent = '';
          if (typeof value === 'number' && !Number.isInteger(value)) {
            columnValue = handleFloatForSpecialCase(value);
          }
          const date = new Date(value);
          if (
            !isNaN(date.getTime()) &&
            typeof value === 'string' &&
            value.includes('-')
          ) {
            columnValue = moment(UTCtoLocal(value)).format('DD-MM-YYYY');
            tooltipContent = moment(UTCtoLocal(value)).format(
              'YYYY-MM-DD HH:mm:ss'
            );
          }
          return (
            <div className="col3 cellSpacing list-view-normal-font">
              {tooltipContent ? (
                <Tooltip
                  title={
                    <div>
                      <div className="tooltipHead">Created</div>
                      <div className="tootipText">{tooltipContent}</div>
                    </div>
                  }
                >
                  <div className="subrecipesubCol2 ">{columnValue}</div>
                </Tooltip>
              ) : (
                columnValue
              )}
            </div>
          );
        },
      };
    });
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getBrandBranch = async () => {
    try {
      const response = await UsersApi.getShopHasBrands(
        selectedBrands[0] !== 'ALL' ? selectedBrands : allBrandIDs
      );
      if (response?.success) {
        const { data } = response;
        setShopBrands(data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchCompanyDetails = async () => {
    const response = await DashBoardActions.fetchCompanyDetails();
    if (response?.success) {
      const data = response.data;
      setBrands(data?.brands || []);
      setBranches(data?.shops || []);
    }
  };

  useEffect(() => {
    setSelectedBrands(['ALL']);
    setSelectedBranches(['ALL']);
  }, []);

  useEffect(() => {
    if (user?.roles[0]?.id === 2) {
      fetchCompanyDetails();
    } else {
      setBrands(company?.brands);
      setBranches(company?.shops);
      getBrandBranch();
    }
  }, [isBrandSelected]);

  useEffect(() => {
    if (dataLength && current) {
      handleReportFilter(selectedCategory?.slug);
    }
  }, [current, dataLength]);

  useEffect(() => {
    if (selectedCategory && selectedCategory?.slug) {
      handleReportFilter(selectedCategory?.slug);
    }
  }, [
    selectedCategory,
    selectedBrands,
    selectedBranches,
    start_date,
    end_date,
    isBrandSelected,
  ]);

  useEffect(() => {
    if (reportsType) {
      handleReportFilter(reportsType);
    }
  }, [reportsType]);

  const pageData = async () => {
    setLoader(true);
    setCurrentBrandId(currentBrand?.brand_id);
    const { success, data } = await ReportsApi.getReportTopBar();
    if (success) {
      setTopBarList(data);
      setTabsValue(
        Object.keys(data)?.map((obj: any, index: number) => ({
          key: index.toString(),
          label: capitalizeFirstLetter(obj),
        }))
      );
      if (reportsType) {
        let found = false;
        for (const key of Object.keys(data)) {
          const foundItem = data[key].find(
            (item: any) => item.slug === reportsType
          );
          if (foundItem) {
            setTabPills(data[key]);
            setSelectedCategory(foundItem);
            found = true;
            break;
          }
        }
        if (!found) {
          setTabPills(data[Object.keys(data)[0]]);
          setSelectedCategory(data[Object.keys(data)[0]][0]);
        }
      } else {
        if (!selectedCategory) {
          setTabPills(data[Object.keys(data)[0]]);
          setSelectedCategory(data[Object.keys(data)[0]][0]);
        }
      }
    } else {
      setCount(0);
      setTotal(0);
      setSortData([]);
    }
    setLoader(false);
  };

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });
  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };
  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  function convertHyphensToSpaces(inputString: any) {
    return inputString.replace(/-/g, ' ');
  }

  const handleReportFilter = async (filter: any) => {
    if (
      filter === undefined &&
      selectedCategory?.slug === undefined &&
      reportsType === undefined
    ) {
      return;
    }
    setLoader(true);
    const tag = isBrandSelected ? 'brand' : 'shop';
    const response = await ReportsApi.getReports(
      resolvedBrandIDs,
      resolvedBranchIDs,
      tag,
      filter != '' ? filter : selectedCategory?.slug || reportsType,
      1000,
      'date',
      start_date?.format('YYYY-MM-DD'),
      end_date?.format('YYYY-MM-DD')
    );
    if (response !== undefined && response?.success) {
      if (selectedCategory?.slug !== 'daywise_profitability') {
        if (Array.isArray(response?.data?.items)) {
          setSortData(response?.data?.items);
          setTotal(response?.data?.pagination?.total);
        } else {
          setSortData([]);
        }
      } else {
        const items = response?.data?.items;
        const daywiseprofit = [];
        for (const day in items) {
          // eslint-disable-next-line no-prototype-builtins
          if (items.hasOwnProperty(day)) {
            for (const brand in items[day]) {
              // eslint-disable-next-line no-prototype-builtins
              if (items[day].hasOwnProperty(brand)) {
                let columnName;
                if (tag === 'shop') {
                  columnName = 'branch name';
                } else {
                  columnName = 'brand name';
                }
                daywiseprofit.push({
                  day: day,
                  name: convertHyphensToSpaces(brand),
                  profitability: items[day][brand].profitability,
                });
              }
            }
          }
        }
        if (
          Array.isArray(daywiseprofit) &&
          daywiseprofit.every(
            (item) => typeof item === 'object' && item !== null
          )
        ) {
          setSortData(daywiseprofit);
          setTotal(daywiseprofit?.length);
        } else {
          setSortData([]);
        }
      }
    } else {
      setCount(0);
      setTotal(0);
      setSortData([]);
    }
    setLoader(false);
  };

  const handleToggleChange = (checked: any) => {
    setIsBrandSelected(checked);
    setBranches([]);
    setBrands([]);
  };

  const isAllSelected = (selectedValues: string[], allValues: string[]) =>
    selectedValues.length === allValues.length;

  const handleRangeChange = (
    values: RangeValue<Moment>,
    formatString: [string, string]
  ) => {
    const start = values?.[0] || moment();
    const end = values?.[1] || moment();
    handleDateRangeChange(start, end);
  };

  const renderBrandSelect = () => {
    const getBrandKeyValue = (brand: any) => {
      return user?.roles[0]?.id === 2 ? brand.id : brand.brand_id;
    };
    return (
      <Select
        size="large"
        placeholder="Brand"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            handleBrandChange(allBrandIDs);
          } else {
            handleBrandChange(value);
          }
        }}
        value={
          isAllSelected(selectedBrands, allBrandIDs) ? ['ALL'] : selectedBrands
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Brands
        </Select.Option>
        {brands.map((brand) => (
          <Select.Option
            key={getBrandKeyValue(brand)}
            value={getBrandKeyValue(brand)}
          >
            {brand.brand_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderBranchSelect = () => {
    const getBranchKeyValue = (branch: any) => {
      return user?.roles[0]?.id === 2 ? branch.id : branch.shop_id;
    };
    return (
      <Select
        size="large"
        placeholder="Branch"
        style={{ width: 150, marginRight: '5px' }}
        mode="multiple"
        onChange={(value) => {
          if (value.includes('ALL')) {
            handleBranchChange(allBranchIDs);
          } else {
            handleBranchChange(value);
          }
        }}
        value={
          isAllSelected(selectedBranches, allBranchIDs)
            ? ['ALL']
            : selectedBranches
        }
      >
        <Select.Option key="ALL" value="ALL">
          ALL Branches
        </Select.Option>
        {branches.map((branch: any) => (
          <Select.Option
            key={getBranchKeyValue(branch)}
            value={getBranchKeyValue(branch)}
          >
            {branch.shop_name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const handleDateRangeChange = (start: Moment, end: Moment) => {
    setStart_date(start);
    setEnd_date(end);
  };

  const getBrandIdentifier = (brand: any) => {
    return user?.roles[0]?.id === 2 ? brand.id : brand.brand_id;
  };

  const getShopIdentifier = (shop: any) => {
    return user?.roles[0]?.id === 2 ? shop.id : shop.shop_id;
  };

  const handleBrandChange = (brandIDs: string[]) => {
    setSelectedBrands(brandIDs.includes('ALL') ? ['ALL'] : brandIDs);
    let filteredShops: { id: string; shop_name: string }[] = [];
    if (brandIDs.includes('ALL')) {
      const allBranches = branches.map((branch) => ({
        id: branch.id || '',
        shop_name: branch.shop_name || '',
      }));
      filteredShops = [{ id: 'ALL', shop_name: 'All Shops' }, ...allBranches];
    } else {
      const matchingBrands = brands.filter((brand) =>
        brandIDs.includes(getBrandIdentifier(brand))
      );
      const allShops = matchingBrands.flatMap((brand) =>
        Array.isArray(brand?.has_shops) ? brand.has_shops : []
      );
      const uniqueShopIDs = new Set(allShops.map((shop) => shop.id));
      if (user?.roles[0]?.id === 2) {
        filteredShops = Array.from(uniqueShopIDs).map((shopId) => {
          const shop = allShops.find((s) => s.id === shopId);
          return {
            id: shop?.id || `unknown-${shopId}`,
            shop_name: shop?.shop_name || '',
          };
        });
      } else {
        const uniqueShopIDs = new Set<string>();
        filteredShops = shopBrands
          .map((shopBrand) => {
            const matchingBrand = matchingBrands.find(
              (brand) => brand.brand_id === shopBrand.brand_id
            );
            if (
              matchingBrand &&
              shopBrand.shop_id &&
              shopBrand.user_brand_status === true &&
              shopBrand.user_shop_status === true
            ) {
              if (!uniqueShopIDs.has(shopBrand.shop_id)) {
                uniqueShopIDs.add(shopBrand.shop_id);
                return {
                  id: shopBrand.shop_id,
                  shop_name: shopBrand.shop_name || '',
                };
              }
            }
            return null;
          })
          .filter(
            (shop): shop is { id: string; shop_name: string } => shop !== null
          );
      }
    }
    setBranches(filteredShops);
  };

  const handleBranchChange = (branchIDs: string[]) => {
    setSelectedBranches(branchIDs.includes('ALL') ? ['ALL'] : branchIDs);
    let filteredBrands: Brand[] = [];
    if (branchIDs.includes('ALL')) {
      const allBrands = brands.map((brand) => ({
        id: brand.id,
        brand_name: brand.brand_name,
      }));
      filteredBrands = [{ id: 'ALL', brand_name: 'All Brands' }, ...allBrands];
    } else {
      const matchingBranches = branches.filter((shop) =>
        branchIDs.includes(getShopIdentifier(shop))
      );
      const allBrands = matchingBranches.flatMap((shop) =>
        Array.isArray(shop?.has_brands) ? shop.has_brands : []
      );
      const uniqueBrandIDs = new Set(allBrands.map((brand: any) => brand.id));
      if (user?.roles[0]?.id === 2) {
        filteredBrands = Array.from(uniqueBrandIDs).map((brandId) => {
          const brand = allBrands.find((s: any) => s.id === brandId);
          return {
            id: brand?.id || `unknown-${brandId}`,
            brand_name: brand?.brand_name || '',
          };
        });
      } else {
        const uniqueBrandIDs = new Set<string>();
        filteredBrands = shopBrands
          .map((shopBrand) => {
            const matchingBranch = matchingBranches.find(
              (shop) => shop.shop_id === shopBrand.shop_id
            );
            if (
              matchingBranch &&
              shopBrand.id &&
              shopBrand.user_brand_status === true &&
              shopBrand.user_shop_status === true
            ) {
              if (!uniqueBrandIDs.has(shopBrand.id)) {
                uniqueBrandIDs.add(shopBrand.id);
                return {
                  id: shopBrand.brand_id,
                  brand_name: shopBrand.brand_name || '',
                };
              }
            }
            return null;
          })
          .filter(
            (shop): shop is { id: string; brand_name: string } => shop !== null
          );
      }
      setBrands(filteredBrands);
    }
  };

  const resolvedBrandIDs = selectedBrands.includes('ALL')
    ? allBrandIDs
    : selectedBrands;

  const resolvedBranchIDs = selectedBranches.includes('ALL')
    ? allBranchIDs
    : selectedBranches;

  return (
    <div className="report-container">
      <Breadcrumb
        // handleCSV={handleCSV}
        heading="Report"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/Dashboard');
        }}
        button={null}
        // perm_status={permissionStatus}
      />
      <Page title="Report">
        <Spin spinning={loader} size="large">
          <Row className="dashboard-top-bar reports-bar">
            <Col lg={4} className="reports-toggle">
              <Row className="">
                <label className="reports-toggle-label">
                  {isBrandSelected ? 'By Brand' : 'By Branch'}
                </label>
                <Switch
                  className={`gridIconContainer ${
                    isBrandSelected ? 'yellow' : 'yellow'
                  }`}
                  checkedChildren="Brand"
                  unCheckedChildren="Branch"
                  onChange={handleToggleChange}
                />
              </Row>
            </Col>
            <Col lg={12} className="brand-filter-container report-filter">
              {isBrandSelected ? (
                <>
                  {renderBrandSelect()}
                  {renderBranchSelect()}
                </>
              ) : (
                <>
                  {renderBranchSelect()}
                  {renderBrandSelect()}
                </>
              )}
            </Col>
            <Col lg={6}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                }}
                format="YYYY/MM/DD"
                defaultValue={[start_date, end_date]}
                onChange={handleRangeChange}
                className="reportsDatePicker"
              />
            </Col>
          </Row>
          <Divider className="report-divider" />
          <Row className="report-tabs">
            {tabsValue.length ? (
              <>
                <Col lg={20} md={24}>
                  <Tabs
                    items={tabsValue}
                    onChange={(key: string) => {
                      setTabPills(topBarList[Object?.keys(topBarList)[+key]]);
                      setSelectedCategory(
                        topBarList[Object?.keys(topBarList)[+key]][0]
                      );
                    }}
                  />
                </Col>
                <Col lg={4} md={24}>
                  {/* <div className="search-wrapper export-report-btn">
                    <div className="exportButton" onClick={() => {}}>
                      <img src={Export} alt="" />
                      <div className="exportText"> Export CSV</div>
                    </div>
                  </div> */}
                </Col>
              </>
            ) : null}
            <Col lg={24} md={24}>
              <div className="report-pills-margin">
                <MenuBar
                  component="report"
                  category={selectedCategory?.slug}
                  handleSelectedPill={handleReportFilter}
                  allowMorePills={false}
                  handleSelectedCategory={(data: any) => {
                    setCurrent(1);
                    // console.log(data);
                    setSelectedCategory(data);
                  }}
                  pillRender={currentBrandId}
                  categoryPills={tabPills}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col lg={24} md={24}>
              <ListViewWithoutExpand
                items={tabsValue}
                // Todo: need to fixed this data mapping temp removed for future work
                dataSource={sortData}
                columns={getColumns(sortData)}
                limit={dataLength}
              />
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Spin>
      </Page>
    </div>
  );
};

export default Reports;
