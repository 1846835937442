/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  PaginationProps,
  Spin,
  // Select,
  Space,
  Dropdown,
  Checkbox,
  Input,
  Alert,
} from 'antd';
import type { MenuProps } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Page,
  Pagination,
  Breadcrumb,
  SearchBox,
  ListViewWithoutExpand,
  ResponseDialog,
  Button,
  DetailDialog,
  Loader,
} from '../../components';
// import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import More from '../../assets/images/More.png';
import Eye from '../../assets/images/Eye.svg';
import Edit from '../../assets/images/Edit.png';
import moment from 'moment';
import SupplierApi from '../../redux/middleware/supplier';
import SubRecipeActions from '../../redux/middleware/subRecipe';
import Utils from '../../redux/utils';
import './cashPurchaseWizardDemo.scss';
import { handleFloat } from '../../helper/sortData';
import { checkAuthentication } from '../../helper/checkAuthentication';

const CashPurchaseWizardDemo = () => {
  const navigate = useNavigate();

  const user = Utils.getCurrentUser();
  const [current, setCurrent] = useState(1);
  const [stockCount, setStockCount] = useState([{}]);
  const [ingredientList, setIngredientList] = useState<any[]>([]);
  const [dataLength, setDataLength] = useState(50);
  const [total, setTotal] = useState(0);
  const [totalIngredients, setTotalIngredients] = useState(0);
  const [stockLoader, setStockLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [apiLoader, setApiLoader] = useState(false);
  // const [permissionStatus, setPermissionStatus] = useState(0);
  const [receipt, setReceipt] = useState('');
  const PageDataLength = [6, 10, 20, 50];
  const [loader, setLoader] = useState(false);
  const [ingredients, setIngredients] = useState<any[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState<any>({});
  const [stockCountRecord, setStockCountRecord] = useState<any>({});
  const [requestType, setRequestType] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [indexes, setIndexes] = useState<number[]>([]);
  // eslint-disable-next-line no-unused-vars
  const { currentCompany } = useSelector((state: any) => state.Auth);

  // React.useEffect(() => {
  //   pageData();
  // }, [currentCompany]);
  useEffect(() => {
    // let permissions = user?.permissions;
    // const data = permissions?.filter(
    //   (permission: any) => permission?.permission === 'Stock Count'
    // );
    // setPermissionStatus(data[0]?.perm_status_id);
    // setCurrent(1);
    checkAuthentication(user, navigate);
    fetchStatus();
  }, []);

  useEffect(() => {
    if (dataLength && current) {
      pageData();
    }
  }, [dataLength, current]);

  const item1: MenuProps['items'] = PageDataLength.map((data, index) => {
    return {
      key: `${index}`,
      label: <div className="font dotOption">{data}</div>,
    };
  });

  const handleContinue = async () => {
    window.scrollTo(0, 0);
    const user = await Utils.getCurrentUser();
    const company = await Utils.getCurrentCompany();
    const brand = await Utils.getCurrentBrand();
    const shop = await Utils.getCurrentShop();
    const checkEmptyIngredient = ingredients?.some(
      (data: any) =>
        !data?.quantity || data?.id == -1 || data?.ingredient_name == ''
    );

    if (receipt === '' || !ingredients?.length || checkEmptyIngredient) {
      setError(true);
      return;
    }

    const payload = {
      request_type_id: requestType?.id,
      request_status_id: status?.id,
      company_id: company?.id,
      brand_id: brand?.brand_id,
      shop_id: shop?.shop_id,
      created_by: user?.id,
      receipt_no: receipt,
      total_cost:
        ingredients
          .map((data: any) => +data.unit_cost * (+data?.quantity || 0))
          .reduce((partialSum: any, a: any) => partialSum + a, 0) || 0,
      tag: user?.roles[0]?.name === 'Branch Manager' ? 'shop' : 'brand',
      ingredients: ingredients?.map((data: any) => ({
        ingredient_id: data?.id,
        request_quantity: data?.quantity || 1,
        unit_cost: data?.unit_cost,
        total_cost: +data.unit_cost * +data?.quantity || 0,
      })),
    };

    // let responseDialogData;
    setApiLoader(true);

    try {
      const response = await SupplierApi.createInventorySupply(payload);
      setApiLoader(false);
      if (!response?.success) {
        // setErrorList(response?.error);
        // setErrorStatus(true);
      } else {
        navigate('/app/cashPurchase');
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStatus = async () => {
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');
    if (success && data?.length > 0)
      setRequestType(data?.find((type: any) => type?.slug == 'cash_purchase'));

    const response = await SupplierApi.getSupplyStatus('status');
    if (response?.success && response?.data?.length > 0)
      setStatus(
        response?.data?.find((status: any) => status?.slug == 'pending')
      );
  };

  const handleMenuClick: MenuProps['onClick'] = (e: any) => {
    setDataLength(PageDataLength[+e?.key]);
  };

  const menuProps = {
    items: item1,
    onClick: handleMenuClick,
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    setCurrent(page);
  };

  const unTaggedColumns = [
    {
      title: (_: any, record: any) => (
        <div className="tableTitle">
          <Checkbox
            onChange={(e: any) => onSelectAllIngredients(e, ingredientList)}
            checked={visible}
          />
        </div>
      ),
      dataIndex: 'category',
      key: 'category',
      responsive: ['md'],
      render: (_: any, record: any) => (
        <div className="col3 cellSpacing">
          {' '}
          <Checkbox
            onChange={(e: any) => onChangeIngredients(e, record)}
            checked={
              ingredients?.findIndex(
                (ingredient: any) => ingredient?.id == record?.id
              ) != -1
            }
          />
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>INGREDIENT NAME</span>
        </div>
      ),
      dataIndex: 'ingredient_name',
      key: 'ingredient_name',
      responsive: ['md'],
      render: (data: any, record: any) => {
        return (
          <div className="col1 font ">
            <div>
              <div className="subCol1 titleContainer font">
                <span className="title stockCount-alignment-left">
                  {record?.ingredient_name}
                </span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>QUANTITY</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => {
        console.log(record);

        return (
          <div className="col3 cellSpacing stockCount-storageInput">
            <Input
              placeholder="Input Quantity"
              type="number"
              value={record?.quantity || ''}
              disabled={
                ingredients?.findIndex(
                  (ingredient: any) => ingredient?.id == record?.id
                ) == -1
              }
              onChange={(e) => {
                let ingredientListDuplicate = ingredientList;
                ingredientListDuplicate[index] = {
                  ...record,
                  quantity: +e.target.value,
                };
                let ingredientDuplicate = ingredients;
                ingredientDuplicate[
                  ingredientDuplicate?.findIndex(
                    (value: any) => value?.id === record?.id
                  )
                ] = {
                  ...record,
                  quantity: +e.target.value,
                };
                setIngredients([...ingredientDuplicate]);
                setIngredientList([...ingredientListDuplicate]);
              }}
            />
            <span className="demo-cash-purchase-wizard-unit-spacing">
              {record?.storage_unit}
            </span>
            {/* {record?.current_balance} */}
          </div>
        );
      },
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>UNIT COST</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any, index: number) => (
        <div className="col3 cellSpacing stockCount-storageInput">
          <Input
            placeholder="Unit Cost"
            type="number"
            value={handleFloat(+record?.unit_cost) || ''}
            disabled={
              ingredients?.findIndex(
                (ingredient: any) => ingredient?.id == record?.id
              ) == -1
            }
            onChange={(e) => {
              let ingredientListDuplicate = ingredientList;
              ingredientListDuplicate[index] = {
                ...record,
                unit_cost: +e.target.value,
              };
              let ingredientDuplicate = ingredients;
              ingredientDuplicate[
                ingredientDuplicate?.findIndex(
                  (value: any) => value?.id === record?.id
                )
              ] = {
                ...record,
                unit_cost: +e.target.value,
              };
              setIngredients([...ingredientDuplicate]);
              setIngredientList([...ingredientListDuplicate]);
            }}
          />
          {/* {record?.current_balance} */}
        </div>
      ),
    },
    {
      title: () => (
        <div className="tableTitle">
          <span>TOTAL COST</span>
        </div>
      ),
      dataIndex: 'unit',
      key: 'unit',
      responsive: ['md'],
      render: (data: any, record: any) => (
        <div className="col3 cellSpacing">
          {Math.round(
            Number(+record?.unit_cost * parseFloat(record?.quantity || 0))
          )}
        </div>
      ),
    },
  ];

  const onSelectAllIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setVisible(true);
      setIngredients(data);
    } else {
      setVisible(false);
      setIngredients([]);
    }
  };

  const onChangeIngredients = (e: CheckboxChangeEvent, data: any) => {
    if (e?.target?.checked) {
      setIngredients([...ingredients, data]);
    } else {
      setIngredients(
        ingredients?.filter((ingredient: any) => ingredient?.id != data?.id)
      );
    }
  };

  const pageData = async () => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'cash_purchase');
    const imagesData = await SubRecipeActions.getAllInventory(
      3,
      1,
      1000,
      brand?.brand_id || 0,
      shop?.shop_id || 0
    );

    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setTotalIngredients(imagesData?.data?.pagination?.total);
      setIngredientList(imagesData?.data?.items);
    }
    setStockLoader(false);
  };

  const onSearch = async (keyword: string) => {
    let request_id;
    setStockLoader(true);
    const shop = await Utils.getCurrentShop();
    const brand = await Utils.getCurrentBrand();
    const { success, data } = await SupplierApi.getSupplyStatus('request_type');

    if (success)
      request_id = data?.find((supply: any) => supply?.slug == 'cash_purchase');
    const imagesData = await SubRecipeActions.getSubRecipeInventory(
      3,
      keyword
      // ''
    );
    if (imagesData === undefined || !imagesData?.success) {
      setTotal(0);
      setTotalIngredients(0);
      setStockCount([]);
      setIngredientList([]);
    } else {
      setTotal(imagesData?.data?.pagination?.total);
      setTotalIngredients(imagesData?.data?.pagination?.total);
      setIngredientList(imagesData?.data?.items);
    }

    setStockLoader(false);
  };

  return (
    <div className="cashPurchaseWizardDemo">
      <Breadcrumb
        heading="Add Cash Purchase"
        breadcrumbIcon
        iconAction={() => {
          navigate('/app/cashPurchase');
        }}
        button={() => {}}
        // perm_status={permissionStatus}
      />

      <Spin spinning={loader} size="large">
        <Page title="Add Cash Purchase">
          {/* <div className="stockCountHeading">
          <p className="heading"> Supplier Name:</p>
          <h1 className="name">{supplier?.supplier_name}</h1>
        </div> */}
          {error && (
            <Alert
              message="Error"
              description="Please complete all required fields to proceed"
              type="error"
              showIcon
              closable
              className="wizardAlert"
              afterClose={() => setError(false)}
            />
          )}
          <Row className="listingTopFilter">
            <Col lg={12}></Col>

            <Col lg={12}>
              <div className="search-wrapper demo-cash-purchase-wizard-head">
                <SearchBox action={onSearch} placeholderText="Search..." />
                <Input
                  placeholder="Enter Receipt No"
                  value={receipt}
                  onChange={(e) => setReceipt(e.target.value)}
                />
                {!!ingredients.length && (
                  <Button
                    text="Submit"
                    className="theme-button recipeBtn"
                    // icon={<PlusOutlined />}
                    handleButtonClick={() => {
                      // SubmitStockCount(false);
                      handleContinue();
                    }}
                    shape="round"
                    size="middle"
                  />
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={12} className="listingTopFilter">
            <Col
              lg={24}
              md={16}
              className="cashPurchaseWizardDemo-list-wrapper"
            >
              {!stockLoader ? (
                <ListViewWithoutExpand
                  limit={dataLength}
                  items={''}
                  dataSource={[...ingredientList]}
                  columns={unTaggedColumns}
                />
              ) : (
                <div className="loader-wrapper">
                  <Spin
                    spinning={stockLoader}
                    className="recipeLoader"
                    size="large"
                  />
                </div>
              )}
              <Pagination
                total={total}
                menuProps={menuProps}
                onChange={onChange}
                current={current}
                dataLength={dataLength >= total ? total : dataLength}
                handleLength={(data: number) => setDataLength(data)}
              />
            </Col>
          </Row>
        </Page>
      </Spin>
    </div>
  );
};
export default CashPurchaseWizardDemo;
