import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = {
  title: string;
  open: boolean;
  handleClose: any;
  data: any;
  component: any;
};

const SettingDialog = (props: Props) => {
  let { open, handleClose, component, data, title } = props;
  const [Info, setInfo] = useState<any>([]);
  const { t } = useTranslation('translation', {
    keyPrefix: 'General',
  });
  useEffect(() => {
    setInfo(data);
  }, [data]);

  const UTCtoLocal = (utcDate: any) => {
    var stillUtc = moment.utc(utcDate).toDate();
    var local = moment(stillUtc).local();
    return local;
  };

  const renderBrandInfo = () => {
    return (
      <Row>
        <Col md={14}>
          <div className="additional font">Brand</div>
          <div className="additional-text">{Info?.name}</div>

          <div className="additional font">Company</div>
          <div className="additional-text">{Info?.company}</div>

          <div className="additional font spacingDiv">Phone</div>
          <div className="additional-text">{Info?.phone}</div>
        </Col>
        <Col md={10}>
          <div className="additional font">Country</div>
          <div className="additional-text">{Info?.country}</div>

          <div className="additional font">Branch Limit</div>
          <div className="additional-text">{Info?.sub_license}</div>

          <div className="additional font spacingDiv">Created</div>
          <div className="additional-text">
            {moment(UTCtoLocal(Info?.created_at)).format('DD-MM-YYYY HH:mm:ss')}
          </div>
        </Col>
      </Row>
    );
  };

  const renderBranchInfo = () => {
    const { t } = useTranslation('translation', {
      keyPrefix: 'branch',
    });
    return (
      <Row>
        <Col md={12}>
          <div className="additional font additional-remove-margin-top ">
            {t('dialog_branch_label')}
          </div>
          <div className="additional-text additional-text-height">
            {Info?.shop_name}
          </div>
          <div className="additional font ">{t('dialog_address_label')}</div>
          <div className="additional-text additional-text-height">
            {Info?.address}
          </div>
        </Col>
        <Col md={12}>
          <div className=" additional font additional-remove-margin-top ">
            {t('dialog_brand_count_label')}
          </div>
          <div className="additional-text additional-text-height">
            {Info?.brand_id?.length}
          </div>

          <div className="additional font">{t('dialog_city_label')}</div>
          <div className="additional-text additional-text-height">
            {Info?.city_name}
          </div>
        </Col>
        <Col md={24}>
          <div className="additional font">{t('dialog_brand_label')}</div>
          <div className="additional-text additional-text-height">
            {Info?.brand_id
              ?.map((data: any) => ` ${data?.brand_name}`)
              ?.toString()}
          </div>
        </Col>
      </Row>
    );
  };

  const renderCompanyInfo = () => {
    return (
      <Row>
        <Col md={14}>
          <div className="additional font">Company</div>
          <div className="additional-text">{Info?.company_name}</div>

          <div className="additional font text-consumption">
            Consumption Method
          </div>
          <div className="additional-text">{Info?.consumption_method}</div>

          <div className="additional font">Country</div>
          <div className="additional-text">{Info?.country}</div>
        </Col>
        <Col md={10}>
          <div className="additional font">Brand Limit</div>
          <div className="additional-text">{Info?.license}</div>
          <div className="additional font">Branch Limit</div>
          <div className="additional-text">{Info?.branch_limit}</div>
          <div className="additional font">Ck Limit</div>
          <div className="additional-text">{Info?.ck_limit}</div>
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={<div className="title font">{t(title)}</div>}
      centered
      open={open}
      onCancel={handleClose}
      width={480}
      footer={null}
      className="SettingWrapper"
    >
      <div>
        {component != 'branch' && (
          <div className="cover-photo">
            <img
              src={Info?.thumbnail}
              alt=""
              width={'100%'}
              height="240px"
              className="image"
            />
          </div>
        )}
        {component === 'brand' && renderBrandInfo()}
        {component === 'branch' && renderBranchInfo()}
        {component === 'company' && renderCompanyInfo()}
      </div>
    </Modal>
  );
};

export default SettingDialog;
