import React from 'react';
import { Button } from '../../components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BrandActions from '../../redux/middleware/brands';
import Success from '../../assets/images/succesResponse.png';
import Error from '../../assets/images/errorResponse.png';
import './styles.scss';
import { Spin } from 'antd';

const Integration = () => {
  const [apiLoader, setApiLoader] = React.useState(false);
  const [isApproved, setIsApproved] = React.useState(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  const navigate = useNavigate();

  const returnBack = () => navigate('/app/brands');

  const updateInfo = async () => {
    if (code && state) {
      try {
        const payload = {
          brand_id: state,
          platform: 'foodics',
          code,
        };

        setApiLoader(true);
        let response = await BrandActions.addBrandIntegrationInfo(payload);
        setApiLoader(false);
        if (response != undefined || response?.success) {
          console.log(response);
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
      } catch (e) {
        console.log(e);
        setIsApproved(false);
      }
    }
  };

  React.useEffect(() => {
    updateInfo();
  }, [searchParams]);

  return (
    <div
      className="integrationContainer"
      style={{ height: window.innerHeight / 1.05 }}
    >
      {apiLoader && (
        <>
          <Spin spinning={apiLoader} className="Loader" size="large" />
          <p>Please wait while Foodics integration is been processed</p>
        </>
      )}
      {!apiLoader && isApproved && (
        <>
          <img src={Success} alt="loading..." />
          <p>Foodics Integration Successful.</p>
        </>
      )}
      {!apiLoader && !isApproved && (
        <>
          <img src={Error} alt="loading..." />
          <p>
            Foodics Integration Failed, Please try again later or contact to
            administrator.
          </p>
        </>
      )}
      <Button
        text="Return to Brands"
        className="theme-button recipeBtn buttonTextSize"
        handleButtonClick={returnBack}
        shape="round"
        size="middle"
        disabled={apiLoader}
      />
    </div>
  );
};

export default Integration;
