/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input, Modal } from 'antd';
import MenuCategoryActions from '../../redux/middleware/menuCategory';
// import MenuCategoryBuilderActions from '../../redux/middleware/menuCategoryBuilder';
// import CategoryActions from '../../redux/middleware/category';

import './style.scss';
import Utils from '../../redux/utils';
type Props = {
  handleDialog: any;
  open: boolean;
  component?: any;
  name?: any;
  modalName?: any;
  parentCategory?: any;
};

const CategoryModal = (props: Props) => {
  const company = Utils.getCurrentCompany();
  const brand = Utils.getCurrentBrand();
  let { handleDialog, open, component, name, modalName, parentCategory } =
    props;
  const [menuCategory, setMenuCategory] = React.useState(name);
  const [errorList, setErrorList] = useState<any>({});
  const [errorStatus, setErrorStatus] = React.useState(false);

  const handleSubmit = async () => {
    const paramForBuilder = {
      company_id: company?.id,
      category_name: menuCategory,
      brand_id: brand?.brand_id,
    };
    const param = {
      company_id: company?.id,
      category_name: menuCategory,
      brand_id: brand?.brand_id,
    };
    const paramForRdSubRecipe = {
      company_id: company?.id,
      sub_category: menuCategory,
      parent_category_id: parentCategory,
      brand_id: brand?.brand_id,
    };
    const paramsForSubRecipe = {
      company_id: company?.id,
      parent_category_id: parentCategory,
      sub_category: menuCategory,
      brand_id: brand?.brand_id,
    };
    let response;
    // const response =
    // component == 'rd_ingredient_category'
    //   ? await IngredientCategoryBuilderActions.addIngredientCategoryBuilder(
    //       paramForRdSubRecipe
    //     )
    //   : component == 'recipebuilder'
    //   ? await MenuCategoryBuilderActions.addMenuCategoryBuilder(
    //       paramForBuilder
    //     )
    //   : component == 'marketlist'
    //   ? await CategoryActions.createIngredientCategory(param)
    //   : await MenuCategoryActions.addMenuCategory(param);
    switch (component) {
      case 'sub_recipe':
        response = await MenuCategoryActions.addSubCategories(
          paramsForSubRecipe
        );
        break;
      case 'rd_ingredient_category':
        response = await MenuCategoryActions.addSubCategories(
          paramForRdSubRecipe
        );
        break;
      case 'recipebuilder':
        response = await MenuCategoryActions.addSubCategories(
          paramForRdSubRecipe
        );
        break;
      case 'marketlist':
        response = await MenuCategoryActions.addSubCategories(
          paramForRdSubRecipe
        );
        break;
      default:
        response = await MenuCategoryActions.addSubCategories(
          paramForRdSubRecipe
        );
    }
    handleFetchCategory(response);
  };
  const handleMenuCategory = (data: string) => {
    setMenuCategory(data);
  };

  const handleFetchCategory = (response: any) => {
    // console.log('response', response);
    if (response?.success) {
      handleDialog(false, response?.data);
    } else {
      setErrorList(response?.error);
      setErrorStatus(true);
    }
  };

  return (
    <div className="CategoryModal">
      <Modal
        title={<div className="title font">Add New Category</div>}
        centered
        open={open}
        onCancel={() => handleDialog(false, {})}
        width={608}
        footer={null}
      >
        <div className="Modal">
          <div className="ingredientCategoryContainerWidth">
            <div className="wizardSubContainer case">
              <div className="CategoryModalwizardLabel">
                {modalName} Category Name <span className="sterik">*</span>
              </div>
              <Input
                placeholder="Enter Menu Category Name"
                size="large"
                value={menuCategory}
                onChange={(e) => {
                  handleMenuCategory(e.target.value);
                }}
                onKeyDown={(event) =>
                  menuCategory != '' && event.key === 'Enter' && handleSubmit()
                }
                required
                className={
                  errorList['sub_category']?.length > 0
                    ? 'dropdownUnitColorRed'
                    : ''
                }
              />
              {errorStatus && (
                <div className="subRecipeErrorMessages">
                  {errorList['sub_category']}
                </div>
              )}
            </div>
          </div>
          <div className="butonGroup">
            <div
              className="wizard-button"
              onClick={() => handleDialog(false, {})}
            >
              Back
            </div>
            <div className="continue wizard-button" onClick={handleSubmit}>
              Submit
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CategoryModal;
