import React, { useEffect } from 'react';
import { focusNextElement } from '../../helper/focusNextElement';

const GlobalKeyHandler: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        focusNextElement();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  return <>{children}</>;
};

export default GlobalKeyHandler;
